import React from "react";

export interface SelectCountryCodeProps {
  value: any;
  onChange: any;
  listData: any;
  valueField: any;
}

const SelectCountryCode: React.SFC<SelectCountryCodeProps> = ({
  listData,
  valueField,
  ...rest
}) => {
  return (
    <select className="form-control" style={{ height: "45px" }} {...rest}>
      {listData.map((val, index) => {
        return (
          <option key={index} value={val[valueField]}>
            {val.dName}
          </option>
        );
      })}
    </select>
  );
};

export default SelectCountryCode;
