import { Checkbox, FormControlLabel } from "@material-ui/core";
import Joi from "joi-browser";
import moment from "moment";
import * as React from "react";
import { Upload, X } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import AuthContainer from "../../components/authContainer";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import TitleBarComp from "../../components/TitleBar";
import { IInvoice } from "../../models/IInvoice";
import commonService from "../../services/commonService";
import globalService from "../../services/globalService";
import userService from "../../services/userService";
import { ApplicationAction } from "../../stores";
import TagInput from "./../../components/TagInput";
import { ApplicationState } from "./../../stores/index";

export interface CreateInvoiceProps {}

export interface CreateInvoiceState {}

class CreateInvoice extends GenericForm {
  state = {
    data: {},
    uploads: [] as any,
    defaultLang: "AR",
    errors: {},
    isModalOpen: false,
    cust: {
      list: [],
      custSearch: "",
      selectedCust: {},
      mobileCode: "965",
    },
    invRefSearch: "",
    invRefList: {
      list: [],
      Search: "",
      selected: {},
      invAmount: "" as any,
    },

    currentCust: "Choose Customer",

    others: {
      invOption: "",
      mobCode: "",
      mobile: "",
      email: "",
    },

    enableMobile: true,
    enableEmail: true,
    isMobRequired: true,
    isEmailRequired: false,

    disableCustRefId: false,
    tagValue: {
      tags: [] as any,
      input: "",
    },

    enableConditionBox: false,
    invItemList: [] as any,

    createItem: {
      itemName: "",
      qty: "1",
      unitPrice: "0",
      totalPrice: "" as any,
      invDate: "" as any,
      localVal: "",
    },
    isInvItemProd: false,
    isInvModalOpen: false,
    tempInvAddedItem: [],
    isSearchExtDB: false,
  };
  excelInput;
  fileInput;
  uploadFile = {
    content: "",
    fileName: "",
    extension: "",
    fName: "InvoiceDoc",
  };
  invOption = [
    { dName: "SMS", id: "SMS" },
    { dName: "EMAIL", id: "EMAIL" },
    { dName: "SMS & EMAIL", id: "SMS & EMAIL" },
    { dName: "LINK", id: "LINK" },
  ];
  schema = {};
  doSubmit() {
    // if (this.state.data["invoiceOpen"] === "CHANGEABLE") {
    //   if (this.state.data["maxAmt"] === 0 || !this.state.data["maxAmt"]) {
    //     toast.error("Please add maximum amount");
    //     return true;
    //   }
    //   if (this.state.data["minAmt"] === 0 || !this.state.data["minAmt"]) {
    //     toast.error("Please add minimum amount");
    //     return true;
    //   }
    //   this.state.data["invoiceOpenInfo"] = {
    //     min: this.state.data["minAmt"],
    //     max: this.state.data["maxAmt"]
    //   };

    // }

    if (
      this.state.data["custMobile"].length !== 8 &&
      this.state.isMobRequired
    ) {
      return false;
    }

    let errors = this.state.errors;
    var emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      !this.state.data["custEmail"].match(emailValidator) &&
      this.state.isEmailRequired
    ) {
      errors["custEmail"] = "Please enter valid email id";
      toast.error("Please enter valid email id");
      this.setState({ errors });
      return false;
    }

    if (
      this.state.data["custMobile"].length !== 8 &&
      this.state.isMobRequired
    ) {
      errors["custMobile"] = "Please enter 8 digit mobile number";
      toast.error("Please enter valid mobile number");
      this.setState({ errors });
      return false;
    }

    if (this.state.cust.selectedCust["fullName"]) {
      this.state.cust.selectedCust["mobileNo"] =
        this.state.data["mobileCode"] + this.state.data["custMobile"];
      this.state.cust.selectedCust["email"] = this.state.data["custEmail"];
      this.state.cust.selectedCust["customerRefId"] = this.state.data[
        "customerRefId"
      ];
      this.state.data["customer"] = this.state.cust.selectedCust;
    } else {
      let obj = {
        fullName: this.state.data["custName"],
        mobileNo: this.state.data["mobileCode"] + this.state.data["custMobile"],
        email: this.state.data["custEmail"],
        customerRefId: this.state.data["customerRefId"],
      };
      this.state.data["customer"] = obj;
    }

    // debugger;
    for (let iim of this.state.invItemList) {
      if (iim["invDate"] !== "" && iim["invDate"] !== null) {
        let invdats = "";
        if (globalService.getLocalDate(iim["invDate"]) !== "Invalid date") {
          invdats = "  [" + moment(iim["invDate"]).format("DD-MM-YYYY") + "]";
        }
        iim["itemName"] = iim["itemName"] + invdats;
      }
    }

    this.state.data["invoiceItemInfo"] = this.state.invItemList;
    this.state.data["invoiceLCVAmt"] = this.state.data["invoiceAmt"];

    let uploadObj: any[] = [];
    for (let up of this.state.uploads) {
      uploadObj.push({
        fileName: up.name,
        filePath: up.file,
        invoiceAttachDocId: 0,
        invoiceId: "",
        pLink: 0,
      });
    }

    this.state.data["invoiceAttachedDocFile"] = uploadObj;

    let invRef = "";
    let tags: any[] = this.state.tagValue.tags;
    if (tags.length > 0) {
      for (let tag of tags) {
        invRef += invRef === "" ? tag : "," + tag;
      }
    }
    this.state.data["customerRef"] = invRef;

    this.addInvoice();
  }

  addInvoice = async () => {
    await userService
      .addInvoice(this.state.data)
      .then(({ data: res }) => {
        if (!res.didError) {
          toast.success(res.message);
          this.props.history.push("/app/inv-list");
        } else {
          let data = this.state.data;
          delete data["customer"];
          this.setState({ data });
        }
      })
      .catch((ex) => {
        // let data = this.state.data;
        // delete data["customer"];
        // console.log(data);
        // this.setState({ data });

        let { errors, data: datas } = this.state;
        const { status, data } = ex.response;
        if (status === 400) {
          toast.error(data.title);
          let keys = Object.keys(data.errors);

          for (let key of keys) {
            if (key !== "invoiceLCVAmt") errors[key] = data.errors[key];
          }
          delete datas["customer"];
          this.setState({ errors, data: datas });
        }
      });
  };

  componentDidMount() {
    this.setupData();
  }
  setupData() {
    let cDate = new Date();
    let newDate = new Date();
    cDate.setDate(newDate.getDate() + 3);
    let data: IInvoice = {
      // customer: {},
      customerUrl: window.location.host + "/payment?invid=",
      invoiceLang: "ENG",
      invoiceAmt: "",
      invoiceAmtValue: "KWD",
      invoiceLCVAmt: "",
      invoiceType: "NORMAL",
      invoiceOption: "SMS",
      customerRef: "",
      custMobile: "",
      custEmail: "",
      custName: "",
      mobileCode: "965",
      invoiceExpiryDate: cDate,
      reminderDays: 0,
      invoiceOpen: "FIXED",
      conditionText: "",
      condition: false,
      note: "",
      minAmt: "",
      maxAmt: "",
      invoiceAttachedDocFile: [],
      customerRefId: "",
    };
    this.setState({ data });
    this.setupSchema();
  }

  setupSchema() {
    let scm = {
      customerUrl: Joi.string().required(),
      invoiceLang: Joi.string().required(),
      invoiceAmt: Joi.number().required(),
      invoiceAmtValue: Joi.string().required(),
      invoiceLCVAmt: Joi.number().allow("", null),
      invoiceType: Joi.string().required(),
      invoiceOption: Joi.string().required(),
      customerRef: Joi.string().allow("", null),
      custMobile: Joi.string().allow("", null),
      // .allow("", null),
      custEmail: Joi.string().email().allow("", null),
      custName: Joi.string().required(),
      mobileCode: Joi.string().allow("", null),

      invoiceExpiryDate: Joi.raw().allow("", null),
      reminderDays: Joi.number().allow("", null),
      invoiceOpen: Joi.string().allow("", null),
      conditionText: Joi.string().allow("", null),
      condition: Joi.boolean().allow("", null),
      note: Joi.string().allow("", null),
      minAmt: Joi.number().allow("", null),
      maxAmt: Joi.number().allow("", null),
      invoiceAttachedDocFile: Joi.array().items().optional(),

      customerRefId: Joi.string().allow("", null),
    };
    this.schema = scm;
  }

  async getCustList(val) {
    if (val && val.length > 2) {
      let search1 = this.state.isSearchExtDB === true ? "&search=1" : "";

      let qryStr = "fullName=" + val + search1;
      await userService.checkCustomer(qryStr).then(({ data: res }) => {
        if (!res.didError) {
          let cust = { ...this.state.cust };
          cust.list = res.model;
          this.setState({ cust });
        }
      });
    } else {
      let cust = { ...this.state.cust };
      cust.list = [];
      this.setState({ cust });
    }
  }

  async getInvList() {
    let { invRefList, invRefSearch } = this.state;
    //{ ...this.state.invRefList };
    const val = invRefSearch;
    if (val && val.length > 1) {
      // if (val && val.length > 5) {
      let qryStr = val.trim();
      await this.props.getInvoiceList(qryStr);
      invRefList.list = this.props.inv.invoiceList;
      this.setState({ invRefList });
    } else {
      invRefList.list = [];
      this.setState({ invRefList });
    }
  }

  handleInvRefInputChange = ({ currentTarget: e }) => {
    const invRefList = { ...this.state.invRefList };
    invRefList[e.name] = e.value;
    if (e.name !== "invAmount") this.setState({ invRefList }, this.getInvList);
    else this.setState({ invRefList });
  };

  handleInvRefsearchInputChange = ({ currentTarget: e }) => {
    // const invRefList = { ...this.state.invRefList };
    let invRefSearch = e.value;
    this.setState({ invRefSearch }, this.getInvList);
  };

  handlePopup = () => {
    let cust = this.state.cust;
    cust.custSearch = "";
    cust.list = [];
    this.setState({ isModalOpen: !this.state.isModalOpen, cust });
  };

  handleInputChange = ({ currentTarget: e }) => {
    const cust = { ...this.state.cust };
    cust[e.name] = e.value;
    this.getCustList(e.value);
    this.setState({ cust });
  };

  handleItemValChange = ({ currentTarget: e }) => {
    const item = { ...this.state.createItem };
    item[e.name] = e.value;
    if (e.name === "qty" && item.unitPrice) {
      item.totalPrice = Number(e.value) * Number(item.unitPrice);
      item.localVal = item.totalPrice;
    }
    if (e.name === "unitPrice" && item.qty) {
      item.totalPrice = Number(e.value) * Number(item.qty);
      item.localVal = item.totalPrice;
    }

    this.setState({ createItem: item });
  };

  handleAddItem = () => {
    let invItemList = this.state.invItemList;
    let createItem = this.state.createItem;

    if (!createItem.itemName || createItem.itemName === "") {
      toast.error("Please enter the product name");
      return true;
    }

    if (!createItem.qty || createItem.qty === "0" || createItem.qty === "") {
      toast.error("Please enter the qty");
      return true;
    }

    if (
      !createItem.unitPrice ||
      createItem.unitPrice === "" ||
      createItem.unitPrice === ""
    ) {
      toast.error("Please enter the unit price");
      return true;
    }

    invItemList.push(this.state.createItem);
    let newCI: any = {};
    newCI.itemName = "";
    newCI.qty = "1";
    newCI.unitPrice = "0";
    newCI.totalPrice = "0.000";
    newCI.localVal = "0.000";

    this.setState({ invItemList, createItem: newCI });

    let invAmt = 0;
    for (let invLst of this.state.invItemList) {
      invAmt = Number(invAmt) + Number(invLst.totalPrice);
    }
    this.state.data["invoiceAmt"] = invAmt;
  };

  selectCust = (da) => {
    let cust = { ...this.state.cust };
    cust.selectedCust = da;
    let { data, errors, isEmailRequired, isMobRequired } = this.state;
    data["custName"] = da.fullName;
    data["custEmail"] = da.email;
    data["custMobile"] =
      da.mobileNo.length > 8 ? da.mobileNo.slice(3) : da.mobileNo;
    data["customerRefId"] = da.customerRefId;

    var emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!da.email.match(emailValidator) && isEmailRequired) {
      errors["custEmail"] = "Please enter valid email id";
    }

    if (data["custMobile"].length !== 8 && isMobRequired) {
      errors["custMobile"] = "Please enter 8 digit mobile number";
    }

    let disableCustRefId = false;
    if (da.customerRefId) {
      disableCustRefId = true;
    } else {
      data["customerRefId"] = "";
    }

    let enableMobile = true,
      enableEmail = true;

    this.setState(
      {
        disableCustRefId,
        cust,
        data,
        errors,
        enableEmail,
        enableMobile,
        isModalOpen: !this.state.isModalOpen,
      },
      this.validate
    );
  };

  selectInvRef = (da) => {
    let { createItem } = this.state;

    createItem.itemName = da["invoiceNumber"];
    createItem.localVal = da["invoiceAmount"];
    createItem.totalPrice = da["invoiceAmount"];
    createItem.unitPrice = da["invoiceAmount"];
    createItem.invDate = moment(da["invoiceDate"]).format("YYYY-MM-DD");
    createItem.qty = "1";

    this.setState({
      createItem,
      isInvModalOpen: !this.state.isInvModalOpen,
    });
  };

  handleUploadClick = (e) => {
    this.fileInput.click();
  };

  handleExcelUpload = () => {
    this.excelInput.click();
  };

  handleExcelSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      this.uploadFile.content = file;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleExcelReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  async _handleExcelReaderLoaded(readerEvt) {
    const fd = new FormData();

    fd.append("formFile", this.uploadFile.content, this.uploadFile.fileName);

    const { data: res } = await commonService.excelUpload(
      fd,
      window.location.host + "/payment?invid="
    );

    if (!res.didError) {
      toast.success(res.message);
      this.props.history.push("/app/inv-list");
    }
  }

  handleFileSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };
  async _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.uploadFile.content = btoa(binaryString);
    const { data: res } = await commonService.fileUpload(this.uploadFile);
    if (!res.didError) {
      let upld = this.state.uploads;
      upld.push({
        file: res.message,
        name: this.uploadFile.fileName,
      });
      this.setState({ uploads: upld });
    } else {
      toast.error("Something problem with uploading image");
    }
  }

  handleDeleteUpload = (val) => {
    let uploads = this.state.uploads;
    uploads = uploads.filter((data) => data !== val);
    this.setState({ uploads });
  };

  deleteItem = (val) => {
    let { invItemList } = this.state.invItemList;
    invItemList = this.state.invItemList.filter((data) => data !== val);
    this.setState({ invItemList });
  };

  addNewCust = () => {
    let cust = { ...this.state.cust };
    cust.selectedCust = {};
    let data = { ...this.state.data };
    data["custName"] = this.state.cust.custSearch;
    data["custEmail"] = "";
    data["custMobile"] = "";
    let enableMobile = true,
      enableEmail = true;
    this.setState({
      cust,
      data,
      enableEmail,
      enableMobile,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  addNewInvItem = () => {
    let { invRefList, createItem, invRefSearch } = this.state;

    if (
      invRefList.invAmount === 0 ||
      invRefList.invAmount === null ||
      invRefList.invAmount === ""
    ) {
      toast.error("Please enter the amount");
      return true;
    }

    createItem.itemName = invRefSearch;
    createItem.localVal = String(invRefList.invAmount);
    createItem.totalPrice = invRefList.invAmount;
    createItem.unitPrice = String(invRefList.invAmount);
    createItem.invDate = "";
    createItem.qty = "1";

    invRefSearch = "";
    invRefList.invAmount = 0;

    this.setState({
      createItem,
      invRefSearch,
      invRefList,
      isInvModalOpen: !this.state.isInvModalOpen,
    });
  };

  handleLang = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data["invoiceLang"] = e.value;
    this.setState({ data });
  };

  handleInvOpen = ({ currentTarget: e }) => {
    let data = this.state.data;
    data["invoiceOpen"] = e.value;
    this.setState({ data });
  };

  handleInvOption = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data[e.name] = e.value;

    // let scms = this.schema;

    // scms["custMobile"] = Joi.number().allow("", null);
    // scms["custEmail"] = Joi.string()
    //   .email()
    //   .allow("", null);

    let isMobRequired = false,
      isEmailRequired = false,
      enableEmail = true,
      enableMobile = true;

    if (e.value === "SMS") {
      isMobRequired = true;
    } else if (e.value === "EMAIL") {
      isEmailRequired = true;
    } else if (e.value === "SMS & EMAIL") {
      isMobRequired = true;
      isEmailRequired = true;
    }

    // if (e.value === "SMS") {
    //   isMobRequired = true;
    //   scms["custMobile"] = Joi.number().required();
    // } else if (e.value === "SMS & EMAIL") {
    //   isMobRequired = true;
    //   isEmailRequired = true;
    //   scms["custMobile"] = Joi.number().required();
    //   scms["custEmail"] = Joi.string()
    //     .email()
    //     .required();
    // } else if (e.value === "EMAIL") {
    //   isEmailRequired = true;
    //   scms["custEmail"] = Joi.string()
    //     .email()
    //     .required();
    // } else {
    //   isMobRequired = true;
    //   isEmailRequired = true;
    //   scms["custMobile"] = Joi.number().required();
    //   scms["custEmail"] = Joi.string()
    //     .email()
    //     .required();
    // }

    // this.schema = scms;
    // this.validate();
    this.setState({
      data,
      isMobRequired,
      isEmailRequired,
      enableEmail,
      enableMobile,
    });
  };

  handleInvOptionOLD = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data[e.name] = e.value;

    let scms = this.schema;

    scms["custMobile"] = Joi.number().allow("", null);
    scms["custEmail"] = Joi.string().email().allow("", null);

    let isMobRequired = false,
      isEmailRequired = false,
      enableEmail = true,
      enableMobile = true;

    if (e.value === "SMS") {
      isMobRequired = true;
      scms["custMobile"] = Joi.number().required();
    } else if (e.value === "SMS & EMAIL") {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string().email().required();
    } else if (e.value === "EMAIL") {
      isEmailRequired = true;
      scms["custEmail"] = Joi.string().email().required();
    } else {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string().email().required();
    }

    // this.schema = scms;
    this.validate();
    this.setState({
      data,
      isMobRequired,
      isEmailRequired,
      enableEmail,
      enableMobile,
    });
  };

  handleCondition = ({ currentTarget: e }) => {
    let data = this.state.data;
    data["condition"] = e.checked;
    this.setState({ enableConditionBox: e.checked, data });
  };

  handleDateChange = (name, val) => {
    let { data } = this.state;
    data[name] = val;
    this.setState({ data });
  };

  onUploadClicked = (val) => {
    debugger;
  };

  handleSelectedInvDate = (e, idx) => {
    // debugger;
    let { invItemList } = this.state;

    // for (let ii of invItemList) {
    invItemList[idx][e.target.name] = e.target.value;
    // }
    this.setState({ invItemList });
  };

  handleAddTag = (e) => {
    const val = e.target.value;
    let tagValue = this.state.tagValue;

    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }
    if ((e.keyCode === 188 || e.key === "Tab" || e.type === "blur") && val) {
      if (
        this.state.tagValue.tags.find(
          (tag: any) => tag.toLowerCase() === val.toLowerCase()
        )
      ) {
        return;
      }
      tagValue.input = "";
      tagValue.tags = [...this.state.tagValue.tags, val];
      this.setState({ tagValue });
    } else if (e.key === "Backspace" && !val) {
      this.handleRemoveTag(tagValue.tags.length - 1);
    } else {
      tagValue.input = e.target.value;
      this.setState({ tagValue });
    }
    // console.log(this.state.errors);
  };
  handleTagInputChange = (e) => {
    let { tagValue } = this.state;
    tagValue.input = e.target.value === "," ? "" : e.target.value;
    this.setState({ tagValue });
  };

  handleRemoveTag = (i) => {
    const newTags = [...this.state.tagValue.tags];
    newTags.splice(i, 1);
    let tagValue = this.state.tagValue;
    tagValue.tags = newTags;
    this.setState({ tagValue });
  };

  handleAddingInvToggle = () => {
    let { invItemList, tempInvAddedItem, isInvItemProd, data } = this.state;
    const locTemp = tempInvAddedItem;
    tempInvAddedItem = [];
    const locCurrentItem = invItemList;
    invItemList = [];
    tempInvAddedItem = locCurrentItem;
    invItemList = locTemp;

    let amout = 0;
    for (let lt of locTemp) {
      amout += lt["totalPrice"];
    }

    data["invoiceAmt"] = amout;

    this.setState({
      isInvItemProd: !isInvItemProd,
      invItemList,
      tempInvAddedItem,
      data,
    });
  };

  handleChooseInvoice = () => {
    let { invRefList, isInvModalOpen, invRefSearch } = this.state;
    invRefList.list = [];
    invRefSearch = "";
    this.setState({
      isInvModalOpen: !isInvModalOpen,
      invRefList,
      invRefSearch,
    });
  };

  render() {
    const {
      isModalOpen,
      cust,
      enableEmail,
      enableMobile,
      data,
      isMobRequired,
      isEmailRequired,
      errors,
      createItem: item,
      invItemList: iList,
      uploads,
      disableCustRefId,
      tagValue,
      isInvItemProd,
      invRefList,
      invRefSearch,
      isInvModalOpen,
      isSearchExtDB,
    } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Create Invoice" />

        <input
          className="d-none"
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept="image/*,.pdf"
          onChange={this.handleFileSelect}
        ></input>
        <input
          className="d-none"
          ref={(excelInput) => (this.excelInput = excelInput)}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={this.handleExcelSelect}
        ></input>
        <ModalComp
          title="Choose Customer"
          isOpen={isModalOpen}
          hideModal={this.handlePopup}
        >
          <div className="modal-content p-2">
            <div className="row">
              <div className="col-10">
                <input
                  type="text"
                  name="custSearch"
                  placeholder="Search by Name, Mobile or Customer ref"
                  value={this.state.cust.custSearch}
                  onChange={this.handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="col-2">
                <Checkbox
                  checked={isSearchExtDB}
                  onChange={(val) => {
                    this.setState({ isSearchExtDB: !isSearchExtDB }, () =>
                      this.getCustList(this.state.cust.custSearch)
                    );
                    // this.getCustList(this.state.cust.custSearch);
                  }}
                  name="addingInvoice"
                />
              </div>
            </div>

            <div
              style={{
                maxHeight: "calc(100vh - 300px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {cust.list.map((da: any, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className="p-2 border my-2 bg-light cursor-pointer"
                        onClick={() => this.selectCust(da)}
                      >
                        <div>
                          {" "}
                          {da.customerRefId ? (
                            <span>{da.customerRefId} | </span>
                          ) : (
                            ""
                          )}{" "}
                          {da.fullName} | {da.mobileNo} | {da.email}
                          <span className="float-right badge badge-danger my-auto">
                            Select
                          </span>
                          {/* <Check
                            className="text-success float-right cursor-pointer"
                            onClick={() => this.selectCust(da)}
                          ></Check> */}
                        </div>
                      </div>
                    </li>
                  );
                })}

                {cust.list.length === 0 && cust.custSearch.length > 2 && (
                  <React.Fragment>
                    {/* <li className="text-center mt-4"> Not found </li> */}
                    {cust.custSearch && (
                      <li className="my-2">
                        <div className="text-right">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.addNewCust}
                          >
                            Add as new customer
                          </button>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </ModalComp>

        <ModalComp
          title="Choose Invoice"
          isOpen={isInvModalOpen}
          hideModal={this.handleChooseInvoice}
        >
          <div className="modal-content p-2">
            <div>
              <input
                type="text"
                name="Search"
                placeholder="Type and search Invoice Ref"
                value={this.state.invRefSearch}
                onChange={this.handleInvRefsearchInputChange}
                className="form-control"
              />
            </div>

            <div
              style={{
                maxHeight: "calc(100vh - 300px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {invRefList.list.map((da: any, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className="p-2 border my-2 bg-light cursor-pointer"
                        onClick={() => this.selectInvRef(da)}
                      >
                        <div>
                          {" "}
                          {da.customerRefId ? (
                            <span>{da.customerRefId} | </span>
                          ) : (
                            ""
                          )}
                          {da.invoiceNumber} | {da.invoiceAmount} |{" "}
                          {da.fullName} | {da.mobileNo}
                          <span className="float-right badge badge-danger my-auto">
                            Select
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}

                {invRefList.list.length === 0 && invRefSearch.length > 2 && (
                  <React.Fragment>
                    {/* <li className="text-center mt-4"> Not found </li> */}
                    {invRefSearch && invRefSearch.length > 2 && (
                      <li className="my-2">
                        <div className="row my-2 py-2 bg-light">
                          <div className="col-6">
                            <input
                              type="text"
                              name="Search"
                              placeholder="Invoice Ref #"
                              value={invRefSearch}
                              disabled={true}
                              // onChange={this.handleInvRefInputChange}
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              name="invAmount"
                              placeholder="Enter amount"
                              value={invRefList.invAmount}
                              onChange={this.handleInvRefInputChange}
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.addNewInvItem}
                          >
                            Add as new invoice
                          </button>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </ModalComp>

        <MainContentComp>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Name <span>*</span>
                  </label>
                  <div
                    className="rounded border bg-light text-dark p-1"
                    onClick={this.handlePopup}
                  >
                    {data["custName"] !== ""
                      ? data["custName"]
                      : "Choose Customer"}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                {/* {this.renderSelect(
                  "invoiceOption",
                  "Send Inovice Option",
                  this.invOption,
                  "id"
                )} */}
                <div className="input-div md-value">
                  <label>Send Inovice Option</label>
                  <select
                    className="input-style"
                    value={this.state.data["invoiceOption"]}
                    name="invoiceOption"
                    onChange={this.handleChange && this.handleInvOption}
                  >
                    {this.invOption.map((inv, idx) => {
                      return (
                        <option key={idx} value={inv.id}>
                          {inv.dName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Mobile{" "}
                    {isMobRequired && <span className="text-danger"> *</span>}
                  </label>

                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td style={{ width: "35%" }}>
                          <select
                            className="input-style"
                            value={this.state.data["mobileCode"]}
                            disabled={!enableMobile}
                            // name="mobileCode"
                            onChange={this.handleChange}
                          >
                            <option value="965">+965</option>
                            {/* <option value="966">+966</option>
                            <option value="973">+973</option>
                            <option value="971">+971</option> */}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="custMobile"
                            value={this.state.data["custMobile"]}
                            // disabled={!enableMobile}
                            onChange={this.handleChange}
                          />
                          {errors["custMobile"] && (
                            <div className="small text-danger">
                              {errors["custMobile"]}
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Email{" "}
                    {isEmailRequired && <span className="text-danger"> *</span>}
                  </label>
                  <input
                    type="text"
                    name="custEmail"
                    value={this.state.data["custEmail"]}
                    disabled={!enableEmail}
                    onChange={this.handleChange}
                  />
                  {errors["custEmail"] && (
                    <div className="small text-danger">
                      {errors["custEmail"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                {/* {this.renderInput("customerRef", "Invoice Reference")} */}

                <div className="form-group">
                  <label>
                    Invoice Ref{" "}
                    <small className="text-secondary mx-1">
                      Add more ref by entering comma(,)
                    </small>{" "}
                  </label>

                  <TagInput
                    addTag={this.handleAddTag}
                    removeTag={this.handleRemoveTag}
                    input={tagValue.input}
                    tagss={tagValue.tags}
                    inputChange={this.handleTagInputChange}
                  ></TagInput>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Invoice Value <span>*</span>
                  </label>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className="input-style"
                            name="invoiceAmtValue"
                            value={this.state.data["invoiceAmtValue"]}
                            onChange={this.handleChange}
                          >
                            <option value="KWD">Kuwait (KD)</option>
                            {/* <option value="SAR">Saudi Arabia (SR)</option>
                            <option value="KWD">Bahrain (BD)</option> */}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-4">
                {this.renderInput(
                  "invoiceLCVAmt",
                  "Invoice Local Currency Value",
                  true,
                  false,
                  "number"
                )}
              </div> */}
              <div className="col-md-3 col-sm-4">
                <label>Language</label>
                <div>
                  <div className="radio">
                    <input
                      id="invoiceLang1"
                      name="radio"
                      type="radio"
                      value="ENG"
                      checked={this.state.data["invoiceLang"] === "ENG"}
                      onChange={(e) => this.handleLang(e)}
                    />
                    <label htmlFor="invoiceLang1" className="radio-label">
                      English
                    </label>
                  </div>

                  <div className="radio">
                    <input
                      id="invoiceLang2"
                      name="radio"
                      type="radio"
                      value="AR"
                      checked={this.state.data["invoiceLang"] === "AR"}
                      onChange={(e) => this.handleLang(e)}
                    />
                    <label htmlFor="invoiceLang2" className="radio-label">
                      Arabic
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                <label>Is Open Invoice?</label>
                <div>
                  <div className="radio">
                    <input
                      id="invoiceOpen2"
                      name="invoiceOpen"
                      type="radio"
                      value="FIXED"
                      onChange={(e) => this.handleInvOpen(e)}
                      checked={this.state.data["invoiceOpen"] === "FIXED"}
                    />
                    <label htmlFor="invoiceOpen2" className="radio-label">
                      Fixed
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      id="invoiceOpen1"
                      name="invoiceOpen"
                      type="radio"
                      value="CHANGEABLE"
                      checked={this.state.data["invoiceOpen"] === "CHANGEABLE"}
                      onChange={(e) => this.handleInvOpen(e)}
                    />
                    <label htmlFor="invoiceOpen1" className="radio-label">
                      Changeable
                    </label>
                  </div>
                </div>
              </div>

              {/* {this.state.data["invoiceOpen"] === "CHANGEABLE" && (
                <div className="col-md-3 col-sm-4">
                  {this.renderInput(
                    "minAmt",
                    "minimum Amount",
                    true,
                    false,
                    "number"
                  )}
                </div>
              )} */}
              {/* {this.state.data["invoiceOpen"] === "CHANGEABLE" && (
                <div className="col-md-3 col-sm-4">
                  {this.renderInput(
                    "maxAmt",
                    "Maximum Amount",
                    true,
                    false,
                    "number"
                  )}
                </div>
              )} */}

              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label md-value">
                  <label>
                    Expire Date <span>*</span>
                  </label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={this.state.data["invoiceExpiryDate"]}
                    format="DD/MM/YYYY hh:mm a"
                    onChange={(value) =>
                      this.handleDateChange("invoiceExpiryDate", value)
                    }
                    // time={false}
                    min={new Date()}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                {this.renderInput(
                  "customerRefId",
                  "Customer Ref",
                  false,
                  disableCustRefId
                )}
              </div>
              <div className="col-md-6  col-sm-8">
                {this.renderTextarea("note", "Comments", 2)}
              </div>

              <div className="col-md-3 col-sm-4">
                <label>Terms And Conditions</label>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="styled-checkbox"
                          id="condition1"
                          type="checkbox"
                          checked={this.state.data["condition"]}
                          onChange={this.handleCondition}
                        />
                        <label htmlFor="condition1"></label>
                      </td>
                      <td>Is Enabled ?</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {this.state["enableConditionBox"] && (
                <div className="col-md-3 col-sm-4">
                  {this.renderTextarea(
                    "conditionText",
                    "Terms And Conditions",
                    2
                  )}
                </div>
              )}

              <div className="col-12 col-md-5 mt-4">
                <span
                  className="btn btn-light text-secondary"
                  style={{ border: "1px dashed #898989" }}
                  onClick={this.handleUploadClick}
                >
                  <span>File Upload</span> <Upload size={22} />
                </span>

                <div>
                  {uploads.map((val, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-light border rounded text-secondary p-1 my-2"
                      >
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="w-75">
                                {" "}
                                <a
                                  href={globalService.IMG_ROOT_INV + val.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {val.name}
                                </a>
                              </td>
                              <td className="text-right">
                                {/* <Eye
                                  className="text-secondary mx-2"
                                  size={22}
                                /> */}
                                <X
                                  className="text-danger mx-2"
                                  onClick={() => this.handleDeleteUpload(val)}
                                  size={22}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
          <br />

          <h4>Invoice Items</h4>

          <div className="row">
            <div className="col-6">
              {" "}
              <p className="d-none">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!isInvItemProd}
                      onChange={this.handleAddingInvToggle}
                      name="addingInvoice"
                    />
                  }
                  label="Adding Invoice"
                />
              </p>
            </div>
            <div className="col text-right">
              <strong className="mx-2">
                Invoice Amount:{" "}
                <span className="text-info h3 bg-light rounded px-1">
                  {data["invoiceAmt"] ? data["invoiceAmt"] : 0} KD
                </span>{" "}
              </strong>
            </div>
          </div>

          <div className="grid-table" style={{ paddingTop: "5px" }}>
            <table>
              <thead>
                <tr>
                  {/* {!isInvItemProd && <th>Invoice #</th>}
                  {isInvItemProd && <th>Product Name</th>} */}
                  <th>Invoice Detail</th>
                  <th>Invoice Date</th>
                  {/* <th>Quantity</th> */}
                  <th>Amount (KD)</th>
                  {/* <th>Amount (KD)</th> */}
                  <th>Invoice Local Currency Value (KD)</th>

                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {iList.map((itm, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="pl-2">{itm.itemName}</td>
                      <td className="pl-2">
                        <input
                          type="date"
                          value={itm.invDate}
                          className="form-control"
                          placeholder="data"
                          name="invDate"
                          onChange={(evt) =>
                            this.handleSelectedInvDate(evt, idx)
                          }
                        />

                        {/* <DateTimePicker
                          defaultValue={new Date()}
                          value={itm.invDate}
                          format="DD/MM/YYYY"
                          disable={true}
                          onChange={value =>
                            this.handleDateChange("invoiceExpiryDate", value)
                          }
                          time={false}
                        /> */}
                        {/* {globalService.getLocalDate(itm.invDate)} */}
                      </td>
                      {/* <td className="pl-2">{itm.qty}</td> */}
                      <td className="pl-2">{itm.unitPrice}</td>

                      {/* <td className="pl-2">{itm.totalPrice}</td> */}
                      <td className="pl-2">{itm.localVal}</td>

                      <td className="text-center">
                        <div className="cursor-pointer">
                          <i
                            className="material-icons text-danger"
                            onClick={() => this.deleteItem(itm)}
                          >
                            delete_outline
                          </i>
                        </div>
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td>
                    {!isInvItemProd && (
                      <div
                        className="rounded border bg-light text-dark p-1"
                        onClick={() =>
                          this.setState({ isInvModalOpen: !isInvModalOpen })
                        }
                      >
                        {item.itemName !== ""
                          ? item.itemName
                          : "Choose Invoice"}
                      </div>
                    )}

                    {isInvItemProd && (
                      <input
                        type="text"
                        name="itemName"
                        placeholder="Name"
                        value={item.itemName}
                        onChange={this.handleItemValChange}
                      />
                    )}
                  </td>
                  <td>
                    <div className="">
                      {/* <DateTimePicker
                        defaultValue={new Date()}
                        value={item.invDate}
                        format="DD/MM/YYYY"
                        onChange={value =>
                          this.handleDateChange("invoiceExpiryDate", value)
                        }
                        time={false}
                      /> */}
                    </div>

                    <input
                      type="date"
                      value={item.invDate}
                      className="form-control"
                      placeholder="data"
                      name="invDate"
                      onChange={this.handleItemValChange}
                    />
                    {/* {globalService.getLocalDate(item.invDate) !== "Invalid date"
                      ? globalService.getLocalDate(item.invDate)
                      : ""} */}
                  </td>

                  {!isInvItemProd && (
                    <td>
                      <input
                        type="number"
                        name="unitPrice"
                        placeholder="Price"
                        value={item.unitPrice}
                        disabled={true}
                        onChange={this.handleItemValChange}
                      />
                    </td>
                  )}
                  {isInvItemProd && (
                    <>
                      <td>
                        <input
                          type="number"
                          name="unitPrice"
                          placeholder="Price"
                          value={item.unitPrice}
                          onChange={this.handleItemValChange}
                        />
                      </td>
                    </>
                  )}
                  {/* <td>{item.totalPrice}</td> */}
                  <td>{item.localVal}</td>

                  <td className="text-center">
                    <button
                      className="normal-btn"
                      onClick={this.handleAddItem}
                      style={{ marginTop: "5px" }}
                    >
                      ADD
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="my-3">
            <Link to="/app/inv-list" className="deactive-btn">
              Back to list
            </Link>
            <AuthContainer action="ADD">
              <button
                className="active-btn"
                disabled={this.validate()}
                type="submit"
                onClick={this.handleSubmit}
              >
                Create
              </button>
            </AuthContainer>
          </div>
          <div className="d-none">
            <hr className="w-75" />
            <div className="text-center text-secondary">
              <h5>OR</h5>
            </div>
            <hr className="w-75" />
          </div>
          <div className="text-center position-relative d-none">
            <span
              className="btn btn-light text-secondary"
              style={{ border: "1px dashed #898989" }}
              onClick={this.handleExcelUpload}
            >
              <span>Bulk Upload</span> <Upload size={22} />
            </span>

            <a
              style={{ position: "absolute", right: 5, top: 5 }}
              href={globalService.BASE_ROOT + "logs/ImportInvoice.xlsx"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Import Format
            </a>
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }
}

// export default CreateInvoice;

const mapActionToProps = {
  getInvoiceList: ApplicationAction.invoice.getInvoiceList,
};

export default connect(
  (state: ApplicationState) => ({
    inv: state.invoice,
    userAuth: state.auth,
  }),
  mapActionToProps
)(CreateInvoice);
