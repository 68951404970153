import React from "react";

export interface SelectCompProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  error: any;
  placeholder: string;
  listData: any;
  valueField: string;
}

const SelectComp: React.SFC<SelectCompProps> = ({
  name,
  label,
  error,
  listData,
  valueField,
  placeholder,
  ...rest
}) => {
  return (
    <div className="form-group">
      <select className="form-control" {...rest} name={name} id={name}>
        <option value="default">{placeholder}</option>
        {listData.map((value, index) => {
          return (
            <option key={index} value={value[valueField]}>
              {value.Name}
            </option>
          );
        })}
      </select>
      {error && <div className="small text-danger">{error}</div>}
    </div>
  );
};

export default SelectComp;
