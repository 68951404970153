import { AxiosRequestConfig } from "axios";
import { AppConstants } from './../common/appConfig';
import authService from "./authService";
import http from "./httpService";

const USER_API = AppConstants.API_ROOT + "users/";
const ROLE_API = AppConstants.API_ROOT + "role/";
const MENU_ACCESS_API = AppConstants.API_ROOT + "access/";
const INV_API = AppConstants.API_ROOT + "invoice/"
const CUST_API = AppConstants.API_ROOT + "Customer/"


// Check user
export function checkUser(userName: string) {
    return http.post(USER_API + "checkuser", { UserName: userName });
}

// Login 
export function login(user: any) {
    return http.post(USER_API + "login", user);
}

// Register
export function register(user: any) {
    return http.post(USER_API + "register", user);
}

// Logout
export function logout(refreshToken) {
    return http.delete(USER_API + "logout");
}

// Forget Password
export function forgetPassword(userName) {
    return http.put(USER_API + "forgetPassword", { userName: userName, loginId: '' });
}

// Change Password
export function changePassword(body) {// {"oldPassword": "string","newPassword": "string"}
    return http.put(USER_API + "changePassword", body);
}
export function verifyUser(id) {
    return http.get(USER_API + "verifyUsers?Id=" + id);
}
export function verifySubUser(body) {
    return http.post(USER_API + "VerifySubUsers", body);
}
export function emailVerification(body) {// {"userName": "string","loginId": "string"}
    return http.post(USER_API + "emailVerification", body);
}
export function verifyOTP(body) {
    return http.post(USER_API + "verifyOTP", body);
}

// Refresh Token
export function refreshToken() {
    return http.put(USER_API + "RefreshToken", { refreshToken: authService.getRefreshToken() });
}
export function getCountries(name = '') {
    return http.get(USER_API + 'getCountryList');
}

// Menu
export function getMenuByRole(roleId: string, parentRId = "") {
    return http.post(MENU_ACCESS_API + "getscreens/", {
        RoleId: roleId,
        ParentRoleId: parentRId
    });
}
export function addRole(body: any) {
    return http.post(MENU_ACCESS_API + "screen", body);
}
export function updateRole(body: any) {
    return http.post(MENU_ACCESS_API + "updatescreen", body);
}

// Role
export function getRoleByCompany(compId: string) {
    return http.get(ROLE_API + "company/" + compId);
}

// User under Role 
export function getCompanyUser(query) {
    return http.get(ROLE_API + "getsubuser?" + query);
}



export function getIpAddress() {
    let hdr = {
        headers: {}
    }
    return http.get('https://ip.nf/me.json/', hdr);
}
export function getCurrentVal() {
    let hdr = {
        headers: {
            'publicAPI': true
        }
    }
    return http.get('https://dog.ceo/api/breeds/image/random', hdr);
    // return http.get('https://free.currconv.com/api/v7/convert?q=KWD_INR&compact=ultra&apiKey=675b03e80000b6651727', hdr);
}

// Invoice
export function checkCustomer(qryStr) {
    let config: AxiosRequestConfig = { params: { hl: true } };
    return http.get(INV_API + "checkCustomer?" + qryStr, config);
}
export function addInvoice(body) {
    return http.post(INV_API + 'addInvoice', body);
}

export function cancelInvoice(invId) {
    return http.put(INV_API + 'cancelInvoice', { invoiceID: invId, invoicePLinkId: '' })
}
export function getCustInvoice(invId) {
    return http.get(INV_API + 'getcustomerInvoice?invoiceId=' + invId);
}

// OLD
export function custInvoice(qryStr) {
    return http.get(INV_API + 'customerInvoice?' + qryStr);
}

export function custInvoiceListNew(qryStr) {
    return http.get(INV_API + 'customerInvoiceList?' + qryStr);
}
export function custPaidInvList(qryStr) {
    return http.get(INV_API + 'customerPaidInvoice?' + qryStr);
}
export function getPaylinkList(qryString) {
    return http.get(INV_API + 'CustomerInvoicePayLink?' + qryString);
}

export function createCustInvoicePLink(body) {
    return http.post(INV_API + 'CustomerAddInvoicePLink', body);
}


export function getInvoiceDetail(invId) {
    return http.get(INV_API + 'InvoiceById?invoiceId=' + invId);
}

export function getInvoiceQuickDetail(invId) {
    return http.get(INV_API + 'InvoiceShortById?invoiceId=' + invId);
}
export function AddInvoicePayLink(body) {
    return http.post(INV_API + 'AddInvoicePLink', body);
}

export function cancelInvPayLink(payLinkId) {
    return http.put(INV_API + 'cancelInvoice', { invoiceID: '', invoicePLinkId: payLinkId })
}

export function getClientList() {
    return http.get(USER_API + 'GetClientListDropDwn');
}

// Customer 

export function customerAddUpdate(body) {
    return http.put(CUST_API + 'UpdateAddCustomer', body)
}


export function getLoginSession() {
    return http.get(USER_API + 'GetLoginSession');
}


// Deposit

export function createInvDeposit(body) {
    return http.put(INV_API + 'CustomerInvoiceDeposit', body);
}

export function getDepositList(qryStr) {
    return http.get(INV_API + 'GetInvoiceDeposit?' + qryStr);
}

export function getInvByDeposit(depositId) {
    return http.get(INV_API + 'GetInvoiceByDepositId?depositId=' + depositId);
}


export default {
    login, register, logout, forgetPassword, changePassword, refreshToken, verifyUser, verifySubUser, emailVerification,
    verifyOTP, getCountries,
    checkUser, getRoleByCompany, getMenuByRole,
    addRole, updateRole, getCompanyUser,
    checkCustomer, addInvoice, getCustInvoice, custInvoice, custInvoiceListNew, custPaidInvList, getInvoiceDetail, getInvoiceQuickDetail, cancelInvoice,
    getIpAddress, getCurrentVal, getClientList, getPaylinkList, AddInvoicePayLink, cancelInvPayLink,
    customerAddUpdate, createCustInvoicePLink, getLoginSession,
    createInvDeposit, getDepositList, getInvByDeposit
};