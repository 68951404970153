import { AppThunkAction } from ".";
// import { connect } from "react-redux";
import http from "../services/httpService";
import globalService from "../services/globalService";
import { toast } from 'react-toastify';



const BRANCE_API = globalService.API_ROOT + "role/";
export interface BranchState {
    branchList: any[];
    branchDetail: {};
    isRespSuccess: boolean;
}

//Actions
interface GET_BRANCH_LIST {
    type: "GET_BRANCH_LIST";
    branchList: any[];
}

interface GET_BRANCH_DETAIL {
    type: "GET_BRANCH_DETAIL";
    branchDetail: {}
}

interface ADD_UPDATE_BRANCH {
    type: "ADD_UPDATE_BRANCH";
    isRespSuccess: false;
}


export type BranchActions = GET_BRANCH_LIST | GET_BRANCH_DETAIL | ADD_UPDATE_BRANCH;


export const actionCreators = {

    getBranchList: (): AppThunkAction<BranchActions> => async (
        dispatch,
        getState) => {
        let bl: any[] = [];
        await http.get(BRANCE_API + 'getBranchList').then(({ data: res }) => {
            if (!res.didError) {
                bl = res.model;
            } else {
                bl = [];
            }
        })
        dispatch({ type: "GET_BRANCH_LIST", branchList: bl });
    },

    addUpdate: (body): AppThunkAction<BranchActions> => async (
        dispatch,
        getState) => {
        let apiSuccess = false;
        await http.post(BRANCE_API + 'addUpdateBranch', body).then(({ data: res }) => {
            apiSuccess = !res.didError;
            if (!res.didError) {
                toast.success(res.message);
            } else {
                toast.error(res.errorMessage);
            }
        })
        dispatch({ type: "ADD_UPDATE_BRANCH", isRespSuccess: apiSuccess });
    },
};


const unloadedState: BranchState = {
    branchDetail: {},
    branchList: [] as any,
    isRespSuccess: false,
};

export function reducer(
    state: BranchState,
    action: BranchActions
): BranchState {
    switch (action.type) {
        case "GET_BRANCH_LIST":
            return {
                ...state, branchList: action.branchList
            };
        case "ADD_UPDATE_BRANCH":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };

    }
    return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
