import * as React from "react";

export interface AuthContainerProps {
  children;
  action: AccessType;
  url?;
}

function getCurrentPage(menulist: any[], url) {
  let curMenu: any = null;
  if (menulist.length > 0) {
    for (const menu of menulist) {
      if (menu.navi === url) {
        // console.log(
        //   menu.navi +
        //     " < menu navi []  location path >   " +
        //     window.location.pathname
        // );
        curMenu = menu;
        return curMenu;
      } else if (menu.child) {
        if (menu.child.length > 0) {
          curMenu = getCurrentPage(menu.child, url);
          if (curMenu) return curMenu;
        }
      } else if (menu.usr_ChildMenus) {
        if (menu.usr_ChildMenus.length > 0) {
          curMenu = getCurrentPage(menu.usr_ChildMenus, url);
          if (curMenu) return curMenu;
        }
      }
    }
  }
  return curMenu;
}
export type AccessType = "ADD" | "EDIT" | "DELETE";
export function canDo(accessType: AccessType, curURL): boolean {
  let menu = [] as any;
  try {
    const data = JSON.parse(localStorage.getItem("user_auth") as any);
    if (data.menu) {
      menu = getCurrentPage(
        data.menu,
        curURL ? curURL : window.location.pathname
      );
    }

    // Read can only view the pages.
    // Write can Add/Edit
    // Full can do all (Add/Edit/Delete)

    // full means always true
    // read means always false
    // write means not delete, true

    if (menu) {
      // console.log(menu);
      // console.log(accessType + "  access tyopess");
      if (menu.read === true) {
        return false;
      } else if (menu.full === true) {
        return true;
      } else if (accessType !== "DELETE" && menu.write === true) {
        return true;
      } else {
        return false;
      }

      //   if (accessType === "ADD" && menu.write) {
      //     return true;
      //   }
      //   if (accessType === "EDIT" && menu.write) {
      //     return true;
      //   }
      //   if (menu.full) {
      //     return true;
      //   }
    }
    return false;
  } catch (error) {
    return false;
  }
}

const AuthContainer: React.SFC<AuthContainerProps> = ({
  children,
  action,
  url = null,
}) => {
  const [isVisible] = React.useState(canDo(action, url));

  return <React.Fragment>{isVisible && <>{children}</>}</React.Fragment>;
};

export default AuthContainer;
