import * as React from "react";
import { Route } from "react-router-dom";
import { AdminRoutes } from "./../admin-router";

import { createBrowserHistory } from "history";

export interface AdminMainProps {}

export interface AdminMainState {}

const history = createBrowserHistory();

class AdminMain extends React.Component<AdminMainProps, AdminMainState> {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div id="content-part" className="main-contain main-contain-expended">
          <Route history={history} children={AdminRoutes} />
        </div>
      </React.Fragment>
    );
  }
}

export default AdminMain;
