const USER_AUTH = "user_auth";

export function getUserToken() {
    try {
        const user = JSON.parse(localStorage.getItem(USER_AUTH) as any);
        return user.sessionInfo.authToken;
    } catch (error) {
        return null;
    }
}
export function getRefreshToken() {
    try {
        const user = JSON.parse(localStorage.getItem(USER_AUTH) as any);
        return user.sessionInfo.refreshToken;
    } catch (error) {
        return null;
    }
}
export function getUserAuth() {
    try {
        const user = JSON.parse(localStorage.getItem(USER_AUTH) as any);
        return user;
    } catch (error) {
        return null;
    }
}

export function checkUserType(type): Boolean {
    try {
        const user = JSON.parse(localStorage.getItem(USER_AUTH) as any);
        if (user.sessionInfo.userType === type) return true;
    } catch (error) {
        return false;
    }
    return false;
}

export function checkCompanyActive(): Boolean {
    try {
        const user = JSON.parse(localStorage.getItem(USER_AUTH) as any);
        if (user.model.userType === 'SPAdmin') {
            return true;
        }
        if (user.model.isApproved === "True") {
            return true;
        }


    } catch (error) {
        return false;
    }
    return false;
}


export function setUserAuth(auth) {
    localStorage.setItem(USER_AUTH, JSON.stringify(auth));
}

export function setRefreshTokenAuth(sessionInfo) {
    let user = getUserAuth();
    localStorage.removeItem(USER_AUTH);
    user.sessionInfo = sessionInfo;
    localStorage.setItem(USER_AUTH, JSON.stringify(user));
}

export function logout() {
    localStorage.removeItem(USER_AUTH);
}

export default {
    setUserAuth,
    getUserToken,
    getRefreshToken,
    setRefreshTokenAuth,
    getUserAuth,
    logout,
    checkUserType, checkCompanyActive
};
