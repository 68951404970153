import Joi from "joi-browser";
import publicIp from "public-ip";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import loginMailIcon from "../../assets/img/login-icon-mail.png";
import loginPasswordIcon from "../../assets/img/login-icon-password.png";
import loginUserIcon from "../../assets/img/login-icon-user.png";
import logo from "../../assets/img/logo_white.png";
import AuthFormComp from "../../components/AuthForm";
import DevIndicator from "../../components/DevEnvironmentIndicator";
import userService from "../../services/userService";
import ModalComp from "./../../components/Modal";
import { ApplicationAction, ApplicationState } from "./../../stores/index";

export interface LoginProps {}

export interface LoginState {}

class Login extends AuthFormComp {
  state = {
    data: {
      userName: "",
      password: "",
      sessionLoginInfo: "",
    },
    errors: { userName: "", password: "" },
    isModalOpen: false,
    forgetEmail: "",
  };

  schema = {
    userName: Joi.string().required().email().label("Username"),
    password: Joi.string().required().min(6).label("Password"),
    sessionLoginInfo: Joi.string().allow("", null),
  };
  doSubmit = () => {
    this.onLogin();
  };

  async getIpAddress() {
    const ips = await publicIp.v4();
    this.setState({ sessionLoginInfo: ips });
  }

  getCurrency() {
    // const some = userService.getCurrentVal();
    // debugger;
  }

  async getAuth() {
    // this.props.test();

    await this.props.checkAuth();
    if (this.props.auth.isLoggedIn) {
      // this.props.history.goBack();
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    this.getAuth();
    this.getIpAddress();

    this.getCurrency();
  }

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }

  toggleForgetModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleForwardRoute = () => {
    const menus = this.props.auth.menus;

    if (menus.length > 0) {
      if (menus[0].child.length > 0) {
        return menus[0].child[0]["navi"];
      } else {
        return menus[0]["navi"];
      }
    } else {
      return "/app/no-access";
    }
  };

  onLogin = async () => {
    await this.props.authLogin(this.state.data);

    if (this.props.auth.isRespSuccess) {
      if (this.props.auth.userDetail.roleId) {
        // this.props.getMenus(this.props.auth.userDetail.roleId);
      }

      let path = this.handleForwardRoute();

      // globalService.getPreviousPath() === "/"
      //     ? "/app"
      //     : globalService.getPreviousPath();

      return this.props.history.push(path);
    }
  };

  onForgetPass = async () => {
    const { data: resp } = await userService.forgetPassword(
      this.state.data.userName
    );
    if (!resp.didError) {
      toast.success(resp.message);
      this.setState({ isModalOpen: false });
    } else {
      toast.error(resp.errorMessage);
    }
  };

  handleInputChange = ({ currentTarget: e }) => {
    const data = { ...this.state.data };
    data[e.name] = e.value;
    this.setState({ data });
  };

  onSendForgetPass = () => {
    const { userName } = this.state.data;

    const option = {
      abortEarly: false,
    };
    const { error } = Joi.validate(userName, this.schema.userName, option);
    if (error) {
      toast.error(error.details[0].message);
      return true;
    }

    this.onForgetPass();
  };

  renderPage() {
    const { isModalOpen, data } = this.state;
    return (
      <React.Fragment>
        <ModalComp
          isOpen={isModalOpen}
          hideModal={this.toggleForgetModal}
          // size="sm"
          title="Forget Password"
        >
          <div className="modal-body">
            <p>
              Forget your password! Enter your account email id to receive a
              reset link to reset your password.
            </p>
            <br />
            <div className="login-input">
              <img src={loginMailIcon} alt="" />
              <input
                type="text"
                name="userName"
                value={data.userName}
                placeholder="Enter your mail id"
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          {/* <!-- Modal footer --> */}
          <div className="modal-footer">
            <button
              type="button"
              className="active-btn"
              onClick={this.onSendForgetPass}
            >
              Send
            </button>
          </div>
        </ModalComp>

        <div className="login-bg">
          {/* <div className="d-flex justify-content-center align-align-items-center">
            <div className="container">dfafdf</div>
          </div> */}

          <div
            className="text-center"
            style={{
              position: "absolute",
              left: 0,
              top: "50%",
              width: "100%",
              transform: "translateY(-50%)",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12 mx-auto">
                  <div className="login-conent-div11 bg-white rounded">
                    <div className="text-center bg-app-gradient py-2 border-bottom rounded-top pb-4">
                      <Link to="/">
                        <img src={logo} className="login-icon mx-auto" alt="" />
                      </Link>
                    </div>
                    <p className="text-center font-weight-bold my-4 h5 text-secondary">
                      Sign In <DevIndicator />
                    </p>
                    <div>
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        {this.renderInput(
                          "userName",
                          "UserName",
                          loginUserIcon
                        )}
                        {this.renderInput(
                          "password",
                          "Password",
                          loginPasswordIcon,
                          "password"
                        )}
                        <div style={{ marginBottom: 15 }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-1"
                                    type="checkbox"
                                    value="value1"
                                  />
                                  <label htmlFor="styled-checkbox-1" />
                                </td>
                                <td>Remember</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="text-center my-3">
                          {this.renderButton("Login")}
                        </div>
                      </form>

                      <div className="bg-app-gradient rounded-bottom border-top py-2">
                        <table className="width-100">
                          <tbody>
                            <tr>
                              <td>
                                <Link to="register" className="text-white">
                                  Sign Up
                                </Link>
                              </td>
                              <td className="text-right">
                                <span
                                  className="text-white cursor-pointer pr-2"
                                  onClick={this.toggleForgetModal}
                                >
                                  Forgot Password
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => ({
//   userAuth: state.userAuth
// });

const mapActionToProps = {
  checkAuth: ApplicationAction.auth.checkAuth,
  authLogin: ApplicationAction.auth.login,
  getMenus: ApplicationAction.access.getRoleMenus,
};
// export default connect(mapStateToProps, mapActionToProps)(Login);

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
  }),
  mapActionToProps
)(Login);
