import * as React from "react";
import { TextField } from "@material-ui/core";

export interface MTextAreaProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  placeholder: string;
}

const MTextArea: React.SFC<MTextAreaProps> = ({
  name,
  label,
  placeholder,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      name={name}
      id={name}
      multiline
      rowsMax={4}
      rows={4}
      className="w-100"
    />
  );
};

export default MTextArea;
