import * as React from "react";
import { AppConstants } from './../common/appConfig';

export interface DevIndicatorProps { }

const DevIndicator: React.SFC<DevIndicatorProps> = () => {
  return (
    <div>
      {AppConstants.ENV !== "PRODUCTION" ? (
        <span className="animated fadeIn infinite badge badge-danger">
          {AppConstants.ENV}
        </span>
      ) : (
          ""
        )}
    </div>
  );
};

export default DevIndicator;
