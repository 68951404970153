import * as React from "react";
import globalService from "../services/globalService";

export interface SocialMediaSetProps {
  mediaList;
}

const SocialMediaSet: React.SFC<SocialMediaSetProps> = ({ mediaList }) => {
  return (
    <React.Fragment>
      <span className="mediaList">
        {mediaList.map((val, idx) => {
          return (
            <a
              key={idx}
              className="mx-1"
              href={globalService.linkCheck(val.accountUrl)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={val.logo}></i>
            </a>
          );
        })}
      </span>
    </React.Fragment>
  );
};

export default SocialMediaSet;
