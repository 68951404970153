import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
import TabViewComp from "../../components/TabView";

export interface CustomizeTempProps {}

export interface CustomizeTempState {}

class CustomizeTemp extends React.Component<
  CustomizeTempProps,
  CustomizeTempState
> {
  state = {
    tabs: ["Default Template", "Custom Template"],
    currentTab: "Default Template",
  };
  handleCurrentTab = (item) => {
    this.setState({ currentTab: item });
    this.getTabContent();
  };
  getTabContent = () => {
    const { tabs, currentTab } = this.state;
    if (currentTab === tabs[0]) {
      return this.renderDefaultTemp();
    } else if (currentTab === tabs[1]) {
      return this.renderCustomTemp();
    }
  };
  render() {
    const { tabs, currentTab } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Customize Templates" />
        <MainContentComp>
          <TabViewComp
            tabs={tabs}
            currentTab={currentTab}
            onClickTab={this.handleCurrentTab}
          >
            {this.getTabContent()}
          </TabViewComp>
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderDefaultTemp() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="gray-bg">
              <img
                src="img/default-image.jpg"
                className="template-img"
                alt=""
              />
              <div className="align-center">
                <p className="header">Zakil Payment</p>
                <p>https://kw.PrincessQ8-payment.com/z/PrincessQ8</p>
                <p>62455799</p>
                <br />
              </div>
              <div>
                <span>Visit the site</span>{" "}
                <img src="img/domain.png" className="site-img" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="input-div default-label">
                  <label>Payment Link Reference</label>
                  <p>2019000001</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div default-label">
                  <label>Payment Url Title</label>
                  <p>Test Payment Link</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Customer Name</label>
                  <input
                    type="text"
                    name="text"
                    className="input-style"
                    value="PrincessQ8"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Country Code</label>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="w-100">
                          <select className="input-style">
                            <option>Kuwait</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="text"
                            placeholder="Customer Mobile"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Customer Email</label>
                  <input
                    type="text"
                    name="text"
                    className="input-style"
                    value="info@PrincessQ8.com"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div">
                  <label>Invoice Value</label>
                  <input type="text" name="text" className="input-style" />
                </div>
              </div>
              <div className="col-md-8  col-sm-12">
                <div className="input-div">
                  <label>Comments</label>
                  <textarea className="input-style" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCustomTemp() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="gray-bg">
              <img
                src="img/default-image.jpg"
                className="template-img"
                alt=""
              />
              <div className="align-center">
                <p className="header">Zakil Payment</p>
                <p>https://kw.PrincessQ8-payment.com/z/PrincessQ8</p>
                <p>62455799</p>
                <br />
              </div>
              <div>
                <span>Visit the site</span>{" "}
                <img src="img/domain.png" className="site-img" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="input-div default-label">
                  <label>Payment Link Reference</label>
                  <p>2019000001</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div default-label">
                  <label>Payment Url Title</label>
                  <p>Test Payment Link</p>
                </div>
              </div>
            </div>
            <br />
            <p className="title">Modify your template in English</p>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Customer Name</label>
                  <input
                    type="text"
                    name="text"
                    className="input-style"
                    value="PrincessQ8"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Country Code</label>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="w-100">
                          <select className="input-style">
                            <option>Kuwait</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="text"
                            placeholder="Customer Mobile"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value">
                  <label>Customer Email</label>
                  <input
                    type="text"
                    name="text"
                    className="input-style"
                    value="info@PrincessQ8.com"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div">
                  <label>Invoice Value</label>
                  <input type="text" name="text" className="input-style" />
                </div>
              </div>
              <div className="col-md-8  col-sm-12">
                <div className="input-div">
                  <label>Comments</label>
                  <textarea className="input-style" />
                </div>
              </div>
            </div>
            <br />
            <p className="title">Modify your template in Arabic</p>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="input-div arabic-input-style">
                  <label>اسم العميل</label>
                  <input type="text" name="text" className="input-style" />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div md-value arabic-input-style">
                  <label>جوال العميل</label>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="w-100">
                          <select className="input-style">
                            <option>الكويت</option>
                          </select>
                        </td>
                        <td>
                          <input type="text" name="text" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div arabic-input-style">
                  <label>البريد الالكتروني للعميل</label>
                  <input type="text" name="text" className="input-style" />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="input-div arabic-input-style">
                  <label>قيمه الفاتوره</label>
                  <input type="text" name="text" className="input-style" />
                </div>
              </div>
              <div className="col-md-8  col-sm-12">
                <div className="input-div arabic-input-style">
                  <label>الملاحظات</label>
                  <textarea className="input-style" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomizeTemp;
