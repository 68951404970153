import { Chip } from "@material-ui/core";
import moment from "moment";
import queryString from "query-string";
import * as React from "react";
import { CheckSquare, Filter } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import searchIcon from "../../assets/img/search_icon.png";
import MainContentComp from "../../components/MainContent";
import PaginationComp from "../../components/PaginationComp";
import TabViewComp from "../../components/TabView";
import TitleBarComp from "../../components/TitleBar";
import authService from "../../services/authService";
import globalService from "../../services/globalService";
import userService from "../../services/userService";
import { AppConstants } from './../../common/appConfig';
import CopyToClipboard from "./../../components/CopyToClipboard";
import ModalComp from "./../../components/Modal";

export interface InvoiceListProps {
  history;
}

export interface InvoiceListState { }

class InvoiceList extends React.Component<InvoiceListProps, InvoiceListState> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    if (search) {
      this.state.qryLink = search;
    }
    if (search.customerId) {
      this.state.filter.customerId = search.customerId;
    }
    if (search.invoicePLinkId) {
      this.state.filter.invoicePLinkId = search.invoicePLinkId;
    }
  }
  state = {
    tabs: ["All", "Paid", "Un Paid", "Cancelled"],
    currentTab: "All",
    invList: [],
    quickDetail: {} as any,
    isOpen: false,
    pagination: {
      pageCount: 0,
      pageSize: 25,
      pageNumber: 1,
      itemsCount: 0,
    },
    showInoviceFilter: false,
    clientList: [] as any,
    filter: {
      isPaid: "0",
      loginId: "",
      clientId: "",
      invRef: "",
      customerId: "",
      invoicePLinkId: "",
      expireDate: null as any,
      startDate: null as any,
      endDate: null as any,
    },
    qryLink: {} as any,
    ClientCompany: {} as any,
    isSuperAdmin: authService.checkUserType(AppConstants.UT_SUPER_ADMIN),
  };

  handleCurrentTab = (item) => {
    this.setState({ currentTab: item });
    this.getTabContent();
    this.getInvList(item);
  };
  getTabContent = () => {
    const { tabs, currentTab } = this.state;
    if (currentTab === tabs[0]) {
      return this.renderInv();
    } else if (currentTab === tabs[1]) {
      return this.renderPaidInv();
    } else if (currentTab === tabs[2]) {
      return this.renderUnpaidInv();
    } else if (currentTab === tabs[3]) {
      return this.renderInv();
    }
  };

  componentDidMount() {
    if (this.state.isSuperAdmin) this.setupClientDetail();
    else this.getInvList(this.state.currentTab);
  }

  setupClientDetail() {
    let strComp = sessionStorage.getItem(globalService.SESS_SEL_COMP);
    if (strComp) {
      this.setState({ ClientCompany: JSON.parse(strComp) }, () =>
        this.handleCurrentTab(this.state.currentTab)
      );
    } else {
      this.setState({ ClientCompany: {} }, () =>
        this.getInvList(this.state.currentTab)
      );
    }

    // else {
    //   toast.error("Client Detail not found");
    //   this.props.history.push("/");
    // }
  }

  async getInvList(currentTab) {
    const {
      filter: f,
      pagination: p,
      clientList,
      tabs,
      ClientCompany: cc,
      isSuperAdmin,
    } = this.state;
    let qryStr =
      "orderBy=1&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;

    if (f.loginId && f.loginId !== "") {
      qryStr += "&createdby=" + f.loginId;
    }

    if (f.clientId && f.clientId !== "") {
      qryStr += "&createdby=" + f.clientId;
    }

    if (f.startDate) {
      qryStr += "&startDate=" + moment(f.startDate).format("YYYY-MM-DD");
    }
    if (f.endDate) {
      qryStr += "&endDate=" + moment(f.endDate).format("YYYY-MM-DD");
    }
    if (f.expireDate) {
      qryStr += "&expiryDate=" + moment(f.expireDate).format("YYYY-MM-DD");
    }

    if (currentTab === tabs[1]) {
      qryStr += "&paid=1";
    } else if (currentTab === tabs[0]) {
      if (f.isPaid !== "0") {
        qryStr += "&paid=" + (f.isPaid === "1" ? 1 : 2);
      }
    } else if (currentTab === tabs[2]) {
      qryStr += "&paid=2";
    } else if (currentTab === tabs[3]) {
      qryStr += "&isCancel=1";
    }

    if (f.customerId && f.customerId !== "") {
      qryStr += "&customerId=" + f.customerId;
    }
    if (f.invoicePLinkId && f.invoicePLinkId !== "") {
      qryStr += "&invoicePLinkId=" + f.invoicePLinkId;
    }
    if (f.invRef && f.invRef !== "") {
      qryStr += "&invoiceRefNo=" + f.invRef;
    }

    if (cc.clientCompanyId) {
      qryStr += "&clientCompanyId=" + cc.clientCompanyId;
    }

    await userService.custInvoiceListNew(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let invList = res.model;
        p.itemsCount = res.itemsCount;
        p.pageCount = res.pageCount;
        p.pageSize = res.pageSize;
        p.pageNumber = res.pageNumber;
        this.setState({ invList, paginatoin: p });
        if (clientList.length === 0) {
          this.getClientList();
        }
      }
    });
  }

  async getClientList() {
    await userService.getClientList().then(({ data: res }) => {
      if (!res.didError) {
        let clientList = res.model;
        this.setState({ clientList });
      }
    });
  }

  togglePopup = (inv = null) => {
    if (inv) {
      this.getQuickDetail(inv);
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  async getQuickDetail(inv) {
    await userService
      .getInvoiceQuickDetail(inv.invoiceId)
      .then(({ data: res }) => {
        if (!res.didError) {
          let quickDetail = res.model[0];
          this.setState({ quickDetail });
        }
      });
  }

  async cancelInvoice(invId) {
    await userService.cancelInvoice(invId).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.togglePopup();
        this.getInvList(this.state.currentTab);
      } else {
        toast.error(res.errorMessage);
      }
    });
  }

  render() {
    const { quickDetail: qd, isSuperAdmin, ClientCompany: cc } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Invoice List" />

        <MainContentComp>
          <TabViewComp
            tabs={this.state.tabs}
            onClickTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
          >
            {cc.clientCompanyId && this.renderClientCompany()}
            {this.renderFilter()}
            {this.getTabContent()}
          </TabViewComp>

          {qd.invoiceId && (
            <ModalComp
              title="Quick View"
              isOpen={this.state.isOpen}
              hideModal={() => this.togglePopup(null)}
              size="xl"
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Last Sent Date</label>
                      <p></p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Url</label>
                      <CopyToClipboard>
                        {({ copy }) => (
                          <p
                            className="hideable"
                            onClick={() => copy(qd.customerUrl)}
                          >
                            {qd.customerUrl}
                            <small
                              style={{ fontWeight: 100 }}
                              className="float-right onlyhover cursor-pointer  bg-dark rounded text-white px-1"
                            >
                              COPY
                            </small>
                          </p>
                        )}
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Expire Date</label>
                      <p>
                        {qd.invoiceExpiryDate &&
                          globalService.getLocalDate(qd.invoiceExpiryDate)}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Sent Email Counter</label>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Is Sms Sent Successfully?</label>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 21.37 21.37"
                        >
                          <path
                            id="checked"
                            d="M10.685,0A10.685,10.685,0,1,0,21.37,10.685,10.685,10.685,0,0,0,10.685,0ZM8.725,16.174l-4.7-4.7L5.588,9.9l3.137,3.136L15.782,5.98,17.35,7.549Z"
                            fill="#6ac259"
                          ></path>
                        </svg>
                        Yes
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Sent Sms Counter</label>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Created Date</label>
                      <p>
                        {qd.createdDate &&
                          globalService.getLocalDate(qd.createdDate)}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Views</label>
                      <p>{qd.viewCount}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Type</label>
                      <p>{qd.invoiceType}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="active-btn input-btn">
                    Resend Receipt
                  </button>

                  {!qd.paid && !qd.isCanceled && (
                    <button
                      className="active-btn  input-btn btn-danger bg-danger mx-2"
                      onClick={() => this.cancelInvoice(qd.invoiceId)}
                    >
                      Cancel Invoice
                    </button>
                  )}
                </div>
                <br />
                <table className="width-100 margin-bottom">
                  <tbody>
                    <tr>
                      <td className="title">Invoice Transactions</td>
                      <td className="align-right">
                        <button className="normal-btn">Print</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="grid-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Transaction Start</th>
                        <th>Transaction Status</th>
                        <th>Payment Gateway</th>
                        <th>Payment ID</th>
                        <th>Auth</th>
                        <th>Transaction End</th>
                      </tr>
                    </thead>
                    <tbody>
                      {qd.paymentSessionInfo.map((val, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{val.sessionStartTime}</td>
                            <td>{val.result}</td>
                            <td>{val.gateway}</td>
                            <td>{val.paymentId}</td>
                            <td>{val.auth}</td>
                            <td>
                              {val.sessionClosedtime &&
                                globalService.getLocalDate(
                                  val.sessionClosedtime
                                )}
                            </td>
                          </tr>
                        );
                      })}
                      {qd.paymentSessionInfo.length === 0 && (
                        <tr>
                          <td colSpan={5} className="align-center">
                            <span>
                              <b>Sorry</b> There are no recrods to show!
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <br />
                <div className="align-right">
                  <button
                    className="normal-btn"
                    onClick={() => this.togglePopup(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </ModalComp>
          )}
        </MainContentComp>
      </React.Fragment>
    );
  }

  handlePageChange = ({ currentTarget: e }) => {
    let { pagination } = this.state;
    pagination.pageSize = e.value;
    this.getInvList(this.state.currentTab);
    this.setState({ pagination });
  };

  handlePageClick = (data) => {
    let { pagination } = this.state;
    pagination.pageNumber = data.selected + 1;
    this.setState({ pagination }, () => {
      this.getInvList(this.state.currentTab);
    });
  };

  handleFilterChange = ({ currentTarget: e }) => {
    let filter = this.state.filter;
    filter[e.name] = e.value;
    this.setState({ filter });
  };

  handleDateChange = (name, val) => {
    // debugger;
    let { filter } = this.state;
    filter[name] = val;
    this.setState({ filter });
  };
  handleFilterSearch = () => {
    this.getInvList(this.state.currentTab);
  };

  handleFilterReset = () => {
    let filter = this.state.filter;
    filter.endDate = null;
    filter.startDate = null;
    filter.expireDate = null;
    filter.clientId = "";
    filter.loginId = "";
    this.setState({ filter, ClientCompany: {} }, () => this.getInvList(this.state.currentTab));
  };

  handleEnterKey = ({ currentTarget: e }) => {
    if (e.key === "Enter") this.getInvList(this.state.currentTab);
  };
  renderInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                <th>Commission</th>
                <th>Deposit</th>

                {/* <th>Payment Method</th> */}
                <th>Views</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                          ? inv.customer.customerRefId + " - "
                          : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                          <td className="unpaid text-center">
                            <span
                              className="badge badge-info font-weight-normal"
                              style={{ fontSize: 12 }}
                            >
                              Unpaid
                          </span>
                          </td>
                        ))}

                    <td className="text-center">
                      {" "}
                      {inv.paid && inv.commission}
                    </td>
                    <td className="text-center">
                      {inv.deposit && (
                        <div className="text-center">
                          <div className="text-success">
                            <CheckSquare />
                          </div>
                          <div>
                            <small>
                              {inv.depositDate &&
                                globalService.getLocalDate(inv.depositDate)}
                            </small>
                          </div>
                        </div>
                      )}
                    </td>
                    {/* <td></td> */}
                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                            ""
                          )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderFilter() {
    const { showInoviceFilter, clientList, filter, ClientCompany: cc } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 col-sm-8">
            {/* <button className="active-btn input-btn">
              Export Daily Report
            </button> */}
          </div>
          <div className="col-md-6 col-sm-4 align-right pt-2">
            <span
              className="my-auto font-weight-bold cursor-pointer"
              onClick={() =>
                this.setState({
                  showInoviceFilter: !this.state.showInoviceFilter,
                })
              }
            >
              Advanced Filter <Filter size={24}></Filter>
            </span>
          </div>
        </div>

        <div className={showInoviceFilter ? "d-block" : "d-none"}>
          <div className="gray-bg">
            <div className="row">
              {/* <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Invoice Status</label>
                  <select
                    className="input-style"
                    value={filter.isPaid}
                    name="isPaid"
                    onChange={this.handleFilterChange}
                  >
                    <option value="0">All</option>
                    <option value="1">Paid</option>
                    <option value="2">Unpaid</option>
                  </select>
                </div>
              </div> */}
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Created By</label>
                  <select
                    name="loginId"
                    className="input-style"
                    value={filter.loginId}
                    onChange={this.handleFilterChange}
                  >
                    <option value="">All</option>
                    {clientList.map((val, idx) => {
                      return (
                        <option key={idx} value={val.clientId}>
                          {val.firstName + " " + val.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Expire Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.expireDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("expireDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Start Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.startDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("startDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>End Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.endDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("endDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              {cc.clientCompanyId &&
                <div className="col-md-3 col-sm-4">
                  <div className="my-1">
                    <h5> <div className="badge badge-primary">Selected Client: <strong className="mx-1">{cc.companyName}</strong>  </div></h5>
                  </div>
                </div>}
              <div className="col-12 text-right">
                <button className="grey-btn" onClick={this.handleFilterReset}>
                  Reset
                </button>
                <button
                  className="active-btn"
                  onClick={this.handleFilterSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPaidInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
              autoComplete="off"
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice / Customer Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                <th>Transaction ID</th>
                <th>Commission</th>
                <th>Deposit</th>
                {/* <th>Payment Method</th> */}
                <th>Views</th>

                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                          ? inv.customer.customerRefId + " - "
                          : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                          <td className="unpaid text-center">
                            <span
                              className="badge badge-info font-weight-normal"
                              style={{ fontSize: 12 }}
                            >
                              Unpaid
                          </span>
                          </td>
                        ))}
                    <td>{inv.transId ? inv.transId : ""}</td>
                    <td className="text-center">
                      {" "}
                      {inv.paid && inv.commission}
                    </td>
                    <td className="text-center">
                      {inv.deposit && (
                        <div className="text-center">
                          <div className="text-success">
                            <CheckSquare />
                          </div>
                          <div>
                            <small>
                              {inv.depositDate &&
                                globalService.getLocalDate(inv.depositDate)}
                            </small>
                          </div>
                        </div>
                      )}
                    </td>

                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                            ""
                          )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderUnpaidInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
              autoComplete="off"
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice / Customer Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                {/* <th>Payment Method</th> */}
                <th>Views</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                          ? inv.customer.customerRefId + " - "
                          : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                          <td className="unpaid text-center">
                            <span
                              className="badge badge-info font-weight-normal"
                              style={{ fontSize: 12 }}
                            >
                              Unpaid
                          </span>
                          </td>
                        ))}
                    {/* <td></td> */}
                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                            ""
                          )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }
  renderClientCompany() {
    const { ClientCompany: cc } = this.state;
    return (
      <>
        <div className="row mx-0 border shadow-sm my-2 bg-app-gradient py-2">
          <div className="col-12">
            <label className="text-white">Client Company</label>
            <h3 className="text-white">{cc.companyName}</h3>
          </div>
        </div>
      </>
    );
  }
  renderPopup(isOpen) {
    return <React.Fragment></React.Fragment>;
  }

  displayChip(valueString: string) {
    if (valueString) {
      let list = valueString.split(",");

      return list.map((val, idx) => {
        return <Chip size="small" key={idx} label={val}></Chip>;
      });
    } else {
      return <></>;
    }
  }
}

export default InvoiceList;
