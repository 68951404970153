import React from "react";

export interface GFTextareaProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  rows: any;
  error: any;
  placeholder: string;
  exClass?: boolean;
}

const GFTextarea: React.SFC<GFTextareaProps> = ({
  name,
  label,
  error,
  rows,
  exClass,
  ...rest
}) => {
  return (
    <div className="input-div md-value">
      <label>{label}</label>
      <textarea
        {...rest}
        id={name}
        name={name}
        className="input-style"
        rows={rows}
        style={{ marginTop: 0, marginBottom: 0, height: "31px" }}
      ></textarea>
      {error && <div className="small text-primary">{error}</div>}
    </div>
  );
};

export default GFTextarea;
