import * as React from "react";
import { Component } from "react";
import InputComp from "./Input";
import SelectComp from "./Select";
import TextareaComp from "./Textarea";
import Joi from "joi-browser";

class AuthFormComp extends Component<any> {
  state = { data: {}, errors: {} };
  schema = {};
  doSubmit() {}

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const { error } = Joi.validate(obj, { [name]: this.schema[name] });
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: e }) => {
    const data = { ...this.state.data };
    data[e.name] = e.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e);
    if (errorMessage) errors[e.name] = errorMessage;
    else delete errors[e.name];

    this.setState({ data, errors });
  };

  validate = () => {
    const option = {
      abortEarly: false,
    };
    const { error } = Joi.validate(this.state.data, this.schema, option);
    if (!error) return null;

    const errors: any = {};
    for (let er of error.details) errors[er.path[0]] = er.message;
    return errors;
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    this.doSubmit();
  };

  renderButton(label: string) {
    return (
      <button
        disabled={this.validate()}
        className="Login-butto btn btn-primary"
      >
        {label}
      </button>
    );
  }

  renderInput(name: string, label: string, img: any, type = "text") {
    const { data, errors } = this.state;
    return (
      <InputComp
        type={type}
        value={data[name]}
        name={name}
        img={img}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        placeholder={label}
        exClass={true}
      />
    );
  }

  renderTextarea(name, label, rows = 3) {
    const { data, errors } = this.state;
    return (
      <TextareaComp
        rows={rows}
        value={data[name]}
        name={name}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        placeholder={label}
        exClass={true}
      />
    );
  }

  renderSelect(name, label, listData, valueField) {
    const { data, errors } = this.state;

    return (
      <SelectComp
        onChange={this.handleChange}
        error={errors[name]}
        placeholder={label}
        name={name}
        value={data[name]}
        label={label}
        listData={listData}
        valueField={valueField}
      />
    );
  }
}

export default AuthFormComp;
