import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
// import { Avatar, Fab } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
// import globalService from "../../services/globalService";
import { ApplicationAction } from "../../stores";
import { connect } from "react-redux";
import { ApplicationState } from "./../../stores/index";
import { AccessState } from "./../../stores/accessStore";
import { AuthState } from "./../../stores/authStore";
// import { Edit2 } from "react-feather";
import GenericForm from "./../../components/genericFormComponents/GenericForm";
import Joi from "joi-browser";

export interface LoginProfileProps {
  getUserInfo;
  access: AccessState;
  auth: AuthState;
}

export interface LoginProfileState {}

class LoginProfile extends GenericForm {
  state = {
    data: {
      loginId: "",
      email: "",
      mobile: "",
      firstName: "",
      lastName: "",
      firstNameAr: "",
      lastNameAr: "",
      countryCode: "965"
    },
    errors: {},
    userDetail: {} as any,
    isEdit: false,
    isAdmin: 0
  };
  schema = {
    loginId: Joi.string().allow("", null),
    firstName: Joi.string().required(),
    firstNameAr: Joi.string().allow("", null),
    lastName: Joi.string().required(),
    lastNameAr: Joi.string().allow("", null),
    mobile: Joi.number().allow("", null),
    email: Joi.string().email(),
    countryCode: Joi.string().allow("", null)
  };

  componentDidMount() {
    this.getUserInfo();
  }

  async getUserInfo() {
    const authInfo = this.props.auth.sessionInfo;
    let isAdmin = 0;
    if (authInfo.userType === "SPAdmin") isAdmin = 1;
    await this.props.getUserInfo(isAdmin === 1 ? "" : authInfo.loginId);
    this.setState({ userDetail: this.props.access.userInfo, isAdmin });
  }

  handleEdit = () => {
    let { data, isEdit, userDetail: ui } = this.state;

    data.firstName = ui.firstName;
    data.lastName = ui.lastName;
    data.firstNameAr = ui.firstNameAr;
    data.lastNameAr = ui.lastNameAr;
    data.email = ui.email;
    data.mobile = ui.mobile;
    data.countryCode = ui.countryCode;
    data.loginId = ui.loginId;

    this.setState({ isEdit: !isEdit, data });
  };

  doSubmit = () => {
    this.updateUser();
  };

  async updateUser() {
    const { data, isAdmin } = this.state;
    await this.props.updateUserInfo(data, isAdmin);
    if (this.props.access.isRespSuccess) {
      this.setState({ isEdit: false });
      this.getUserInfo();
    }
  }

  render() {
    const { userDetail: ui, isEdit, isAdmin } = this.state;
    // console.log(isAdmin);
    return (
      <React.Fragment>
        <TitleBarComp title="User Profile"></TitleBarComp>
        <MainContentComp>
          {/* <div className="text-center my-4">
            <Avatar src={globalService.getImg(ui.)}></Avatar>
          </div> */}

          <div className="my-4 position-relative p-4">
            {!isEdit && (
              <div className="row aminated fadeIn">
                <div className="col-6 form-group">
                  <label className="key">First Name</label>
                  <div className="val">{ui.firstName}</div>
                </div>
                <div className="col-6 form-group">
                  <label className="key">Last Name</label>
                  <div className="val">{ui.lastName}</div>
                </div>
                {isAdmin === 0 && (
                  <React.Fragment>
                    <div className="col-6 form-group">
                      <label className="key">First Name Arabic</label>
                      <div className="val">{ui.firstNameAr}</div>
                    </div>
                    <div className="col-6 form-group">
                      <label className="key">Last Name Arabic</label>
                      <div className="val">{ui.lastNameAr}</div>
                    </div>
                    <div className="col-6 form-group">
                      <label className="key">Email</label>
                      <div className="val">{ui.email}</div>
                    </div>
                  </React.Fragment>
                )}
                <div className="col-6 form-group">
                  <label className="key">Mobile</label>
                  <div className="val">
                    {ui.countryCode}
                    {ui.mobile}
                  </div>
                </div>
              </div>
            )}
            {isEdit && (
              <form onSubmit={this.handleSubmit}>
                <div className="row animated fadeIn">
                  {/* <div className="col-12">
                    {this.renderSelect(
                      "roleId",
                      "Select role",
                      this.props.access.roleList,
                      "roleId"
                    )}
                  </div> */}
                  <div className="col-6">
                    {this.renderInput("firstName", "First tName", true)}
                  </div>
                  <div className="col-6">
                    {this.renderInput("lastName", "Last Name", true)}
                  </div>

                  {isAdmin === 0 && (
                    <React.Fragment>
                      <div className="col-6">
                        {this.renderInput("firstNameAr", "First Name Ar")}
                      </div>
                      <div className="col-6">
                        {this.renderInput("lastNameAr", "Last Name Ar")}
                      </div>
                      <div className="col-6">
                        {this.renderInput("email", "Email", false, isEdit)}
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-6">
                    {this.renderInput(
                      "mobile",
                      "Mobile",
                      false,
                      false,
                      "number"
                    )}
                  </div>

                  <div className="col-12 my-2 text-right">
                    {this.renderButton(isEdit ? "Update" : "Add")}
                  </div>
                </div>
              </form>
            )}

            <div>
              {/* {isEdit && (
                <div className="my-4">
                  <button className="active-btn" onClick={this.handleEdit}>
                    Update
                  </button>
                </div>
              )} */}

              {!isEdit && (
                <div className="my-4">
                  <button className="normal-btn" onClick={this.handleEdit}>
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }
}

const mapActionToProps = {
  getUserInfo: ApplicationAction.access.getUserInfo,
  updateUserInfo: ApplicationAction.access.updateUserInfo
};

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
    access: state.access
  }),
  mapActionToProps
)(LoginProfile);
