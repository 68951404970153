import Joi from "joi-browser";
import * as React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import loginCompanyIcon from "../../assets/img/login-icon-company.png";
import loginMailIcon from "../../assets/img/login-icon-mail.png";
import loginCallIcon from "../../assets/img/login-icon-mobile.png";
import loginPasswordIcon from "../../assets/img/login-icon-password.png";
import loginURLIcon from "../../assets/img/login-icon-url.png";
import loginUserIcon from "../../assets/img/login-icon-user.png";
// import logo from "../../assets/img/logo.png";
import logo from "../../assets/img/logo_white.png";
import SelectCountryCode from "../../components/SelectCountryCode";
import userService, { getCountries } from "../../services/userService";
import AuthFormComp from "./../../components/AuthForm";

export interface RegisterProps {}

export interface RegisterState {}

class Register extends AuthFormComp {
  state = {
    data: {
      firstName: "",
      lastName: "",
      companyName: "",
      companyURL: "",
      mobile: "",
      email: "",
      password: "",
      userType: "Client",
      countryId: "114",
      confirmPass: "",
    },
    errors: { userName: "", password: "", confirmPass: "" },
    countries: [],
    selCountry: "114",
  };

  schema = {
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    companyName: Joi.string().required(),
    companyURL: Joi.string().required(),
    mobile: Joi.string().required(),
    email: Joi.string().required().email().label("Email Id"),
    password: Joi.string().required().min(6).label("Password"),
    userType: Joi.string().required(),
    countryId: Joi.string().required(),
    confirmPass: Joi.string().required().min(6).label("Confirm Password"),
  };
  // confirmPass: Joi.any()
  // .valid(Joi.ref("password"))
  // .required()
  // .label("Confirm Password")
  // .options({ language: { any: { allowOnly: "must match password" } } })

  doSubmit = () => {
    const data = this.state.data;
    if (data.password !== data.confirmPass) {
      toast.error("Confirm Password doesn't match");
      return true;
    }
    this.onRegister();
  };

  onRegister = async () => {
    let selectedData = this.state.countries.find(
      (dat) => dat["id"] === this.state.data.countryId
    );

    let tempMob = this.state.data.mobile;

    this.state.data.mobile = selectedData
      ? selectedData["phoneCode"]
      : "965" + this.state.data.mobile;

    // const { data: resp } =

    await userService
      .register(this.state.data)
      .then(({ data: resp }) => {
        if (!resp.didError) {
          toast.success(resp.message);
          this.props.history.push("/");
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .finally(() => {
        let data = this.state.data;
        data.mobile = tempMob;
        this.setState({ data: data });
      });
  };

  componentDidMount() {
    this.getCountryList();
  }

  async getCountryList() {
    await getCountries().then(({ data }) => {
      if (!data.didError) {
        for (let d of data.data) {
          d["dName"] = d.niceName + " (" + d.phoneCode + ")";
        }
        this.setState({ countries: data.data });
      }
    });
  }
  handleCountryChange = ({ currentTarget: e }) => {
    let data = this.state.data;
    data.countryId = e.value;
    this.setState({ data: data });
  };

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }

  renderPage() {
    return (
      <React.Fragment>
        <div className="login-bg">
          <div className="login_details">
            <div
              style={{
                height: "100vh",
                width: 2000,
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <div className="container">
                <div className="login_details_div">
                  <div className="row" style={{ margin: 0 }}>
                    <div className="register-div p-2">
                      <div className="text-center bg-app-gradient py-2 border-bottom rounded-top pb-4">
                        <Link to="/">
                          <img
                            src={logo}
                            className="login-icon mx-auto"
                            alt=""
                          />
                        </Link>
                      </div>

                      <p className="text-center font-weight-bold my-4 h5 text-secondary">
                        Sign Up
                      </p>

                      {/* <p>Register Here !..</p> */}
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className="col-md-6">
                            {this.renderInput(
                              "firstName",
                              "First Name",
                              loginUserIcon
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "lastName",
                              "Last Name",
                              loginUserIcon
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "companyName",
                              "Company Name",
                              loginCompanyIcon
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "companyURL",
                              "Company URL",
                              loginURLIcon
                            )}
                          </div>
                          <div className="col-md-2">
                            <SelectCountryCode
                              listData={this.state.countries}
                              onChange={this.handleCountryChange}
                              valueField="id"
                              value={this.state.data.countryId}
                            ></SelectCountryCode>

                            {/* <input
                            style={{ height: 45 }}
                            type="text"
                            disabled
                            value="965"
                            className="form-control"
                          /> */}
                          </div>
                          <div className="col-md-4 pl-0">
                            {this.renderInput(
                              "mobile",
                              "Mobile Number",
                              loginCallIcon
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "email",
                              "Email Id",
                              loginMailIcon
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "password",
                              "Password",
                              loginPasswordIcon,
                              "password"
                            )}
                          </div>
                          <div className="col-md-6">
                            {this.renderInput(
                              "confirmPass",
                              "Confirm Password",
                              loginPasswordIcon,
                              "password"
                            )}
                          </div>
                        </div>
                        <div className="text-center my-3">
                          {this.renderButton("Register Now")}
                        </div>
                        <div className="text-center" />

                        <div className="bg-app-gradient rounded-bottom border-top py-2">
                          <div className="row">
                            <div className="col">
                              <Link className="text-white ml-3" to="/">
                                Home
                              </Link>
                            </div>
                            <div className="col text-right">
                              <Link className="text-white mr-3" to="/login">
                                Sign In
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
