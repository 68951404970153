import authService from "../services/authService";

export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FAILED = "LOGIN_USER";

export function updateUser(newUser) {
    authService.setUserAuth(newUser);
    return {
        type: UPDATE_USER,
        payload: {
            data: newUser,
            isLoggedIn: true
        }
    };
}
export function loginUser() {
    return {
        type: LOGIN_USER,
        payload: {
            data: {},
            isLoggedIn: true
        }
    };
}
export function logoutUser() {
    authService.logout();
    return {
        type: LOGOUT_USER,
        payload: {
            data: {},
            isLoggedIn: false
        }
    };
}
export function loginFailed() {
    return {
        type: LOGIN_FAILED,
        payload: {
            data: "Login Failed",
            isLoggedIn: false
        }
    };
}