import * as React from "react";
import { connect } from "react-redux";
import Joi from "joi-browser";
import queryString from "query-string";
import { Link } from "react-router-dom";
import downRightIcon from "../../assets/img/down-right-arrow.svg";

import GenericForm from "./../../components/genericFormComponents/GenericForm";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
import { ApplicationState } from "./../../stores/index";
import { actionCreators } from "./../../stores/accessStore";
import AuthContainer from "../../components/authContainer";

class RoleAddUpdate extends GenericForm {
  constructor(props) {
    super(props);
    const { match, location } = props;
    const search: any = queryString.parse(location.search);
    this.state.type = match.params.type;
    const { type } = this.state;
    if (type === "add") {
      this.state.data.Name = "";
      this.state.data.RoleId = "";
      this.isEdit = false;
      this.childMenu = "usr_ChildMenus";
    } else if (type === "edit") {
      this.state.data.Name = search.name;
      this.state.data.RoleId = match.params.id;
      this.getEditMenuList(match.params.id);
      this.isEdit = true;
      this.childMenu = "child";
    }
  }
  mLists: any[] = [];

  bodyParam: any = {
    roleName: "",
    roleId: "",
    roleDelete: false,
    screenAccesses: []
  };
  isEdit: boolean = false;
  childMenu: string = "";
  state = {
    type: "",
    menuList: [],
    checked: [],
    expanded: [],
    data: {
      Name: "",
      RoleId: ""
    },
    errors: { Name: "" }
  };

  schema = {
    Name: Joi.string().required(),
    RoleId: Joi.string().allow("", null)
  };

  componentDidMount() {
    if (!this.isEdit) {
      this.getDefaultMenuList();
    }
  }

  async getEditMenuList(id) {
    let menuList = [];
    await this.props.getRoleMenus(id);
    menuList = this.props.access.roleMenus;
    this.setState({ menuList });
  }

  async getDefaultMenuList() {
    let menuList = [];
    await this.props.getDefaultMenus(1);
    menuList = this.props.access.defaultMenus;
    this.setState({ menuList });
  }

  doSubmit = () => {
    this.saveUpdate();
  };

  saveUpdate() {
    this.bodyParam.screenAccesses = [];
    this.setMenuAsList(this.state.menuList);
    this.addUpdateR();
  }

  async addUpdateR() {
    await this.props.addUpdateRole(this.bodyParam);
    if (this.props.access.isRespSuccess) {
      this.props.history.push("/app/role");
    }
  }

  setMenuAsList(menuList: any[]) {
    const { Name, RoleId } = this.state.data;
    this.bodyParam.roleName = Name;
    this.bodyParam.roleId = RoleId;

    menuList.forEach(d => {
      if (d[this.childMenu]) {
        if (d[this.childMenu].length > 0) {
          this.setMenuAsList(d[this.childMenu]);
        }
      }
      let obj = {
        menuId: d.menuId,
        roleId: d.roleId,
        full: d.full,
        read: d.read,
        write: d.write,
        isActive: true,
        isDeleted: false
      };
      if (this.isEdit) {
        obj.roleId = RoleId;
      }
      if (obj.full || obj.read || obj.write)
        this.bodyParam.screenAccesses.push(obj);
    });
  }

  render() {
    const menuList = this.state.menuList;
    let formType = "";
    if (this.isEdit) {
      formType = "Update";
    } else if (!this.isEdit) {
      formType = "Add";
    }

    return (
      <React.Fragment>
        <TitleBarComp title={formType + " Role"}></TitleBarComp>

        <MainContentComp>
          <form onSubmit={this.handleSubmit}>
            <div className="row header">
              {/* <div className="col my-auto">
                <h4 className="my-auto text-secondary">{formType} Role</h4>
              </div> */}
            </div>

            <div className="row mx-0 my-4">
              <div className="col-6">
                {this.renderInput("Name", "Role Name")}
              </div>
              <div className="col-12">{this.renderMenus(menuList, false)}</div>
              <div className="col-6 my-3 text-center">
                <div>
                  <hr />
                </div>
                <AuthContainer action="ADD" url={"/app/role"}>
                  {this.renderButton(formType)}
                </AuthContainer>
                <Link className="btn btn-light btn-sm mx-2" to="/app/role">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderMenus(menuList, isChild) {
    const loop = (menuList, isChild) => {
      return menuList.map((item, i) => {
        if (item[this.childMenu]) {
          return (
            <div key={i} className={isChild ? "pl-4" : ""}>
              {this.renderCheckBox(item, isChild)}
              {loop(item[this.childMenu], true)}
            </div>
          );
        } else {
          return (
            <div className={isChild ? "pl-4" : ""} key={i}>
              {this.renderCheckBox(item, isChild)}
            </div>
          );
        }
      });
    };

    return loop(menuList, isChild);
  }

  handleCheckbox = (data, fieldName) => {
    let menuList: any = this.state.menuList;
    this.updateTreeView(menuList, data, fieldName);
    this.setState({ menuList });
  };

  updateTreeView(menuList, node, fieldName) {
    menuList.forEach(data => {
      if (data.menuId === node.menuId) {
        let isEnable = false;
        if (data[fieldName]) {
          isEnable = true;
        }

        if (data["parentMenuId"] !== 0) {
          if (!isEnable) {
            this.state.menuList.forEach((pMenu: any) => {
              if (pMenu["menuId"] === data["parentMenuId"]) {
                pMenu.full = true;
                pMenu.write = false;
                pMenu.read = false;
              }
            });
          }
        }

        data[fieldName] = !isEnable;
        if (fieldName === "full" && !isEnable) {
          data.write = isEnable;
          data.read = isEnable;
        } else if (fieldName === "write" && !isEnable) {
          data.full = isEnable;
          data.read = isEnable;
        } else if (fieldName === "read" && !isEnable) {
          data.write = isEnable;
          data.full = isEnable;
        }
      } else if (data[this.childMenu]) {
        if (data[this.childMenu].length > 0)
          this.updateTreeView(data[this.childMenu], node, fieldName);
      }
    });
  }

  renderCheckBox(data, isChild) {
    return (
      <div className="row bg-light my-2">
        <div className="col-3">
          {isChild && <img src={downRightIcon} style={{ width: 15 }} alt="" />}{" "}
          <span className="mx-2 fs-14">{data.displayName}</span>
        </div>
        <div className="col-9">
          <span
            className="mx-3 cursor-pointer"
            onClick={() => this.handleCheckbox(data, "full")}
          >
            <i
              className={` mx-2 fa fa-${
                data.full ? "check-square text-primary" : "square text-light"
              }`}
            />
            <small>Full Access</small>
          </span>
          <span
            className="mx-3 cursor-pointer"
            onClick={() => this.handleCheckbox(data, "read")}
          >
            <i
              className={` mx-2 fa fa-${
                data.read ? "check-square text-primary" : "square text-light"
              }`}
            />
            <small>Read Only</small>
          </span>
          <span
            className="mx-3 cursor-pointer"
            onClick={() => this.handleCheckbox(data, "write")}
          >
            <i
              className={`mx-2 fa fa-${
                data.write ? "check-square text-primary" : "square text-light"
              }`}
            />
            <small>Write Only</small>
          </span>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    access: state.access
  }),
  actionCreators
)(RoleAddUpdate);
