import { toast } from 'react-toastify';
import { AppThunkAction } from ".";
import http from "../services/httpService";
import { AppConstants } from './../common/appConfig';
import { AuthActions } from "./authStore";


const ROLE_API = AppConstants.API_ROOT + "Role/";
const USER_API = AppConstants.API_ROOT + "Users/";
const COMPANY_API = AppConstants.API_ROOT + "Company/";


export interface CompanyState {
    chargeList: any[];
    companyComisnList: any[],
    roleList: any[];
    defaultMenus: any[];
    roleMenus: any[];
    userList: any[];
    userInfo: any[];
    totalRecord: number;
    isRespSuccess: boolean;
}


// New Action

interface GET_CHARGE_TYPE {
    type: "GET_CHARGE_TYPE";
    chargeList: any[];
}

interface UPDATE_CHARGE_TYPE {
    type: "UPDATE_CHARGE_TYPE";
    isRespSuccess: boolean;
}

interface ADD_UPDATE_CCHARGE {
    type: "ADD_UPDATE_CCHARGE";
    isRespSuccess: boolean;
}

interface GET_COMPANY_COMMISN {
    type: "GET_COMPANY_COMMISN";
    companyComisnList: any[];
}


//Actions
interface GET_ROLE_LIST {
    type: "GET_ROLE_LIST";
    roleList: any[];
}
interface GET_ROLE_MENUS {
    type: "GET_ROLE_MENUS";
    roleMenus: any[];
}

interface GET_DEFAULT_MENUS {
    type: "GET_DEFAULT_MENUS";
    defaultMenus: any[];
}

interface GET_USER_INFO {
    type: "GET_USER_INFO";
    userInfo: any[];
}
interface ADD_UPDATE_ROLE {
    type: "ADD_UPDATE_ROLE";
    isRespSuccess: boolean;
}

interface ADD_UPDATE_MENU {
    type: "ADD_UPDATE_MENU";
    isRespSuccess: boolean;
}

interface UPDATE_USER_INFO {
    type: "UPDATE_USER_INFO";
    isRespSuccess: boolean;
}



interface ADD_UPDATE_SUBUSER {
    type: "ADD_UPDATE_SUBUSER";
    isRespSuccess: boolean;
}
interface DELETE_SUBUSER {
    type: "DELETE_SUBUSER";
    isRespSuccess: boolean;
}
interface GET_USER_LIST {
    type: "GET_USER_LIST";
    userList: any[];
    totalRecord: number;
}

export type CompanyActions = AuthActions | GET_CHARGE_TYPE | UPDATE_CHARGE_TYPE | ADD_UPDATE_CCHARGE | GET_COMPANY_COMMISN | GET_ROLE_LIST | GET_ROLE_MENUS | GET_DEFAULT_MENUS |
    ADD_UPDATE_ROLE | UPDATE_USER_INFO | ADD_UPDATE_MENU | ADD_UPDATE_SUBUSER | DELETE_SUBUSER | GET_USER_LIST | GET_USER_INFO;


export const actionCreators = {



    getChargeList: (): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(COMPANY_API + 'GetVendorChargeType').then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_CHARGE_TYPE", chargeList: res.data });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    updateChargeType: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {

        await http.post(COMPANY_API + 'UpdateVendorChargeType', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "UPDATE_CHARGE_TYPE", isRespSuccess: true });
            } else {
                dispatch({ type: "UPDATE_CHARGE_TYPE", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    addUpdateCCharge: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.post(COMPANY_API + 'AddUpdateCompanyCommssion', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_CCHARGE", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_CCHARGE", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    getCompanyComisn: (): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(COMPANY_API + 'GetClientCompanyCommssion').then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_COMPANY_COMMISN", companyComisnList: res.data });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },


    getRoleList: (): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + 'getRoleAll').then(({ data: res }) => {
            if (!res.didError) {
                // console.log(res.message);
                dispatch({ type: "GET_ROLE_LIST", roleList: res.model });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    getRoleMenus: (roleId): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + 'GetMenubyRole/' + roleId).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_ROLE_MENUS", roleMenus: res.model });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    getDefaultMenus: (typeId): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {

        await http.get(ROLE_API + 'GetDefaultMenu/' + typeId).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_DEFAULT_MENUS", defaultMenus: res.model });
            } else {
                dispatch({ type: "GET_DEFAULT_MENUS", defaultMenus: [] });
                toast.error(res.errorMessage);
            }
        })
    },

    getUserInfo: (userId, isOthers = 0): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(USER_API + 'getUserInfo?clientId=' + userId + '&isAdmin=' + isOthers).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_USER_INFO", userInfo: res.data });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    addUpdateRole: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.post(ROLE_API + 'AddUpdateRole', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_ROLE", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_ROLE", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    updateUserInfo: (data, isAdmin): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {

        let updateURL = isAdmin === 1 ? "updateAdminUser" : "updateClientUser";

        await http.put(USER_API + updateURL, data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "UPDATE_USER_INFO", isRespSuccess: true });
            } else {
                dispatch({ type: "UPDATE_USER_INFO", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    addUpdateMenu: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.post(ROLE_API + 'AddUpdateMenu', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_MENU", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_MENU", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },
    addUpdateSubUser: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        let link = "AddSubUser";
        if (data.loginId !== '') {
            link = "UpdateSubUser";
        }
        await http.post(ROLE_API + link, data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_SUBUSER", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_SUBUSER", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    deleteSubUser: (data): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {

        await http.post(ROLE_API + 'UpdateSubUser', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "DELETE_SUBUSER", isRespSuccess: true });
            } else {
                dispatch({ type: "DELETE_SUBUSER", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },


    getSubUserList: (query): AppThunkAction<CompanyActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + "getsubuser?" + query).then(({ data: res }) => {
            if (!res.didError) {
                // console.log(res.message);
                dispatch({ type: "GET_USER_LIST", userList: res.model, totalRecord: res.itemsCount });
            } else {
                console.error(res.errorMessage);
            }
        })
    },




};


const unloadedState: CompanyState = {
    chargeList: [] as any,
    companyComisnList: [],
    roleList: [],
    defaultMenus: [],
    userInfo: {} as any,

    roleMenus: [],
    userList: [],
    totalRecord: 0,
    isRespSuccess: false

};

export function reducer(
    state: CompanyState,
    action: CompanyActions
): CompanyState {
    switch (action.type) {
        case "GET_CHARGE_TYPE":
            return { ...state, chargeList: action.chargeList };
        case "ADD_UPDATE_CCHARGE":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "GET_COMPANY_COMMISN":
            return { ...state, chargeList: action.companyComisnList };
        case "UPDATE_CHARGE_TYPE":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "GET_ROLE_LIST":
            return { ...state, roleList: action.roleList };
        case "GET_ROLE_MENUS":
            return { ...state, roleMenus: action.roleMenus };
        case "GET_DEFAULT_MENUS":
            return { ...state, defaultMenus: action.defaultMenus };
        case "GET_USER_INFO":
            return { ...state, userInfo: action.userInfo };
        case "UPDATE_USER_INFO":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_ROLE":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_MENU":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_SUBUSER":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "DELETE_SUBUSER":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "GET_USER_LIST":
            return { ...state, userList: action.userList, totalRecord: action.totalRecord };
    }
    return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
