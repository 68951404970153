import { Chip, Icon, IconButton } from "@material-ui/core";
import queryString from "query-string";
import * as React from "react";
import { AlertTriangle, CheckCircle, Eye } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import moment from "moment";
import { toast } from "react-toastify";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { DateTimePicker } from "react-widgets";
import AuthContainer from "../../components/authContainer";
// import GFInput from "../../components/genericFormComponents/GFInput";
import MSelect from "../../components/MSelect";
import MTextArea from "../../components/MTextArea";
import TitleBarComp from "../../components/TitleBar";
// import userService from "../../services/userService";
import globalService from "../../services/globalService";
import { ApplicationAction } from "../../stores";
import CopyToClipboard from "./../../components/CopyToClipboard";
import ModalComp from "./../../components/Modal";
import { AuthState } from "./../../stores/authStore";
import { ApplicationState } from "./../../stores/index";
import { InvoiceState } from "./../../stores/invoiceStore";

export interface InvoiceDetailProps {
  updateInvoice;
  getInvDetail;
  getSMSContent;
  inv: InvoiceState;
  userAuth: AuthState;
  sendSMS;
}

export interface InvoiceDetailState {}

class InvoiceDetail extends React.Component<
  InvoiceDetailProps,
  InvoiceDetailState
> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    this.state.id = search.id;
  }
  state = {
    id: "",
    invDetail: {} as any,
    isModalOpen: false,
    updates: {
      type: "",
      expireDate: null as any,
      invoiceOpen: "Fixed",
      conditionText: "",
      condition: false,
      note: "",
    },
    invSMS: [],
    sentSMS: [],
    isSmsModalOpen: false,
    isEmailModalOpen: false,
    smsContent: "",
    selSmsType: {} as any,
    smsTypeList: [],
    isClient: true,
  };

  componentDidMount() {
    this.getInvDetail();
  }

  async getInvDetail() {
    await this.props.getInvDetail(this.state.id);

    let invDetail = this.props.inv.invoiceDetail;
    let invSMS = this.props.inv.invoiceSMS;
    let sentSMS = invSMS.filter((data: any) => data.smsSent === true);

    let isClient =
      this.props.userAuth.userDetail["userType"] === "Client" ? true : false;
    this.getinvoiceViewInfo(invDetail);
    this.setState({ invDetail, invSMS, sentSMS, isClient });
  }

  getinvoiceViewInfo(invDetail) {
    for (let iv of invDetail.invoiceViewInfo) {
      iv.viewExtraInfo = JSON.parse(iv.viewExtraInfo);
    }

    if (invDetail.invoiceItemInfo) {
      let paidAmount = 0;
      let balAmount = 0;
      let isPartialPaid = false;
      for (let itemInfo of invDetail.invoiceItemInfo) {
        if (itemInfo.isPaid === 2) {
          isPartialPaid = true;
          paidAmount += itemInfo.totalPrice;
        } else {
          balAmount += itemInfo.totalPrice;
        }
      }

      invDetail["paidAmt"] = paidAmount;
      invDetail["balAmt"] = balAmount;
      invDetail["isPartialPaid"] = isPartialPaid;
    }
    this.setState({ invDetail });
  }

  handleDateChange = (name, val) => {
    let { updates } = this.state;
    updates[name] = val;
    this.setState({ updates });
  };

  handleInvOpen = ({ currentTarget: e }) => {
    let data = this.state.updates;
    data[e.name] = e.value;
    this.setState({ updates: data });
  };

  handleCondition = ({ currentTarget: e }) => {
    let updates = this.state.updates;
    updates.condition = e.checked;
    this.setState({ updates });
  };

  handleSMSModal = () => {
    this.getSMSContent();
    this.setState({ isSmsModalOpen: !this.state.isSmsModalOpen });
  };

  handleSMSTypeChange = (e) => {
    let val = e.target.value;
    this.setState({ smsContent: val.smsBody, selSmsType: val });
  };
  handleNEWsmsChange = (e) => {
    let val = e.target.value;
    this.setState({ smsContent: val });
  };
  async getSMSContent() {
    await this.props.getSMSContent(this.state.id);
  }

  sendSMS = () => {
    const { smsContent } = this.state;
    if (smsContent.length > 0) {
      let body = {
        invoiceId: this.state.id,
        smsBody: smsContent,
      };
      this.sendSMSmsg(body);
    } else {
      toast.error("Please enter the sms content");
    }
  };

  async sendSMSmsg(body) {
    await this.props.sendSMS(body);
    if (this.props.inv.isRespSuccess) {
      this.setState({ isSmsModalOpen: false });
    }
  }

  handleUpdatePanel = (type) => {
    let { isModalOpen, invDetail, updates } = this.state;
    updates.type = type;

    updates.expireDate = invDetail.invoiceExpiryDate
      ? new Date(invDetail.invoiceExpiryDate)
      : //  new Date(invDetail.invoiceExpiryDate)
        null;
    updates.invoiceOpen = invDetail.invoiceOpen;
    updates.condition = invDetail.condition;
    updates.note = invDetail.note ? invDetail.note : "";
    updates.conditionText = invDetail.conditionText
      ? invDetail.conditionText
      : "";

    // console.log(updates);
    this.setState({ isModalOpen: !isModalOpen, updates });
  };

  handleUpdates = () => {
    this.updateDetail();
  };

  async updateDetail() {
    const { updates, invDetail } = this.state;

    // let sample = {
    //   expiryDate: "2020-03-26T12:28:36.934Z",
    //   invoiceId: "string",
    //   conditionText: "string",
    //   condition: true,
    //   note: "string"
    // };

    let body = {
      invoiceId: invDetail.invoiceId,
    };

    if (updates.type === "expireDate") {
      body["expiryDate"] = updates.expireDate;
    }
    if (updates.type === "invoiceOpen") {
      body["invoiceOpen"] = updates.invoiceOpen;
    }

    if (updates.type === "conditionApply") {
      body["condition"] = updates.condition;
      body["conditionText"] = updates.conditionText;
    }

    if (updates.type === "note") {
      body["note"] = updates.note;
    }

    await this.props.updateInvoice(body);

    if (this.props.inv.isRespSuccess) {
      // if (updates.type === "expireDate") {
      //   invDetail["invoiceExpiryDate"] = updates.expireDate;
      // }
      // if (updates.type === "invoiceOpen") {
      //   invDetail["invoiceOpen"] = updates.invoiceOpen;
      // }

      // if (updates.type === "conditionApply") {
      //   invDetail["condition"] = updates.condition;
      //   invDetail["conditionText"] = updates.conditionText;
      // }

      // if (updates.type === "note") {
      //   invDetail["note"] = updates.note;
      // }

      this.setState({ isModalOpen: false }, this.getInvDetail);
    }
  }

  render() {
    const { invDetail: id } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Invoice Detail" />

        {this.renderUpdateModal()}
        {this.renderSMSModal()}

        {id.customer && this.renderContent()}
      </React.Fragment>
    );
  }

  renderContent() {
    const { invDetail: id, sentSMS, invSMS, isClient } = this.state;

    return (
      <React.Fragment>
        <div className="main-contain-content-area">
          <div className="bg-white main-contain-bg">
            <p className="title">Basic Info</p>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Payment Ref</label>
                  <p>{id.invoiceRefNo}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Status</label>
                  {!id.isCanceled && (
                    <p>
                      {id.paid ? (
                        <span
                          style={{ letterSpacing: 1.5, fontSize: 14 }}
                          className="badge badge-success text-white p-1"
                        >
                          PAID
                          {/* {id.isPartialPaid ? "(Partially)" : ""} */}
                        </span>
                      ) : (
                        <span
                          style={{ letterSpacing: 1.5, fontSize: 14 }}
                          className="badge badge-info text-white p-1"
                        >
                          Unpaid
                        </span>
                      )}
                    </p>
                  )}
                  {id.isCanceled ? (
                    <span
                      style={{ letterSpacing: 1.5, fontSize: 14 }}
                      className="badge badge-danger text-white p-1"
                    >
                      Cancelled
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Deposit Status</label>
                  {id.deposit && (
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 21.37 21.37"
                      >
                        <path
                          id="checked"
                          d="M10.685,0A10.685,10.685,0,1,0,21.37,10.685,10.685,10.685,0,0,0,10.685,0ZM8.725,16.174l-4.7-4.7L5.588,9.9l3.137,3.136L15.782,5.98,17.35,7.549Z"
                          fill="#6ac259"
                        ></path>
                      </svg>
                      Deposited
                    </p>
                  )}

                  {!id.deposit && (
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 21.369 21.368"
                      >
                        <g
                          id="cancel_1_"
                          data-name="cancel (1)"
                          transform="translate(0 -0.003)"
                        >
                          <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M21.369,10.687A10.684,10.684,0,1,0,10.684,21.371,10.684,10.684,0,0,0,21.369,10.687Z"
                            fill="#e04f5f"
                          ></path>
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M147.118,132.8l3.124-3.626a.945.945,0,0,0-1.432-1.234l-2.939,3.412-2.939-3.412a.945.945,0,1,0-1.432,1.234l3.123,3.626L141.5,136.43a.945.945,0,0,0,1.432,1.234l2.939-3.411,2.939,3.411a.945.945,0,0,0,1.432-1.234Z"
                            transform="translate(-135.186 -122.116)"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                      Not Deposited
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Value</label>
                  <p>
                    {id.invoiceAmt} {id.invoiceAmtValue}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Created Date</label>
                  <p>{globalService.getLocalDate(id.createdDate)}</p>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Created By</label>
                  <p>
                    <span>{id.createdInfo.firstName}</span>{" "}
                    <span className="onlyhover">
                      {id.createdInfo.lastName}{" "}
                      {id.company ? (
                        <span>( {id.company.companyName})</span>
                      ) : (
                        ""
                      )}
                    </span>{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Last Sent Date</label>
                  {/* <p>29/08/2019</p> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Expire Date</label>
                  <p>
                    {/* {moment(id.invoiceExpiryDate).format("DD/MM/YYYY hh:mm a")} */}
                    {globalService.getLocalDate(id.invoiceExpiryDate)}{" "}
                    {!id.paid && (
                      <AuthContainer action="EDIT" url={"/app/inv-list"}>
                        <IconButton
                          size="medium"
                          color="primary"
                          className="ml-2"
                          disabled={!isClient}
                          onClick={() => this.handleUpdatePanel("expireDate")}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </IconButton>
                      </AuthContainer>
                    )}
                  </p>{" "}
                  <span className="ml-2 text-black cursor-pointer"></span>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Url</label>
                  <CopyToClipboard>
                    {({ copy }) => (
                      <p
                        className="hideable"
                        onClick={() => copy(id.customerUrl)}
                      >
                        {id.customerUrl}
                        <small
                          style={{ fontWeight: 100 }}
                          className="float-right onlyhover cursor-pointer  bg-dark rounded text-white px-1"
                        >
                          COPY
                        </small>
                      </p>
                    )}
                  </CopyToClipboard>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Display Value</label>
                  <p>
                    {id.invoiceAmt} {id.invoiceAmtValue}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Language</label>
                  <p>{id.invoiceLang}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Type</label>
                  <p>{id.invoiceType}</p>
                </div>
              </div>
            </div>
            <hr />
            <p className="title">Customer Info</p>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Customer Name</label>
                  <p>{id.customer.fullName}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Customer Ref</label>
                  <p>{this.displayChip(id.customerRef)}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Customer Mobile</label>
                  <p>
                    {id.customer.mobileNo}
                    {!id.paid && (
                      <AuthContainer action="EDIT" url={"/app/inv-list"}>
                        <IconButton
                          size="medium"
                          color="primary"
                          title="Send again"
                          className="ml-2"
                          disabled={!isClient}
                          onClick={this.handleSMSModal}
                        >
                          <Icon fontSize="small">textsms</Icon>
                        </IconButton>{" "}
                      </AuthContainer>
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Customer Email</label>
                  <p>{id.customer.email}</p>
                </div>
              </div>
            </div>
            <hr />
            {id.invoiceType !== "QUICK" && (
              <React.Fragment>
                <p className="title">Other Info</p>
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Option</label>
                      <p>
                        {id.invoiceOpen}
                        {id.invoiceType !== "QUICK" && !id.paid && (
                          <AuthContainer action="EDIT" url={"/app/inv-list"}>
                            <IconButton
                              size="medium"
                              color="primary"
                              className="ml-2"
                              disabled={!isClient}
                              onClick={() =>
                                this.handleUpdatePanel("invoiceOpen")
                              }
                            >
                              <Icon fontSize="small">edit</Icon>
                            </IconButton>
                          </AuthContainer>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Condition apply</label>
                      <p>
                        {id.condition ? "YES" : "NO"}{" "}
                        {!id.paid && (
                          <AuthContainer action="EDIT" url={"/app/inv-list"}>
                            <IconButton
                              size="medium"
                              color="primary"
                              className="ml-3"
                              disabled={!isClient}
                              onClick={() =>
                                this.handleUpdatePanel("conditionApply")
                              }
                            >
                              <Icon fontSize="small">edit</Icon>
                            </IconButton>
                          </AuthContainer>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    {id.condition === true && (
                      <div className="input-div default-label">
                        <label>Condition Detail</label>
                        <p>
                          {id.conditionText}{" "}
                          {!id.paid && (
                            <AuthContainer action="EDIT" url={"/app/inv-list"}>
                              <IconButton
                                size="medium"
                                color="primary"
                                className="ml-2"
                                disabled={!isClient}
                                onClick={() =>
                                  this.handleUpdatePanel("conditionApply")
                                }
                              >
                                <Icon fontSize="small">edit</Icon>
                              </IconButton>
                            </AuthContainer>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Notes</label>
                      <p>
                        {id.note}{" "}
                        {!id.paid && (
                          <AuthContainer action="EDIT" url={"/app/inv-list"}>
                            <IconButton
                              size="medium"
                              color="primary"
                              className="ml-2"
                              disabled={!isClient}
                              onClick={() => this.handleUpdatePanel("note")}
                            >
                              <Icon fontSize="small">edit</Icon>
                            </IconButton>
                          </AuthContainer>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
              </React.Fragment>
            )}
            <p className="title">More Options</p>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Send Invoice Option</label>
                  <p>{id.invoiceOption}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Views</label>
                  <p>{id.viewCount}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Sent Sms Counter</label>
                  <p>{sentSMS.length}</p>
                  {/* <p>
                    <a data-toggle="pill" href="#sms-detail">
                      {sentSMS.length}
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Sent Email Counter</label>
                  <p>0</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Remind After</label>
                  <p>0</p>
                </div>
              </div>
            </div>
            <hr />
            <p className="title">Payment Information Details</p>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Paid Date</label>
                  <p>
                    {id.paidDate && globalService.getLocalDate(id.paidDate)}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Invoice Value</label>
                  <p>
                    {id.invoiceAmt} {id.invoiceAmtValue}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Vendor Service Charge</label>
                  <p>0.000 KWD</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Customer Service Charge</label>
                  <p>{id.commission} KWD</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Due Value</label>
                  <p>{id.paid ? "0.000" : id.invoiceAmt}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Due Deposit</label>
                  {/* <p>20.000 KD</p> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="input-div default-label">
                  <label>Deposit Date</label>
                  <p>
                    {id.depositDate &&
                      globalService.getLocalDate(id.depositDate)}
                  </p>
                </div>
              </div>

              {id.isPartialPaid && (
                <React.Fragment>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Partially Paid Amount</label>
                      <p className="text-success font-weight-bold">
                        {id.paidAmt}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Balance Amount</label>
                      <p className="text-danger font-weight-bold">
                        {id.balAmt}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <br />
            <div className="align-right">
              <Link to="/app/inv-list" className="normal-btn">
                Back to List
              </Link>
            </div>
            <br />
            <hr />

            {/* <!-- Nav pills --> */}
            <ul className="nav nav-pills">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  href="#invoice-item"
                >
                  Invoice Item
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  href="#invoice-transaction"
                >
                  Invoice Transactions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  href="#invoice-view"
                >
                  Invoice Views
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  href="#invoice-files"
                >
                  Uploaded files
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " data-toggle="pill" href="#sms-detail">
                  SMS Detail
                </a>
              </li>
            </ul>

            {/* <!-- Tab panes --> */}
            <div className="tab-content">
              <div className="tab-pane active" id="invoice-item">
                {/* <div className="row">
                  <div className="col-md-3 col-sm-4 input-div">
                    <table>
                      <tbody>
                        <tr>
                          <td>Record Show</td>
                          <td>
                            <select>
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 col-sm-4"></div>
                  <div className="col-md-3 col-sm-4 input-div">
                    <input type="text" name="text" />
                    <img
                      src="img/search_icon.png"
                      className="search_icon"
                      alt=""
                    />
                  </div>
                </div> */}
                <div className="grid-table">
                  <table className="table-bordered">
                    <thead>
                      <tr>
                        <th>Invoice / Product Name</th>
                        {/* <th>Quantity</th>
                        <th>Unit Price</th> */}
                        <th className="text-center">Amount</th>
                        {id.isPartialPaid && (
                          <th className="text-center">Status</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {id.invoiceItemInfo.map((val, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {val.itemName}{" "}
                              {val.qty > 1 ? (
                                <strong className="float-right">
                                  {val.qty} x {val.unitPrice}
                                </strong>
                              ) : (
                                ""
                              )}{" "}
                            </td>
                            {/* <td>{val.qty}</td>
                            <td>{val.unitPrice}</td> */}
                            <td className="text-center">{val.totalPrice}</td>
                            {id.isPartialPaid && (
                              <td className="text-center">
                                {val.isPaid === 0 ? (
                                  ""
                                ) : val.isPaid === 1 ? (
                                  <span
                                    title="Tried to pay"
                                    className="badge badge-info ltrSpace15 fs-20"
                                  >
                                    initiated
                                  </span>
                                ) : val.isPaid === 2 ? (
                                  <span className="badge badge-success ltrSpace15">
                                    Paid
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}

                      {id.invoiceItemInfo.length === 0 && (
                        <tr>
                          <td colSpan={4} className="align-center">
                            <span>
                              <b>Sorry</b> There are no recrods to show!
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="invoice-transaction">
                <div className="row">
                  <div className="col-md-3 col-sm-4 input-div">
                    <table>
                      <tbody>
                        <tr>
                          <td>Record Show</td>
                          <td>
                            <select>
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 col-sm-4"></div>
                  <div className="col-md-3 col-sm-4 input-div">
                    <input type="text" name="text" />
                    <img
                      src="img/search_icon.png"
                      className="search_icon"
                      alt=""
                    />
                  </div>
                </div>
                <div className="grid-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Transaction Start</th>
                        <th>Customer paid</th>
                        <th>Commission</th>
                        <th>Received Amount</th>
                        <th>Transaction Status</th>
                        <th>Payment Gateway</th>
                        <th>Payment ID</th>
                        <th>Auth</th>
                        <th>Track ID</th>
                        <th>Transaction ID</th>
                        <th>Transaction End</th>
                      </tr>
                    </thead>
                    <tbody>
                      {id.paymentSessionInfo.map((val, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {globalService.getLocalDate(val.sessionStartTime)}
                            </td>
                            <td>{val.amount}</td>
                            <td>{val.deliveryFees}</td>
                            <td>{val.amount - val.deliveryFees}</td>
                            <td>{val.result}</td>
                            <td>{val.gateway}</td>
                            <td>{val.paymentId}</td>
                            <td>{val.auth}</td>
                            <td>{val.trackId}</td>
                            <td>{val.tranId}</td>
                            <td>
                              {val.sessionClosedtime &&
                                globalService.getLocalDate(
                                  val.sessionClosedtime
                                )}
                            </td>
                          </tr>
                        );
                      })}
                      {id.paymentSessionInfo.length === 0 && (
                        <tr>
                          <td colSpan={7} className="align-center">
                            <span>
                              <b>Sorry</b> There are no recrods to show!
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="invoice-view">
                {/* <div className="row">
                  <div className="col-md-3 col-sm-4 input-div">
                    <table>
                      <tbody>
                        <tr>
                          <td>Record Show</td>
                          <td>
                            <select>
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 col-sm-4"></div>
                  <div className="col-md-3 col-sm-4 input-div">
                    <input type="text" name="text" />
                    <img
                      src="img/search_icon.png"
                      className="search_icon"
                      alt=""
                    />
                  </div>
                </div> */}

                <div className="bg-light p-2">
                  <VerticalTimeline>
                    {id.invoiceViewInfo.map((val, idx) => {
                      return (
                        <VerticalTimelineElement
                          key={idx}
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "#103a71",
                            color: "#fff",
                          }}
                          contentArrowStyle={{
                            borderRight: "7px solid #103a71",
                          }}
                          date={globalService.getLocalDate(val.viewDate)}
                          iconStyle={{
                            background: "#103a71",
                            color: "#fff",
                          }}
                          icon={<Eye />}
                        >
                          <h4 className="vertical-timeline-element-subtitle">
                            {val.viewExtraInfo.ip}
                          </h4>
                          <p>
                            {val.viewExtraInfo.city},{" "}
                            {val.viewExtraInfo.country}
                          </p>
                        </VerticalTimelineElement>
                      );
                    })}
                  </VerticalTimeline>
                </div>
                {/* <div className="grid-table">
                  <table>
                    <thead>
                      <tr>
                        <th>View DateTime</th>
                        <th>IpAddress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {id.invoiceViewInfo.map((val, idx) => {
                        return (
                          <tr>
                            <td>
                              {val.viewDate &&
                                globalService.getLocalDate(val.viewDate)}
                            </td>
                            <td>{val.viewIp}</td>
                          </tr>
                        );
                      })}
                      {id.invoiceViewInfo.length === 0 && (
                        <tr>
                          <td colSpan={7} className="align-center">
                            <span>
                              <b>Sorry</b> There are no recrods to show!
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              */}
              </div>
              <div className="tab-pane fade" id="invoice-files">
                <div className="p-2">
                  <div className="col-12 col-md-5">
                    {id["invoiceAttachedDocFile"].map((val, idx) => {
                      return (
                        <div
                          key={idx}
                          className="bg-light border rounded text-secondary w-100 p-1 my-2"
                        >
                          <a
                            href={globalService.IMG_ROOT_INV + val.filePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {val.fileName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="sms-detail">
                <div className="bg-light p-2">
                  <VerticalTimeline>
                    {invSMS.map((val: any, idx) => {
                      return (
                        <VerticalTimelineElement
                          key={idx}
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "#103a71",
                            color: "#fff",
                          }}
                          contentArrowStyle={{
                            borderRight: "7px solid #103a71",
                          }}
                          date={
                            <div>
                              <div>
                                {globalService.getLocalDate(val.sendDate)}
                              </div>
                              <div>
                                {" "}
                                <small> {val.smsResponse}</small>
                              </div>
                            </div>
                          }
                          iconStyle={{
                            background: "#103a71",
                            color: "#fff",
                          }}
                          icon={
                            val.smsSent === true ? (
                              <CheckCircle />
                            ) : (
                              <AlertTriangle className="text-danger" />
                            )
                          }
                        >
                          <h4 className="vertical-timeline-element-subtitle">
                            {val.senderNo}
                          </h4>
                          <p className="line-clamp">{val.smsMessage}</p>
                        </VerticalTimelineElement>
                      );
                    })}
                  </VerticalTimeline>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderUpdateModal() {
    const { isModalOpen, updates } = this.state;
    return (
      <>
        <ModalComp
          title="Update Panel"
          isOpen={isModalOpen}
          hideModal={() => this.setState({ isModalOpen: !isModalOpen })}
        >
          <div className="modal-content p-2">
            <div className="mb-3">
              {updates.type === "expireDate" && (
                <div className="input-div default-label">
                  <label>Expire Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={updates.expireDate}
                    format="DD/MM/YYYY hh:mm a"
                    onChange={(value) =>
                      this.handleDateChange("expireDate", value)
                    }
                  />
                </div>
              )}
              {updates.type === "invoiceOpen" && (
                <div className="">
                  <label>Is Open Invoice?</label>
                  <div>
                    <div className="radio">
                      <input
                        id="invoiceOpen2"
                        name="invoiceOpen"
                        type="radio"
                        value="FIXED"
                        onChange={(e) => this.handleInvOpen(e)}
                        checked={updates.invoiceOpen === "FIXED"}
                      />
                      <label htmlFor="invoiceOpen2" className="radio-label">
                        Fixed
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        id="invoiceOpen1"
                        name="invoiceOpen"
                        type="radio"
                        value="CHANGEABLE"
                        checked={updates.invoiceOpen === "CHANGEABLE"}
                        onChange={(e) => this.handleInvOpen(e)}
                      />
                      <label htmlFor="invoiceOpen1" className="radio-label">
                        Changeable
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {updates.type === "conditionApply" && (
                <div>
                  <div className="my-2">
                    <div className="">
                      <label>Terms And Conditions</label>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className="styled-checkbox"
                                id="condition1"
                                type="checkbox"
                                checked={updates.condition}
                                onChange={this.handleCondition}
                              />
                              <label htmlFor="condition1"></label>
                            </td>
                            <td>Is Enabled ?</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mb-2 form-group">
                    <label>Condition Detail</label>
                    <textarea
                      value={updates.conditionText}
                      className="form-control"
                      placeholder="Enter the conditions"
                      rows={3}
                      name="conditionText"
                      onChange={this.handleInvOpen}
                    ></textarea>
                  </div>
                </div>
              )}
              {/* {updates.type === "conditionDetail" && fsadfs} */}
              {updates.type === "note" && (
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    value={updates.note}
                    onChange={this.handleInvOpen}
                    name="note"
                    className="form-control"
                    placeholder="Enter the note"
                    rows={3}
                  ></textarea>
                </div>
              )}
            </div>

            <hr />
            <div className="mb-3 text-center">
              <button className="active-btn" onClick={this.handleUpdates}>
                Update
              </button>
            </div>
          </div>
        </ModalComp>
      </>
    );
  }

  renderSMSModal() {
    const { isSmsModalOpen, smsContent, selSmsType } = this.state;
    const { inv } = this.props;
    return (
      <>
        <ModalComp
          title="Send SMS"
          isOpen={isSmsModalOpen}
          hideModal={() => this.setState({ isSmsModalOpen: !isSmsModalOpen })}
        >
          <div className="modal-content p-2">
            <div className="form-group">
              <MSelect
                label="SMS Type"
                listData={inv.smsTypeList}
                name="smsType"
                onChange={this.handleSMSTypeChange}
                placeholder="Choose SMS Type"
                value={selSmsType}
                displayField="smsType"
              ></MSelect>
            </div>
            {selSmsType.smsType !== "NEW SMS" && (
              <div className="form-group">
                <p>{smsContent}</p>
              </div>
            )}
            {selSmsType.smsType === "NEW SMS" && (
              <div className="form-group">
                <MTextArea
                  label="SMS"
                  value={smsContent}
                  placeholder="Choose type"
                  name="someName"
                  onChange={this.handleNEWsmsChange}
                ></MTextArea>
              </div>
            )}

            <hr />
            <div className="mb-3 text-center">
              <button
                className="active-btn"
                disabled={smsContent.length === 0}
                onClick={this.sendSMS}
              >
                Send
              </button>
            </div>
          </div>
        </ModalComp>
      </>
    );
  }

  displayChip(valueString: string) {
    if (valueString) {
      let list = valueString.split(",");

      return list.map((val, idx) => {
        return <Chip size="small" key={idx} label={val}></Chip>;
      });
    } else {
      return <></>;
    }
  }
}

// export default InvoiceDetail;

const mapActionToProps = {
  updateInvoice: ApplicationAction.invoice.updateInvoice,
  getInvDetail: ApplicationAction.invoice.getInvoiceDetail,
  getSMSContent: ApplicationAction.invoice.getSMSContent,
  sendSMS: ApplicationAction.invoice.SendSMS,
};

export default connect(
  (state: ApplicationState) => ({
    inv: state.invoice,
    userAuth: state.auth,
  }),
  mapActionToProps
)(InvoiceDetail);
