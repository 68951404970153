import * as React from "react";
import { connect } from "react-redux";
import globalService from "../services/globalService";
import { ApplicationAction, ApplicationState } from "../stores";
import { AuthState } from "./../stores/authStore";
import { InvoiceState } from "./../stores/invoiceStore";

export interface WalletProps {
  getWalletDetail;
  inv: InvoiceState;
  userAuth: AuthState;
}

const Wallet: React.SFC<WalletProps> = ({ getWalletDetail, inv, userAuth }) => {
  React.useEffect(() => {
    (async function getWallet() {
      await getWalletDetail();
    })();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-3 my-auto">
          <i className="fa fa-briefcase fa-2x"></i>
        </div>
        <div className="col-9">
          <h6 className="my-0">Wallet Balance</h6>
          <h4 className="my-0">
            {globalService.getKWD(
              Number(
                inv.custWalletDetail["clientReceived"]
                  ? inv.custWalletDetail["clientReceived"]
                  : 0
              )
            )}
          </h4>
        </div>
      </div>
    </>
  );
};

const mapActionToProps = {
  getWalletDetail: ApplicationAction.invoice.getWalletDetail,
};
export default connect(
  (state: ApplicationState) => ({
    inv: state.invoice,
    userAuth: state.auth,
  }),
  mapActionToProps
)(Wallet);
