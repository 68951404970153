import { Checkbox, Chip } from "@material-ui/core";
import moment from "moment";
import queryString from "query-string";
import * as React from "react";
import { Check, Filter, Upload, X } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import searchIcon from "../../assets/img/search_icon.png";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import PaginationComp from "../../components/PaginationComp";
import TabViewComp from "../../components/TabView";
import TitleBarComp from "../../components/TitleBar";
import commonService from "../../services/commonService";
import globalService from "../../services/globalService";
import userService from "../../services/userService";

export interface DepositListProps {
  history;
}

export interface DepositListState {}

class DepositList extends React.Component<DepositListProps, DepositListState> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    if (search) {
      this.state.qryLink = search;
    }
    if (search.customerId) {
      this.state.filter.customerId = search.customerId;
    }
    if (search.invoicePLinkId) {
      this.state.filter.invoicePLinkId = search.invoicePLinkId;
    }
  }
  state = {
    tabs: ["List", "Create Deposit"],
    currentTab: "List",
    invList: [] as any,
    depositList: [] as any,
    quickDetail: [] as any,
    isOpen: false,
    pagination: {
      pageCount: 0,
      pageSize: 25,
      pageNumber: 1,
      itemsCount: 0,
    },
    showInoviceFilter: false,
    clientList: [] as any,
    filter: {
      isPaid: "0",
      loginId: "",
      clientId: "",
      invRef: "",
      customerId: "",
      invoicePLinkId: "",
      expireDate: null as any,
      startDate: null as any,
      endDate: null as any,
    },
    qryLink: {} as any,
    collapseNo: "-1",
    sumInvoice: 0,
    sumComisn: 0,
    sumRecieve: 0,
    allItemCheck: true,

    uploads: [] as any,
    selectedInv: 0,
    selectedInvList: [] as any,
    invTotal: 0,
    depNotes: "",
    isModalOpen: false,
    ClientCompany: {} as any,
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleCurrentTab = (item) => {
    this.setState({ currentTab: item, selectedInv: 0 });
    this.getTabContent();
    const { tabs } = this.state;

    if (item === tabs[0]) {
      this.getDepositList();
    } else if (item === tabs[1]) {
      this.getInvList(item);
    }
  };
  getTabContent = () => {
    const { tabs, currentTab } = this.state;
    if (currentTab === tabs[0]) {
      return this.renderDeposit();
    } else if (currentTab === tabs[1]) {
      return this.renderInv();
    }
  };

  handleCollapseRow = (no) => {
    this.setState({ collapseNo: this.state.collapseNo === no ? "-1" : no });
  };
  handleCheckbox = (e, isAll) => {
    let { invList, allItemCheck } = this.state;
    if (isAll) {
      for (let inv of invList) {
        inv["isChecked"] = e.target.checked;
      }
      allItemCheck = e.target.checked;
    } else {
      invList[Number(e.target.name)]["isChecked"] = e.target.checked;
      if (!e.target.checked) {
        allItemCheck = false;
      }
    }
    this.calculateAmounts(invList);
  };

  calculateAmounts = (invList) => {
    let sumInvoice = 0,
      sumComisn = 0,
      sumRecieve = 0,
      selectedInv = 0,
      invTotal = 0;

    let selectedInvList = [] as any;

    let allItemCheck = true;
    for (let inv of invList) {
      if (inv["isChecked"]) {
        selectedInv += 1;
        selectedInvList.push(inv.invoiceId);
        if (inv.paymentSessionInfo.length > 0) {
          for (let ps of inv.paymentSessionInfo) {
            sumInvoice += ps.invoiceAmt;
            sumComisn += ps.deliveryFees;
            sumRecieve += ps.clientAmt;
            invTotal += ps.invoiceAmt;
          }
        } else {
          sumInvoice += inv.invoiceAmt;
          sumComisn += inv.commission;
          sumRecieve += inv.invoiceAmt - inv.commission;
          invTotal += inv.invoiceAmt - inv.commission;
        }
      } else {
        if (allItemCheck) {
          allItemCheck = false;
        }
      }
    }
    this.setState({
      invList,
      allItemCheck,
      sumInvoice,
      sumComisn,
      sumRecieve,
      selectedInv,
      selectedInvList,
      invTotal,
    });
  };

  calculateQuickAmounts = (invList) => {
    let sumInvoice = 0,
      sumComisn = 0,
      sumRecieve = 0,
      selectedInv = 0,
      invTotal = 0;

    let selectedInvList = [] as any;

    let allItemCheck = true;
    for (let inv of invList) {
      selectedInv += 1;
      selectedInvList.push(inv.invoiceId);
      if (inv.paymentSessionInfo.length > 0) {
        for (let ps of inv.paymentSessionInfo) {
          sumInvoice += ps.invoiceAmt;
          sumComisn += ps.deliveryFees;
          sumRecieve += ps.clientAmt;
          invTotal += ps.invoiceAmt;
        }
      } else {
        sumInvoice += inv.invoiceAmt;
        sumComisn += inv.commission;
        sumRecieve += inv.invoiceAmt - inv.commission;
        invTotal += inv.invoiceAmt - inv.commission;
      }
    }

    this.setState({
      quickDetail: invList,
      sumInvoice,
      sumComisn,
      sumRecieve,
    });
  };
  componentDidMount() {
    this.setupClientDetail();
  }

  setupClientDetail() {
    let strComp = sessionStorage.getItem(globalService.SESS_SEL_COMP);
    if (strComp) {
      this.setState({ ClientCompany: JSON.parse(strComp) }, () =>
        this.handleCurrentTab(this.state.currentTab)
      );
    } else {
      toast.error("Client Detail not found");
      this.props.history.push("/");
    }
  }

  async getInvList(currentTab) {
    const {
      filter: f,
      pagination: p,
      clientList,
      tabs,
      ClientCompany: cc,
    } = this.state;
    let qryStr =
      "orderBy=1&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;

    qryStr += "&clientCompanyId=" + cc.clientCompanyId;

    if (f.startDate) {
      qryStr += "&startDate=" + moment(f.startDate).format("YYYY-MM-DD");
    }
    if (f.endDate) {
      qryStr += "&endDate=" + moment(f.endDate).format("YYYY-MM-DD");
    }
    if (f.expireDate) {
      qryStr += "&expiryDate=" + moment(f.expireDate).format("YYYY-MM-DD");
    }

    // if (currentTab === tabs[1]) {
    //   qryStr += "&deposit=0";
    //   // qryStr += "&deposit=1";
    // } else if (currentTab === tabs[2]) {
    //   qryStr += "&deposit=0";
    // }

    qryStr += "&deposit=2"; // not deposited.  if 0, its all.

    if (f.customerId && f.customerId !== "") {
      qryStr += "&customerId=" + f.customerId;
    }
    if (f.invoicePLinkId && f.invoicePLinkId !== "") {
      qryStr += "&invoicePLinkId=" + f.invoicePLinkId;
    }
    if (f.invRef && f.invRef !== "") {
      qryStr += "&invoiceRefNo=" + f.invRef;
    }

    await userService.custPaidInvList(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let invList = res.model;
        p.itemsCount = res.itemsCount;
        p.pageCount = res.pageCount;
        p.pageSize = res.pageSize;
        p.pageNumber = res.pageNumber;

        for (let inv of invList) {
          inv["isChecked"] = false;
        }

        this.calculateAmounts(invList);

        this.setState({
          invList,
          paginatoin: p,
        });

        // if (clientList.length === 0) {
        //   this.getClientList();
        // }
      }
    });
  }

  async getDepositList() {
    const {
      filter: f,
      pagination: p,
      clientList,
      tabs,
      ClientCompany: cc,
    } = this.state;
    let qryStr =
      "orderBy=1&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;

    qryStr += "&clientCompanyId=" + cc.clientCompanyId;

    await userService.getDepositList(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let depositList = res.model;
        p.itemsCount = res.itemsCount;
        p.pageCount = res.pageCount;
        p.pageSize = res.pageSize;
        p.pageNumber = res.pageNumber;

        // for (let inv of invList) {
        //   inv["isChecked"] = false;
        // }

        // this.calculateAmounts(invList);

        this.setState({
          depositList,
          paginatoin: p,
        });

        // if (clientList.length === 0) {
        //   this.getClientList();
        // }
      }
    });
  }

  async getClientList() {
    await userService.getClientList().then(({ data: res }) => {
      if (!res.didError) {
        let clientList = res.model;
        this.setState({ clientList });
      }
    });
  }

  togglePopup = (inv = null) => {
    if (inv) {
      this.getInvListByDeposit(inv);
    }
    this.setState({
      isOpen: !this.state.isOpen,
      sumInvoice: 0,
      sumComisn: 0,
      sumRecieve: 0,
      quickDetail: [],
    });
  };

  async getInvListByDeposit(depo) {
    await userService.getInvByDeposit(depo.depositId).then(({ data: res }) => {
      if (!res.didError) {
        let quickDetail = res.data;
        this.calculateQuickAmounts(quickDetail);
        // this.setState({ quickDetail });
      }
    });
  }

  handleViewInv = (depo) => {
    this.togglePopup(depo);
  };

  async cancelInvoice(invId) {
    await userService.cancelInvoice(invId).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.togglePopup();
        this.getInvList(this.state.currentTab);
      } else {
        toast.error(res.errorMessage);
      }
    });
  }

  render() {
    const {
      quickDetail,
      sumInvoice,
      sumComisn,
      sumRecieve,
      isModalOpen,
    } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Deposited List" />

        <input
          className="d-none"
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept="image/*,.pdf"
          onChange={this.handleFileSelect}
        ></input>

        <MainContentComp>
          <TabViewComp
            tabs={this.state.tabs}
            onClickTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
          >
            <div className="row text-center border shadow-sm p-2 rounded d-none">
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Invoice Total</h4>
                <h1 className="text-primary">
                  {globalService.getKWD(sumInvoice)}
                </h1>
              </div>
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Commission Total</h4>
                <h1 className="text-danger">
                  {globalService.getKWD(sumComisn)}
                </h1>
              </div>
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Recieve Total</h4>
                <h1 className="text-success">
                  {globalService.getKWD(sumRecieve)}
                </h1>
              </div>
            </div>
            {this.renderClientCompany()}
            {this.getTabContent()}
          </TabViewComp>

          <ModalComp
            title="Invoice Details"
            isOpen={this.state.isOpen}
            hideModal={() => this.togglePopup(null)}
            size="xl"
          >
            <div className="modal-body">
              <div className="row text-center border shadow-sm p-2 rounded">
                <div className="col-12 col-md-4">
                  <h4 className="text-secondary">Invoice Total</h4>
                  <h1 className="text-primary">
                    {globalService.getKWD(sumInvoice)}
                  </h1>
                </div>
                <div className="col-12 col-md-4">
                  <h4 className="text-secondary">Commission Total</h4>
                  <h1 className="text-danger">
                    {globalService.getKWD(sumComisn)}
                  </h1>
                </div>
                <div className="col-12 col-md-4">
                  <h4 className="text-secondary">Recieve Total</h4>
                  <h1 className="text-success">
                    {globalService.getKWD(sumRecieve)}
                  </h1>
                </div>
              </div>

              <div className="grid-table">
                <table>
                  <thead>
                    <tr>
                      <th>Reference #</th>
                      <th>Invoice Type</th>
                      <th>Initiate Amount</th>
                      <th>Invoice Amount</th>
                      <th>Deposit Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quickDetail.map((val, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            {" "}
                            <Link
                              to={"/app/inv-detail?id=" + val.invoiceId}
                              target="_blank"
                            >
                              {" "}
                              {val.invoiceRefNo}
                            </Link>
                          </td>
                          <td>{val.invoiceType}</td>
                          <td>{val.initiateAmt}</td>
                          <td>{val.invoiceAmt}</td>
                          <td>{globalService.getLocalDate(val.depositDate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <br />
              <div className="align-right">
                <button
                  className="normal-btn"
                  onClick={() => this.togglePopup(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </ModalComp>
        </MainContentComp>
      </React.Fragment>
    );
  }

  handlePageChange = ({ currentTarget: e }) => {
    let { pagination } = this.state;
    pagination.pageSize = e.value;
    this.getInvList(this.state.currentTab);
    this.setState({ pagination });
  };

  handlePageClick = (data) => {
    let { pagination } = this.state;
    pagination.pageNumber = data.selected + 1;
    this.setState({ pagination }, () => {
      this.getInvList(this.state.currentTab);
    });
  };

  handleFilterChange = ({ currentTarget: e }) => {
    let filter = this.state.filter;
    filter[e.name] = e.value;
    this.setState({ filter });
  };

  handleDateChange = (name, val) => {
    // debugger;
    let { filter } = this.state;
    filter[name] = val;
    this.setState({ filter });
  };
  handleFilterSearch = () => {
    this.getInvList(this.state.currentTab);
  };

  handleFilterReset = () => {
    let filter = this.state.filter;
    filter.endDate = null;
    filter.startDate = null;
    filter.expireDate = null;
    filter.clientId = "";
    filter.loginId = "";
    this.setState({ filter }, () => this.getInvList(this.state.currentTab));
  };

  handleEnterKey = ({ currentTarget: e }) => {
    if (e.key === "Enter") this.getInvList(this.state.currentTab);
  };
  renderInv() {
    const {
      invList,
      pagination,
      filter,
      uploads,
      collapseNo,
      allItemCheck,
      selectedInv,
      invTotal,
      depNotes,
      sumInvoice,
      sumComisn,
      sumRecieve,
    } = this.state;
    return (
      <React.Fragment>
        <div className="row mx-0 bg-light border py-3 my-3">
          <div className="col-12 col-md-3">
            <div className="form-group">
              <h4 className="text-secondary">Selected Invoice</h4>
              <h1>{selectedInv}</h1>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <h4 className="text-secondary">Invoice Total</h4>
            <h1 className="text-primary">{globalService.getKWD(sumInvoice)}</h1>
          </div>
          <div className="col-12 col-md-3">
            <h4 className="text-secondary">Commission Total</h4>
            <h1 className="text-danger">{globalService.getKWD(sumComisn)}</h1>
          </div>
          <div className="col-12 col-md-3">
            <h4 className="text-secondary">Recieve Total</h4>
            <h1 className="text-success">{globalService.getKWD(sumRecieve)}</h1>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Notes</label>
              <textarea
                value={depNotes}
                className="form-control"
                placeholder="Enter the notes"
                rows={3}
                name="depNotes"
                onChange={this.handleTextChange}
              ></textarea>
            </div>
          </div>

          {/* <div className="col-12 col-md-3">
            <div className="form-group text-center">
              <label>Total Amount</label>
              <h2>{globalService.getKWD(invTotal)}</h2>
            </div>
          </div> */}

          <div className="col-12 col-md-6">
            <div className="form-group mt-3">
              <span
                className="btn btn-light text-secondary"
                style={{ border: "1px dashed #898989" }}
                onClick={this.handleUploadClick}
              >
                <span>File Upload</span> <Upload size={22} />
              </span>

              <div>
                {uploads.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className="bg-light border rounded text-secondary p-1 my-2"
                    >
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="w-75">
                              {" "}
                              <a
                                href={globalService.IMG_ROOT_INV + val.file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {val.name}
                              </a>
                            </td>
                            <td className="text-right">
                              {/* <Eye
                                  className="text-secondary mx-2"
                                  size={22}
                                /> */}
                              <X
                                className="text-danger mx-2"
                                onClick={() => this.handleDeleteUpload(val)}
                                size={22}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 text-right">
            <button
              className="btn btn-primary bg-brand"
              onClick={this.handleCreateDeposit}
            >
              Create
            </button>
          </div>
        </div>

        {this.renderFilter()}
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div ">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    color="primary"
                    checked={allItemCheck}
                    onChange={(e) => this.handleCheckbox(e, true)}
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                </th>
                <th>SNo</th>
                <th>Payment Ref</th>
                <th className="text-center">Invoice Amount</th>
                <th></th>
                <th className="text-center">Commission</th>
                <th className="text-center">Deposit</th>
                <th>Customer</th>
                <th>Created By</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr onClick={() => this.handleCollapseRow(idx)}>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          color="primary"
                          checked={inv.isChecked}
                          name={idx + ""}
                          onChange={(e) => this.handleCheckbox(e, false)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      </td>
                      <td>
                        {idx + 1} {inv.isChecked}
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                          {inv.invoiceRefNo}
                        </Link>
                      </td>
                      <td className="text-center">{inv.invoiceAmt} </td>
                      <td className="text-center">
                        {inv.paymentSessionInfo.length > 0 && (
                          <span className="mx-1">
                            <i className="fa fa-info-circle text-info fa-2x"></i>
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {" "}
                        {inv.paid && inv.commission}
                      </td>
                      <td className="text-center">
                        {inv.deposit && (
                          <div className="text-center">
                            <div>
                              <Check /> Deposit
                            </div>
                            <div>
                              <small>
                                {inv.depositDate &&
                                  globalService.getLocalDate(inv.depositDate)}
                              </small>
                            </div>
                          </div>
                        )}
                      </td>
                      <td>{inv.customer ? inv.customer.fullName : ""}</td>
                      <td>
                        <div>
                          <span>
                            {inv.createdInfo ? inv.createdInfo.firstName : ""}
                          </span>
                        </div>
                        {globalService.getLocalDate(inv.createdDate)}
                      </td>
                      <td className="hideable d-none">
                        <span className="onlyhover"></span>{" "}
                      </td>
                    </tr>
                    <tr
                      className={
                        idx === Number(collapseNo)
                          ? "collapse show"
                          : "collapse"
                      }
                    >
                      <td colSpan={12}>
                        {inv.paymentSessionInfo.length > 0 && (
                          <table className="my-2 bg-secondary">
                            <thead>
                              <th>Customer Paid</th>
                              <th>Commission</th>
                              <th>Receive Amount</th>
                              <th>Charge By</th>
                              <th>Calculation</th>
                            </thead>
                            <tbody>
                              {inv.paymentSessionInfo.map((ci: any, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{ci.clientAmt + ci.deliveryFees}</td>
                                    <td>{ci.deliveryFees}</td>
                                    <td>{ci.clientAmt}</td>
                                    <td>{ci.vendorChargeType}</td>
                                    <td>{ci.calculation}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}

                        {inv.paymentSessionInfo.length === 0 && (
                          <div className="text-center py-2 my-2">No Data</div>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderFilter() {
    const { showInoviceFilter, clientList, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 col-sm-8">
            {/* <button className="active-btn input-btn">
              Export Daily Report
            </button> */}
          </div>
          <div className="col-md-6 col-sm-4 align-right pt-2">
            <span
              className="my-auto font-weight-bold cursor-pointer"
              onClick={() =>
                this.setState({
                  showInoviceFilter: !this.state.showInoviceFilter,
                })
              }
            >
              Advanced Filter <Filter size={24}></Filter>
            </span>
          </div>
        </div>

        <div className={showInoviceFilter ? "d-block" : "d-none"}>
          <div className="gray-bg border">
            <div className="row">
              <div className="col-md-3 col-sm-4 d-none">
                <div className="input-div md-value">
                  <label>Created By</label>
                  <select
                    name="loginId"
                    className="input-style"
                    value={filter.loginId}
                    onChange={this.handleFilterChange}
                  >
                    <option value="">All</option>
                    {clientList.map((val, idx) => {
                      return (
                        <option key={idx} value={val.clientId}>
                          {val.firstName + " " + val.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Expire Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.expireDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("expireDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Start Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.startDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("startDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>End Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.endDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("endDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              <div className="col-12 text-">
                <button className="grey-btn" onClick={this.handleFilterReset}>
                  Reset
                </button>
                <button
                  className="active-btn"
                  onClick={this.handleFilterSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDeposit() {
    const { depositList, pagination, filter, uploads } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>#</th>
                {/* <th>Client ID</th> */}
                <th>Invoices</th>
                <th>Comment</th>
                <th>Attachment</th>
                <th>Deposited</th>
              </tr>
            </thead>
            <tbody>
              {depositList.map((dep: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {/* <td>{dep.clientCompanyId}</td> */}
                    <td>
                      {" "}
                      <button
                        className="btn-sm btn-link btn"
                        onClick={() => this.handleViewInv(dep)}
                      >
                        view
                      </button>{" "}
                    </td>
                    <td>{dep.note}</td>
                    <td>
                      {dep.refImage && (
                        <a
                          href={globalService.IMG_ROOT_INV + dep.refImage}
                          target="_blank"
                        >
                          Open
                        </a>
                      )}
                    </td>
                    <td>{globalService.getLocalDate(dep.depositDate)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderUnpaidInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
              autoComplete="off"
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice / Customer Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                {/* <th>Payment Method</th> */}
                <th>Views</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                        ? inv.customer.customerRefId + " - "
                        : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                        <td className="unpaid text-center">
                          <span
                            className="badge badge-info font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Unpaid
                          </span>
                        </td>
                      ))}
                    {/* <td></td> */}
                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderPopup(isOpen) {
    return <React.Fragment></React.Fragment>;
  }

  displayChip(valueString: string) {
    if (valueString) {
      let list = valueString.split(",");

      return list.map((val, idx) => {
        return <Chip size="small" key={idx} label={val}></Chip>;
      });
    } else {
      return <></>;
    }
  }

  displayInvLink(valueString: string) {
    if (valueString) {
      let list = valueString.split(",");
      return list.map((val, idx) => {
        return (
          <Link
            className="mx-1"
            target="_blank"
            key={idx}
            to={"/app/inv-detail?id=" + val}
          >
            Invoice {idx + 1}{" "}
          </Link>
        ); //<Chip size="small" key={idx} label={val}></Chip>;
      });
    } else {
      return <></>;
    }
  }

  renderClientCompany() {
    const { ClientCompany: cc } = this.state;
    return (
      <>
        <div className="row mx-0 border shadow-sm my-2 bg-app-gradient py-2">
          <div className="col-12">
            <label className="text-white">Client Company</label>
            <h3 className="text-white">{cc.companyName}</h3>
          </div>
        </div>
      </>
    );
  }

  // NEW code starts here

  fileInput;
  uploadFile = {
    content: "",
    fileName: "",
    extension: "",
    fName: "InvoiceDoc",
  };

  handleDepositInv = () => {
    this.toggleModal();
  };

  handleUploadClick = (e) => {
    if (this.state.uploads.length === 0) this.fileInput.click();
    else toast.error("Attachment can be only one.");
  };

  handleFileSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };
  async _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.uploadFile.content = btoa(binaryString);
    const { data: res } = await commonService.fileUpload(this.uploadFile);
    if (!res.didError) {
      let upld = this.state.uploads;
      upld.push({
        file: res.message,
        name: this.uploadFile.fileName,
      });
      this.setState({ uploads: upld });
    } else {
      toast.error("Something problem with uploading image");
    }
  }

  handleDeleteUpload = (val) => {
    let uploads = this.state.uploads;
    uploads = uploads.filter((data) => data !== val);
    this.setState({ uploads });
  };

  handleTextChange = ({ currentTarget: e }) => {
    this.setState({ [e.name]: e.value });
  };

  handleCreateDeposit = async () => {
    const {
      selectedInvList,
      depNotes,
      uploads,
      ClientCompany: cc,
    } = this.state;

    if (!depNotes) {
      toast.error("Please add notes");
      return false;
    }

    if (selectedInvList.length === 0) {
      toast.error("Please invoice to create Deposit");
      return false;
    }

    let body = {
      invoiceId: selectedInvList,
      refImage: uploads.length > 0 ? uploads[0].file : "",
      clientCompanyId: cc.clientCompanyId,
      note: depNotes,
    };

    await userService.createInvDeposit(body).then(({ data: res }) => {
      if (!res.didError) {
        this.setState({
          selectedInv: [],
          depNotes: "",
          uploads: [],
        });
        this.handleCurrentTab(this.state.tabs[0]);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });

    console.log(body);
  };
}

export default DepositList;
