import * as React from "react";
import { Link } from "react-router-dom";
import commonService from "../../services/commonService";
import InvListCard from "../invoice/inv-list-card";
import AuthContainer from "../../components/authContainer";
// import BtnCreate from "../../components/btnCreate";
export interface DashboardProps {
  history;
  d;
}

export interface DashboardState {}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  state = {
    detail: {} as any,
  };
  render() {
    return <React.Fragment>{this.renderContent()}</React.Fragment>;
  }

  componentDidMount() {
    this.getDetail();
  }

  async getDetail() {
    await commonService.getDashboard().then(({ data: res }) => {
      if (!res.didError) {
        let detail = res.data;
        this.setState({ detail });
      }
    });
  }

  renderContent() {
    const { detail: de } = this.state;

    if (!de.currentMonth) {
      return true;
    }

    return (
      <React.Fragment>
        <div className="main-contain-content-area">
          {/* <div className="my-5">
            btn : <BtnCreate label={"TEST BTN"} action="ADD"></BtnCreate>
          </div> */}
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-1">
                <p>Number of Transactions</p>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <h3>
                          {de.currentMonth.nPaidAllTrans
                            ? de.currentMonth.nPaidAllTrans
                            : 0}
                        </h3>
                        <span>Current Month</span>
                      </td>
                      <td className="w-50">
                        <h3>
                          {de.allMonth.nPaidAllTrans
                            ? de.allMonth.nPaidAllTrans
                            : 0}
                        </h3>
                        <span>Over all</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-2">
                <p>Value Of Transactions</p>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <h3>
                          {de.currentMonth.nPaidinvoiceAmt
                            ? de.currentMonth.nPaidinvoiceAmt
                            : 0}
                        </h3>
                        <span>Current Month</span>
                      </td>
                      <td className="w-50">
                        <h3>
                          {de.allMonth.nPaidinvoiceAmt
                            ? de.allMonth.nPaidinvoiceAmt
                            : 0}
                        </h3>
                        <span>Over all</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-1">
                <p>Number of Paid Transactions</p>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <h3>
                          {de.currentMonth.paidAllTrans
                            ? de.currentMonth.paidAllTrans
                            : 0}
                        </h3>
                        <span>Current Month</span>
                      </td>
                      <td className="w-50">
                        <h3>
                          {de.allMonth.paidAllTrans
                            ? de.allMonth.paidAllTrans
                            : 0}
                        </h3>
                        <span>Over all</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-2">
                <p>Value Of Paid Transactions</p>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <h3>
                          {de.currentMonth.paidinvoiceAmt
                            ? de.currentMonth.paidinvoiceAmt
                            : 0}
                        </h3>
                        <span>Current Month</span>
                      </td>
                      <td className="w-50">
                        <h3>
                          {de.allMonth.paidinvoiceAmt
                            ? de.allMonth.paidinvoiceAmt
                            : 0}
                        </h3>
                        <span>Over all</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-3">
                <p>Awaiting Balance</p>
                <h3>0.000</h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="dashbaord-box dashbaord-box-4">
                <p>Awaiting to Transfer</p>
                <h3>0.000</h3>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="row">
                <AuthContainer action="ADD" url="/app/create-inv">
                  <div className="col-md-12 col-sm-6">
                    <div className="create-box">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td style={{ padding: "10px" }}>
                              <i className="material-icons float-left">
                                description
                              </i>
                              <span>Create Invoice</span>
                              <br />
                              &emsp;&emsp;Over all
                            </td>
                            <td
                              className="box box-1 cursor-pointer"
                              onClick={() =>
                                this.props.history.push("/app/create-inv")
                              }
                            >
                              <Link
                                to="/app/create-inv"
                                className="w-100 h-100 cursor-pointer"
                              >
                                <i className="material-icons">trending_flat</i>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </AuthContainer>

                <AuthContainer action="ADD" url="/app/create-quick-inv">
                  <div className="col-md-12 col-sm-6">
                    <div className="create-box">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td style={{ padding: "10px" }}>
                              <i className="material-icons float-left">
                                flash_on
                              </i>
                              <span>Create Quick Invoice</span>
                              <br />
                              &emsp;&emsp;Over all
                            </td>
                            <td
                              className="box box-2 cursor-pointer"
                              onClick={() =>
                                this.props.history.push("/app/create-quick-inv")
                              }
                            >
                              <Link
                                to="/app/create-quick-inv"
                                className="w-100 h-100 cursor-pointer"
                              >
                                <i className="material-icons">trending_flat</i>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>{" "}
                </AuthContainer>
              </div>
            </div>
            <div className="col-md-9 col-sm-12"></div>
          </div>
          <br />

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="bg-white">
                <p className="header">Today's Invoice</p>
                <InvListCard></InvListCard>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="bg-white">
                <p className="header">Today's Paid Invoice</p>
                <InvListCard isPaidOnly={true}></InvListCard>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
