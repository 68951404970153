import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
import userService from "../../services/userService";
import { toast } from "react-toastify";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import { IInvoice } from "../../models/IInvoice";
import Joi from "joi-browser";
import ModalComp from "../../components/Modal";
import { Link } from "react-router-dom";
import { X, Upload } from "react-feather";
import commonService from "../../services/commonService";
import globalService from "../../services/globalService";
import AuthContainer from "../../components/authContainer";

export interface CreatePayLinkProps {}

export interface CreatePayLinkState {}

class CreatePayLink extends GenericForm {
  state = {
    data: {},
    uploads: [] as any,
    defaultLang: "AR",
    errors: {},
    isModalOpen: false,
    cust: {
      list: [],
      custSearch: "",
      selectedCust: {},
      mobileCode: "965"
    },
    currentCust: "Choose Customer",

    others: {
      invOption: "",
      mobCode: "",
      mobile: "",
      email: ""
    },

    enableMobile: true,
    enableEmail: true,
    isMobRequired: true,
    isEmailRequired: false,
    enableConditionBox: false,
    invItemList: [] as any,

    createItem: {
      itemName: "",
      qty: "0",
      unitPrice: "0",
      totalPrice: "" as any,
      localVal: ""
    }
  };

  fileInput;
  uploadFile = {
    content: "",
    fileName: "",
    extension: "",
    fName: "InvoiceDoc"
  };
  invOption = [
    { dName: "SMS", id: "SMS" },
    { dName: "EMAIL", id: "EMAIL" },
    { dName: "SMS & EMAIL", id: "SMS & EMAIL" },
    { dName: "LINK", id: "LINK" }
  ];
  schema = {};
  doSubmit() {
    if (this.state.data["invoiceOpen"] === "CHANGEABLE") {
      if (this.state.data["maxAmt"] === 0 || !this.state.data["maxAmt"]) {
        toast.error("Please add maximum amount");
        return true;
      }
      if (this.state.data["minAmt"] === 0 || !this.state.data["minAmt"]) {
        toast.error("Please add minimum amount");
        return true;
      }
      this.state.data["invoiceOpenInfo"] = {
        min: this.state.data["maxAmt"],
        max: this.state.data["maxAmt"]
      };
    }

    this.state.data["invoiceItemInfo"] = this.state.invItemList;
    this.state.data["invoiceLCVAmt"] = this.state.data["invoiceAmt"];
    let uploadObj: any[] = [];
    for (let up of this.state.uploads) {
      uploadObj.push({
        fileName: up.name,
        filePath: up.file,
        invoiceAttachDocId: 0,
        invoiceId: "",
        pLink: 0
      });
    }

    this.state.data["invoiceAttachedDocFile"] = uploadObj;
    this.addInvoice();
  }

  addInvoice = async () => {
    await userService
      .AddInvoicePayLink(this.state.data)
      .then(({ data: res }) => {
        if (!res.didError) {
          toast.success(res.message);
          this.props.history.push("/app/paylink-list");
        } else {
          let data = this.state.data;
          delete data["customer"];
          // console.log(data);
          this.setState({ data });
        }
      })
      .catch(ex => {
        let data = this.state.data;
        delete data["customer"];
        // console.log(data);
        this.setState({ data });
      });
  };

  componentDidMount() {
    this.setupData();
  }
  setupData() {
    let cDate = new Date();
    let newDate = new Date();
    cDate.setDate(newDate.getDate() + 3);
    let data: IInvoice = {
      // customer: {},
      customerUrl: window.location.host + "/paylink?plinkid=",
      invoiceLang: "ENG",
      invoiceAmt: "",
      invoiceAmtValue: "KWD",
      invoiceLCVAmt: "",
      invoiceType: "PAYLINK",
      invoiceOption: "LINK",
      customerRef: "",
      custMobile: "",
      custEmail: "",
      custName: "",
      mobileCode: "965",
      invoiceExpiryDate: cDate,
      reminderDays: 0,
      invoiceOpen: "FIXED",
      conditionText: "",
      condition: false,
      note: "",
      minAmt: "",
      maxAmt: "",
      invoiceTitle: "",
      invoiceAttachedDocFile: []
    };
    this.setState({ data });
    this.setupSchema();
  }

  setupSchema() {
    let scm = {
      customerUrl: Joi.string().required(),
      invoiceLang: Joi.string().required(),
      invoiceAmt: Joi.number().required(),
      invoiceAmtValue: Joi.string().required(),
      invoiceLCVAmt: Joi.number().allow("", null),
      invoiceType: Joi.string().required(),
      invoiceOption: Joi.string().required(),
      customerRef: Joi.string().allow("", null),
      custMobile: Joi.string().allow("", null),
      custEmail: Joi.string()
        .email()
        .allow("", null),
      custName: Joi.string().allow("", null),
      mobileCode: Joi.string().allow("", null),

      invoiceExpiryDate: Joi.raw().allow("", null),
      reminderDays: Joi.number().allow("", null),
      invoiceOpen: Joi.string().allow("", null),
      conditionText: Joi.string().allow("", null),
      condition: Joi.boolean().allow("", null),
      note: Joi.string().allow("", null),
      minAmt: Joi.number().allow("", null),
      maxAmt: Joi.number().allow("", null),
      invoiceTitle: Joi.string().required(),
      invoiceAttachedDocFile: Joi.array()
        .items()
        .optional()
    };
    this.schema = scm;
  }

  async getCustList(val) {
    if (val !== "") {
      let qryStr = "fullName=" + val;
      await userService.checkCustomer(qryStr).then(({ data: res }) => {
        if (!res.didError) {
          let cust = { ...this.state.cust };
          cust.list = res.model;
          this.setState({ cust });
        }
      });
    } else {
      let cust = { ...this.state.cust };
      cust.list = [];
      this.setState({ cust });
    }
  }

  handlePopup = () => {
    let cust = this.state.cust;
    cust.custSearch = "";
    this.setState({ isModalOpen: !this.state.isModalOpen, cust });
  };

  handleInputChange = ({ currentTarget: e }) => {
    const cust = { ...this.state.cust };
    cust[e.name] = e.value;
    this.getCustList(e.value);
    this.setState({ cust });
  };

  handleItemValChange = ({ currentTarget: e }) => {
    const item = { ...this.state.createItem };
    item[e.name] = e.value;
    if (e.name === "qty" && item.unitPrice) {
      item.totalPrice = Number(e.value) * Number(item.unitPrice);
      item.localVal = item.totalPrice;
    }
    if (e.name === "unitPrice" && item.qty) {
      item.totalPrice = Number(e.value) * Number(item.qty);
      item.localVal = item.totalPrice;
    }

    this.setState({ createItem: item });
  };

  handleAddItem = () => {
    let invItemList = this.state.invItemList;
    let createItem = this.state.createItem;

    if (!createItem.itemName || createItem.itemName === "") {
      toast.error("Please enter the product name");
      return true;
    }

    if (!createItem.qty || createItem.qty === "0" || createItem.qty === "") {
      toast.error("Please enter the qty");
      return true;
    }

    if (
      !createItem.unitPrice ||
      createItem.unitPrice === "0" ||
      createItem.unitPrice === ""
    ) {
      toast.error("Please enter the unit price");
      return true;
    }

    invItemList.push(this.state.createItem);
    let newCI: any = {};
    newCI.itemName = "";
    newCI.qty = "0";
    newCI.unitPrice = "0";
    newCI.totalPrice = "0.000";
    newCI.localVal = "0.000";

    this.setState({ invItemList, createItem: newCI });

    let invAmt = 0;
    for (let invLst of this.state.invItemList) {
      invAmt = Number(invAmt) + Number(invLst.totalPrice);
    }
    this.state.data["invoiceAmt"] = invAmt;
  };

  selectCust = da => {
    let cust = { ...this.state.cust };
    cust.selectedCust = da;
    let data = { ...this.state.data };
    data["custName"] = da.fullName;
    data["custEmail"] = da.email;
    data["custMobile"] = da.mobileNo.slice(3);

    let enableMobile = false,
      enableEmail = false;
    this.setState({
      cust,
      data,
      enableEmail,
      enableMobile,
      isModalOpen: !this.state.isModalOpen
    });
  };

  addNewCust = () => {
    let cust = { ...this.state.cust };
    cust.selectedCust = {};
    let data = { ...this.state.data };
    data["custName"] = this.state.cust.custSearch;
    data["custEmail"] = "";
    data["custMobile"] = "";
    let enableMobile = true,
      enableEmail = true;
    this.setState({
      cust,
      data,
      enableEmail,
      enableMobile,
      isModalOpen: !this.state.isModalOpen
    });
  };

  handleLang = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data["invoiceLang"] = e.value;
    this.setState({ data });
  };

  handleInvOpen = ({ currentTarget: e }) => {
    let data = this.state.data;
    data["invoiceOpen"] = e.value;
    this.setState({ data });
  };
  handleInvOption = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data[e.name] = e.value;

    let scms = this.schema;

    scms["custMobile"] = Joi.number().allow("", null);
    scms["custEmail"] = Joi.string()
      .email()
      .allow("", null);

    let isMobRequired = false,
      isEmailRequired = false,
      enableEmail = true,
      enableMobile = true;

    if (e.value === "SMS") {
      isMobRequired = true;
      scms["custMobile"] = Joi.number().required();
    } else if (e.value === "SMS & EMAIL") {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string()
        .email()
        .required();
    } else if (e.value === "EMAIL") {
      isEmailRequired = true;
      scms["custEmail"] = Joi.string()
        .email()
        .required();
    } else {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string()
        .email()
        .required();
    }

    this.schema = scms;
    this.validate();
    this.setState({
      data,
      isMobRequired,
      isEmailRequired,
      enableEmail,
      enableMobile
    });
  };

  handleCondition = ({ currentTarget: e }) => {
    let data = this.state.data;
    data["condition"] = e.checked;
    this.setState({ enableConditionBox: e.checked, data });
  };

  handleUploadClick = e => {
    this.fileInput.click();
  };

  handleFileSelect = evt => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };
  async _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.uploadFile.content = btoa(binaryString);
    const { data: res } = await commonService.fileUpload(this.uploadFile);
    if (!res.didError) {
      let upld = this.state.uploads;
      upld.push({
        file: res.message,
        name: this.uploadFile.fileName
      });
      this.setState({ uploads: upld });
    } else {
      toast.error("Something problem with uploading image");
    }
  }

  handleDeleteUpload = val => {
    let uploads = this.state.uploads;
    uploads = uploads.filter(data => data !== val);
    this.setState({ uploads });
  };

  render() {
    const {
      isModalOpen,
      cust,
      uploads
      // enableEmail,
      // enableMobile,
      // data,
      // isMobRequired,
      // isEmailRequired,
      // errors,
      // createItem: item,
      // invItemList: iList
    } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Create Payment Link" />

        <input
          className="d-none"
          ref={fileInput => (this.fileInput = fileInput)}
          type="file"
          accept="image/*,.pdf"
          onChange={this.handleFileSelect}
        ></input>
        <ModalComp
          title="Choose Customer"
          isOpen={isModalOpen}
          hideModal={this.handlePopup}
        >
          <div className="modal-content p-2">
            <div>
              <input
                type="text"
                name="custSearch"
                placeholder="Search by Name and Mobile"
                value={this.state.cust.custSearch}
                onChange={this.handleInputChange}
                className="form-control"
              />
            </div>

            <div
              style={{
                maxHeight: "calc(100vh - 300px",
                overflow: "hidden",
                overflowY: "auto"
              }}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {cust.list.map((da: any, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className="p-2 border my-2 bg-light cursor-pointer"
                        onClick={() => this.selectCust(da)}
                      >
                        <div>
                          {da.fullName} | {da.mobileNo} | {da.email}
                          <span className="float-right badge badge-danger my-auto">
                            Select
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}

                {cust.list.length === 0 && (
                  <React.Fragment>
                    {/* <li className="text-center mt-4"> Not found </li> */}
                    {cust.custSearch && (
                      <li className="my-2">
                        <div className="text-right">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.addNewCust}
                          >
                            Add as new customer
                          </button>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </ModalComp>
        <MainContentComp>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-md-3 col-sm-4">
                {this.renderInput("invoiceTitle", "Payment URL Title", true)}
              </div>
              <div className="col-md-3 col-sm-4">
                {this.renderInput(
                  "invoiceAmt",
                  "Invoice Amount",
                  true,
                  false,
                  "number"
                )}
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Display currency</label>
                  <select
                    className="input-style"
                    placeholder="Display currency"
                    name="invoiceAmtValue"
                    value={this.state.data["invoiceAmtValue"]}
                    onChange={this.handleChange}
                  >
                    <option value="KWD">Kuwait (KD)</option>
                    <option value="SAR">Saudi Arabia (SR)</option>
                    <option value="KWD">Bahrain (BD)</option>
                  </select>
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                <label>Language</label>
                <div>
                  <div className="radio">
                    <input
                      id="invoiceLang1"
                      name="radio"
                      type="radio"
                      value="ENG"
                      checked={this.state.data["invoiceLang"] === "ENG"}
                      onChange={e => this.handleLang(e)}
                    />
                    <label htmlFor="invoiceLang1" className="radio-label">
                      English
                    </label>
                  </div>

                  <div className="radio">
                    <input
                      id="invoiceLang2"
                      name="radio"
                      type="radio"
                      value="AR"
                      checked={this.state.data["invoiceLang"] === "AR"}
                      onChange={e => this.handleLang(e)}
                    />
                    <label htmlFor="invoiceLang2" className="radio-label">
                      Arabic
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <label>Is Open Invoice?</label>
                <div>
                  <div className="radio">
                    <input
                      id="invoiceOpen2"
                      name="invoiceOpen"
                      type="radio"
                      value="FIXED"
                      onChange={e => this.handleInvOpen(e)}
                      checked={this.state.data["invoiceOpen"] === "FIXED"}
                    />
                    <label htmlFor="invoiceOpen2" className="radio-label">
                      Fixed
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      id="invoiceOpen1"
                      name="invoiceOpen"
                      type="radio"
                      value="CHANGEABLE"
                      checked={this.state.data["invoiceOpen"] === "CHANGEABLE"}
                      onChange={e => this.handleInvOpen(e)}
                    />
                    <label htmlFor="invoiceOpen1" className="radio-label">
                      Changable
                    </label>
                  </div>
                </div>
              </div>

              {this.state.data["invoiceOpen"] === "CHANGEABLE" && (
                <div className="col-md-3 col-sm-4">
                  {this.renderInput(
                    "minAmt",
                    "minimum Amount",
                    true,
                    false,
                    "number"
                  )}
                </div>
              )}
              {this.state.data["invoiceOpen"] === "CHANGEABLE" && (
                <div className="col-md-3 col-sm-4">
                  {this.renderInput(
                    "maxAmt",
                    "Maximum Amount",
                    true,
                    false,
                    "number"
                  )}
                </div>
              )}

              <div className="col-md-6  col-sm-8">
                {this.renderTextarea("note", "Comments", 2)}
              </div>

              <div className="col-md-3 col-sm-4">
                <label>Terms And Conditions</label>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="styled-checkbox"
                          id="condition1"
                          type="checkbox"
                          checked={this.state.data["condition"]}
                          onChange={this.handleCondition}
                        />
                        <label htmlFor="condition1"></label>
                      </td>
                      <td>Is Enabled ?</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {this.state["enableConditionBox"] && (
                <div className="col-md-3 col-sm-4">
                  {this.renderTextarea(
                    "conditionText",
                    "Terms And Conditions",
                    2
                  )}
                </div>
              )}

              <div className="col-12 col-md-5">
                <span
                  className="btn btn-light text-secondary"
                  style={{ border: "1px dashed #898989" }}
                  onClick={this.handleUploadClick}
                >
                  <span>File Upload</span> <Upload size={22} />
                </span>

                <div>
                  {uploads.map((val, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-light border rounded text-secondary p-1 my-2"
                      >
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="w-75">
                                <a
                                  href={globalService.IMG_ROOT_INV + val.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {val.name}
                                </a>
                              </td>
                              <td className="text-right">
                                {/* <Eye
                                  className="text-secondary mx-2"
                                  size={22}
                                /> */}
                                <X
                                  className="text-danger mx-2"
                                  onClick={() => this.handleDeleteUpload(val)}
                                  size={22}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </form>
          <br />
          <div className="my-3">
            <Link to="/app/inv-list" className="deactive-btn">
              Back to list
            </Link>

            <AuthContainer action="ADD">
              <button
                className="active-btn"
                disabled={this.validate()}
                type="submit"
                onClick={this.handleSubmit}
              >
                Create
              </button>
            </AuthContainer>
          </div>

          <br />
          <br />
        </MainContentComp>
      </React.Fragment>
    );
  }
}

export default CreatePayLink;
