import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
import searchIcon from "../../assets/img/search_icon.png";
import userService from "../../services/userService";
import globalService from "../../services/globalService";
import ModalComp from "../../components/Modal";
import { Eye } from "react-feather";
import CopyToClipboard from "./../../components/CopyToClipboard";

export interface PaymentLinkListProps {
  history;
}

export interface PaymentLinkListState {}

class PaymentLinkList extends React.Component<
  PaymentLinkListProps,
  PaymentLinkListState
> {
  state = {
    // tabs: ["Invoices", "Legacy Invoices"],
    // currentTab: "Invoices",
    linkList: [],
    isOpen: false,
    pagination: {
      pageSize: 20,
      pageNumber: 1
    },
    filter: {
      search: ""
    }
  };

  componentDidMount() {
    this.getLinkList();
  }

  async getLinkList() {
    const { pagination: p, filter: f } = this.state;

    let qryStr =
      "orderBy=1&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;
    if (f.search && f.search !== "") {
      qryStr += "&fullName=" + f.search;
    }
    // if (f.isPaid) {
    //   qryStr += "&paid=" + (f.isPaid === 1 ? true : false);
    // }
    // if (f.invRef && f.invRef !== "") {
    //   qryStr += "&customerId=" + f.invRef;
    // }
    // if (f.invId && f.invId !== "") {
    //   qryStr += "&invoicePLinkId=" + f.invId;
    // }

    await userService.getPaylinkList(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let linkList = res.model;
        this.setState({ linkList });
      }
    });
  }

  togglePopup = (inv = null) => {
    if (inv) {
      // this.getQuickDetail(inv);
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    // const { quickDetail: qd } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Payment Invoices List" />
        <MainContentComp>
          <ModalComp
            title="Link"
            isOpen={this.state.isOpen}
            hideModal={() => this.togglePopup(null)}
            size="xl"
          >
            <div className="modal-body">Add link contedfnd</div>
          </ModalComp>
          {this.renderInv()}
        </MainContentComp>
      </React.Fragment>
    );
  }

  handlePageChange = ({ currentTarget: e }) => {
    let { pagination } = this.state;
    pagination.pageSize = e.value;
    this.getLinkList();
    this.setState({ pagination });
  };

  handleFilterChange = ({ currentTarget: e }) => {
    let filter = this.state.filter;
    filter[e.name] = e.value;
    this.setState({ filter });
  };

  handleFilterSearch = () => {
    this.getLinkList();
  };

  renderInv() {
    const { linkList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          {/* <div className="col-12 text-right">
            <Link to="/app/create-paylink" className="normal-btn">
              Create
            </Link>
          </div> */}
          <div className="col-md-3 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-sm-4"></div>
          <div className="col-md-3 col-sm-4 input-div">
            <input
              type="text"
              name="search"
              value={filter.search}
              onChange={this.handleFilterChange}
              onBlur={this.handleFilterSearch}
            />
            <img src={searchIcon} className="search_icon" alt="" />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Reference No</th>
                <th>Created Date</th>
                <th>Payment Amount</th>
                <th>Is Open Amount</th>
                <th>Pay Link</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {linkList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>{inv.invoiceRefNo}</td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOpen}</td>
                    <td>
                      <CopyToClipboard>
                        {({ copy }) => (
                          <span
                            className="text-secondary hideable"
                            onClick={() => copy(inv.customerUrl)}
                          >
                            {inv.customerUrl}
                            <small
                              style={{ fontWeight: 100 }}
                              className="float-right onlyhover cursor-pointer  bg-dark rounded text-white px-1"
                            >
                              COPY
                            </small>
                          </span>
                        )}
                      </CopyToClipboard>
                    </td>
                    <td>
                      <div className="text-center cursor-pointer text-secondary">
                        <Eye
                          size={20}
                          onClick={() =>
                            this.props.history.push(
                              "/app/inv-list?invoicePLinkId=" +
                                inv.invoicePLinkId
                            )
                          }
                        ></Eye>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentLinkList;
