import * as React from "react";
import { Component } from "react";
import Joi from "joi-browser";
import GFInput from "./GFInput";
import GFTextarea from "./GFTextarea";
import GFSelect from "./GFSelect";

class GenericForm extends Component<any> {
  state = { data: {}, errors: {} };
  schema = {};
  doSubmit() {}

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const { error } = Joi.validate(obj, { [name]: this.schema[name] });
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: e }) => {
    const data = { ...this.state.data };
    data[e.name] = e.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e);
    if (errorMessage) errors[e.name] = errorMessage;
    else delete errors[e.name];
    this.setState({ data, errors });
  };

  validate = () => {
    const option = {
      abortEarly: false
    };
    const { error } = Joi.validate(this.state.data, this.schema, option);
    if (!error) return null;

    const errors: any = {};
    for (let er of error.details) errors[er.path[0]] = er.message;
    return errors;
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    this.doSubmit();
  };

  renderButton(label: string) {
    return (
      <button disabled={this.validate()} type="submit" className="active-btn">
        {label}
      </button>
    );
  }

  renderInput(
    name: string,
    label: string,
    isRquired = false,
    disabled = false,
    type = "text"
  ) {
    const { data, errors } = this.state;
    return (
      <GFInput
        type={type}
        value={data[name]}
        name={name}
        isRequired={isRquired}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        placeholder={label}
        disabled={disabled}
        exClass={true}
      />
    );
  }

  renderTextarea(name, label, rows = 3) {
    const { data, errors } = this.state;
    return (
      <GFTextarea
        rows={rows}
        value={data[name]}
        name={name}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        placeholder={label}
        exClass={true}
      />
    );
  }

  renderSelect(name, label, listData, valueField) {
    const { data, errors } = this.state;

    return (
      <GFSelect
        onChange={this.handleChange}
        error={errors[name]}
        placeholder={label}
        name={name}
        value={data[name]}
        label={label}
        listData={listData}
        valueField={valueField}
      />
    );
  }
}

export default GenericForm;
