import moment from "moment";
import { AppConstants } from './../common/appConfig';


const IMG_ROOT = AppConstants.IMAGE_PATH
const NOT_FOUND_IMG = '8bc9be3c-31e1-43ec-acec-8adce09de2ee.jpg'
export const API_ROOT = AppConstants.API_ROOT
export const IMG_ROOT_INV = AppConstants.IMAGE_PATH + 'InvoiceDoc/'
export const IMG_ROOT_APP = AppConstants.IMAGE_PATH + 'AppImages/'
export const IMG_ROOT_LOGO = AppConstants.IMAGE_PATH + 'Companylogo/';
export const BASE_ROOT = AppConstants.BAST_ROOT;

export const SESS_SEL_COMP = "scd";


// https://papi.hosting-tracking.com/api/v1/Invoice/invAPi?secureId=42316e39-caf3-4012-8109-b52842e98458&customerRef=ameer&amt=18.500
// export const SECURE_API = API_ROOT + "";

export const USER_TYPE = {
    SUPERADMIN: 'SPAdmin'
};
//  'http://p.princessq8.com/api/v1/'

export function getImg(img: string) {
    let file = img !== '' ? img : NOT_FOUND_IMG;
    return IMG_ROOT + file;
}

export function getCompanyLogo(img: string) {
    // let file = img !== '' ? img : NOT_FOUND_IMG;
    return IMG_ROOT_LOGO + img;
}

export function generateXAPIKey() {
    let url: URL = new URL(API_ROOT);
    let key = btoa(url.hostname + '~Zpay');
    return key;
    // return "emFwaS56YWppbC5jb21+WnBheQ==";
}

export function getCurrentHost() {
    let url: URL = new URL(window.location.hash);
    return url;
}

export function setPreviousPath() {
    sessionStorage.setItem("prevPath", window.location.pathname + window.location.search);
}

export function getPreviousPath() {
    return sessionStorage.getItem("prevPath") ? sessionStorage.getItem("prevPath") : "/";
}

export function getLocalDate(utcDate, format = 'DD/MM/YYYY hh:mm a'): any {
    let temp = new Date(utcDate);
    return moment(new Date(temp.getTime() -
        (temp.getTimezoneOffset() * 60 * 1000)).toLocaleString()).format(format);
}

export function checkIsExpired(utcDate): any {
    var expiration = getLocalDate(utcDate, "YYYY-MM-DD HH:mm");
    var current_date = moment().format("YYYY-MM-DD HH:mm");
    // var days = moment(expiration).diff(current_date, 'm');
    // var isExpired = moment(expiration).isBefore(current_date, "m");
    return moment(expiration).isBefore(current_date, "m");
}

export function getKWD(amount: Number) {
    return amount.toFixed(3);
}

export function linkCheck(url) {
    if (!url.match(/^http?:\/\//i) || !url.match(/^https?:\/\//i))
        return 'http://' + url;
    else
        return url;
}

export default {
    getImg, getCompanyLogo, generateXAPIKey, getCurrentHost,
    BASE_ROOT, API_ROOT, IMG_ROOT_INV, IMG_ROOT_APP, IMG_ROOT_LOGO,
    USER_TYPE, SESS_SEL_COMP,
    getLocalDate, checkIsExpired, getKWD,
    setPreviousPath, getPreviousPath, linkCheck

}