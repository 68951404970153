import * as React from "react";

export interface TabViewCompProps {
  tabs: any[];
  currentTab;
  onClickTab;
  children;
}

const TabViewComp: React.SFC<TabViewCompProps> = ({
  tabs,
  currentTab,
  onClickTab,
  children
}) => {
  return (
    <React.Fragment>
      <ul className="nav nav-pills">
        {tabs.map((tab, idx) => {
          return (
            <li
              key={idx}
              className="nav-item cursor-pointer"
              onClick={() => onClickTab(tab)}
            >
              <span
                className={`nav-link cursor-pointer  ${
                  currentTab === tab ? "active" : ""
                }`}
              >
                {tab}
              </span>
            </li>
          );
        })}
      </ul>

      <div className="tab-content">
        <div className="tab-pane active">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default TabViewComp;
