import * as React from "react";
import { AuthState } from "./../../../stores/authStore";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminMain from "./AdminMain";
import { ApplicationState, ApplicationAction } from "./../../../stores/index";
import { connect } from "react-redux";
import globalService from "../../../services/globalService";

export interface AdminTempProps {
  history;
  checkAuth;
  auth: AuthState;
}

export interface AdminTempState {}

class AdminTemp extends React.Component<AdminTempProps, AdminTempState> {
  state = {};

  componentWillMount() {
    this.checkLogin();
  }

  checkLogin = async () => {
    await this.props.checkAuth();
    if (!this.props.auth.isLoggedIn) {
      globalService.setPreviousPath();
      this.props.history.push("/login");
    }
  };

  render() {
    return (
      <React.Fragment>
        <AdminHeader history={this.props.history} />
        <div className="body-contain">
          <AdminSidebar history={this.props.history} />
          <AdminMain />
        </div>
      </React.Fragment>
    );
  }
}

// export default AdminTemp;

const mapActionToProps = {
  checkAuth: ApplicationAction.auth.checkAuth
};

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth
  }),
  mapActionToProps
)(AdminTemp);
