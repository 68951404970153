import queryString from "query-string";
import * as React from "react";
import { toast } from "react-toastify";
import domainImg from "../../assets/img/domain.png";
import GFSelect from "../../components/genericFormComponents/GFSelect";
import SocialMediaSet from "../../components/SocialMediaSet";
import globalService from "../../services/globalService";
import userService from "../../services/userService";
import GFInput from "./../../components/genericFormComponents/GFInput";

export interface PayLinkProps {
  history;
}

export interface PayLinkState {}

class PayLink extends React.Component<PayLinkProps, PayLinkState> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    this.state.search = search;
  }
  invD: any;
  state = {
    invDetail: this.invD,
    search: {},
    type: "",
    id: "",
    payMode: "",
    payURL: "",
    amount: 0,
    min: 0,
    max: 0,
    isChangable: false,
    isTcChecked: false,
    inv: {
      name: "",
      code: "965",
      mobile: "",
      email: "",
      comment: "",
    },
    countryList: [],
  };
  componentWillMount() {
    this.checkPageType(this.state.search);
  }
  checkPageType(search) {
    let { type, id } = this.state;
    if (search) {
      let keyName = Object.keys(search)[0];
      id = search[keyName];
      switch (keyName) {
        case "plinkid":
          type = keyName;
          //   this.accountVerify(id);
          //   debugger;
          this.getInvDetail(id);
          break;
        case "plinkiddd":
          type = keyName;
          break;
        default:
          toast.error("Page key isn't valid, Please contact administrator");
          this.props.history.push("/");
          break;
      }
      this.setState({ type, id });
    } else {
      this.props.history.push("/");
    }
  }

  async getInvDetail(id) {
    await userService
      .getPaylinkList("invoicePLinkId=" + id)
      .then(({ data: res }) => {
        if (res.model.length > 0) {
          let invDetail = res.model[0];
          this.setState({ invDetail });
          this.getCountryList();
        }
      });
  }

  async getCountryList() {
    await userService.getCountries().then(({ data }) => {
      if (!data.didError) {
        for (let d of data.data) {
          d["dName"] = d.niceName + " (" + d.phoneCode + ")";
        }
        this.setState({ countryList: data.data });
      }
    });
  }

  handleChangeAmount = ({ currentTarget: e }) => {
    if (e.type === "checkbox") this.setState({ isTcChecked: e.checked });
    else this.setState({ amount: e.value });
  };

  handlePay = () => {
    let { amount, min, max, isChangable, payURL } = this.state;
    if (isChangable) {
      if (amount) {
        if (amount > max || amount < min) {
          toast.error("Your amount not allow by provider");
          return true;
        } else {
          payURL = payURL + "?amtIN=" + amount;
        }
      }
    }
    window.location.replace(payURL);
  };

  handleChange = ({ currentTarget: e }) => {
    let inv = this.state.inv;
    inv[e.name] = e.value;
    this.setState({ inv });
  };

  paySubmit = () => {
    const { inv } = this.state;

    if (!inv.name || inv.name === "") {
      toast.error("Please enter customer name");
      return true;
    }

    if (inv.code === "default") {
      toast.error("Please select country code");
      return true;
    }

    if (!inv.mobile || inv.mobile === "") {
      toast.error("Please enter mobile number");
      return true;
    }
    this.createCustInv();
  };

  createCustInv = async () => {
    const { inv, invDetail: invD } = this.state;
    let body = {
      customerInfo: {
        fullName: inv.name,
        mobileNo: inv.code + inv.mobile,
        email: inv.email,
        clientCompanyId: invD.clientCompanyId,
      },
      invoicePLinkId: this.state.id,
      invoiceLang: invD.invoiceLang,
      invoiceType: "PAYLINK",
      invoiceOption: invD.invoiceOption,
      customerRef: invD.customerRef,
      invoiceRefNo: invD.invoiceRefNo,
      customerUrl: window.location.host + "/payment?invid=",
      clientCompanyId: invD.clientCompanyId,
      invoiceLCVAmt: invD.invoiceLCVAmt,
      invoiceAmt: invD.invoiceAmt,
      invoiceAmtValue: invD.invoiceAmtValue,
      invoiceExpiryDate: invD.invoiceExpiryDate,
    };
    await userService.createCustInvoicePLink(body).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.props.history.replace("/payment?invid=" + res.model.invoiceId);
      }
    });
  };

  render() {
    let { invDetail: det, inv, countryList } = this.state;

    if (det) {
    }

    return (
      <React.Fragment>
        {det && (
          <div className="paymentPage font-weight-bold">
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: 20,
              }}
            >
              <div className="invoice-template-logo">
                <table className="width-100">
                  <tbody>
                    <tr>
                      <td className="width-d-2">
                        <img
                          src={det["company"].logo}
                          style={{ height: 50 }}
                          alt=""
                        />
                      </td>
                      <td className="align-right width-d-2">
                        <a
                          href={globalService.linkCheck(
                            det["company"].companyURL
                          )}
                        >
                          <span>Visit the site</span>{" "}
                          <img src={domainImg} className="site-img" alt="" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="invoice-template-header bg-dark-blue rounded-top">
                <table className="width-100">
                  <tbody>
                    <tr>
                      <td className="width-d-2">
                        <p className="invoice-header">
                          {det["company"].companyName}
                        </p>
                      </td>
                      <td className="align-right width-d-2">
                        <SocialMediaSet
                          mediaList={
                            det["companySocialMedia"]
                              ? det["companySocialMedia"]
                              : []
                          }
                        ></SocialMediaSet>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="invoice-template-new bg-light-blue rounded-bottom">
                <div className="text-center my-3">
                  <div className="input-div-style">
                    <h3>Total Invoice</h3>
                    <h1 className="text-white">{det.invoiceAmt}</h1>
                  </div>
                </div>

                <hr />

                <div className="row mx-0">
                  <div className="col 6 form-group">
                    <div className="input-div default-label">
                      <label>Payment Link Reference</label>
                      <p>{det.invoiceRefNo} </p>
                    </div>
                  </div>
                  <div className="col 6 form-group">
                    <div className="input-div default-label">
                      <label>Payment Url Title</label>
                      <p>{det.invoiceTitle} </p>
                    </div>
                  </div>
                  <div className="col-12 form-group">
                    <GFInput
                      name="name"
                      label="Customer Name"
                      value={inv.name}
                      isRequired={true}
                      onChange={this.handleChange}
                      type="text"
                      error=""
                      placeholder=""
                      disabled={false}
                    ></GFInput>
                  </div>

                  <div className="col-6 form-group">
                    <div className="row">
                      <div className="col-4 pr-0 paylink">
                        <GFSelect
                          name="code"
                          label="Country"
                          value={inv.code}
                          onChange={this.handleChange}
                          error=""
                          placeholder="Country"
                          listData={countryList}
                          valueField="phoneCode"
                        ></GFSelect>
                      </div>
                      <div className="col-8 pl-0">
                        <GFInput
                          name="mobile"
                          label="Customer Mobile"
                          value={inv.mobile}
                          isRequired={true}
                          onChange={this.handleChange}
                          type="number"
                          error=""
                          placeholder=""
                          disabled={false}
                        ></GFInput>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 form-group">
                    <GFInput
                      name="email"
                      label="Customer Email"
                      value={inv.email}
                      isRequired={false}
                      onChange={this.handleChange}
                      type="email"
                      error=""
                      placeholder="Optional"
                      disabled={false}
                    ></GFInput>
                  </div>
                </div>
                <hr />
                <div className="text-center payment-option">
                  <button
                    className="Login-button btn-shadow"
                    onClick={this.paySubmit}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PayLink;
