import React from "react";

export interface InputCompProps {
  name: any;
  label: any;
  value: any;
  img: any;
  onChange: any;
  type: any;
  error: any;
  placeholder: string;
  exClass?: boolean;
}

export interface InputCompState {}

class InputComp extends React.Component<InputCompProps, InputCompState> {
  state = {
    type: this.props.type,
    isPassword: this.props.type === "password" ? true : false
  };

  handleShowHide = () => {
    let { type } = this.state;
    if (type === "text") type = "password";
    else type = "text";
    this.setState({ type });
  };
  render() {
    const { name, label, error, img, exClass, ...rest } = this.props;
    const { type, isPassword } = this.state;
    return (
      // <div className="form-group">
      //   {/* <label htmlFor={name}> {label}</label> */}
      //   <input
      //     {...rest}
      //     type={type}
      //     id={name}
      //     name={name}
      //     className={`form-control  ${exClass ? "trans-input" : ""}`}
      //   />

      //   {isPassword &&
      //     !error &&
      //     (type === "password" ? (
      //       <span className="showHide" onClick={this.handleShowHide}>
      //         <i className="fa fa-eye" />
      //       </span>
      //     ) : (
      //       <span className="showHide" onClick={this.handleShowHide}>
      //         <i className="fa fa-eye-slash" />
      //       </span>
      //     ))}
      //   {error && <div className="small text-danger">{error}</div>}
      // </div>

      <div className="login-input position-relative mb-3">
        <img src={img} alt="" />
        <input className="mb-0" {...rest} type={type} id={name} name={name} />
        {isPassword &&
          !error &&
          (type === "password" ? (
            <span className="showHide" onClick={this.handleShowHide}>
              <i className="fa fa-eye" />
            </span>
          ) : (
            <span className="showHide" onClick={this.handleShowHide}>
              <i className="fa fa-eye-slash" />
            </span>
          ))}
        {error && <div className="small text-danger">{error}</div>}
      </div>
    );
  }
}

export default InputComp;
