import * as React from "react";
import queryString from "query-string";
import UpdateProfile from "../profile/update-profile";

export interface CompanyDetailProps {
  location;
  history;
}

export interface CompanyDetailState {}

class CompanyDetail extends React.Component<
  CompanyDetailProps,
  CompanyDetailState
> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    this.state.search = search;
    this.state.id = search.id;
  }
  state = {
    search: "" as any,
    id: "",
    detail: {} as any
  };

  componentDidMount() {
    // this.getCompanyDetail();
  }

  //   async getCompanyDetail() {
  //     let qry = "clientCompanyId=" + this.state.id;
  //     await companyService.getClientCompanyById(qry).then(({ data: res }) => {
  //       if (!res.didError) {
  //         let detail = res.model;
  //         this.setState({ detail });
  //       }
  //     });
  //   }

  render() {
    return (
      <React.Fragment>
        {/* <TitleBarComp title="Company Detail"></TitleBarComp> */}
        <UpdateProfile
          location={this.props.location}
          history={this.props.history}
        ></UpdateProfile>
      </React.Fragment>
    );
  }
}

export default CompanyDetail;
