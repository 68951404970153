import { Icon, IconButton } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WOW } from "wowjs/dist/wow";
import "../assets/css/landing.css";
import appStore from "../assets/img/land/appStore.png";
import familyImg from "../assets/img/land/familyImg.jpg";
import gbi1 from "../assets/img/land/gbi1.png";
import gbi2 from "../assets/img/land/gbi2.png";
import gbi3 from "../assets/img/land/gbi3.png";
import gbi4 from "../assets/img/land/gbi4.png";
import gbi5 from "../assets/img/land/gbi5.png";
import girlsImg from "../assets/img/land/girlsImg.jpg";
import googlePlay from "../assets/img/land/googlePlay.png";
import icon1 from "../assets/img/land/icon1.png";
import icon2 from "../assets/img/land/icon2.png";
import icon3 from "../assets/img/land/icon3.png";
import icon4 from "../assets/img/land/icon4.png";
import logo from "../assets/img/land/logo.png";
import mobileImg from "../assets/img/land/mobiles.png";
import rightIcon from "../assets/img/land/rightIcon.png";
import si1 from "../assets/img/land/si1.png";
import si2 from "../assets/img/land/si2.png";
import si3 from "../assets/img/land/si3.png";
import { actionCreators, AuthState } from "./../stores/authStore";
import { ApplicationState } from "./../stores/index";

export interface LandingPageProps {
  auth: AuthState;
  checkAuth;
  history;
  logout;
}

export interface LandingPageState { }

class LandingPage extends React.Component<LandingPageProps, LandingPageState> {
  state = {};

  componentDidMount() {
    new WOW().init();
    this.getAuth();
  }

  async getAuth() {
    await this.props.checkAuth();
  }

  handleToApp = () => {
    this.props.history.push("/app");
  };
  handleLogout = async () => {
    await this.props.logout();
    if (this.props.auth.isRespSuccess) {
      this.props.history.replace("/login");
    }
  };

  render() {
    return (
      <>
        <div
          className="position-relative"
          style={{
            height: "100vh",
            background: "#f6f4f9",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <div className="landing-bg">
            <div className="clr-shadow">
              <div className="container my-2">
                <div className="row header">
                  <div className="col">
                    <img src={logo} alt="" />
                  </div>
                  <div className="col text-right my-auto text-white">
                    {this.props.auth.isLoggedIn && (
                      <>
                        {/* <Link to="/app" className="login-style"> */}
                        <span
                          className="cursor-pointer"
                          onClick={this.handleToApp}
                        >
                          {/* <img src={userIcon} alt="" />{" "} */}
                          <IconButton size="medium" className="ml-2 iconColor">
                            <Icon
                              className="iconColor my-auto mx-1"
                              fontSize="small"
                            >
                              account_circle
                            </Icon>{" "}
                          </IconButton>
                          <span className="d-none d-md-inline text-white">
                            {this.props.auth.userDetail.email}
                          </span>
                        </span>
                        {/* </Link> */}

                        <IconButton
                          title="Logout"
                          size="medium"
                          className="ml-2 iconColor"
                          onClick={this.handleLogout}
                        >
                          <Icon fontSize="small">exit_to_app</Icon>
                        </IconButton>
                      </>
                    )}

                    {!this.props.auth.isLoggedIn && (
                      <Link to="/login" className="login-style">
                        <i className="fa fa-user-circle-o fa-lg"></i> Login
                        {/* <img src={userIcon} sizes="15" alt="" /> Login */}
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div className="content-center">
                <h1 className="textb-primary title">Secured Hassle - Free</h1>
                <h3 className="sub-title">Online Payment</h3>
                <p className="text-white">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum molestiae nulla delectus quisquam sapiente totam
                  voluptatem obcaecati, enim consequuntur neque pariatur sequi
                  aspernatur. Tempora totam soluta dolorem hic quia quo.
                </p>

                <button className="btn btn-brand btn-lg my-2">
                  Get Started
                </button>
              </div>
            </div>
          </div>

          <div className="landing-sec container">
            <div className=" bg-white shadow service-container">
              <div className="row no-gutters mx-0">
                <div className="col-6 col-md-3 text-center border-right">
                  <img src={icon1} alt="" />
                  <h4 className="text-dark">Transfer Money</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iste, blanditiis eos! Maiores voluptates, deleniti hic ipsa
                    placeat
                  </p>
                </div>
                <div className="col-6 col-md-3 text-center border-right">
                  <img src={icon2} alt="" />
                  <h4 className="text-dark">Fully Encrypted</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iste, blanditiis eos! Maiores voluptates, deleniti hic ipsa
                    placeat
                  </p>
                </div>
                <div className="col-6 col-md-3 text-center border-right">
                  <img src={icon3} alt="" />
                  <h4 className="text-dark">Instand Case</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iste, blanditiis eos! Maiores voluptates, deleniti hic ipsa
                    placeat
                  </p>
                </div>
                <div className="col-6 col-md-3 text-center">
                  <img src={icon4} alt="" />
                  <h4 className="text-dark">ٍSafe and Secure</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iste, blanditiis eos! Maiores voluptates, deleniti hic ipsa
                    placeat
                  </p>
                </div>
              </div>
            </div>

            <div className="serivce-spacing"></div>
            <div className="py-5 text-center">
              <div className="sec-title-center-line">
                Simple integration on All services
              </div>
              <p className="sec-sub-title">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Mollitia maiores quod natus dolores minima
              </p>
            </div>

            <div className="row my-4">
              <div className="col-12 col-md-4 my-1">
                <div className="shadow bg-white mx-1 mx-md-1">
                  <div className="icon-bg">
                    <img src={si1} alt="" />
                  </div>
                  <h4>Secure Payment</h4>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.{" "}
                  </p>
                </div>{" "}
              </div>
              <div className="col-12 col-md-4 my-1">
                <div className="shadow bg-white mx-1 mx-md-1">
                  <div className="icon-bg" style={{ background: "#f3cce3" }}>
                    <img src={si2} alt="" />
                  </div>
                  <h4>Online Transaction</h4>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.{" "}
                  </p>
                </div>{" "}
              </div>
              <div className="col-12 col-md-4 my-1">
                <div className="shadow bg-white mx-1 mx-md-1">
                  <div className="icon-bg" style={{ background: "#bdf9d0" }}>
                    <img src={si3} alt="" />
                  </div>
                  <h4>Fast Settlement</h4>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section
            className="bg-white sec-payment rounded-0"
            style={{ boxShadow: "none" }}
          >
            <div className="row mx-0">
              <div className="col-12 col-md-6 text-center">
                <img src={familyImg} alt="" className="sec-side-img" />
              </div>

              <div className="col-12 col-md-6">
                <h1 className="sec-title-left-line mt-4">
                  Your all-in-one payment partner
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero, nemo assumenda at, laudantium.
                </p>

                <div className="list-content">
                  <div className="row mx-0">
                    <div className="col-2">
                      <img src={rightIcon} alt="" />
                    </div>
                    <div className="col">
                      <h3>Easy setups</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Amet praesentium porro debitis cum, iusto deleniti
                        voluptas maxime dignissimos dolorem expedita veritatis
                        nulla placeat
                      </p>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-2">
                      <img src={rightIcon} alt="" />
                    </div>
                    <div className="col">
                      <h3>Secure Transaction</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Amet praesentium porro debitis cum, iusto deleniti
                        voluptas maxime dignissimos dolorem expedita veritatis
                        nulla placeat
                      </p>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-2">
                      <img src={rightIcon} alt="" />
                    </div>
                    <div className="col">
                      <h3>Advanced fraud protection</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Amet praesentium porro debitis cum, iusto deleniti
                        voluptas maxime dignissimos dolorem expedita veritatis
                        nulla placeat
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="sec-payment">
            <div className="row mx-0 no-gutters">
              <div className="col-12 col-md-6 p-3 pl-4">
                <div className="">
                  <h1 className="sec-title-left-line mt-4">
                    Accept payments in less than a second with us
                  </h1>
                  <p className="my-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Libero, nemo assumenda at, laudantium.
                  </p>

                  <p className="my-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptas rerum dolorum consectetur beatae earum delectus
                    nostrum repellat quos voluptates, harum consequatur quisquam
                    ipsum sequi maiores quae magni reprehenderit. Consequuntur,
                    velit?
                  </p>

                  <p className="my-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Libero, nemo assumenda at, laudantium.
                  </p>

                  <div className="my-5">
                    <button className="btn btn-brand btn-lg">
                      Our Services
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 text-center">
                <img className="sec-side-img" src={girlsImg} alt="" />
              </div>
            </div>
          </section>

          <section className="bg-gradient-green py-5">
            <div className="text-center">
              <h1 className="sec-title-center-line">
                Everything is you need to grow your business
              </h1>

              <div className="container text-white">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Necessitatibus odit expedita ipsa in, enim error voluptas
                  doloremque natus tempore, quam, at ea. Praesentium nam illum
                  natus magni obcaecati, voluptates ex.
                </p>
                <div className="row text-center justify-content-center">
                  <div className="col-6 col-md-2 my-1 text-center">
                    <img src={gbi1} alt="" />
                    <h4>Merchant Process</h4>
                  </div>
                  <div className="col-6 col-md-2 my-1 text-center">
                    <img src={gbi2} alt="" />
                    <h4>Shopping Cart</h4>
                  </div>
                  <div className="col-6 col-md-2 my-1 text-center">
                    <img src={gbi3} alt="" />
                    <h4>Instant Insight</h4>
                  </div>
                  <div className="col-6 col-md-2 my-1 text-center">
                    <img src={gbi4} alt="" />
                    <h4>Chargeback</h4>
                  </div>
                  <div className="col-6 col-md-2 my-1 text-center">
                    <img src={gbi5} alt="" />
                    <h4>Fraud Protection</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="bg-white sec-payment rounded-0"
            style={{ boxShadow: "none" }}
          >
            <div className="row mx-0">
              <div className="col-12 col-md-6 text-center">
                <img className="sec-side-img" src={mobileImg} alt="" />
              </div>
              <div className="col-12 col-md-6 my-auto">
                <div className="sec my-auto">
                  <h1 className="sec-title-left-line">
                    Start Accepting Payment Now
                  </h1>

                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptas rerum dolorum consectetur beatae earum delectus
                    nostrum repellat quos voluptates, harum consequatur quisquam
                    ipsum sequi maiores quae magni reprehenderit. Consequuntur,
                    velit?
                  </p>

                  <div className="my-4">
                    <img
                      className="mr-2 my-2"
                      src={appStore}
                      alt=""
                      style={{ width: "130px" }}
                    />
                    <img
                      src={googlePlay}
                      className="my-2"
                      alt=""
                      style={{ width: "130px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer-bg">
            <div className="container">
              <div className="row py-5 mx-0">
                <div className="col-7 col-md-5">
                  <div className="pl-2">
                    <img src={logo} alt="" className="logo" />
                    <p className="my-4">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eaque ratione aliquid in eligendi amet tenetur! Veniam
                      neque sint harum dicta repellendus debitis eum numquam non
                      qui, accusamus, eveniet eligendi eius?
                    </p>
                  </div>
                </div>
                <div className="col col-md">
                  <div className="row">
                    <div className="col-12 col-md-4 my-1">
                      <h3>Features</h3>
                      <ul className="p-0" style={{ listStyle: "none" }}>
                        <li>
                          <div>Link One</div>
                        </li>
                        <li>
                          <div>Link Two</div>
                        </li>
                        <li>
                          <div>Link Three</div>
                        </li>
                        <li>
                          <div>Link Four</div>
                        </li>
                        <li>
                          <div>Link Five</div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-4 my-1">
                      <h3>Help & Supports</h3>
                      <ul className="p-0" style={{ listStyle: "none" }}>
                        <li>
                          <div>Link One</div>
                        </li>
                        <li>
                          <div>Link Two</div>
                        </li>
                        <li>
                          <div>Link Three</div>
                        </li>
                        <li>
                          <div>Link Four</div>
                        </li>
                        <li>
                          <div>Link Five</div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-4 my-1">
                      <h3>Company</h3>
                      <ul className="p-0" style={{ listStyle: "none" }}>
                        <li>
                          <div>Link One</div>
                        </li>
                        <li>
                          <div>Link Two</div>
                        </li>
                        <li>
                          <div>Link Three</div>
                        </li>
                        <li>
                          <div>Link Four</div>
                        </li>
                        <li>
                          <div>Link Five</div>
                        </li>
                        <li>
                          <div>Link Six and Seven</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="text-center my-3">
                Copyright reserved 2020 - PrincessQ8
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
  }),
  actionCreators
)(LandingPage);
