import * as React from "react";
import ReactPaginate from "react-paginate";

export interface PaginationCompProps {
  pageCount;
  onPageClick;
}

const PaginationComp: React.SFC<PaginationCompProps> = ({
  pageCount,
  onPageClick
}) => {
  return (
    <ReactPaginate
      previousLabel={"previous"}
      nextLabel={"next"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={onPageClick}
      containerClassName={"pagination ml-auto my-auto"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      subContainerClassName={"page-item"}
      activeClassName={"active"}
    />
  );
};

export default PaginationComp;
