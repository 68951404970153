import { default as axios, default as Axios } from "axios";
import { toast } from "react-toastify";
import logger from "../services/logService";
import authService from "./authService";
import globalService from "./globalService";
import userService from "./userService";

const LOADER = "app-loader";

axios.defaults.headers.common["Content-Type"] = "application/json";
// axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

axios.interceptors.response.use(
    (resp) => {
        loader(false);
        return resp;
    },
    (error) => {
        const expectedErr =
            error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500;

        if (error.response) {
            if (
                error.response.headers["token-expired"] ||
                error.response.statusText === "Unauthorized"
            ) {
                // console.log("Refresh token called at: " + new Date());

                if (authService.getUserToken()) {
                    userService.refreshToken().then(({ data: resp }) => {
                        authService.setRefreshTokenAuth(resp.data);
                        error.config.headers["Authorization"] =
                            "Bearer " + resp.data.authToken;
                        Axios.request(error.config);
                    });
                } else {
                    window.location.href = "/login";
                }
            }

            const { data } = error.response;
            getErrorResponse(data);
            if (expectedErr) {
                // toast.error(data.errorMessage);
            }
        }

        if (!expectedErr) {
            logger.log(error);
            toast.error("Error detecting");
        }

        loader(false);
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    (req) => {
        // debugger;
        if (req.params) {
            loader(!req.params.hl);
            // console.log("loader hidden");
        } else {
            loader(true);
            // console.log("loader showing");
        }

        req.headers["X-Api-Key"] = "Basic " + globalService.generateXAPIKey();

        if (req.headers.publicAPI) {
            delete req.headers.common["Authorization"];
        } else {
            const token = authService.getUserToken();
            // if (token) {
            req.headers["Authorization"] = "Bearer " + token;
            // }
        }

        return req;
    },
    (error) => {
        loader(false);
        return Promise.reject(error);
    }
);

function loader(enable) {
    if (enable) document.body.classList.add(LOADER);
    else document.body.classList.remove(LOADER);
}

function getErrorResponse(errResp) {
    if (errResp.errors) {
        let keys = Object.keys(errResp.errors);
        for (let key of keys) {
            toast.error(errResp.errors[key][0]);
        }
    }
    if (errResp.didError) {
        toast.error(errResp.errorMessage);
    }
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};