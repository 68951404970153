import { toast } from 'react-toastify';
import { AppThunkAction } from ".";
import globalService from "../services/globalService";
// import { connect } from "react-redux";
import http from "../services/httpService";



const INV_API = globalService.API_ROOT + "Invoice/";
const CMN_API = globalService.API_ROOT + "Common/";

export interface InvoiceState {
    invoiceList: any[];
    invoiceDetail: {};
    custWalletDetail: {};
    invoiceSMS: [];
    smsTypeList: any[];
    isRespSuccess: boolean;
}

//Actions
interface GET_INV_LIST {
    type: "GET_INV_LIST";
    invoiceList: any[];
}

interface GET_INV_DETAIL {
    type: "GET_INV_DETAIL";
    invoiceSMS: [];
    invoiceDetail: {};
}

interface GET_WALLET_DETAIL {
    type: "GET_WALLET_DETAIL";
    custWalletDetail: {};
}

interface ADD_UPDATE_INV {
    type: "ADD_UPDATE_INV";
    isRespSuccess: false;
}

interface GET_SMS_CONTENT {
    type: "GET_SMS_CONTENT";
    smsTypeList: [];
}

interface SEND_SMS {
    type: "SEND_SMS";
    isRespSuccess: false;
}



export type InvoiceActions = GET_INV_LIST | GET_INV_DETAIL | GET_WALLET_DETAIL | ADD_UPDATE_INV | GET_SMS_CONTENT | SEND_SMS;


export const actionCreators = {


    getInvoiceList: (query): AppThunkAction<InvoiceActions> => async (
        dispatch,
        getState) => {
        let bl: any[] = [];
        let config = { params: { hl: true } };
        await http.get(INV_API + 'searchInvoiceRef?invoiceRef=' + query, config).then(({ data: res }) => {
            if (!res.didError) {
                bl = res.model;
            } else {
                bl = [];
            }
        })
        dispatch({ type: "GET_INV_LIST", invoiceList: bl });
    },

    getInvoiceListNew: (query): AppThunkAction<InvoiceActions> => async (
        dispatch,
        getState) => {
        let bl: any[] = [];
        await http.get(INV_API + 'customerInvoiceList' + query).then(({ data: res }) => {
            if (!res.didError) {
                bl = res.model;
            } else {
                bl = [];
            }
        })
        dispatch({ type: "GET_INV_LIST", invoiceList: bl });
    },


    getInvoiceDetail: (invId): AppThunkAction<InvoiceActions> => async (
        dispatch,
        getState) => {
        let bl: any = {}, sms: any;
        await http.get(INV_API + 'InvoiceById?invoiceId=' + invId).then(({ data: res }) => {

            if (!res.didError) {
                bl = res.model[0];
                sms = res.modelSMS;
            } else {
                bl = {};
                sms = [];
            }
        })
        dispatch({ type: "GET_INV_DETAIL", invoiceDetail: bl, invoiceSMS: sms });
    },
    getWalletDetail: (clientCompanyId = null): AppThunkAction<InvoiceActions> => async (
        dispatch,
        getState) => {
        let wd: any = {};

        let qry = clientCompanyId ? "companyClientId=" + clientCompanyId : "";
        await http.get(INV_API + 'CustomerWallet?' + qry).then(({ data: res }) => {
            if (!res.didError) {
                wd = res.data;
            } else {
                wd = {};
            }
        })
        dispatch({ type: "GET_WALLET_DETAIL", custWalletDetail: wd });
    },


    getSMSContent: (invId): AppThunkAction<InvoiceActions> => async (
        dispatch,
        getState) => {
        let bl: any;
        await http.get(INV_API + 'getSMSTemplate?invoiceId=' + invId).then(({ data: res }) => {
            if (!res.didError) {
                bl = res.model; bl.push({ smsType: "NEW SMS", smsBody: "" });
            } else {
                bl = [];
            }
        })
        dispatch({ type: "GET_SMS_CONTENT", smsTypeList: bl });
    },


    updateInvoice: (body): AppThunkAction<InvoiceActions> => async (
        dispatch, getState) => {
        let apiSuccess = false;
        await http.put(INV_API + 'UpdateInvoiceExpiry', body).then(({ data: res }) => {
            apiSuccess = !res.didError;
            if (!res.didError) {
                toast.success(res.message);
            } else {
                toast.error(res.errorMessage);
            }
        })
        dispatch({ type: "ADD_UPDATE_INV", isRespSuccess: apiSuccess });
    },
    SendSMS: (body): AppThunkAction<InvoiceActions> => async (
        dispatch, getState) => {
        let apiSuccess = false;
        await http.post(CMN_API + 'sendSMS', body).then(({ data: res }) => {
            apiSuccess = !res.didError;
            if (!res.didError) {
                toast.success(res.message);
            } else {
                toast.error(res.errorMessage);
            }
        })
        dispatch({ type: "SEND_SMS", isRespSuccess: apiSuccess });
    },
};


const unloadedState: InvoiceState = {
    invoiceDetail: {},
    custWalletDetail: {},
    invoiceList: [] as any,
    smsTypeList: [],
    invoiceSMS: [],
    isRespSuccess: false,
};

export function reducer(
    state: InvoiceState,
    action: InvoiceActions
): InvoiceState {
    switch (action.type) {
        case "GET_INV_LIST":
            return {
                ...state, invoiceList: action.invoiceList
            };
        case "GET_INV_DETAIL":
            return {
                ...state, invoiceDetail: action.invoiceDetail, invoiceSMS: action.invoiceSMS
            };

        case "GET_WALLET_DETAIL":
            return {
                ...state, custWalletDetail: action.custWalletDetail
            };
        case "ADD_UPDATE_INV":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };
        case "GET_SMS_CONTENT":
            return {
                ...state, smsTypeList: action.smsTypeList
            };
        case "SEND_SMS":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };

    }
    return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
