import * as React from "react";

export interface MainContentCompProps {
  children;
}

const MainContentComp: React.SFC<MainContentCompProps> = props => {
  return <div className="bg-white main-contain-bg">{props.children}</div>;
};

export default MainContentComp;
