import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminTemp from "./pages/admin/layout/AdminTemplate";
import Login from "./pages/auth/login";
import PayLink from "./pages/auth/payLink";
import Payment from "./pages/auth/payment";
import PaymentResult from "./pages/auth/paymentResult";
import Register from "./pages/auth/register";
import RegisterConfirm from "./pages/auth/register-confirmation";
// import AdminLayout from "./pages/admin/layout/AdminLayout";
import VerifyPage from "./pages/auth/verify";
import LandingPage from "./pages/landing";

export const routes = (
  <Switch>
    {/* <Route path="/register" component={RegisterPage} />
    <Route path="/login" component={LoginPage} />
    <Route path="/" exact component={HomePage} />
    <Route path="/not-found" component={NotFound} />
    <Route path="/admin" component={AdminLayout} /> */}
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/reg-confirm" component={RegisterConfirm} />
    {/* <Route path="/app" component={AdminLayout} /> */}

    <Route path="/app" component={AdminTemp} />

    <Route path="/verify" component={VerifyPage} />
    <Route path="/payment" component={Payment} />
    <Route path="/paylink" component={PayLink} />
    <Route path="/result" component={PaymentResult} />

    {/* <Route path="/" render={() => <Redirect to="/not-found" />} /> */}
    <Redirect to="/not-found" />
  </Switch>
);
