import { IconButton } from "@material-ui/core";
import * as React from "react";
import { CheckCircle, Clock, Mail, PhoneCall, User, X } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import depoIcon from "../../assets/img/new/deposit.svg";
import invIcon from "../../assets/img/new/invoice.svg";
import ModalComp from "../../components/Modal";
import TitleBarComp from "../../components/TitleBar";
import companyService from "../../services/companyService";
import globalService from "../../services/globalService";
import { CompanyState } from "../../stores/companyStore";
import { AuthState } from "./../../stores/authStore";
import { ApplicationAction, ApplicationState } from "./../../stores/index";

export interface CompanyListProps {
  userAuth;
  history;
  auth: AuthState;
  getChargeTypes;
  company: CompanyState;
}

export interface CompanyListState { }

class CompanyList extends React.Component<CompanyListProps, CompanyListState> {
  state = {
    dataList: [] as any[],
    selComp: {} as any,
    isModalOpen: false,
    action: "accept",
    // commission: {
    //   selType: "",
    //   amount: "",
    // },
    // chargeType: [] as any,
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  // handleChange = ({ currentTarget: e }) => {
  //   const commission = { ...this.state.commission };
  //   commission[e.name] = e.value;
  //   this.setState({ commission });
  // };
  render() {
    const { isModalOpen, action } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Company List"></TitleBarComp>

        <ModalComp
          title="Confirmation"
          hideModal={this.toggleModal}
          isOpen={isModalOpen}
        >
          <div className="modal-body my-4 text-center">
            <p> Are you sure, do you want {action} ?</p>
            {/* {action === "accept" && (
              <>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <GFSelect
                      name="selType"
                      label="Choose Charge Type"
                      value={commission.selType}
                      onChange={this.handleChange}
                      error=""
                      placeholder="Charge Type"
                      listData={chargeType}
                      valueField="vendorChargeTypeId"
                    ></GFSelect>
                  </div>

                  <div className="col-6">
                    <GFInput
                      name="amount"
                      label="Commission amount"
                      value={commission.amount}
                      isRequired={true}
                      onChange={this.handleChange}
                      type="number"
                      error=""
                      placeholder=""
                      disabled={false}
                    ></GFInput>
                  </div>
                </div>
                <hr />
              </>
            )} */}
          </div>
          <div className="mb-3 text-center">
            <button className="btn btn-light btn-sm mx-1">Cancal</button>
            <button
              className="btn btn-secondary btn-sm mx-1 text-capitalize"
              onClick={this.acceptDecline}
            >
              {action}
            </button>
          </div>
        </ModalComp>

        {this.renderContent()}
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.getCompanyList();
  }

  async getCompanyList() {
    // const { pagination: p, filter: f } = this.state;
    // const { dataList } = this.state;
    let qryStr = "";
    let dataList = [];
    await companyService.getCompanyList(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        dataList = res.model;
      }
    });

    // await this.props.getChargeTypes();
    // let cts = this.props.company.chargeList;
    // cts.forEach((data) => {
    //   data["dName"] = data["vendorChargeType"];
    // });

    // let chargeType = cts.filter((data: any) => data.isActive);

    this.setState({ dataList });
  }

  handleAction = (comp, type) => {
    this.setState({ selComp: comp, action: type, isModalOpen: true });
  };

  acceptDecline = async () => {
    let qry = "clientCompanyId=" + this.state.selComp.clientCompanyId;

    const { selComp } = this.state;

    if (this.state.action === "accept") {
      // if (
      //   !commission.selType ||
      //   commission.selType === "" ||
      //   commission.selType === "default"
      // ) {
      //   toast.error("Please select the charge type");
      //   return;
      // }

      // if (!commission.amount) {
      //   toast.error("Please fill the commission amount");
      //   return;
      // }
      qry = selComp.clientCompanyId;

      // +
      // "/" +
      // commission.selType +
      // "/" +
      // commission.amount;
      await companyService.approveComp(qry).then(({ data: res }) => {
        if (!res.didError) {
          toast.success(res.message);
          this.toggleModal();
          this.getCompanyList();
        } else {
          toast.error(res.errorMessage);
        }
      });
    } else {
      await companyService.deClineComp(qry).then(({ data: res }) => {
        if (!res.didError) {
          toast.success(res.message);
          this.toggleModal();
          this.getCompanyList();
        } else {
          toast.error(res.errorMessage);
        }
      });
    }
  };

  handleStoreSession = (comp) => {
    sessionStorage.setItem(globalService.SESS_SEL_COMP, JSON.stringify(comp));
  };

  renderContent() {
    const { dataList: dl } = this.state;

    const spAdmin = "SPAdmin".toLocaleLowerCase();
    let isSuperAdmin = false;
    if (this.props.auth.isLoggedIn) {
      isSuperAdmin =
        this.props.auth.userDetail.userType.toLocaleLowerCase() === spAdmin
          ? true
          : false;
    }

    return (
      <React.Fragment>
        <div className="row mx-0 my-5">
          {dl.map((val: any, idx) => {
            return (
              <div key={idx} className="col-12 col-md-4 p-1">
                <div className="shadow bg-white rounded companyList">
                  <Link
                    className="name"
                    data-toggle="tooltip"
                    data-placement="top"
                    to={"/app/company/detail?id=" + val.clientCompanyId}
                    title={val.companyName}
                  >
                    {val.companyName}
                  </Link>
                  <div className="border m-3 mt-0">
                    <div className="url pl-2 ml-5 text-app">
                      <a
                        href={globalService.linkCheck(val.companyURL)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {val.companyURL}{" "}
                      </a>
                    </div>

                    {!val.isApproved && !val.isDeclined && isSuperAdmin && val.registerUser.isEmailVerified && (
                      <div className="action">
                        <div>
                          <span
                            className="Login-button"
                            onClick={() => this.handleAction(val, "accept")}
                          >
                            Accept
                          </span>
                        </div>
                        <div>
                          <span
                            className="Login-button text-danger border-danger"
                            onClick={() => this.handleAction(val, "decline")}
                          >
                            Decline
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <img src={val.logo} className="logo" alt="" />
                  {/* </div> */}
                  <div className="content bg-white">
                    <div className="cname text-center">
                      {val.registerUser &&
                        val.registerUser.firstName +
                        " " +
                        val.registerUser.lastName}
                    </div>
                    <div className="cname text-center">
                      {val.registerUser.firstNameAr &&
                        val.registerUser.firstNameAr +
                        " " +
                        val.registerUser.lastNameAr}
                    </div>
                    <div className="cemail">
                      {val.registerUser && (
                        <span className="mr-2">
                          <Mail size={14}></Mail>{" "}
                        </span>
                      )}
                      {val.registerUser && val.registerUser.email}
                    </div>
                    <div className="cmobile">
                      {val.registerUser && (
                        <span className="mr-2">
                          <PhoneCall size={14}></PhoneCall>
                        </span>
                      )}
                      {val.registerUser && val.registerUser.mobile}
                    </div>
                    <div>
                      <span className="mr-2">
                        <User size={14} />
                      </span>
                      Users count: {val.users}
                    </div>
                    <div className="cstatus">
                      {val.isApproved && (
                        <div>
                          <span className="text-success">
                            <CheckCircle /> Activated{" "}
                          </span>{" "}
                          {isSuperAdmin && (
                            <div
                              className="my-2"
                              onClick={() => this.handleStoreSession(val)}
                            >
                              <Link
                                className="mx-2"
                                title="Invoices"
                                to="/app/inv-list"
                              >
                                <IconButton color="primary">
                                  <img src={invIcon} width={25} alt="" />
                                </IconButton>
                              </Link>
                              <Link
                                className="mx-2"
                                title="Deposit"
                                to={"/app/deposit-list"}
                              >
                                <IconButton color="primary">
                                  <img src={depoIcon} width={25} alt="" />
                                </IconButton>
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                      {!val.isApproved && !val.isDeclined && (
                        <span className="text-warning">
                          <Clock /> Waiting
                        </span>
                      )}
                      {val.isDeclined && (
                        <span className="text-danger">
                          <X /> Declined
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

const mapActionToProps = {
  getChargeTypes: ApplicationAction.company.getChargeList,
  // authLogin: ApplicationAction.auth.login,
  // getMenus: ApplicationAction.access.getRoleMenus
};

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
    company: state.company,
  }),
  mapActionToProps
)(CompanyList);
