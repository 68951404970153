// import globalService from "../../services/globalService";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationAction } from "../../stores";
import { ApplicationState } from "./../../stores/index";
import { InvoiceState } from "./../../stores/invoiceStore";

export interface InvListCardProps {
  getInvList;
  inv: InvoiceState;
  isPaidOnly?;
}

export interface InvListCardState {}

class InvListCard extends React.Component<InvListCardProps, InvListCardState> {
  state = { invList: [] };

  componentDidMount() {
    this.getInvList("");
  }

  async getInvList(currentTab) {
    let qryStr = "?orderBy=1&pageSize=5&pageNumber=1";

    if (true) {
      qryStr += "&startDate=" + moment().format("YYYY-MM-DD");
    }
    if (true) {
      qryStr += "&endDate=" + moment().format("YYYY-MM-DD");
    }

    if (this.props.isPaidOnly) {
      qryStr += "&paid=1";
    }

    // if (f.expireDate) {
    //   qryStr += "&expiryDate=" + moment(f.expireDate).format("YYYY-MM-DD");
    // }

    // if (currentTab === tabs[1]) {
    //   qryStr += "&paid=1";
    // } else if (currentTab === tabs[0]) {
    //   if (f.isPaid !== "0") {
    //     qryStr += "&paid=" + (f.isPaid === "1" ? 1 : 2);
    //   }
    // } else if (currentTab === tabs[2]) {
    //   qryStr += "&paid=2";
    // }

    await this.props.getInvList(qryStr);

    this.setState({ invList: this.props.inv.invoiceList });

    // await userService.custInvoiceListNew(qryStr).then(({ data: res }) => {
    //   if (!res.didError) {
    //     let invList = res.model;
    //     p.itemsCount = res.itemsCount;
    //     p.pageCount = res.pageCount;
    //     p.pageSize = res.pageSize;
    //     p.pageNumber = res.pageNumber;
    //     this.setState({ invList, paginatoin: p });

    //   }
    // });
  }

  render() {
    const { invList } = this.state;
    return (
      <>
        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Payment Ref</th>
                {/* <th>ID - Customer</th> */}
                <th>Invoice Value</th>
                {this.props.isPaidOnly && <th>Comission</th>}
                <th>Invoice Status</th>
                {this.props.isPaidOnly && <th>Transaction ID</th>}
                {/* <th>Payment Method</th> */}
                {/* <th>Views</th> */}

                {/* <th>Created By</th> */}
              </tr>
            </thead>
            <tbody>
              {invList.length === 0 && (
                <tr>
                  <td
                    colSpan={this.props.isPaidOnly ? 5 : 3}
                    className="text-center"
                  >
                    No Data found
                  </td>
                </tr>
              )}
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    {/* <td>{globalService.getLocalDate(inv.createdDate)}</td> */}
                    {/* <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                        ? inv.customer.customerRefId + " - "
                        : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td> */}
                    {/* <td>{this.displayChip(inv.customerRef)}</td> */}
                    <td>{inv.invoiceAmt}</td>
                    {this.props.isPaidOnly && <td>{inv.commission}</td>}
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          {/* <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div> */}
                        </td>
                      ) : (
                        <td className="unpaid text-center">
                          <span
                            className="badge badge-info font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Unpaid
                          </span>
                        </td>
                      ))}

                    {this.props.isPaidOnly && (
                      <td>{inv.transId ? inv.transId : ""}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapActionToProps = {
  getInvList: ApplicationAction.invoice.getInvoiceListNew,
  getInvDetail: ApplicationAction.invoice.getInvoiceDetail,
  getSMSContent: ApplicationAction.invoice.getSMSContent,
  sendSMS: ApplicationAction.invoice.SendSMS,
};

export default connect(
  (state: ApplicationState) => ({
    inv: state.invoice,
    userAuth: state.auth,
  }),
  mapActionToProps
)(InvListCard);
