import Joi from "joi-browser";
import queryString from "query-string";
import * as React from "react";
import {
  CheckCircle,
  Edit2,
  Mail,
  Phone,
  Trash2,
  Upload,
  X
} from "react-feather";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import AuthContainer from "../../components/authContainer";
import ComisnCharge from "../../components/ComisnCharge";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import GFSelect from "../../components/genericFormComponents/GFSelect";
import MainContentComp from "../../components/MainContent";
import TabViewComp from "../../components/TabView";
import TitleBarComp from "../../components/TitleBar";
import Wallet from "../../components/wallet";
import { ICompany } from "../../models/ICompany";
import authService from "../../services/authService";
import commonService, { getAllBanks } from "../../services/commonService";
import globalService from "../../services/globalService";
import userService from "../../services/userService";
import { AppConstants } from './../../common/appConfig';
import GFInput from "./../../components/genericFormComponents/GFInput";
import ModalComp from "./../../components/Modal";
import {
  addUpdateClientBank,
  addUpdateCompanyInfo,
  addUpdateUserProfile,
  getClientCompanyById
} from "./../../services/companyService";
import { ApplicationAction, ApplicationState } from "./../../stores/index";

export interface UpdateProfileProps { }

export interface UpdateProfileState { }

class UpdateProfile extends GenericForm {
  constructor(props) {
    super(props);
    const { location } = props;
    if (location) {
      const search: any = queryString.parse(location.search);
      this.state.search = search;
      this.state.id = search.id;
    }
  }
  state = {
    data: {},
    errors: {},
    tabs: [
      "Business Detail",
      "Bank Detail",
      "Social Media",
      "Vendor Documents",
      "Contact Detail",
      "Commission Charge",
    ],
    currentTab: "Business Detail",
    search: "" as any,
    id: "",
    userType: "",
    vendDoc: {
      fCivilId: null,
      civilIdExDate: null as any,

      fComLicense: null,
      comLicenseExDate: null as any,

      fArticleOfAssociation: null,
      articleOfAssociationExDate: null as any,

      fSignAuthorisation: null,
      signAuthorisationExDate: null as any,

      fOthers: null,
      othersExDate: null as any,

      fcivilIdBank: null,
      civilIdBankExDate: "",
      clientVendorDocId: null,
    },
    isAddBank: false,
    bankDetail: {
      accountHolderName: "",
      accountNo: "",
      iban: "",
      bankName: "",
      address: "",
      swiftCode: "",
    },
    bankList: [],
    addMedia: false,
    isEdit: false,
    isEditContact: false,
    media: {
      socialName: "",
      accountName: "",
      accountUrl: "",
      logo: "",
    },
    mediaList: [] as any,
    isModalOpen: false,
    isAddContact: false,
    contactDetail: {
      clientContactId: null as any,
      isMobile: "mobile",
      isPrimary: false,
      email: "",
      type: "",
      countryCode: "965",
      mobileNo: "",
      serviceProvider: "",
      sendNotification: false,
      otherType: "",
      otherService: "",
      isEmail: false,
      isActive: true,
      isDeleted: false,
      isVerified: false,
    },
    countryList: [],
    contactList: [] as any,
    // commission: {
    //   selType: "",
    //   amount: "",
    // },
    commissions: {
      commissionId: "null",
      vendorChargeTypeId: "",
      clientCompanyId: "",
      paymentType: "",
      isActive: false,
      deliveryFees: 0,
      isDeleted: false,
    },

    // chargeType: [] as any, // delete
    cchargeList: [] as any,
    addCCharge: false,
    ccharge: {} as any,
    commissionInfo: [] as any,
  };

  isSuperAdmin = authService.checkUserType(AppConstants.UT_SUPER_ADMIN)
    ? true
    : false;
  fileInput;
  uploadName = "";
  uploadFile = {
    content: "",
    fileName: "",
    extension: "",
    fName: "Companylogo",
  };

  paymentType = [
    { dName: "KNET", id: "KNET" },
    { dName: "VISA/MASTER", id: "VISA/MASTER" },
  ];

  siteList = [
    {
      dName: "Facebook",
      link: "https://www.facebook.com/",
      icon: "fa fa-facebook text-primary",
    },
    {
      dName: "Twitter",
      link: "https://www.twitter.com/",
      icon: "fa fa-twitter text-info",
    },
    {
      dName: "Youtube",
      link: "https://www.youtube.com/",
      icon: "fa fa-youtube-play text-danger",
    },
    {
      dName: "Instagram",
      link: "https://www.instagram.com/",
      icon: "fa fa-instagram text-dark",
    },
  ];
  contTypeList = [
    { dName: "Mobile" },
    { dName: "Telephone" },
    { dName: "Fax" },
  ];
  serviceProviderList = [
    { dName: "Ooredoo" },
    { dName: "Viva" },
    { dName: "Zain" },
    { dName: "Others" },
  ];

  handleUploadClick = (e, field) => {
    this.fileInput.click();
    this.uploadName = field;
  };
  handleFileSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };
  async _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.uploadFile.content = btoa(binaryString);
    const { data: res } = await commonService.fileUpload(this.uploadFile);
    if (!res.didError) {
      let data: any = this.state.data;
      // debugger;
      if (this.uploadName) {
        let vendDoc = this.state.vendDoc;
        vendDoc[this.uploadName] = res.message;
        this.setState({ vendDoc });
      } else {
        data.logo = globalService.IMG_ROOT_LOGO + res.message;
        this.setState({ data });
      }
    } else {
      toast.error("Something problem with uploading image");
    }
  }
  expiryDays = [
    { dName: "1 days", id: "1" },
    { dName: "2 days", id: "2" },
    { dName: "3 days", id: "3" },
    { dName: "4 days", id: "4" },
    { dName: "5 days", id: "5" },
  ];
  schema = {};

  handleCurrentTab = (item) => {
    this.setState({ currentTab: item });
    this.getTabContent();
  };
  getTabContent = () => {
    const { tabs, currentTab, countryList, data, commissionInfo } = this.state;
    if (currentTab === tabs[0]) {
      return this.renderBusiDetail();
    } else if (currentTab === tabs[1]) {
      return this.renderBankDetail();
    } else if (currentTab === tabs[2]) {
      return this.renderSocialMedia();
    } else if (currentTab === tabs[3]) {
      return this.renderVendDoc();
    } else if (currentTab === tabs[4]) {
      if (countryList.length === 0) {
        this.getCountryList();
      }
      return this.renderContactDetail();
    } else {
      return (
        <ComisnCharge
          comisnList={commissionInfo}
          companyId={data["clientCompanyId"]}
          refereshDetail={this.getCompanyDetail}
        ></ComisnCharge>
      );
    }
  };

  componentDidMount() {
    // debugger;
    this.getCompanyDetail();
  }

  doSubmit() {
    this.companyAddUpdate();
  }

  getCompanyDetail = async () => {
    let qury = "clientCompanyId=" + this.state.id;
    if (!this.state.id) {
      qury = "";
    }
    await getClientCompanyById(qury).then(({ data }) => {
      if (!data.didError) {
        this.assignBusiness(data.model[0]);

        if (data.model[0].clientVendorDocInfo) {
          this.setupVendDoc(data.model[0].clientVendorDocInfo);
        }
        let bd = this.state.bankDetail;
        if (data.model[0].clientBankInfo) {
          bd = data.model[0].clientBankInfo;
        }
        // let commission = this.state.commission;
        // commission.amount = data.model[0].deliveryFees;
        // commission.selType = data.model[0].vendorChargeTypeId;
        this.setState({
          mediaList: data.model[0].socialMediaInfo,
          contactList: data.model[0].contactInfo,
          bankDetail: bd,
          commissionInfo: data.model[0].commissionInfo,
        });
        this.getBanks();
      } else {
        this.resetBusiness();
      }
    });

    let cts: [] = this.props.company.chargeList;

    cts.forEach((data) => {
      data["dName"] = data["vendorChargeType"];
    });
    let chargeType = cts.filter((data: any) => data.isActive);
    this.setState({ chargeType });
  };
  getWalletDetail = async (clientCompanyId) => {
    await this.props.getWalletDetail(clientCompanyId);
  };
  // handleCommissionData = ({ currentTarget: e }) => {
  //   let commission = this.state.commission;
  //   commission[e.name] = e.value;
  //   this.setState({ commission });
  // };

  setupVendDoc(vd) {
    let vendDoc = this.state.vendDoc;
    vendDoc = vd;
    vendDoc.articleOfAssociationExDate = vd.articleOfAssociationExDate
      ? new Date(vd.articleOfAssociationExDate)
      : null;
    vendDoc.civilIdExDate = vd.civilIdExDate
      ? new Date(vd.civilIdExDate)
      : null;
    vendDoc.comLicenseExDate = vd.comLicenseExDate
      ? new Date(vd.comLicenseExDate)
      : null;
    vendDoc.othersExDate = vd.comLicenseExDate
      ? new Date(vd.comLicenseExDate)
      : null;
    vendDoc.signAuthorisationExDate = vd.signAuthorisationExDate
      ? new Date(vd.signAuthorisationExDate)
      : null;
    this.setState({ vendDoc });
  }
  companyAddUpdate = async () => {
    await addUpdateCompanyInfo(this.state.data).then(({ data }) => {
      if (!data.didError) {
        toast.success(data.message);
      } else {
        toast.error(data.errorMessage);
      }
    });
  };

  assignBusiness(comp: ICompany) {
    let data: ICompany = {
      companyName: comp.companyName,
      logo: comp.logo,
      companyURL: comp.companyURL,
      termsConditions: comp.termsConditions,
      deliveryFees: comp.deliveryFees,
      defaultLang: comp.defaultLang,
      isApproved: comp.isApproved,
      invoiceExDays: comp.invoiceExDays,
      promoCode: comp.promoCode,
      depositTerms: comp.depositTerms,
      profileName: comp.profileName,
      clientCompanyId: comp.clientCompanyId,
      customSmsAr: comp.customSmsAr,
      customSmsEn: comp.customSmsEn,
      isActive: true,
      countryId: "114",
    };
    let userType = this.props.auth.isLoggedIn
      ? this.props.auth.userDetail.userType
      : "";

    if (
      this.props.auth.userDetail.userType === globalService.USER_TYPE.SUPERADMIN
    ) {
      this.getWalletDetail(data.clientCompanyId);
    }

    this.setState({ data: data, userType });
    this.resetBusinessSchema();
  }

  resetBusiness() {
    let data: ICompany = {
      companyName: "",
      logo: "logo.png",
      companyURL: "",
      termsConditions: "",
      deliveryFees: "",
      defaultLang: "AR",
      isApproved: false,
      invoiceExDays: "",
      promoCode: "",
      depositTerms: "",
      profileName: "",
      clientCompanyId: "",
      customSmsAr: "",
      customSmsEn: "",
      isActive: true,
      countryId: "114",
    };

    this.setState({ data: data });
    this.resetBusinessSchema();
  }

  resetBusinessSchema() {
    let scm = {
      companyName: Joi.string().required(),
      logo: Joi.string().allow("", null),
      companyURL: Joi.string().allow("", null),
      termsConditions: Joi.string().allow("", null),
      deliveryFees: Joi.allow("", null),
      defaultLang: Joi.string().allow("", null),
      isApproved: Joi.boolean().required(),
      invoiceExDays: Joi.allow("", null),
      promoCode: Joi.string().allow("", null),
      depositTerms: Joi.string().allow("", null),
      profileName: Joi.string().allow("", null),
      clientCompanyId: Joi.string().allow("", null),
      customSmsAr: Joi.string().allow("", null),
      customSmsEn: Joi.string().allow("", null),
      isActive: Joi.boolean().allow("", null),
      countryId: Joi.string().allow("", null),
    };

    this.schema = scm;
  }

  handleRadioBtn(vl) {
    let data = this.state.data;
    data["defaultLang"] = vl.target.value;
    this.setState({
      data,
    });
  }

  // ########### - Vendor Document
  handleDateChange = (name, val) => {
    let { vendDoc } = this.state;
    vendDoc[name] = val;
    this.setState({ vendDoc });
  };

  handleRemoveDoc = (dd) => {
    let { vendDoc } = this.state;
    vendDoc[dd] = null;
    this.setState({ vendDoc });
  };

  handleSaveVendDoc = () => {
    this.updateVendDoc();
  };

  updateVendDoc = async () => {
    let vendDoc = this.state.vendDoc;
    let param = {
      // usr_ClientContacts: [],
      // usr_ClientSocialMedia: [],
      // usr_ClientBankInfo: {},
      // usr_ClientCompany: {},
      usr_ClientVendorDoc: {
        clientVendorDocId: vendDoc.clientVendorDocId,
        fCivilId: vendDoc.fCivilId,
        fcivilIdBank: vendDoc.fcivilIdBank,
        civilIdExDate: vendDoc.civilIdExDate,
        fComLicense: vendDoc.fComLicense,
        comLicenseExDate: vendDoc.comLicenseExDate,
        fArticleOfAssociation: vendDoc.fArticleOfAssociation,
        articleOfAssociationExDate: vendDoc.articleOfAssociationExDate,
        fSignAuthorisation: vendDoc.fSignAuthorisation,
        signAuthorisationExDate: vendDoc.signAuthorisationExDate,
        fOthers: vendDoc.fOthers,
        othersExDate: vendDoc.othersExDate,
      },
    };
    await addUpdateUserProfile(param).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
      } else {
        toast.error(res.errorMessage);
      }
    });
  };

  // ############### Bank detail

  async getBanks() {
    await getAllBanks().then(({ data: res }) => {
      if (!res.didError) {
        for (let d of res.data) {
          d["dName"] = d.bankName;
        }
        this.setState({ bankList: res.data });
      }
    });
  }

  handleChangeBank = ({ currentTarget: e }) => {
    let bankDetail = this.state.bankDetail;
    bankDetail[e.name] = e.value;
    this.setState({ bankDetail });
  };

  updateBankInfo = async () => {
    const bDetail = this.state.bankDetail;
    let param = {
      usr_ClientBankInfo: {
        clientCompanyId: this.state.id,
        accountHolderName: bDetail.accountHolderName,
        accountNo: bDetail.accountNo,
        iban: bDetail.iban,
        bankName: bDetail.bankName,
        address: "",
        swiftCode: bDetail.swiftCode,
      },
    };

    if (bDetail["clientBankId"]) {
      param.usr_ClientBankInfo["clientBankId"] = bDetail["clientBankId"];
    }

    await addUpdateClientBank(param).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.setState({ isAddBank: !this.state.isAddBank });
      } else {
        toast.error(res.errorMessage);
      }
    });
  };

  // ############### Social media

  handleChangeSite = ({ currentTarget: e }) => {
    let media = this.state.media;
    media[e.name] = e.value;

    if (e.name === "socialName") {
      let obj: any = this.siteList.filter((data) => data.link === e.value);
      media.logo = obj[0].icon;
    }

    this.setState({ media });
  };

  handleDeleteSocialMedia = (data) => {
    this.setState({ media: data, isModalOpen: true });
  };

  createSocialMedia = () => {
    // let { media } = this.state;

    let media = {
      accountName: "",
      accountUrl: "",
      logo: "",
      socialName: "",
    };
    this.setState({
      addMedia: !this.state.addMedia,
      isEdit: false,
      media,
    });
  };

  updateSocialMedia = async (isDelete) => {
    let obj = this.state.media;
    let param = {
      usr_ClientSocialMedia: [] as any,
    };
    param.usr_ClientSocialMedia.push(obj as any);
    param.usr_ClientSocialMedia["0"]["accountUrl"] =
      this.state.media.socialName + this.state.media.accountName;

    if (isDelete) {
      param.usr_ClientSocialMedia["0"]["isActive"] = false;
      param.usr_ClientSocialMedia["0"]["isDeleted"] = true;
    }
    await addUpdateUserProfile(param).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        let mediaList = this.state.mediaList;
        if (isDelete) {
          mediaList = this.state.mediaList.filter(
            (data) =>
              data.clientSocialMedId !== this.state.media["clientSocialMedId"]
          );
          this.toggleModal();
        } else {
          if (!this.state.isEdit)
            mediaList.push(res.model.usr_ClientSocialMedia[0]);
        }

        this.setState({
          mediaList,
          addMedia: false,
        });
      } else {
        toast.error(res.errorMessage);
      }
    });
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  deleteSocialMedia = () => { };

  // ############### Contact Detail

  async getCountryList() {
    await userService.getCountries().then(({ data }) => {
      if (!data.didError) {
        for (let d of data.data) {
          d["dName"] = d.niceName + " (" + d.phoneCode + ")";
        }
        this.setState({ countryList: data.data });
      }
    });
  }

  handleChangeContact = ({ currentTarget: e }) => {
    let contactDetail = this.state.contactDetail;
    if (e.type === "checkbox") {
      contactDetail[e.name] = e.checked;
    } else {
      contactDetail[e.name] = e.value;
    }

    this.setState({ contactDetail, isEdit: false });
  };

  saveContact() {
    // dasfaf
  }

  updateContact = async () => {
    let { contactDetail: cd, isEditContact } = this.state;
    if (cd.isMobile === "mobile") {
      if (!cd.mobileNo || !cd.countryCode || !cd.type || !cd.serviceProvider) {
        toast.error("Please fill all the required fields");
        return true;
      }
      cd.email = "";
    } else {
      if (!cd.email) {
        toast.error("Please enter email id");
        return true;
      }
      cd.mobileNo = "";
      cd.countryCode = "";
      cd.type = "";
      cd.serviceProvider = "";
    }

    let isEmail = cd.isMobile === "mobile" ? false : true;

    cd.isEmail = isEmail;

    if (!isEditContact) {
      delete cd.clientContactId;
    }

    let param = {
      usr_ClientContacts: [] as any,
    };

    param.usr_ClientContacts.push(cd);

    await addUpdateUserProfile(param).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        let mediaList = this.state.mediaList;
        mediaList.push(res.model.usr_ClientSocialMedia);
        this.setState({
          mediaList,
          isAddContact: false,
        });
        this.getCompanyDetail();
      } else {
        toast.error(res.errorMessage);
      }
    });
  };

  editContact = (data) => {
    let contactDetail = this.state.contactDetail;
    contactDetail = data;
    contactDetail.isMobile = data.isEmail ? "email" : "mobile";

    this.setState({
      isEditContact: true,
      contactDetail,
      isAddContact: true,
    });
  };

  createContact = () => {
    let { contactDetail } = this.state;

    contactDetail.clientContactId = null;
    contactDetail.email = "";
    contactDetail.isActive = true;
    contactDetail.isDeleted = false;
    contactDetail.isEmail = false;
    contactDetail.isMobile = "mobile";
    contactDetail.mobileNo = "";
    contactDetail.serviceProvider = "";
    contactDetail.countryCode = "";
    contactDetail.type = "";
    contactDetail.otherType = "";
    this.setState({
      isAddContact: !this.state.isAddContact,
      isEditContact: false,
      contactDetail,
    });
  };

  deleteContact = async (data) => {
    let param = {
      usr_ClientContacts: [] as any,
    };
    data.isActive = false;
    data.isDeleted = true;

    param.usr_ClientContacts.push(data);

    await addUpdateUserProfile(param).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.setState({
          isAddContact: false,
        });
        this.getCompanyDetail();
      } else {
        toast.error(res.errorMessage);
      }
    });
  };

  // ############ Commission Charge

  // handleDeleteSocialMedia = (data) => {
  //   this.setState({ media: data, isModalOpen: true });
  // };

  createCCharge = () => {
    // let { media } = this.state;

    let ccharge = {
      commissionId: "string",
      vendorChargeTypeId: "string",
      clientCompanyId: "string",
      paymentType: "string",
      isActive: false,
      deliveryFees: 0,
      isDeleted: false,
    };
    this.setState({
      addCCharge: !this.state.addCCharge,
      isEdit: false,
      ccharge,
    });
  };

  // updateSocialMedia = async (isDelete) => {
  //   let obj = this.state.media;
  //   let param = {
  //     usr_ClientSocialMedia: [] as any,
  //   };
  //   param.usr_ClientSocialMedia.push(obj as any);
  //   param.usr_ClientSocialMedia["0"]["accountUrl"] =
  //     this.state.media.socialName + this.state.media.accountName;

  //   if (isDelete) {
  //     param.usr_ClientSocialMedia["0"]["isActive"] = false;
  //     param.usr_ClientSocialMedia["0"]["isDeleted"] = true;
  //   }
  //   await addUpdateUserProfile(param).then(({ data: res }) => {
  //     if (!res.didError) {
  //       toast.success(res.message);
  //       let mediaList = this.state.mediaList;
  //       if (isDelete) {
  //         mediaList = this.state.mediaList.filter(
  //           (data) =>
  //             data.clientSocialMedId !== this.state.media["clientSocialMedId"]
  //         );
  //         this.toggleModal();
  //       } else {
  //         if (!this.state.isEdit)
  //           mediaList.push(res.model.usr_ClientSocialMedia[0]);
  //       }

  //       this.setState({
  //         mediaList,
  //         addMedia: false,
  //       });
  //     } else {
  //       toast.error(res.errorMessage);
  //     }
  //   });
  // };

  // renderCommissionChargeNNNN() {
  //   const {
  //     addCCharge,
  //     addMedia,
  //     mediaList,
  //     cchargeList,
  //     isEdit,
  //     data,
  //   } = this.state;

  //   let ccharge = {
  //     commissionId: "string",
  //     vendorChargeTypeId: "string",
  //     clientCompanyId: "string",
  //     paymentType: "string",
  //     isActive: false,
  //     deliveryFees: 0,
  //     isDeleted: false,
  //   };
  //   return (
  //     <React.Fragment>
  //       <div className="row">
  //         <div className="col-12 text-right">
  //           {authService.checkUserType( AppConstants.UT_SUPER_ADMIN) && (
  //             <AuthContainer action="ADD">
  //               <button className="active-btn" onClick={this.createCCharge}>
  //                 Create New
  //               </button>
  //             </AuthContainer>
  //           )}
  //         </div>
  //         <div className="col-12">
  //           <hr />
  //         </div>

  //         {!addCCharge &&
  //           mediaList.map((val: any, idx) => {
  //             return (
  //               <div key={idx} className="col-3 text-center">
  //                 <div className="mx-1 shadow pt-2">
  //                   <div
  //                     className="text-center overflow-hidden my-2 text-primary"
  //                     style={{ height: 50 }}
  //                   >
  //                     <i className={val.logo} style={{ fontSize: 45 }}></i>
  //                   </div>
  //                   <div>{new URL(val.socialName).host} </div>
  //                   <div className="my-2 overflow-hidden">
  //                     <a
  //                       href={val.accountUrl}
  //                       target="_blank"
  //                       rel="noopener noreferrer"
  //                       className="text-wrap"
  //                     >
  //                       {val.accountUrl}
  //                     </a>{" "}
  //                   </div>
  //                   <hr />
  //                   <div className="my-2 pb-2">
  //                     <AuthContainer action="EDIT">
  //                       <span
  //                         className="mx-3 text-secondary h6 cursor-pointer"
  //                         onClick={() =>
  //                           this.setState({
  //                             addMedia: true,
  //                             media: val,
  //                             isEdit: true,
  //                           })
  //                         }
  //                       >
  //                         <Edit2 size={20}></Edit2>
  //                       </span>
  //                     </AuthContainer>

  //                     <AuthContainer action="DELETE">
  //                       <span
  //                         className="mx-3 text-danger h6 cursor-pointer"
  //                         onClick={() => this.handleDeleteSocialMedia(val)}
  //                       >
  //                         <Trash2 size={20}></Trash2>
  //                       </span>
  //                     </AuthContainer>
  //                   </div>
  //                 </div>
  //               </div>
  //             );
  //           })}
  //       </div>
  //       {addCCharge && (
  //         <>
  //           <div className="row shadow py-2">
  //             <div className="col-md-3 col-sm-4">
  //               <FormControl component="fieldset">
  //                 <FormLabel component="legend">Gender</FormLabel>
  //                 <RadioGroup aria-label="gender" name="gender1">
  //                   <FormControlLabel
  //                     value="female"
  //                     control={<Radio />}
  //                     label="Female"
  //                   />
  //                 </RadioGroup>
  //               </FormControl>
  //             </div>
  //             <div className="col-md-3 col-sm-4">
  //               <GFSelect
  //                 name="vendorChargeTypeId"
  //                 label="Select Charge Type"
  //                 value={ccharge.vendorChargeTypeId}
  //                 onChange={this.handleChangeSite}
  //                 error=""
  //                 placeholder="Select Charge Type"
  //                 listData={cchargeList}
  //                 valueField="dName"
  //               ></GFSelect>
  //             </div>
  //             <div className="col-md-3 col-sm-4">
  //               <GFInput
  //                 name="deliveryFees"
  //                 label="Charge Fees"
  //                 value={ccharge.deliveryFees}
  //                 isRequired={true}
  //                 onChange={this.handleChangeSite}
  //                 type="number"
  //                 error=""
  //                 placeholder=""
  //                 disabled={false}
  //               ></GFInput>
  //             </div>
  //             <div className="col-12"></div>
  //             <div className="col-md-3 col-sm-4 py-2"></div>

  //             <div className="col-md-3 col-sm-4 my-auto">
  //               <button
  //                 className="active-btn"
  //                 onClick={() => this.updateSocialMedia(false)}
  //               >
  //                 {isEdit ? "Update" : "Save"}
  //               </button>

  //               <button
  //                 className="active-btn bg-secondary mx-2"
  //                 onClick={() => this.setState({ addCCharge: false })}
  //               >
  //                 Cancel
  //               </button>
  //             </div>
  //           </div>
  //         </>
  //       )}
  //     </React.Fragment>
  //   );
  // }

  render() {
    // if (!this.props.auth.isLoggedIn) {
    //   this.props.history.push("/login");
    //   return true;
    // }
    const { isModalOpen } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Company Profile" />

        <ModalComp
          title="Confirmation"
          hideModal={this.toggleModal}
          isOpen={isModalOpen}
        >
          <div className="modal-body my-4 text-center">
            Are you sure, do you want delete ?
          </div>
          <div className="mb-3 text-center">
            <button
              className="btn btn-light btn-sm mx-1"
              onClick={this.toggleModal}
            >
              Cancal
            </button>
            <button
              className="btn btn-danger btn-sm mx-1 text-capitalize"
              onClick={() => this.updateSocialMedia(true)}
            >
              Delete
            </button>
          </div>
        </ModalComp>

        <input
          className="d-none"
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept="image/*,.pdf"
          onChange={this.handleFileSelect}
        ></input>
        <MainContentComp>
          <TabViewComp
            tabs={this.state.tabs}
            onClickTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
          >
            {this.getTabContent()}
          </TabViewComp>
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderBusiDetail() {
    const isApproved = this.state.data["isApproved"];
    const defaultLang = this.state.data["defaultLang"];
    // const { commission, chargeType } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-md-3 col-sm-4">
              {this.renderInput("companyName", "Company Name", false, true)}
            </div>
            <div className="col-md-3 col-sm-4 my-auto">
              <img
                style={{ height: 60 }}
                className="mx-1"
                src={this.state.data["logo"]}
                alt=""
              />
              <span
                className="btn btn-light text-secondary"
                style={{ border: "1px dashed #898989" }}
                onClick={(e) => this.handleUploadClick(e, null)}
              >
                <span>Upload Logo</span> <Upload size={22} />
              </span>
            </div>
            <div className="col-md-3 col-sm-4"></div>
            <div className="col-md-3 col-sm-4"></div>
            <div className="col-md-3  col-sm-4">
              {this.renderTextarea("termsConditions", "Terms & Conditions")}
            </div>
            <div className="col-md-3  col-sm-4">
              {this.renderTextarea("customSmsEn", "Custom SMS EN")}
            </div>
            <div className="col-md-3 col-sm-4">
              {this.renderTextarea("customSmsAr", "Custom SMS AR")}
            </div>
            <div className="col-md-3 col-sm-4"></div>
            <div className="col-md-3 col-sm-4">
              {this.renderInput("companyURL", "Company URL")}
            </div>
            <div className="col-md-3 col-sm-4">
              {this.renderSelect(
                "invoiceExDays",
                "Default Invoice Expiry Days",
                this.expiryDays,
                "id"
              )}
            </div>
            <div className="col-md-3 col-sm-4">
              {this.renderInput("deliveryFees", "Products Delivery Fees")}
            </div>
            <div className="col-md-3 col-sm-4">
              <label>
                Default Language <span>*</span>
              </label>
              <div>
                <div className="radio">
                  <input
                    id="langAr"
                    type="radio"
                    name="lang"
                    value="AR"
                    checked={defaultLang === "AR"}
                    onChange={(e) => this.handleRadioBtn(e)}
                  />
                  <label htmlFor="langAr" className="radio-label">
                    Arabic
                  </label>
                </div>

                <div className="radio">
                  <input
                    id="langEng"
                    type="radio"
                    name="lang"
                    value="ENG"
                    checked={defaultLang === "ENG"}
                    onChange={(e) => this.handleRadioBtn(e)}
                  />
                  <label htmlFor="langEng" className="radio-label">
                    English
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-4">
              {this.renderInput("promoCode", "Promo Code")}
            </div>
            <div className="col-md-3 col-sm-4">
              {this.renderInput("depositTerms", "Deposit Terms")}
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Approval Status</label>

                {isApproved ? (
                  <p className="approve">
                    <i className="material-icons">offline_pin</i>Yes
                  </p>
                ) : (
                    <p className="approve">
                      <i className="material-icons text-dark">offline_pin</i>No
                    </p>
                  )}
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              {this.renderInput("profileName", "Profile Name")}
            </div>
          </div>
          <br />

          {authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && (
            <div className="row">
              <div className="col-4 shadow-sm p-2 border">
                <Wallet />
              </div>
            </div>
          )}

          {!authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && (
            <AuthContainer action="ADD">
              <div>{this.renderButton("Save")}</div>
            </AuthContainer>
          )}
        </form>
      </React.Fragment>
    );
  }

  renderBankDetail() {
    const { bankDetail: bd, isAddBank } = this.state;

    let isEdit = false;
    if (bd["clientCompanyId"]) {
      isEdit = true;
    }
    return (
      <React.Fragment>
        <div className="text-right">
          {this.isSuperAdmin && (
            <button
              className="active-btn"
              onClick={() =>
                this.setState({ isAddBank: !this.state.isAddBank })
              }
            >
              {isEdit ? "Edit" : "Add"}
            </button>
          )}
        </div>

        <hr />
        {bd && !isAddBank && (
          <div className="row">
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Account Holder Name</label>
                <p>{bd.accountHolderName}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Account</label>
                <p>{bd.accountNo}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Iban</label>
                <p>{bd.iban}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Name</label>
                <p>{bd.bankName} </p>
              </div>
            </div>
          </div>
        )}

        {authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && isAddBank && (
          <div className="row my-4">
            <div className="col-md-3 col-sm-4">
              <GFSelect
                name="bankName"
                label="Bank Name"
                value={bd.bankName}
                onChange={this.handleChangeBank}
                error=""
                placeholder="Bank Name"
                listData={this.state.bankList}
                valueField="dName"
              ></GFSelect>
            </div>
            <div className="col-md-3 col-sm-4">
              <GFInput
                name="accountHolderName"
                label="Bank Account Holder Name"
                value={bd.accountHolderName}
                isRequired={false}
                onChange={this.handleChangeBank}
                type="text"
                error=""
                placeholder=""
                disabled={false}
              ></GFInput>
            </div>
            <div className="col-md-3 col-sm-4">
              <GFInput
                name="accountNo"
                label="Bank Account Number"
                value={bd.accountNo}
                isRequired={false}
                onChange={this.handleChangeBank}
                type="number"
                error=""
                placeholder=""
                disabled={false}
              ></GFInput>
            </div>
            <div className="col-md-3 col-sm-4">
              <GFInput
                name="iban"
                label="IBAN"
                value={bd.iban}
                isRequired={false}
                onChange={this.handleChangeBank}
                type="text"
                error=""
                placeholder=""
                disabled={false}
              ></GFInput>
            </div>
            <div className="col-md-3 col-sm-4">
              <GFInput
                name="swiftCode"
                label="Swift Code"
                value={bd.swiftCode}
                isRequired={false}
                onChange={this.handleChangeBank}
                type="text"
                error=""
                placeholder=""
                disabled={false}
              ></GFInput>
            </div>
            <div className="col-12 my-4">
              <button className="active-btn" onClick={this.updateBankInfo}>
                {isEdit ? "Update" : "Create"}
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderSocialMedia() {
    const { addMedia, media, mediaList, isEdit } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right">
            {!authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && (
              <AuthContainer action="ADD">
                <button className="active-btn" onClick={this.createSocialMedia}>
                  New Social Media
                </button>
              </AuthContainer>
            )}
          </div>
          <div className="col-12">
            <hr />
          </div>

          {!addMedia &&
            mediaList.map((val: any, idx) => {
              return (
                <div key={idx} className="col-3 text-center">
                  <div className="mx-1 shadow pt-2">
                    <div
                      className="text-center overflow-hidden my-2 text-primary"
                      style={{ height: 50 }}
                    >
                      <i className={val.logo} style={{ fontSize: 45 }}></i>
                    </div>
                    <div>{new URL(val.socialName).host} </div>
                    <div className="my-2 overflow-hidden">
                      <a
                        href={globalService.linkCheck(val.accountUrl)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-wrap"
                      >
                        {val.accountUrl}
                      </a>{" "}
                    </div>
                    <hr />
                    <div className="my-2 pb-2">
                      <AuthContainer action="EDIT">
                        <span
                          className="mx-3 text-secondary h6 cursor-pointer"
                          onClick={() =>
                            this.setState({
                              addMedia: true,
                              media: val,
                              isEdit: true,
                            })
                          }
                        >
                          <Edit2 size={20}></Edit2>
                        </span>
                      </AuthContainer>

                      <AuthContainer action="DELETE">
                        <span
                          className="mx-3 text-danger h6 cursor-pointer"
                          onClick={() => this.handleDeleteSocialMedia(val)}
                        >
                          <Trash2 size={20}></Trash2>
                        </span>
                      </AuthContainer>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {addMedia && (
          <div className="row shadow py-2">
            <div className="col-md-3 col-sm-4">
              <GFSelect
                name="socialName"
                label="Select Site"
                value={media.socialName}
                onChange={this.handleChangeSite}
                error=""
                placeholder="Select Site"
                listData={this.siteList}
                valueField="link"
              ></GFSelect>
            </div>
            <div className="col-md-3 col-sm-4">
              <GFInput
                name="accountName"
                label="Account Name"
                value={media.accountName}
                isRequired={false}
                onChange={this.handleChangeSite}
                type="text"
                error=""
                placeholder=""
                disabled={false}
              ></GFInput>
            </div>
            <div className="col-12"></div>
            <div className="col-md-3 col-sm-4 py-2">
              <div className="input-div default-label">
                <label>Social Media link</label>
                <p>
                  <span className="mx-2 text-primary">
                    <i className={media.logo}></i>
                  </span>
                  <span className="text-secondary">{media.socialName}</span>
                  <span className="text-dark">{media.accountName}</span>
                </p>
              </div>
            </div>

            <div className="col-md-3 col-sm-4 my-auto">
              <button
                className="active-btn"
                onClick={() => this.updateSocialMedia(false)}
              >
                {isEdit ? "Update" : "Save"}
              </button>

              <button
                className="active-btn bg-secondary mx-2"
                onClick={() => this.setState({ addMedia: false })}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
  renderVendDoc() {
    const { vendDoc: vd } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <hr />
          </div>
          <div className="col-md-3 col-sm-4">
            <label className="default-label-style">Commercial License</label>
            {this.renderVendDocField(vd.fComLicense, "fComLicense")}
          </div>
          <div className="col-md-3 col-sm-4">
            {this.renderVendDocExpireDate(
              vd.comLicenseExDate,
              "comLicenseExDate"
            )}
          </div>
          <div className="col-md-3 col-sm-4">
            <label className="default-label-style">
              Articles of Association
            </label>
            {this.renderVendDocField(
              vd.fArticleOfAssociation,
              "fArticleOfAssociation"
            )}
          </div>
          <div className="col-md-3 col-sm-4">
            {this.renderVendDocExpireDate(
              vd.articleOfAssociationExDate,
              "articleOfAssociationExDate"
            )}
          </div>
          <div className="col-md-3 col-sm-4">
            <label className="default-label-style">
              Signature Authorisation
            </label>
            {this.renderVendDocField(
              vd.fSignAuthorisation,
              "fSignAuthorisation"
            )}
          </div>
          <div className="col-md-3 col-sm-4">
            {this.renderVendDocExpireDate(
              vd.signAuthorisationExDate,
              "signAuthorisationExDate"
            )}{" "}
          </div>
          <div className="col-md-3 col-sm-4">
            <label className="default-label-style">Others</label>
            {this.renderVendDocField(vd.fOthers, "fOthers")}{" "}
          </div>
          <div className="col-md-3 col-sm-4">
            {this.renderVendDocExpireDate(vd.othersExDate, "othersExDate")}{" "}
          </div>
          <div className="col-md-3 col-sm-4">
            <label className="default-label-style">Civil Id Front</label>
            {this.renderVendDocField(vd.fCivilId, "fCivilId")}
            <label className="default-label-style">Civil Id Back</label>
            {this.renderVendDocField(vd.fcivilIdBank, "fcivilIdBank")}{" "}
          </div>
          <div className="col-md-3 col-sm-4">
            {this.renderVendDocExpireDate(vd.civilIdExDate, "civilIdExDate")}
          </div>
        </div>
        <br />
        {!authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && (
          <div>
            <AuthContainer action="ADD">
              <button className="active-btn" onClick={this.handleSaveVendDoc}>
                Save
              </button>
            </AuthContainer>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderVendDocField(field, fieldName) {
    return (
      <React.Fragment>
        <div>
          {!field && (
            <button
              className="bg-light text-secondary p-1 btn-block"
              style={{ border: "1px dashed #828290" }}
              onClick={(e) => this.handleUploadClick(e, fieldName)}
              disabled={this.isSuperAdmin}
            >
              Upload Document
            </button>
          )}
        </div>

        <div>
          {field && (
            <div className="bg-light p-2  my-2">
              <a
                href={globalService.getCompanyLogo(
                  this.state.vendDoc[fieldName]
                )}
                // {globalService.IMG_ROOT + val.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Document
              </a>

              {!this.isSuperAdmin && (
                <AuthContainer action="DELETE">
                  <span
                    className="float-right text-danger"
                    onClick={() => this.handleRemoveDoc(fieldName)}
                  >
                    <X></X>
                  </span>
                </AuthContainer>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderVendDocExpireDate(field, fieldName) {
    return (
      <div className="input-div default-label md-value">
        <label>Expire Date</label>
        <DateTimePicker
          defaultValue={new Date()}
          value={field}
          format="DD/MM/YYYY"
          onChange={(value) => this.handleDateChange(fieldName, value)}
          time={false}
          min={new Date()}
        />
      </div>
    );
  }

  renderContactDetail() {
    let {
      contactDetail: cd,
      isAddContact,
      countryList,
      contactList: cl,
      isEditContact,
    } = this.state;
    return (
      <React.Fragment>
        <div className="text-right">
          {!authService.checkUserType(AppConstants.UT_SUPER_ADMIN) && (
            <AuthContainer action="ADD">
              <button className="active-btn" onClick={this.createContact}>
                New Contact
              </button>
            </AuthContainer>
          )}
        </div>

        {isAddContact && (
          <div className="row shadow my-2  p-2 rounded">
            <div className="col-md-3 col-sm-4 text-left">
              <label>
                Choose contact Type <span className="text-danger">*</span>
              </label>
              <div>
                <div className="radio">
                  <input
                    id="ctMob"
                    type="radio"
                    name="isMobile"
                    value="mobile"
                    checked={cd.isMobile === "mobile"}
                    onChange={this.handleChangeContact}
                  />
                  <label htmlFor="ctMob" className="radio-label">
                    Mobile
                  </label>
                </div>

                <div className="radio">
                  <input
                    id="ctEm"
                    type="radio"
                    name="isMobile"
                    value="email"
                    checked={cd.isMobile === "email"}
                    onChange={this.handleChangeContact}
                  />
                  <label htmlFor="ctEm" className="radio-label">
                    Email
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 my-1"></div>

            <div className="col-12">
              {cd.isMobile === "email" && (
                <div className="row">
                  <div className="col-md-3 col-sm-4">
                    <GFInput
                      name="email"
                      label="Email ID"
                      value={cd.email}
                      isRequired={false}
                      onChange={this.handleChangeContact}
                      type="text"
                      error=""
                      placeholder=""
                      disabled={false}
                    ></GFInput>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <label>Send Notification</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className="styled-checkbox"
                              id="isPrim"
                              name="isPrimary"
                              type="checkbox"
                              checked={cd.isPrimary}
                              onChange={this.handleChangeContact}
                            />
                            <label htmlFor="isPrim"></label>
                          </td>
                          <td>Is Enabled ?</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <button
                      className="active-btn mx-1"
                      onClick={this.updateContact}
                    >
                      {isEditContact ? "Update" : "Create"}
                    </button>
                    <button
                      className="active-btn  bg-secondary my-1"
                      onClick={() => this.setState({ isAddContact: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12">
              {cd.isMobile === "mobile" && (
                <div className="row">
                  <div className="col-3">
                    <GFSelect
                      name="type"
                      label="Type"
                      value={cd.type}
                      onChange={this.handleChangeContact}
                      error=""
                      placeholder="Type"
                      listData={this.contTypeList}
                      valueField="dName"
                    ></GFSelect>
                  </div>
                  <div className="col-2">
                    <GFSelect
                      name="countryCode"
                      label="Country Code"
                      value={cd.countryCode}
                      onChange={this.handleChangeContact}
                      error=""
                      placeholder="Country Code"
                      listData={countryList}
                      valueField="phoneCode"
                    ></GFSelect>
                  </div>
                  <div className="col-2">
                    <GFInput
                      name="mobileNo"
                      label="Number"
                      value={cd.mobileNo}
                      isRequired={false}
                      onChange={this.handleChangeContact}
                      type="number"
                      error=""
                      placeholder=""
                      disabled={false}
                    ></GFInput>
                  </div>
                  <div className="col-2">
                    <GFSelect
                      name="serviceProvider"
                      label="service Provider"
                      value={cd.serviceProvider}
                      onChange={this.handleChangeContact}
                      error=""
                      placeholder="service Provider"
                      listData={this.serviceProviderList}
                      valueField="dName"
                    ></GFSelect>
                  </div>
                  <div className="col-2 col-md-2 col-sm-4">
                    <label>Send Notification</label>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className="styled-checkbox"
                              id="isPrim"
                              name="sendNotification"
                              type="checkbox"
                              checked={cd.sendNotification}
                              onChange={this.handleChangeContact}
                            />
                            <label htmlFor="isPrim"></label>
                          </td>
                          <td>Is Enabled ?</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12">
                    <button
                      className="active-btn my-1"
                      onClick={this.updateContact}
                    >
                      {isEditContact ? "Update" : "Create"}
                    </button>
                    <button
                      className="active-btn  bg-secondary my-1"
                      onClick={() => this.setState({ isAddContact: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {!isAddContact && (
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
            {cl.map((val: any, idx) => {
              return (
                <div key={idx} className="col-3 px-2 py-1 my-2">
                  <div className="shadow h-100">
                    <div className="row h-100">
                      <div className="col-3 my-auto text-center text-app">
                        {val.email ? (
                          <Mail className="my-auto" size={40}></Mail>
                        ) : (
                            <Phone className="my-auto" size={40}></Phone>
                          )}
                      </div>
                      <div className="col-9 pl-0 py-1 my-auto">
                        <div>
                          {val.email !== "" ? (
                            <a href={"mailto:" + val.email}>{val.email}</a>
                          ) : (
                              ""
                            )}{" "}
                          {val.mobileNo !== "" ? (
                            <React.Fragment>
                              <a href={"tel:" + val.mobileNo}>
                                {val.countryCode}-{val.mobileNo}
                              </a>
                              <span className="mx-2">
                                ({val.serviceProvider})
                              </span>
                            </React.Fragment>
                          ) : (
                              ""
                            )}
                        </div>

                        {val.sendNotification && (
                          <div className="text-success">
                            <CheckCircle size={14}></CheckCircle>{" "}
                            <small>Notification</small>
                          </div>
                        )}
                        <div>
                          <AuthContainer action="EDIT">
                            <span className="mr-2 cursor-pointer">
                              <Edit2
                                size={14}
                                onClick={() => this.editContact(val)}
                              ></Edit2>
                            </span>
                          </AuthContainer>

                          <AuthContainer action="DELETE">
                            <span className="mx-2 text-danger cursor-pointer">
                              <Trash2
                                size={14}
                                onClick={() => this.deleteContact(val)}
                              >
                                {" "}
                              </Trash2>
                            </span>
                          </AuthContainer>
                        </div>
                      </div>
                    </div>
                    {/* {val.mobileNo} {val.email} {val.serviceProvider} {val.type} */}
                  </div>{" "}
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }

  handleTest = () => {
    // console.log(this.state.data);
    // console.log(this.schema);
  };
}

const mapActionToProps = {
  getChargeTypes: ApplicationAction.company.getChargeList,
  getWalletDetail: ApplicationAction.invoice.getWalletDetail,
};

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
    company: state.company,
  }),
  mapActionToProps
)(UpdateProfile);
