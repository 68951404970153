import React from "react";
import "./App.scss";

import { Route, withRouter } from "react-router-dom";
import { routes } from "./router";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const App: React.FC = () => {
  return <Route history={history} children={routes} />;
};

export default withRouter(App as any);
