import * as React from "react";
import emailSVG from "../../assets/img/email.svg";
import { Link } from "react-router-dom";

export interface RegisterConfirmProps {}

export interface RegisterConfirmState {}

class RegisterConfirm extends React.Component<
  RegisterConfirmProps,
  RegisterConfirmState
> {
  state = {};
  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }

  renderPage() {
    return (
      <React.Fragment>
        <div className="login-bg">
          <div className="login_details">
            <div
              style={{
                height: "100vh",
                width: 2000,
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <div className="login_details_div">
                <div className="row m-0">
                  <div className="register-div">
                    <div className="align-center">
                      <img src={emailSVG} className="msg-img" alt="" />
                    </div>
                    <br />
                    <div className="border-top-1">
                      <p className="header align-center">Email Confirmation</p>
                      <p className="content">
                        Hey Ameer Khan, Your email is successufully register
                        with PrincessQ8.
                      </p>
                      <p className="content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                    </div>
                    <br />
                    <br />
                    <div className="align-center">
                      <Link to="/login" className="Login-button">
                        Login Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterConfirm;
