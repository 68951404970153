import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CompanyList from "../company/companyList";
import CreateInvoice from "../invoice/create-invoice";
import InvoiceDetail from "../invoice/invoice-detail";
import InvoiceList from "../invoice/invoice-list";
import PaymentLinkList from "../paymentLink/paymentLinkList";
import LoginSession from "../profile/login-session";
import menus from "../setting/menus";
import vendorChargeList from "../setting/vendorChargeList";
import CompanyDetail from "./../company/companyDetail";
import CustomerList from "./../customer-list";
import CreateQuickInvoice from "./../invoice/create-quick-invoice";
import DepositList from "./../invoice/deposit-list";
import PaidInvList from "./../invoice/paid-inv-list";
import CreatePayLink from "./../paymentLink/create-paylink";
import CommissionCharge from "./../profile/commission-charge";
import CustomizeTemp from "./../profile/customize-template";
import UpdateProfile from "./../profile/update-profile";
import CompanyUser from "./../setting/CompanyUser";
import RoleAddUpdate from "./../setting/RoleAddUpdate";
import Role from "./../setting/roles";
import Dashboard from "./Dashboard";
import LoginProfile from "./loginProfile";
import NoAccess from "./noAccess";

export const AdminRoutes = (
  <Switch>
    <Route exact path="/app" component={Dashboard} />
    <Route path="/app/dashboard" component={Dashboard} />
    {/* Profile */}
    <Route path="/app/update-profile" component={UpdateProfile} />
    <Route path="/app/commission" component={CommissionCharge} />
    <Route path="/app/cust-temp" component={CustomizeTemp} />

    {/* Invoice */}
    <Route path="/app/inv-list" component={InvoiceList} />
    <Route path="/app/paid-inv" component={PaidInvList} />
    <Route path="/app/deposit-list" component={DepositList} />

    <Route path="/app/create-inv" component={CreateInvoice} />
    <Route path="/app/create-quick-inv" component={CreateQuickInvoice} />
    <Route path="/app/inv-detail" component={InvoiceDetail} />

    {/* Customer */}
    <Route path="/app/cust-list" component={CustomerList} />
    <Route path="/app/paylink-list" component={PaymentLinkList} />
    <Route path="/app/create-paylink" component={CreatePayLink} />

    {/* Company */}
    <Route exact path="/app/company" component={CompanyList} />
    <Route path="/app/company/detail" component={CompanyDetail} />

    {/* setting */}
    <Route exact path="/app/role" component={Role} />
    <Route path="/app/role/:type/:id?" component={RoleAddUpdate} />
    <Route path="/app/user" component={CompanyUser} />
    <Route path="/app/menu" component={menus} />
    <Route path="/app/vcharge" component={vendorChargeList} />

    <Route path="/app/user-session" component={LoginSession} />
    <Route path="/app/myprofile" component={LoginProfile} />
    <Route path="/app/no-access" component={NoAccess} />

    {/* <Route path="/app/iuser" component={CompanyUser} />
    <Route exact path="/app/role" component={Roles} />
    <Route path="/app/role/:type/:id?" component={RoleAddUpdate} />
    <Route path="/app/branch" component={Branch} />
    <Route path="/app/profile" component={UserProfile} />
    <Route exact path="/app/category" component={Category} />
    <Route path="/app/category/:id" component={SubCategory} />
    <Route path="/app/brand" component={Brand} />
    <Route exact path="/app/asset" component={VendorAsset} />
    <Route path="/app/asset/:type" component={VAssetAddUpdate} />
    <Route path="/app/asset/:id" component={VendorAssetDetail} />
    <Route path="/app/masterasset" component={MasterAsset} /> */}
    <Redirect to="/not-found" />
  </Switch>
);
