import { toast } from 'react-toastify';
import { AppThunkAction } from ".";
import httpService from "../services/httpService";
// import { connect } from "react-redux";
// import { CommonState } from './commonStore';
import { IMenu, ISessionInfo, IUser } from "../services/userInterface";
// import globalService from "../services/globalService";
import { AppConstants } from './../common/appConfig';

//CommonStore is for common actions such as loading, popups, and toasts.
//I only kept loading state, and commented the rest.
//Usually, this is connected to a Layout.tsx components, but since its not here, MultiLanguage.tsx was used to display loading bar.

//Global store containg properires of loading, popup, and toast.

// const USER_AUTH = AppConstants.LS_USER_AUTH;
// const USER_API = AppConstants.API_ROOT_OLD + 'user/';
// const CMN_API = globalService.CMN_API_ROOT;

const USER_AUTH = "user_auth";
//  "AppConstants.LS_USER_AUTH;"
const USER_API = AppConstants.API_ROOT + "Users/";
const CMN_API = "globalService.CMN_API_ROOT";
export interface AuthState {
    isLoggedIn: boolean;
    sessionInfo: ISessionInfo | any;
    menus: IMenu | any;
    userDetail: IUser | any;
    isRespSuccess: boolean;
    responseData: any
}

//Actions
interface SET_AUTH {
    type: "SET_AUTH";
    isLoggedIn: boolean;
    sessionInfo: any;
    menus: any[];
    userDetail: any;
}

interface UPDATE_USER_DETAIL {
    type: "UPDATE_USER_DETAIL";
    userDetail: any;
    isRespSuccess: boolean;
}

interface CHECK_AUTH {
    type: "CHECK_AUTH";
    isLoggedIn: boolean;
    sessionInfo: any;
    menus: any[];
    userDetail: any;
}

interface LOGOUT {
    type: "LOGOUT";
    isLoggedIn: boolean;
    isRespSuccess: boolean;
}

// New API
interface LOGIN {
    type: "LOGIN";
    isLoggedIn: boolean;
    sessionInfo: any;
    menus: any[];
    userDetail: any;
    isRespSuccess: boolean;
}

interface SET_LOGIN_MENU {
    type: "SET_LOGIN_MENU";
    menus: any[];
}

interface FORGET_PASS {
    type: "FORGET_PASS";
    isRespSuccess: boolean;
}


interface REGISTER {
    type: "REGISTER";
    isRespSuccess: boolean;
}

interface CHECK_USERNAME {
    type: "CHECK_USERNAME";
    isRespSuccess: boolean;
}

interface CHANGE_PASS {
    type: "CHANGE_PASS";
    isRespSuccess: boolean
}
interface MASTER_CARD_PAYMENT {
    type: "MASTER_CARD_PAYMENT";
    isRespSuccess: boolean;
    responseData: any
}


export type AuthActions = SET_AUTH | UPDATE_USER_DETAIL | CHECK_AUTH | REGISTER | LOGIN | LOGOUT |
    FORGET_PASS | CHECK_USERNAME | CHANGE_PASS | SET_LOGIN_MENU | MASTER_CARD_PAYMENT;


export const actionCreators = {

    setAuth: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        dispatch({ type: "SET_AUTH", isLoggedIn: true, menus: data.menu, userDetail: data.model, sessionInfo: data.sessionInfo });
    },

    updateAuth: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {

        await httpService.post(USER_API + 'update', data).then(({ data: res }) => {
            if (res.Status === "Success") {
                toast.success("Profile details updated successfully");
                let ua: IUser = getState().auth.userDetail;
                ua.firstName = data.FirstName;
                ua.firstName = data.Id;
                ua.firstName = data.FirstName;
                ua.middleName = data.MiddleName;
                ua.lastName = data.LastName;
                ua.mobile = data.Mobile;
                ua.profilePic = data.ProfilePic;

                let sessionData = JSON.parse(localStorage.getItem(USER_AUTH) as any);
                sessionData.model = ua;
                localStorage.setItem(USER_AUTH, JSON.stringify(sessionData));
                dispatch({ type: "UPDATE_USER_DETAIL", userDetail: ua, isRespSuccess: true });
            } else {
                toast.error(res.response);
            }
        })
    },

    login: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.post(USER_API + 'Login', data).then(({ data: res }) => {
            if (!res.didError) {
                localStorage.setItem(USER_AUTH, JSON.stringify(res));
                dispatch({
                    type: "LOGIN", isLoggedIn: true,
                    menus: res.menu, userDetail: res.model,
                    sessionInfo: res.sessionInfo, isRespSuccess: true
                });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    setLoginMenu: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        dispatch({ type: "SET_LOGIN_MENU", menus: data });
    },

    forgetPassword: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.post(CMN_API + 'resetlink', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "FORGET_PASS", isRespSuccess: true });
            } else {
                dispatch({ type: "FORGET_PASS", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    register: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.post(CMN_API + 'RegisterUser', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "REGISTER", isRespSuccess: true });
            } else {
                dispatch({ type: "REGISTER", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    checkUsername: (userName): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.post(CMN_API + 'UsernameCheck', { userName: userName, password: 'worngpass' }).then(({ data: res }) => {
            if (!res.didError) {
                if (res.errorMessage === "Allow to register.") {
                    dispatch({ type: "CHECK_USERNAME", isRespSuccess: true });
                } else {
                    dispatch({ type: "CHECK_USERNAME", isRespSuccess: false });
                    toast.error("Your email id already registered with us. Please login in.");
                }
            } else {
                dispatch({ type: "CHECK_USERNAME", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    logout: (): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.delete(USER_API + 'logout').then(({ data: res }) => {
            if (!res.didError) {
                localStorage.removeItem(USER_AUTH);
                dispatch({ type: "LOGOUT", isLoggedIn: false, isRespSuccess: true });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },


    checkAuth: (): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        const data = JSON.parse(localStorage.getItem(USER_AUTH) as any);

        if (data)
            dispatch({ type: "CHECK_AUTH", isLoggedIn: true, menus: data.menu, userDetail: data.model, sessionInfo: data.sessionInfo });
        else console.log('Login required');
    },

    changePassword: (data): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.put(USER_API + "changePassword", data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "CHANGE_PASS", isRespSuccess: true });
            } else {
                dispatch({ type: "CHANGE_PASS", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },
    masterCardPayment: (body): AppThunkAction<AuthActions> => async (
        dispatch,
        getState) => {
        await httpService.post("https://store.PrincessQ8.net/Zpay/api/PaymentMaster", body).then(({ data: res }) => {
            if (res.Status === "success") {
                // toast.success(res.message);
                dispatch({ type: "MASTER_CARD_PAYMENT", isRespSuccess: true, responseData: res.Response });
            } else {
                dispatch({ type: "MASTER_CARD_PAYMENT", isRespSuccess: false, responseData: res.Response });
                // toast.error(res.errorMessage);
            }
        })
    }
};


const unloadedState: AuthState = {
    isLoggedIn: false,
    menus: [],
    sessionInfo: {},
    userDetail: {},
    isRespSuccess: false,
    responseData: {}

};

export function reducer(
    state: AuthState,
    action: AuthActions
): AuthState {
    switch (action.type) {
        case "SET_AUTH":
            return {
                ...state, isLoggedIn: true, sessionInfo: action.sessionInfo,
                userDetail: action.userDetail, menus: action.menus
            };
        case "UPDATE_USER_DETAIL":
            return {
                ...state, userDetail: action.userDetail, isRespSuccess: action.isRespSuccess
            };
        case "CHECK_AUTH":
            return {
                ...state, isLoggedIn: true, sessionInfo: action.sessionInfo,
                userDetail: action.userDetail, menus: action.menus
            };
        case "LOGIN":
            return {
                ...state, isLoggedIn: true, sessionInfo: action.sessionInfo,
                userDetail: action.userDetail, menus: action.menus, isRespSuccess: action.isRespSuccess
            };
        case "SET_LOGIN_MENU":
            return {
                ...state, menus: action.menus
            };
        case "FORGET_PASS":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };
        case "REGISTER":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };
        case "CHECK_USERNAME":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };
        case "LOGOUT":
            return { ...state, isLoggedIn: false, isRespSuccess: action.isRespSuccess };
        case "CHANGE_PASS":
            return {
                ...state, isRespSuccess: action.isRespSuccess
            };
        case "MASTER_CARD_PAYMENT":
            return {
                ...state, isRespSuccess: action.isRespSuccess, responseData: action.responseData
            };

    }
    return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
