import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";

export interface CommissionChargeProps {}

export interface CommissionChargeState {}

class CommissionCharge extends React.Component<
  CommissionChargeProps,
  CommissionChargeState
> {
  state = {};
  render() {
    return (
      <React.Fragment>
        <TitleBarComp title="Commission Charges" />
        <MainContentComp>
          <div className="row">
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Account Holder Name</label>
                <p>PrincessQ8</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Account</label>
                <p>KW80KFHO00000078475</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Iban</label>
                <p>KW80KFHO00000078475</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Bank Name</label>
                <p>Kuwait Finance House (KFH)</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Is Debit Enabled</label>
                <p className="approve">
                  <i className="material-icons">offline_pin</i>Yes
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-4">
              <div className="input-div default-label">
                <label>Is Credit Enabled</label>
                <p className="approve">
                  <i className="material-icons">offline_pin</i>Yes
                </p>
              </div>
            </div>
          </div>
          <br />
          <h4>API Details</h4>
          <div className="row">
            <div className="col-md-3 col-sm-4">
              <div className="input-div md-value">
                <label className="label-style">API Payment Mode</label>
                <select className="input-style">
                  <option>All</option>
                  <option>KNET</option>
                  <option>AMEX</option>
                </select>
              </div>
            </div>
          </div>
          <h4>Payment Methods</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">KNET</div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 0.75 KD ON EACH
                        TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-1"
                            name="radio"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-2" name="radio" type="radio" />
                          <label htmlFor="radio-2" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">VISA/Master</div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 2.750 PERCENT ON
                        EACH TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-11"
                            name="radio1"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-11" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-21" name="radio1" type="radio" />
                          <label htmlFor="radio-21" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">
                  AMEX{" "}
                  <label className="denied">
                    <i className="material-icons">warning</i> Disabled By Admin!
                  </label>
                </div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 2.750 PERCENT ON
                        EACH TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-12"
                            name="radio2"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-12" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-22" name="radio2" type="radio" />
                          <label htmlFor="radio-22" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">
                  Sadad{" "}
                  <label className="denied">
                    <i className="material-icons">warning</i> Disabled By Admin!
                  </label>
                </div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 0.75 KD ON EACH
                        TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-13"
                            name="radio3"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-13" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-23" name="radio3" type="radio" />
                          <label htmlFor="radio-23" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">Benefit</div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 2.750 PERCENT ON
                        EACH TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-1"
                            name="radio4"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-2" name="radio4" type="radio" />
                          <label htmlFor="radio-2" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">
                  Debit Cards UAE{" "}
                  <label className="denied">
                    <i className="material-icons">warning</i> Disabled By Admin!
                  </label>
                </div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 0.75 KD ON EACH
                        TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-1"
                            name="radio5"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-2" name="radio5" type="radio" />
                          <label htmlFor="radio-2" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="payment-method-list">
                <div className="payment-method-list-title">Quat Debit Card</div>
                <div className="payment-method-list-body">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="info">
                        <i className="material-icons">info</i> 2.750 KD ON EACH
                        TRANSACTION
                      </p>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="input-div md-value">
                        <label className="label-style">
                          Commission From <span>*</span>
                        </label>
                        <select className="input-style">
                          <option>--Choose--</option>
                          <option>Vendor</option>
                          <option>Customer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <label>Is Active</label>
                      <div>
                        <div className="radio">
                          <input
                            id="radio-1"
                            name="radio6"
                            type="radio"
                            checked={true}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Yes
                          </label>
                        </div>

                        <div className="radio">
                          <input id="radio-2" name="radio6" type="radio" />
                          <label htmlFor="radio-2" className="radio-label">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div>
            <button className="active-btn">Save</button>
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }
}

export default CommissionCharge;
