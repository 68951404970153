
const devConfig = {
    BAST_ROOT: "https://papi1.hosting-tracking.com/",
    API_ROOT: "https://papi1.hosting-tracking.com/api/v1/",
    IMAGE_PATH: "https://papi1.hosting-tracking.com/logs/",
    NOT_FOUND_IMG: "8bc9be3c-31e1-43ec-acec-8adce09de2ee.jpg",
    ENV: "DEVELOPMENT"
}

const stageConfig = {
    BAST_ROOT: "https://papi1.hosting-tracking.com/",
    API_ROOT: "https://papi1.hosting-tracking.com/api/v1/",
    IMAGE_PATH: "https://papi1.hosting-tracking.com/logs/",
    NOT_FOUND_IMG: "8bc9be3c-31e1-43ec-acec-8adce09de2ee.jpg",
    ENV: "STAGING"
}

const prodConfig = {
    BAST_ROOT: "https://papi.hosting-tracking.com/",
    API_ROOT: "https://papi.hosting-tracking.com/api/v1/",
    IMAGE_PATH: "https://papi.hosting-tracking.com/logs/",
    ENV: "PRODUCTION"
}



const ENV = process.env.REACT_APP_ENV === "PRODUCTION" ? prodConfig : process.env.REACT_APP_ENV === "STAGING" ? stageConfig : devConfig;

export const AppConstants = {
    ...ENV,
    UT_CLIENT: "Client",
    UT_SUPER_ADMIN: "SPAdmin",
    NOT_FOUND_IMG: "8bc9be3c-31e1-43ec-acec-8adce09de2ee.jpg",
}