import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-vertical-timeline-component/style.min.css";
import "./assets/css/animate.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import * as serviceWorker from "./serviceWorker";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";

import logger from "./services/logService";

import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import userReducer from "./reducers/userReducer";
// import authService from "./services/authService";
import { reducers } from "./stores";
import thunkMiddleware from "redux-thunk";

Moment.locale("en");
momentLocalizer();

const store = createStore(
  combineReducers({
    ...reducers,
    userAuth: userReducer
  } as any),
  {},
  compose(
    applyMiddleware(thunkMiddleware),

    //enables redux dev tools
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

// React.useEffect(() => {
//   (async function anyNameFunction() {
//     await ApplicationAction.auth.checkAuth();
//   })();
// }, []);

logger.init();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App /> <ToastContainer />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
