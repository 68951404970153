import React from "react";

export interface GFInputProps {
  name: any;
  label: any;
  value: any;
  isRequired: any;
  onChange: any;
  type: any;
  error: any;
  placeholder: string;
  disabled: boolean;
  exClass?: boolean;
}

export interface GFInputState {}

class GFInput extends React.Component<GFInputProps, GFInputState> {
  // state = {
  //   type: this.props.type,
  //   isPassword: this.props.type === "password" ? true : false
  // };

  // handleShowHide = () => {
  //   let { type } = this.state;
  //   if (type === "text") type = "password";
  //   else type = "text";
  //   this.setState({ type });
  // };
  render() {
    const { name, label, error, isRequired, exClass, ...rest } = this.props;
    // const { type, isPassword } = this.state;
    return (
      <div className="input-div md-value">
        <label>
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </label>
        <input
          className="input-style"
          {...rest}
          // type={type}
          id={name}
          name={name}
          step="0.001"
        />
        {error && <div className="small text-danger">{error}</div>}
      </div>

      // <div className="login-input mb-3">
      //   <img src={img} alt="" />
      //   <input className="mb-0" {...rest} type={type} id={name} name={name} />
      //   {isPassword &&
      //     !error &&
      //     (type === "password" ? (
      //       <span className="showHide" onClick={this.handleShowHide}>
      //         <i className="fa fa-eye" />
      //       </span>
      //     ) : (
      //       <span className="showHide" onClick={this.handleShowHide}>
      //         <i className="fa fa-eye-slash" />
      //       </span>
      //     ))}
      //   {error && <div className="small text-danger">{error}</div>}
      // </div>
    );
  }
}

export default GFInput;
