import Joi from "joi-browser";
import * as React from "react";
import { Edit, Eye } from "react-feather";
import { toast } from "react-toastify";
import searchIcon from "../assets/img/search_icon.png";
import AuthContainer from "../components/authContainer";
import GenericForm from "../components/genericFormComponents/GenericForm";
import GFSelect from "../components/genericFormComponents/GFSelect";
import MainContentComp from "../components/MainContent";
import ModalComp from "../components/Modal";
import TitleBarComp from "../components/TitleBar";
import userService from "../services/userService";
import CopyToClipboard from "./../components/CopyToClipboard";

export interface CustomerListProps {
  history;
}

export interface CustomerListState {}

class CustomerList extends GenericForm {
  state = {
    data: {
      fullName: "",
      email: "",
      code: "965",
      mobileNo: "",
      customerId: null,
      customerRefId: "",
    },
    errors: {},
    custList: [],
    isOpen: false,
    pagination: {
      pageSize: 20,
      pageNumber: 1,
    },
    filter: {
      search: "",
    },
    countryList: [],
  };

  isEdit: boolean = false;

  schema = {
    fullName: Joi.string().required(),
    email: Joi.string().email().required(),
    code: Joi.string().required(),
    mobileNo: Joi.number().allow("", null),
    customerId: Joi.string().allow("", null),
    customerRefId: Joi.string().allow("", null),
  };
  componentDidMount() {
    this.getInvList();
  }

  async getCountryList() {
    await userService.getCountries().then(({ data }) => {
      if (!data.didError) {
        for (let d of data.data) {
          d["dName"] = d.niceName + " (" + d.phoneCode + ")";
        }
        this.setState({ countryList: data.data });
      }
    });
  }

  async getInvList() {
    const { pagination: p, filter: f } = this.state;

    let qryStr =
      "orderBy=0&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;
    if (f.search && f.search !== "") {
      qryStr += "&fullName=" + f.search;
    }

    await userService.checkCustomer(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let custList = res.model;
        this.setState({ custList });
      }
    });
  }

  handlePageChange = ({ currentTarget: e }) => {
    let { pagination } = this.state;
    pagination.pageSize = e.value;
    this.getInvList();
    this.setState({ pagination });
  };

  handleFilterChange = ({ currentTarget: e }) => {
    let filter = this.state.filter;
    filter[e.name] = e.value;
    this.setState({ filter });
  };

  handleFilterSearch = () => {
    this.getInvList();
  };

  doSubmit = () => {
    let { data } = this.state;
    data.mobileNo = data.code + data.mobileNo;
    this.setState({ data });
    this.addUpdateCustomer();
  };

  addUpdateCustomer = async () => {
    await userService
      .customerAddUpdate(this.state.data)
      .then(({ data: res }) => {
        if (!res.didError) {
          this.togglePopup();
          toast.success(res.message);
          this.getInvList();
        }
      });
  };

  togglePopup = () => {
    this.clearField();
    const { countryList } = this.state;

    if (countryList.length === 0) {
      this.getCountryList();
    }
    this.isEdit = false;
    this.setState({ isOpen: !this.state.isOpen });
  };

  editCust = (cust) => {
    this.isEdit = true;
    this.clearField();
    const { data: cu } = this.state;
    cu.customerId = cust.customerId;
    cu.fullName = cust.fullName;
    cu.email = cust.email;
    cu.mobileNo = cust.mobileNo;
    this.setState({ data: cu, isOpen: true });
  };

  clearField() {
    const data = this.state.data;
    data.customerId = null;
    data.fullName = "";
    data.email = "";
    data.mobileNo = "";
    data.customerRefId = "";
    this.setState({ data });
  }

  render() {
    const { countryList, data } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Customer List" />
        <MainContentComp>
          <ModalComp
            title={this.isEdit ? "Update Customer" : "Create Customer"}
            isOpen={this.state.isOpen}
            hideModal={this.togglePopup}
            size="md"
          >
            <div className="modal-body">
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-12">
                    {this.renderInput("fullName", "Full Name", true, false)}
                  </div>
                  <div className="col-12">
                    {this.renderInput("email", "Email ID", true, false)}
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <GFSelect
                          name="code"
                          label="Country"
                          value={data.code}
                          onChange={this.handleChange}
                          error=""
                          placeholder="Country"
                          listData={countryList}
                          valueField="phoneCode"
                        ></GFSelect>
                      </div>
                      <div className="col-8">
                        {this.renderInput(
                          "mobileNo",
                          "Mobile Number",
                          true,
                          false,
                          "number"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {this.renderInput(
                      "customerRefId",
                      "Customer Reference",
                      true,
                      false
                    )}
                  </div>

                  <div className="col-12 text-center">
                    {this.renderButton(this.isEdit ? "Update" : "Create")}
                  </div>
                </div>
              </form>
            </div>
          </ModalComp>
          {this.renderInv()}
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderInv() {
    const { custList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right">
            <AuthContainer action="ADD">
              <button className="normal-btn" onClick={this.togglePopup}>
                Create
              </button>
            </AuthContainer>
          </div>
          <div className="col-md-3 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-sm-4"></div>
          <div className="col-md-3 col-sm-4 input-div">
            <input
              type="text"
              name="search"
              value={filter.search}
              onChange={this.handleFilterChange}
              onBlur={this.handleFilterSearch}
            />
            <img src={searchIcon} className="search_icon" alt="" />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Mobile Number</th>
                {/* <th>Customer Reference</th> */}
                <th>Email</th>
                <th>Customer Reference</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {custList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>{inv.fullName}</td>
                    <td>{inv.mobileNo}</td>
                    {/* <td>{inv.customerRef}</td> */}
                    <td>
                      <CopyToClipboard>
                        {({ copy }) => (
                          <span
                            className="text-secondary hideable"
                            onClick={() => copy(inv.email)}
                          >
                            {inv.email}
                            <small
                              style={{ fontWeight: 100 }}
                              className="float-right onlyhover cursor-pointer  bg-dark rounded text-white px-1"
                            >
                              COPY
                            </small>
                          </span>
                        )}
                      </CopyToClipboard>
                    </td>
                    <td>{inv.customerRefId}</td>
                    <td>
                      <div className="text-center cursor-pointer text-secondary">
                        <Eye
                          className="mx-1"
                          size={20}
                          onClick={() =>
                            this.props.history.push(
                              "/app/inv-list?customerId=" + inv.customerId
                            )
                          }
                        ></Eye>

                        <AuthContainer action="EDIT">
                          <Edit
                            size={20}
                            className="mx-1"
                            onClick={() => this.editCust(inv)}
                          ></Edit>
                        </AuthContainer>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerList;
