import * as React from "react";

export interface TagInputProps {
  tagss;
  input;
  removeTag;
  addTag;
  inputChange;
}

export interface TagInputState {}

class TagInput extends React.Component<TagInputProps, TagInputState> {
  state = { tags: ["Tags", "Input"], keyboard: "" };

  tagInput;
  removeTag = i => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
  };

  inputKeyDown = e => {
    const val = e.target.value;
    if ((e.keyCode === 188 || e.key === "Enter") && val) {
      if (
        this.state.tags.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ keyboard: "", tags: [...this.state.tags, val] });
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      keyboard: event.target.value === "," ? "" : event.target.value
    });
  };

  render() {
    const { tagss, removeTag, addTag, input, inputChange } = this.props;

    return (
      <div className="input-tag">
        <ul className="input-tag__tags">
          {tagss.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button
                type="button"
                onClick={() => {
                  removeTag(i);
                }}
              >
                +
              </button>
            </li>
          ))}
          <li className="input-tag__tags__input">
            <input
              type="text"
              onKeyDown={addTag}
              onBlur={addTag}
              value={input}
              onChange={inputChange}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default TagInput;
