import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import authService from "../../../services/authService";
import { connect } from "react-redux";
import { ApplicationState } from "./../../../stores/index";
import { actionCreators, AuthState } from "./../../../stores/authStore";
import { ChevronDown, ChevronRight } from "react-feather";

export interface AdminSidebarProps {
  auth: AuthState;
  history;
}

export interface AdminSidebarState {}

class AdminSidebar extends React.Component<
  AdminSidebarProps,
  AdminSidebarState
> {
  iconSize = 22;
  state = { collapse: "" };

  openNav = () => {
    const menuPart = document.getElementById("menu-part");
    const menuIconPart = document.getElementById("menu-icon-part");
    const contentPart = document.getElementById("content-part");

    if (menuPart) {
      if (!menuPart.classList.contains("menu-expended")) {
        menuPart.classList.add("menu-expended");
        if (menuIconPart) menuIconPart.classList.add("menu-icon-open");
        if (contentPart) contentPart.classList.add("main-contain-expended");
      }
    }
  };
  nothing = "";

  handleCollapse = (name) => {
    const col = this.state.collapse;
    if (col !== name) {
      this.setState({ collapse: name });
    } else {
      this.setState({ collapse: "none" });
    }
  };

  render() {
    return (
      <>
        <div id="menu-part" className="side-menu-contain menu-expended">
          {/* {this.renderMenus()} */}

          {this.renderMenu2()}

          {/* {this.renderStaticMenu()} */}
          {/* {this.renderTest()} */}
        </div>
      </>
    );
  }

  renderMenus() {
    const menus = this.props.auth.menus;
    return (
      <React.Fragment>
        <ul className="menu-list">
          {menus.map((data, idx) => {
            if (data.child.length > 0) {
              return (
                <React.Fragment key={idx}>
                  <li
                    data-toggle="collapse"
                    data-target={`#${data.menuName}`}
                    onClick={this.openNav}
                  >
                    <div className="menu-style cursor-pointer">
                      <i className="material-icons">{data.icon}</i>
                      <span>{data.displayName}</span>
                    </div>
                  </li>
                  <div
                    id={data.menuName}
                    className="collapse"
                    onClick={this.openNav}
                  >
                    <ul className="submenu-list">
                      {data.child.map((m, idx) => {
                        return (
                          <li key={idx}>
                            <Link to={m.navi}>{m.displayName}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <li key={idx} className="active-li">
                  <NavLink className="menu-style" to={data.navi}>
                    <i className="material-icons">{data.icon}</i>
                    <span>{data.displayName}</span>
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>
      </React.Fragment>
    );
  }
  renderStaticMenu() {
    let isApproved = authService.checkCompanyActive();
    return (
      // <div id="menu-part" className="side-menu-contain">
      <ul className="menu-list">
        <li className="active-li">
          <NavLink className="menu-style" to="/app/dashboard">
            <i className="material-icons">home</i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li
          data-toggle="collapse"
          data-target="#Profile"
          onClick={this.openNav}
        >
          <div className="menu-style cursor-pointer">
            <i className="material-icons">person</i>
            <span>Profile</span>
          </div>
        </li>
        <div id="Profile" className="collapse" onClick={this.openNav}>
          <ul className="submenu-list">
            <li>
              <Link to="/app/update-profile">Update Profile</Link>
            </li>
            <li>
              <Link to="/app/user-session">Login session</Link>
            </li>
          </ul>
        </div>
        {isApproved && (
          <React.Fragment>
            <li
              data-toggle="collapse"
              data-target="#Invoices"
              onClick={this.openNav}
            >
              <div className="menu-style cursor-pointer">
                <i className="material-icons">money</i>
                <span>Invoices Report</span>
              </div>
            </li>
            <div id="Invoices" className="collapse" onClick={this.openNav}>
              <ul className="submenu-list">
                <li>
                  <Link to="/app/inv-list">Invoice List</Link>
                </li>
                <li>
                  <Link to="/app/create-quick-inv">Create Quick Invoice</Link>
                </li>
                <li>
                  <Link to="/app/create-inv">Create Invoice</Link>
                </li>
              </ul>
            </div>
            <li
              data-toggle="collapse"
              data-target="#PayLink"
              onClick={this.openNav}
            >
              <div className="menu-style cursor-pointer">
                <i className="material-icons">link</i>
                <span>Payment Links</span>
              </div>
            </li>
            <div id="PayLink" className="collapse" onClick={this.openNav}>
              <ul className="submenu-list">
                <li>
                  <Link to="paylink-list">Payment Link List</Link>
                </li>
                <li>
                  <Link to="create-paylink">Create Payment Link</Link>
                </li>
              </ul>
            </div>
            <li
              data-toggle="collapse"
              data-target="#cust"
              onClick={this.openNav}
            >
              <div className="menu-style cursor-pointer">
                <i className="material-icons">perm_contact_calendar</i>
                <span>Customer</span>
              </div>
            </li>
            <div id="cust" className="collapse">
              <ul className="submenu-list">
                <li>
                  <Link to="/app/cust-list">Customer List</Link>
                </li>
              </ul>
            </div>
            <li
              data-toggle="collapse"
              data-target="#comp"
              onClick={this.openNav}
            >
              <div className="menu-style cursor-pointer">
                <i className="material-icons">business</i>
                <span>Company</span>
              </div>
            </li>
            <div id="comp" className="collapse" onClick={this.openNav}>
              <ul className="submenu-list">
                <li>
                  <Link to="/app/company">Company List</Link>
                </li>
              </ul>
            </div>

            {/* <li
            data-toggle="collapse"
            data-target="#cust"
            onClick={this.openNav}
          >
            <div className="menu-style cursor-pointer">
              <i className="material-icons">perm_contact_calendar</i>
              <span>Customer</span>
            </div>
          </li>
          <div id="cust" className="collapse">
            <ul className="submenu-list">
              <li>
                <Link to="/app/cust-list">Customer List</Link>
              </li>
            </ul>
          </div> */}
            <li
              data-toggle="collapse"
              data-target="#setting"
              onClick={this.openNav}
            >
              <div className="menu-style cursor-pointer">
                <i className="material-icons">settings</i>
                <span>Settings</span>
              </div>
            </li>
            <div id="setting" className="collapse" onClick={this.openNav}>
              <ul className="submenu-list">
                <li>
                  <Link to="/app/role">Roles</Link>
                </li>
                <li>
                  <Link to="/app/user">Users</Link>
                </li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </ul>
    );
  }

  renderMenu2() {
    const { collapse } = this.state;
    const menuList = this.props.auth.menus;
    // console.log(menuList);
    return (
      <React.Fragment>
        <ul className="nav flex-column flex-nowrap">
          {menuList.map((m: any, idx) => {
            return (
              <li key={idx} className="nav-item fs-12">
                {m.child.length > 0 && (
                  <React.Fragment>
                    <a
                      className={
                        collapse === m.menuId
                          ? "nav-link my-auto"
                          : "nav-link my-auto collapsed"
                      }
                      href={m.navi}
                      data-toggle="collapse"
                      data-target={m.menuId}
                      onClick={() => this.handleCollapse(m.menuId)}
                    >
                      {/* <FeatherIcon>{m.icon}</FeatherIcon> */}

                      {/* <i className={"fa fa-" + m.icon}></i> */}
                      <span className="text-capitalize fs-14 ml-1">
                        {m.displayName}
                      </span>
                      <span className="up-arrow float-right animated rotateIn">
                        <ChevronDown size={this.iconSize} />
                      </span>
                      <span className="down-arrow float-right animated rotateIn">
                        <ChevronRight size={this.iconSize} />
                      </span>
                    </a>
                    <div
                      className={
                        collapse === m.menuId ? "collapse show" : "collapse"
                      }
                      id={m.menuId}
                      aria-expanded="false"
                    >
                      <ul className="flex-column pl-2 nav animated fadeInLeft">
                        {m.child.map((cm, cidx) => {
                          return (
                            <li key={cidx} className="nav-item">
                              <NavLink
                                exact
                                className="nav-link my-auto"
                                activeClassName="active"
                                to={cm.navi}
                              >
                                <span className="text-capitalize fs-14">
                                  {cm.displayName}
                                </span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </React.Fragment>
                )}

                {m.child.length === 0 && (
                  <NavLink
                    exact
                    to={m["navi"]}
                    className="nav-link my-auto"
                    activeClassName="active"
                  >
                    {/* <FeatherIcon>{m.icon}</FeatherIcon> */}
                    {/* <i className={"fa fa-" + m.icon}></i> */}
                    <span className="text-capitalize fs-14">
                      {" "}
                      {m["displayName"]}
                    </span>
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

// export default AdminSidebar;

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
  }),
  actionCreators
)(AdminSidebar);
