import { AppConstants } from './../common/appConfig';
import http from "./httpService";

const USER_API = AppConstants.API_ROOT + "users/";
const INV_API = AppConstants.API_ROOT + "Invoice/";


export function fileUpload(body) { // content, extension, fileName
    return http.post(USER_API + 'FileUpload', body);
}

export function excelUpload(body, qry) { // file
    return http.post(INV_API + 'importInvoiceVT1?customerUrl=' + qry, body);
}



export function getDashboard() {
    // ?clientCompanyId=
    return http.get(AppConstants.API_ROOT + 'Dashboard/GetCompanyDashboard')
}


// Master - Bank 

export function getAllBanks() {
    return http.get(AppConstants.API_ROOT + 'Master/GetMasterBank')
}


export default {
    fileUpload, excelUpload, getDashboard,
    getAllBanks
};