import * as React from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import userService from "../../services/userService";
import { Link } from "react-router-dom";

import loginPasswordIcon from "../../assets/img/login-icon-password.png";
import logo from "../../assets/img/logo.png";
import { Smile } from "react-feather";
import AuthFormComp from "../../components/AuthForm";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { ApplicationState, ApplicationAction } from "./../../stores/index";
import { AuthState } from "./../../stores/authStore";

export interface VerifyPageProps {
  history;
  logout;
  auth: AuthState;
}

export interface VerifyPageState {}

class VerifyPage extends AuthFormComp {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    this.state.search = search;
  }
  state = {
    data: { otp: "", password: "", email: "" },
    errors: {},
    type: "",
    id: "",
    search: {},
    isActivationSuccess: false,
    isActivationFailure: false,
    isResetPassSuccess: false,
    isResetPass: false,
    isSubUserMail: false,
    otpMessage: "",
    otherFields: {
      password: "",
    },
  };

  schema = {
    otp: Joi.string().required(),
    password: Joi.string().required().min(6),
    email: Joi.string().required(),
  };

  doSubmit() {
    if (this.state.isSubUserMail) {
      this.subUserActivation();
    } else {
      this.onReset();
    }
  }

  preReset = async (id) => {
    let body = { username: "", loginId: id };
    const { data } = await userService.emailVerification(body);

    if (!data.didError) {
      this.setState({ otpMessage: data.message });
      this.getEmail(data.message);
      this.setState({ isResetPass: true });
    } else {
      toast.error(data.errorMessage);
      this.setState({ isResetPass: false });
    }
  };

  onReset = async () => {
    const { otp, password, email } = this.state.data;

    let body = {
      otp: otp,
      password: password,
      userName: email,
      loginId: this.state.id,
    };
    const { data: res } = await userService.verifyOTP(body);

    if (res) {
      if (!res.didError) {
        toast.success(res.message);
        this.setState({ isResetPassSuccess: true, otpMessage: "" });
      } else {
        toast.error(res.errorMessage);
      }
    } else {
      toast.error(res.errorMessage);
    }
  };

  subUserActivation = async () => {
    const { password } = this.state.data;

    let body = {
      roleId: this.state.id,
      password: password,
    };
    const { data: res } = await userService.verifySubUser(body);

    if (res) {
      if (!res.didError) {
        toast.success(res.message);
        this.setState({ isResetPassSuccess: true, otpMessage: "" });
      } else {
        toast.error(res.errorMessage);
      }
    } else {
      toast.error(res.errorMessage);
    }
  };

  componentWillMount() {
    this.checkPageType(this.state.search);
  }
  checkPageType(search) {
    let {
      type,
      id,
      isActivationSuccess,
      isResetPass,
      isSubUserMail,
      data,
    } = this.state;

    if (this.props.auth.isLoggedIn) this.props.logout();

    if (search) {
      let keyName = Object.keys(search)[0];
      id = search[keyName];
      switch (keyName) {
        case "mail":
          type = keyName;
          this.accountVerify(id);
          break;
        case "subusermail":
          type = keyName;
          data.email = "admin@PrincessQ8.com";
          data.otp = "236548";
          isSubUserMail = true;
          break;
        case "otp":
          type = keyName;
          isResetPass = true;
          this.preReset(id);
          break;
        default:
          toast.error("Page key isn't valid, Please contact administrator");
          this.props.history.push("/");
          break;
      }
      this.setState({
        type,
        id,
        isActivationSuccess,
        isResetPass,
        data,
        isSubUserMail,
      });
    } else {
      this.props.history.push("/");
    }
  }

  getEmail(id) {
    let temp = id.split("'");
    const { data } = this.state;
    data.email = temp[1];
    this.setState({ data: data });
  }

  accountVerify = async (id) => {
    const { data } = await userService.verifyUser(id);
    if (!data) {
      this.setState({ isActivationFailure: true });
      return true;
    }
    if (!data.didError) {
      this.setState({ isActivationSuccess: true });
    } else {
      this.setState({ isActivationFailure: true });
    }
  };

  // subAccountVerify = async id => {
  //   let body = { roleId: id, password: "" };
  //   const { data } = await userService.verifySubUser(body);
  //   if (!data) {
  //     this.setState({ isActivationFailure: true });
  //     return true;
  //   }
  //   if (!data.didError) {
  //     this.setState({ isActivationSuccess: true });
  //   } else {
  //     this.setState({ isActivationFailure: true });
  //   }
  // };

  onInputValueChange = ({ currentTarget: e }) => {
    let { otherFields } = this.state;
    otherFields[e.name] = e.value;
    this.setState({ otherFields });
  };

  render() {
    const {
      isActivationSuccess,
      isActivationFailure,
      isResetPass,
      isSubUserMail,
      otpMessage,
    } = this.state;
    return (
      <React.Fragment>
        <div className="login-bg">
          <div className="login_details">
            <div
              style={{
                height: "100vh",
                width: "2000px",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <div className="login_details_div">
                <div className="row m-0">
                  <div className="register-div">
                    <div className="align-center">
                      <img src={logo} className="msg-img" alt="" />
                    </div>
                    <br />
                    <div className="border-top-1">
                      <div className="row">
                        <div className="col-12 text-center pt-3">
                          <small style={{ fontSize: 14 }}>{otpMessage}</small>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 mx-auto">
                          {isActivationSuccess && this.renderActivation()}
                          {isActivationFailure &&
                            this.renderActivationFailure()}
                          {isResetPass && this.renderResetPass()}
                          {isSubUserMail && this.renderSubUserPanel()}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSubUserPanel() {
    return (
      <React.Fragment>
        {" "}
        {this.state.isResetPassSuccess ? (
          this.renderResetSucc()
        ) : (
          <React.Fragment>
            {" "}
            <div className="text-center">
              Please create new password for your account
            </div>
            <div className="row text-center my-4">
              <div className="col-12 col-md-8 text-center mx-auto">
                <form onSubmit={this.handleSubmit} autoComplete="off">
                  {this.renderInput(
                    "password",
                    "Enter new password",
                    loginPasswordIcon,
                    "password"
                  )}
                  <div className="text-center my-3">
                    <div className="d-inline">
                      {this.renderButton("Submit")}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  renderActivation() {
    return (
      <React.Fragment>
        <p className="header text-center">Welcome to PrincessQ8</p>
        <p className="content text-center">
          Hey User, Your email has been successufully register with us.
        </p>
        <p className="content text-center">
          You can access your account right now!!!
        </p>
        <div className="align-center mt-4">
          <Link to="/login" className="Login-button">
            Login Now
          </Link>
        </div>
      </React.Fragment>
    );
  }

  renderActivationFailure() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-center">
            <div className="h5 font-weight-bold my-3">
              Activation Failure!!!
            </div>
            <div className="fs-12pl-1" style={{ letterSpacing: "1px" }}>
              Something wrong with activation. Please try after sometime or
              contact Administration!
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderResetPass() {
    const { isResetPassSuccess } = this.state;
    return (
      <React.Fragment>
        {isResetPassSuccess ? (
          this.renderResetSucc()
        ) : (
          <div>
            <div className="my-3">
              <p className="text-secondary  mt-2" style={{ fontSize: 18 }}>
                Reset your password with new one!
              </p>
            </div>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              {this.renderInput("otp", "OTP", loginPasswordIcon)}
              {this.renderInput(
                "password",
                "New Password",
                loginPasswordIcon,
                "password"
              )}

              <div className="text-center my-3">
                <div className="d-inline">{this.renderButton("Submit")}</div>
              </div>
            </form>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderResetSucc() {
    return (
      <React.Fragment>
        <div className="text-center fs-18 text-dark my-5 shadow-lg p-3 rounded">
          <div className="row">
            <div className="col-12 m-auto ">
              <div>
                <Smile size={70} />
              </div>
            </div>
            <div className="col-12 text-center mt-2">
              {this.state.isSubUserMail ? (
                <span>Your have successfully activated your account</span>
              ) : (
                <span>Your have successfully reset your password</span>
              )}

              <div>You may Login</div>
            </div>
          </div>
        </div>

        <div className="mt-2 pt-2">
          <span className="float-left">
            <Link to="/" className="text-dark">
              HOME
            </Link>
          </span>
          <span className="float-right">
            <Link className="text-dark" to="/login">
              LOGIN <i className="fa fa-chevron-right ml-2" />
            </Link>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

const mapActionToProps = {
  logout: ApplicationAction.auth.logout,
};
// export default connect(mapStateToProps, mapActionToProps)(Login);

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
  }),
  mapActionToProps
)(VerifyPage);
