import * as React from "react";
import Breadcrumb from "../pages/admin/layout/Breadcrumb";
export interface TitleBarCompProps {
  title;
}

const TitleBarComp: React.SFC<TitleBarCompProps> = ({ title }) => {
  return (
    <div className="row mx-0 shadow my-2 bg-white rounded bg-breadcrumb">
      <div className="col-lg-6 col-md-6 my-auto">
        <p className="text-uppercase font-weight-bold">{title}</p>
      </div>
      <div className="col-lg-6 col-md-6 text-right my-auto ">
        <Breadcrumb></Breadcrumb>
      </div>
    </div>
  );
};

export default TitleBarComp;
