import React from "react";

export interface TextareaCompProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  rows: any;
  error: any;
  placeholder: string;
  exClass?: boolean;
}

const TextareaComp: React.SFC<TextareaCompProps> = ({
  name,
  label,
  error,
  rows,
  exClass,
  ...rest
}) => {
  return (
    <div className="form-group">
      {/* <label htmlFor={name}> {label}</label> */}
      <textarea
        {...rest}
        id={name}
        name={name}
        className={`form-control  ${exClass ? "trans-input" : ""}`}
        rows={rows}
      />
      {error && <div className="small text-primary">{error}</div>}
    </div>
  );
};

export default TextareaComp;
