import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import * as React from "react";
import { Check, Edit2, Trash2, X } from "react-feather";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import authService from "../services/authService";
import { ApplicationAction } from "../stores";
import { AppConstants } from './../common/appConfig';
import { CompanyState } from "./../stores/companyStore";
import { ApplicationState } from "./../stores/index";
import GFInput from "./genericFormComponents/GFInput";
import GFSelect from "./genericFormComponents/GFSelect";
import ModalComp from "./Modal";



export interface ComisnChargeProps {
  companyId;
  comisnList;
  refereshDetail;
  company: CompanyState;
  getChargeTypes;
  addUpdateCCharge;
}

export interface ComisnChargeState { }

class ComisnCharge extends React.Component<
  ComisnChargeProps,
  ComisnChargeState
  > {
  componentDidMount() {
    this.fetchChargeTypes();
  }

  async fetchChargeTypes() {
    let chargeTypeList = [] as any;
    if (this.props.company.chargeList.length > 0) {
      this.props.company.chargeList.forEach((data) => {
        if (data.isActive) {
          data["dName"] = data.vendorChargeType;
          chargeTypeList.push(data);
        }
      });
    } else {
      await this.props.getChargeTypes();
      this.props.company.chargeList.forEach((data) => {
        if (data.isActive) {
          data["dName"] = data.vendorChargeType;
          chargeTypeList.push(data);
        }
      });
    }
    this.setState({ chargeTypeList });
  }

  state = {
    ccharge: {
      commissionId: null as any,
      vendorChargeTypeId: "",
      clientCompanyId: "",
      paymentType: "",
      isActive: false,
      deliveryFees: 0,
      isDeleted: false,
      calculation: "Flat",
    },
    chargeTypeList: [] as any,
    isEdit: false,
    isModalOpen: false,
    isConfirmModalOpen: false,
    cchargeList: [] as any,
    deleteData: {},
    paymentMethod: [
      { dName: "KNET", id: "KNET", isDisabled: false },
      { dName: "VISA/MASTER", id: "VISA/MASTER", isDisabled: false },
    ],
  };

  handleChange = ({ currentTarget: e }) => {
    const ccharge = { ...this.state.ccharge };
    ccharge[e.name] = e.value;
    this.setState({ ccharge });
  };
  handleCheckbox = ({ currentTarget: e }) => {
    let ccharge = this.state.ccharge;
    ccharge[e.name] = e.checked;
    this.setState({ ccharge });
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getCommissionTypeName(id) {
    let type = this.state.chargeTypeList.filter(
      (data) => data.vendorChargeTypeId === id
    );
    let val = "";
    if (type.length > 0) {
      val = type[0].vendorChargeType;
    }
    return val;
  }

  handleAddUpdate = async () => {
    let { ccharge, isEdit } = this.state;

    if (
      ccharge.calculation === "Percent" &&
      (ccharge.deliveryFees < 0 || ccharge.deliveryFees > 100)
    ) {
      toast.error("Delivery fee must be between 0 to 100");
      return;
    }

    if (!isEdit) {
      ccharge.commissionId = null;
    }
    ccharge.clientCompanyId = this.props.companyId;
    let body = [] as any;
    body.push(ccharge);

    await this.props.addUpdateCCharge(body);

    if (this.props.company.isRespSuccess) {
      this.toggleModal();
      this.props.refereshDetail();
      // TODO: Have to refersh data
    }
  };

  handleCreate = () => {
    const { comisnList } = this.props;
    let paymentMethod = this.state.paymentMethod;

    if (comisnList.length === paymentMethod.length) {
      toast.error(
        "You have added commission charge for all the payment method"
      );
    } else {
      comisnList.forEach((data) => {
        paymentMethod.forEach((element) => {
          if (element.id === data.paymentType) {
            element.isDisabled = true;
          }
        });
      });
      this.setState({ isModalOpen: true, isEdit: false, paymentMethod });
    }
  };

  handleEdit = (data) => {
    this.setState({ isModalOpen: true, ccharge: data, isEdit: true });
  };

  // handleDeleteModal = (data) => {
  //   this.setState({ isComfirmModalOpen: true, deleteData: data });
  // };

  handleDelete = async () => {
    let data: any = this.state.deleteData;
    data.isDeleted = true;

    await this.props.addUpdateCCharge([data]);

    if (this.props.company.isRespSuccess) {
      this.setState({ isConfirmModalOpen: false, deleteData: {} });
      this.props.refereshDetail();
      // TODO: Have to refersh data
    }

    // this.setState({ isModalOpen: true, ccharge: data, isEdit: true });
    // TODO: Have to delete. only allow to SP
  };
  render() {
    const {
      isModalOpen,
      isConfirmModalOpen,
      isEdit,
      ccharge,
      chargeTypeList,
      paymentMethod,
    } = this.state;
    const { comisnList } = this.props;
    const isSuperAdmin = authService.checkUserType(AppConstants.UT_SUPER_ADMIN);
    if (!isEdit) {
      ccharge.isActive = true;
    }
    return (
      <>
        <div className="text-right">
          {isSuperAdmin && (
            <button className="active-btn" onClick={this.handleCreate}>
              Create New
            </button>
          )}
        </div>

        <hr />

        <ModalComp
          title="Commission Charge"
          hideModal={this.toggleModal}
          isOpen={isModalOpen}
        >
          <div className="modal-body my-3 text-center">
            <>
              <hr />
              <div className="row">
                <div className="col-6">
                  <GFSelect
                    name="paymentType"
                    label="Choose Payment Type"
                    value={ccharge.paymentType}
                    onChange={this.handleChange}
                    error=""
                    placeholder="Payment Type"
                    listData={paymentMethod}
                    valueField="id"
                    disabled={!isSuperAdmin}
                  ></GFSelect>
                </div>

                <div className="col-6">
                  <GFInput
                    name="deliveryFees"
                    label="Commission Fees"
                    value={ccharge.deliveryFees}
                    isRequired={true}
                    onChange={this.handleChange}
                    type="number"
                    error=""
                    placeholder=""
                    disabled={!isSuperAdmin}
                  ></GFInput>
                </div>

                <div className="col-6 my-auto text-left">
                  <RadioGroup
                    aria-label="calculation"
                    name="calculation"
                    value={ccharge.calculation}
                    onChange={this.handleChange}
                    color="primary"
                    row={true}
                  >
                    <FormControlLabel
                      value="Flat"
                      control={<Radio />}
                      label="Flat"
                      color="primary"
                      disabled={!isSuperAdmin}
                    />
                    <FormControlLabel
                      value="Percent"
                      control={<Radio />}
                      label="Percentage"
                      color="primary"
                      disabled={!isSuperAdmin}
                    />
                  </RadioGroup>
                </div>

                <div className="col-6">
                  <GFSelect
                    name="vendorChargeTypeId"
                    label="Choose charge Type"
                    value={ccharge.vendorChargeTypeId}
                    onChange={this.handleChange}
                    error=""
                    placeholder="Select Charge Type"
                    listData={chargeTypeList}
                    valueField="vendorChargeTypeId"
                  ></GFSelect>
                </div>

                {isEdit && !isSuperAdmin && (
                  <div className="col-6 text-left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isActive"
                          checked={ccharge.isActive}
                          onChange={this.handleCheckbox}
                          color="primary"
                        />
                      }
                      label="Is Active"
                    />
                  </div>
                )}
              </div>
              <hr />
            </>
          </div>
          <div className="mb-3 text-center">
            <button
              className="btn btn-light btn-sm mx-1"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Cancal
            </button>
            <button
              className="btn btn-secondary btn-sm mx-1 text-capitalize"
              onClick={this.handleAddUpdate}
            >
              {isEdit ? "UPDATE" : "SAVE"}
            </button>
          </div>
        </ModalComp>

        <ModalComp
          title="Confirmation"
          hideModal={() =>
            this.setState({ isConfirmModalOpen: !isConfirmModalOpen })
          }
          isOpen={isConfirmModalOpen}
        >
          <div className="modal-body my-3 text-center">
            <>
              <hr />
              <p>Are you sure, do you want delete?</p>
              <hr />
            </>
          </div>
          <div className="mb-3 text-center">
            <button
              className="btn btn-light btn-sm mx-1"
              onClick={() => this.setState({ isConfirmModalOpen: false })}
            >
              Cancal
            </button>

            <button
              className="btn btn-danger btn-sm mx-1 text-capitalize"
              onClick={this.handleDelete}
            >
              Delete
            </button>
          </div>
        </ModalComp>

        <div className="container"></div>

        <h4>Commission Charge List</h4>
        <div className="row">
          {comisnList.map((data, idx) => {
            return (
              <div key={idx} className="col-md-6 col-sm-6 col-xs-12">
                <div className="payment-method-list">
                  <div className="payment-method-list-title">
                    {data.paymentType}

                    {data.isActive && (
                      <small className=" mx-2 rounded p-1 text-uppercase bg-success text-white">
                        <Check size={18} /> Active
                      </small>
                    )}
                    {!data.isActive && (
                      <small className=" mx-2 rounded p-1 text-uppercase bg-danger text-white">
                        <X size={18} /> Inactive{" "}
                      </small>
                    )}
                    <strong className="float-right text-dark">
                      {data.deliveryFees}{" "}
                      <span className="text-secondary mx-2">
                        {data.calculation === "Flat" ? "KWD" : "%"}{" "}
                      </span>
                    </strong>
                  </div>
                  <div className="payment-method-list-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="info">
                          <i className="material-icons">info</i>{" "}
                          {data.deliveryFees} KD ON EACH TRANSACTION
                        </p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div>
                          <label className="info mt-4 mb-1">
                            Commission Type
                          </label>
                          <div className="font-weight-bold">
                            {this.getCommissionTypeName(
                              data.vendorChargeTypeId
                            )}

                            {isSuperAdmin && (
                              <span
                                className="float-right"
                                onClick={() =>
                                  this.setState({
                                    isConfirmModalOpen: true,
                                    deleteData: data,
                                  })
                                }
                              >
                                <Trash2
                                  className="mx-1 text-danger"
                                  size={18}
                                />
                              </span>
                            )}
                            <span
                              className="float-right"
                              onClick={() => this.handleEdit(data)}
                            >
                              <Edit2
                                className="mx-1 text-secondary"
                                size={18}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

// export default ComisnCharge;
const mapActionToProps = {
  getChargeTypes: ApplicationAction.company.getChargeList,
  addUpdateCCharge: ApplicationAction.company.addUpdateCCharge,
};

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
    company: state.company,
  }),
  mapActionToProps
)(ComisnCharge);
