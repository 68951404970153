import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel
} from "@material-ui/core";
import Joi from "joi-browser";
import * as React from "react";
import { Check } from "react-feather";
import { connect } from "react-redux";
import AuthContainer from "../../components/authContainer";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import TitleBarComp from "../../components/TitleBar";
import authService from "../../services/authService";
import globalService from "../../services/globalService";
import { ApplicationState } from "../../stores";
import { AppConstants } from './../../common/appConfig';
import GenericForm from "./../../components/genericFormComponents/GenericForm";
import { actionCreators } from "./../../stores/accessStore";


export interface CompanyUserProps { }

export interface CompanyUserState { }

class CompanyUser extends GenericForm {
  navigate = {
    pageSize: 12,
    pageNumber: 1,
    firstName: "",
    lastName: "",
    userName: "",
    orderBy: 0,
    total: 0,
  };
  state = {
    data: {
      loginId: "",
      roleId: "",
      email: "",
      mobile: "",
      firstName: "",
      lastName: "",
      firstNameAr: "",
      lastNameAr: "",
      countryCode: "965",
      isActive: true,
      isDelete: false,
      apiAccess: true,
    },
    errors: {},
    userList: [],
    roleList: [],
    branchList: [],
    isModalOpen: false,
    isConfirmModalOpen: false,
    oldRoleId: "",
    isUpdate: false,
    searchName: "",
    isClient: authService.checkUserType(AppConstants.UT_CLIENT),
    popOverId: "-1",
  };

  schema = {
    id: Joi.string().allow("", null),
    loginId: Joi.string().allow("", null),
    roleId: Joi.string().allow("", null),
    firstName: Joi.string().required(),
    firstNameAr: Joi.string().allow("", null),
    lastName: Joi.string().required(),
    lastNameAr: Joi.string().allow("", null),
    isActive: Joi.boolean().allow("", null),
    isDelete: Joi.boolean().allow("", null),
    apiAccess: Joi.boolean().allow("", null),
    mobile: Joi.number().allow("", null),
    email: Joi.string().email(),
    countryCode: Joi.string().allow("", null),
  };

  componentDidMount() {
    this.getCompanyUser();
  }

  openModal = () => {
    this.clearFields();
    if (this.props.access.roleList.length === 0) {
      this.props.getRoleList();
    }
    this.setState({ isModalOpen: true, isUpdate: false });
  };
  openEditModal = (user) => {
    if (this.props.access.roleList.length === 0) {
      this.props.getRoleList();
    }
    this.clearFields();
    let { data } = this.state;
    data.loginId = user.userId;
    data.roleId = user.roleId;
    data.firstName = user.firstName;
    data.lastName = user.lastName;
    data.firstNameAr = user.firstNameAr;
    data.lastNameAr = user.lastNameAr;
    data.mobile = user.mobile;
    data.email = user.email;
    data.isActive = user.isActive;
    data.isDelete = false;
    data.apiAccess = true;
    this.setState({
      data,
      isModalOpen: true,
      isUpdate: true,
      oldRoleId: user.roleId,
    });
  };
  openConfirmModal = (user) => {
    let { data } = this.state;
    data.loginId = user.userId;
    data.roleId = user.roleId;
    data.firstName = user.firstName;
    data.lastName = user.lastName;
    data.firstNameAr = user.firstNameAr;
    data.lastNameAr = user.lastNameAr;
    data.mobile = user.mobile;
    data.email = user.email;
    data.countryCode = user.countryCode;
    data.isActive = user.isActive;
    data.isDelete = true;
    data.apiAccess = user.apiAccess;
    this.setState({ isModalOpen: false, isConfirmModalOpen: true, data });
  };
  hideModal = () => {
    this.setState({ isModalOpen: false, isConfirmModalOpen: false });
  };

  clearFields() {
    let data = {
      id: "",
      loginId: "",
      roleId: "",
      firstName: "",
      lastName: "",
      firstNameAr: "",
      lastNameAr: "",
      email: "",
      mobile: "",
      countryCode: "",
      isActive: true,
      isDelete: false,
      apiAccess: true,
    };
    this.setState({ data });
  }

  getSearchQuery() {
    let qry =
      "pageSize=" +
      this.navigate.pageSize +
      "&pageNumber=" +
      this.navigate.pageNumber +
      "&orderBy=0";
    if (this.state.searchName && this.state.searchName !== "")
      qry = qry + "&fisrtName=" + this.state.searchName;

    return qry;
  }
  getCompanyUser = async () => {
    let query = this.getSearchQuery();
    // const { data } = await userService.getCompanyUser(query);

    await this.props.getSubUserList(query);

    this.navigate.total = this.props.access.itemsCount;
    this.setState({ userList: this.props.access.userList });
    // const data: any = {};
    // let userList = [];
    // if (!data.didError) {
    //   userList = data.model;
    //   this.navigate.total = data.itemsCount;
    // }
  };

  // Handle Search
  navigatePage = (act) => {
    if (act === "next") {
      this.navigate.pageNumber += 1;
    }
    if (act === "prev") {
      this.navigate.pageNumber -= 1;
    }
    this.getCompanyUser();
  };

  onChangeSearchText = ({ currentTarget: e }) => {
    this.setState({ searchName: e.value });
  };

  handleSearch = () => {
    this.navigate.pageNumber = 1;
    this.navigate.total = 0;
    this.getCompanyUser();
  };

  handleDelete = async () => {
    await this.props.deleteSubUser(this.state.data);
    if (this.props.access.isRespSuccess) {
      this.setState({ isConfirmModalOpen: false });
      this.getCompanyUser();
    }
  };

  handlePoperOver = (id) => {
    this.setState({ popOverId: this.state.popOverId === id ? "-1" : id });
  };
  doSubmit = () => {
    this.addUpdateUser();
  };

  async addUpdateUser() {
    await this.props.addUpdateSubUser(this.state.data);
    if (this.props.access.isRespSuccess) {
      this.setState({ isModalOpen: false });
      this.getCompanyUser();
    }
  }

  handleCheckbox = ({ currentTarget: e }) => {
    let ccharge = this.state.data;
    ccharge[e.name] = e.checked;
    this.setState({ ccharge });
  };

  render() {
    const { isUpdate, isModalOpen, userList, isConfirmModalOpen } = this.state;
    // const { pageSize, pageNumber, total } = this.navigate;
    return (
      <React.Fragment>
        <TitleBarComp title="Users"></TitleBarComp>

        <MainContentComp>
          <div className="row my-3 mx-1">
            <div className="col-12 text-right">
              <AuthContainer action="ADD">
                <button className="normal-btn" onClick={this.openModal}>
                  Create
                </button>
              </AuthContainer>
            </div>
            <div className="col-12 my-4">
              <div className="row">
                {userList.map((data, index) => {
                  return (
                    <div key={index} className="col-3">
                      {this.renderUserCard(data)}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 p-2">
              {/* {<NoDataContainer isDisplay={userList.length === 0} />} */}
            </div>
            <div className="col-12 text-right my-2 p-2">
              {/* {this.navigate.total > 0 && (
              <PaginationComp
                start={pageNumber * pageSize - pageSize + 1}
                end={
                  pageSize * pageNumber > total ? total : pageSize * pageNumber
                }
                total={total}
                navNextPage={() => this.navigatePage("next")}
                navPrevPage={() => this.navigatePage("prev")}
              />
            )} */}
            </div>
          </div>
        </MainContentComp>

        <div>
          <ModalComp
            title={`${isUpdate ? "Edit" : "Add"} User`}
            isOpen={isModalOpen}
            hideModal={this.hideModal}
          >
            <div className="modal-body">{this.renderFormContent()}</div>
          </ModalComp>
        </div>
        <div>
          <ModalComp
            title="Delete user"
            isOpen={isConfirmModalOpen}
            hideModal={() =>
              this.setState({
                isConfirmModalOpen: !this.state.isConfirmModalOpen,
              })
            }
          >
            <div className="modal-body text-center">
              <p>Are you sure, Do you want delete this user?</p>
              <div className="my-2">
                <button className="active-btn" onClick={this.handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </ModalComp>
        </div>
      </React.Fragment>
    );
  }

  renderFormContent() {
    const { isUpdate, isClient } = this.state;

    for (let d of this.props.access.roleList) {
      d["dName"] = d["roleName"];
    }
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-12">
              {this.renderSelect(
                "roleId",
                "Select role",
                this.props.access.roleList,
                "roleId"
              )}
            </div>
            <div className="col-6">
              {this.renderInput("firstName", "First tName")}
            </div>
            <div className="col-6">
              {this.renderInput("lastName", "Last Name")}
            </div>
            <div className="col-6">
              {this.renderInput("firstNameAr", "First Name Ar")}
            </div>
            <div className="col-6">
              {this.renderInput("lastNameAr", "Last Name Ar")}
            </div>
            <div className="col-6">
              {this.renderInput("email", "Email", true, this.state.isUpdate)}
            </div>
            <div className="col-6">
              {this.renderInput("mobile", "Mobile", false, false, "number")}
            </div>

            {isClient && (
              <div className="col-12">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="apiAccess"
                      checked={this.state.data.apiAccess}
                      onChange={this.handleCheckbox}
                      value="apiAccess"
                      color="primary"
                    />
                  }
                  label="API Access"
                />
              </div>
            )}

            <div className="col-12 my-2 text-right">
              {this.renderButton(isUpdate ? "Update" : "Add")}
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderUserCard(data) {
    return (
      <>
        <Dialog
          open={data.userId === this.state.popOverId}
          onClose={() => this.setState({ popOverId: "-1" })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
            <h6>Sample</h6>
            <p>
              {globalService.API_ROOT +
                "Invoice/invAPi?secureId=" +
                data.userId +
                "&customerRef="}
              <strong className="text-info">Customer_Reference</strong>
              &amt= <strong className="text-info">Invoice_Amount</strong>
            </p>

            <p className="mt-4">
              <strong className="text-danger">Secure Id</strong>:{" "}
              <span className="mx-2 text-info">{data.userId}</span>
            </p>
            <p className="">
              <strong className="text-danger">Customer_Reference</strong>:{" "}
              <span className="mx-2 text-info">Your Customer Reference</span>
            </p>
            <p>
              <strong className="text-danger">Invoice_Amount</strong>:{" "}
              <span className="mx-2 text-info">Invoice Amount</span>
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ popOverId: "-1" })}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className="user-card shadow-sm">
          <div className="row m-0 p-0 my-1">
            <div className="col-12 my-1 text-center">
              <i className="fa fa-user text-white fa-4x" />
            </div>
            <div className="col-12 text-center">
              <div className="h5">
                {data.fullName} | {data.fullNameAr}
              </div>
              <div className="email">
                <i className="fa fa-envelope" />
                <span className="ml-2 email"> {data.userName}</span>
              </div>
              <div className="mobile">
                <i className="fa fa-phone-square" />
                <span className="ml-2 mobile">{data.mobile}</span>
              </div>
            </div>
            <div className="col-12 mt-2 text-center bg-light">
              <span className="badge text-dark my-2">
                {/* {data.roles.length > 0 ? data.roles[0].name : "Not Assigned"} */}
              </span>
            </div>

            {data.apiAccess && (
              <div style={{ top: 2, left: 0, position: "absolute" }}>
                <span className="bg-success p-1 text-white">
                  <Check size={16} /> API
                </span>
                <span
                  className="mx-1"
                  onClick={() => this.handlePoperOver(data.userId)}
                  title="Click"
                >
                  <i className="fa fa-info-circle fa-lg text-info"></i>
                </span>
              </div>
            )}

            {/* <div className="p-1 bg-white border rounded">
              <span>
                {globalService.API_ROOT +
                  "Invoice/invAPi?secureId=" +
                  data.loginId +
                  "&customerRef=" +
                  data.userName +
                  "&amt=1"}
              </span>
            </div> */}
          </div>

          <AuthContainer action="DELETE">
            <div
              className="delete"
              onClick={() => this.openConfirmModal(data)}
              style={{ right: 12, bottom: 10, top: "auto" }}
            >
              <i className="fa fa-trash" />
            </div>
          </AuthContainer>

          {!data.isActive && !data.isDeleted && (
            <div className="inActive" style={{ top: 60 }}>
              <div>Not Activated</div>
            </div>
          )}

          {data.isDeleted && (
            <div className="inActive" style={{ top: 60 }}>
              <div>Deleted</div>
            </div>
          )}

          <AuthContainer action="EDIT">
            <div className="edit" onClick={() => this.openEditModal(data)}>
              <i className="fa fa-pencil" />
            </div>
          </AuthContainer>
        </div>
      </>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    access: state.access,
  }),
  actionCreators
)(CompanyUser);
