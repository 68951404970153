import * as Common from "./commonStore";
import * as Auth from "./authStore";
import * as Branch from "./branchStore";
import * as Access from "./accessStore";
import * as Invoice from "./invoiceStore";
import * as Company from "./companyStore";



// The top-level state object
export interface ApplicationState {
  common: Common.CommonState;
  auth: Auth.AuthState;
  branch: Branch.BranchState;
  access: Access.AccessState;
  invoice: Invoice.InvoiceState;
  company: Company.CompanyState
}

export const ApplicationAction = {
  common: Common.actionCreators,
  auth: Auth.actionCreators,
  branch: Branch.actionCreators,
  access: Access.actionCreators,
  invoice: Invoice.actionCreators,
  company: Company.actionCreators
}

export const reducers = {
  common: Common.reducer,
  auth: Auth.reducer,
  branch: Branch.reducer,
  access: Access.reducer,
  invoice: Invoice.reducer,
  company: Company.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
