import React from "react";

export interface GFSelectProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  error: any;
  placeholder: string;
  listData: any;
  valueField: string;
  disabled?: boolean;
}

const GFSelect: React.SFC<GFSelectProps> = ({
  name,
  label,
  error,
  listData,
  valueField,
  placeholder,
  ...rest
}) => {
  return (
    <div className="input-div md-value">
      <label>
        {placeholder} <span>*</span>
      </label>
      <select className="input-style" {...rest} name={name} id={name}>
        <option value="default">--Choose {placeholder}--</option>
        {listData.map((value, index) => {
          return (
            <option
              key={index}
              value={value[valueField]}
              disabled={value.isDisabled}
            >
              {value.dName}
            </option>
          );
        })}
      </select>
      {error && <div className="small text-danger">{error}</div>}
    </div>

    // <div className="form-group">
    //   <select className="form-control" {...rest} name={name} id={name}>
    //     <option value="default">{placeholder}</option>
    //     {listData.map((value, index) => {
    //       return (
    //         <option key={index} value={value[valueField]}>
    //           {value.Name}
    //         </option>
    //       );
    //     })}
    //   </select>
    //   {error && <div className="small text-danger">{error}</div>}
    // </div>
  );
};

export default GFSelect;
