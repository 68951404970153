import * as React from "react";
import MainContentComp from "../../components/MainContent";

export interface NoAccessProps {}

const NoAccess: React.SFC<NoAccessProps> = () => {
  return (
    <React.Fragment>
      <MainContentComp>
        <div className="py-5 text-center my-3 h3 text-dark">No Page Access</div>
      </MainContentComp>
    </React.Fragment>
  );
};

export default NoAccess;
