import Joi from "joi-browser";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loginPasswordIcon from "../../../assets/img/login-icon-password.png";
import logoWhite from "../../../assets/img/logo_white.png";
import menuIcon from "../../../assets/img/menu-icon.png";
import userIcon from "../../../assets/img/user-icon.png";
import DevIndicator from "../../../components/DevEnvironmentIndicator";
import ModalComp from "../../../components/Modal";
import Wallet from "../../../components/wallet";
import globalService from "../../../services/globalService";
import { actionCreators, AuthState } from "./../../../stores/authStore";
import { ApplicationState } from "./../../../stores/index";

export interface AdminHeaderProps {
  history;
  auth: AuthState;
  logout;
  changePassword;
}

export interface AdminHeaderState {}

class AdminHeader extends React.Component<AdminHeaderProps, AdminHeaderState> {
  state = {
    data: {
      oldPassword: "",
      newPassword: "",
    },
    errors: {},
    isOpenModal: false,
  };

  schema = {
    oldPassword: Joi.string().required().min(6).label("Old Password"),
    newPassword: Joi.string().required().min(6).label("New Password"),
  };

  toggleModal = () => {
    let { data } = this.state;
    data.newPassword = "";
    data.oldPassword = "";
    this.setState({ isOpenModal: !this.state.isOpenModal, data });
  };

  handleInputChange = ({ currentTarget: e }) => {
    const data = { ...this.state.data };
    data[e.name] = e.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e);
    if (errorMessage) errors[e.name] = errorMessage;
    else delete errors[e.name];

    this.setState({ data, errors });
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const { error } = Joi.validate(obj, { [name]: this.schema[name] });
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const option = {
      abortEarly: false,
    };
    const { error } = Joi.validate(this.state.data, this.schema, option);
    if (!error) return null;

    const errors: any = {};
    for (let er of error.details) errors[er.path[0]] = er.message;
    return errors;
  };

  onChangePass = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    this.onSubmitChangePass();
  };

  onSubmitChangePass = async () => {
    await this.props.changePassword(this.state.data);
    if (this.props.auth.isRespSuccess) {
      this.setState({ isOpenModal: false });
    }
  };

  onLogout = async () => {
    await this.props.logout();
    if (this.props.auth.isRespSuccess) {
      this.props.history.replace("/login");
    }
  };

  handleExpendMenu = () => {
    const menuPart = document.getElementById("menu-part");
    const menuIconPart = document.getElementById("menu-icon-part");
    const contentPart = document.getElementById("content-part");
    const colps = document.getElementsByClassName("collapse show");

    for (var i = 0; i < colps.length; i++) {
      colps[i].classList.remove("show");
    }

    if (menuPart && contentPart && menuIconPart) {
      if (!menuPart.classList.contains("menu-expended")) {
        menuPart.classList.remove("menu-comp");
        contentPart.classList.remove("main-contain-comp");

        menuPart.classList.add("menu-expended");
        menuIconPart.classList.add("menu-icon-open");
        contentPart.classList.add("main-contain-expended");
      } else {
        menuPart.classList.add("menu-comp");
        contentPart.classList.add("main-contain-comp");

        setTimeout(() => {
          menuPart.classList.remove("menu-expended");
          contentPart.classList.remove("main-contain-expended");
          menuIconPart.classList.remove("menu-icon-open");
        }, 300);
      }
    }
  };

  componentDidMount() {}
  render() {
    const { userDetail: ud, isLoggedIn } = this.props.auth;

    return (
      <React.Fragment>
        {this.renderPasswordModal()}
        <div className="nav top-header">
          <div className="row w-100">
            <div className="col-sm-3 col-6">
              <ul className="nav-left">
                <li
                  className="menu-icon-part menu-icon-open"
                  id="menu-icon-part"
                >
                  <span onClick={this.handleExpendMenu}>
                    <i className="material-icons forward">arrow_back</i>
                    <img
                      src={menuIcon}
                      className="back"
                      alt=""
                      style={{ marginTop: 13, marginLeft: 7, width: 20 }}
                    />
                  </span>
                </li>

                <li>
                  <img
                    src={logoWhite}
                    alt=""
                    style={{ height: "35px", marginLeft: 15 }}
                  />
                </li>
              </ul>
            </div>
            <div className="col-sm-9 col-6 text-right">
              <ul className="nav-right nav-right-padding">
                <li className="notification-part notification-occur d-none">
                  <button
                    type="button"
                    className="btn notification-drop-down dropdown-toggle text-white"
                    data-toggle="dropdown"
                  >
                    <i className="material-icons">notifications</i>
                    <span className="badge badge-style">3</span>
                  </button>
                  <div className="dropdown-menu">
                    <div className="notification-part-header">
                      <table className="width-100">
                        <tbody>
                          <tr>
                            <td>113 New Notification&emsp;&emsp;</td>
                            <td className="align-right">
                              <i className="material-icons">notifications</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="notifications-body">
                      <div className="notification-list">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt
                        </p>
                        <span>
                          <i className="material-icons">access_time</i> 12 hours
                          ago
                        </span>
                      </div>
                      <div className="notification-list">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt
                        </p>
                        <span>
                          <i className="material-icons">access_time</i> 17 hours
                          ago
                        </span>
                      </div>
                    </div>
                    <div className="notifications-bottom">
                      <button>See All</button>
                    </div>
                  </div>
                </li>

                {this.props.auth.userDetail.userType !==
                  globalService.USER_TYPE.SUPERADMIN && (
                  <li className="mx-2">
                    <Wallet />
                  </li>
                )}
                <li>
                  <DevIndicator />
                </li>
                <li>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <button
                            type="button"
                            className="btn header-profile-dropdown dropdown-toggle text-white"
                            data-toggle="dropdown"
                          >
                            <img src={userIcon} style={{ height: 34 }} alt="" />
                            <span className="hide-mobile-view">
                              {isLoggedIn && (
                                <span>
                                  {ud.firstName} {ud.lastName}
                                </span>
                              )}
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            {/* <a className="dropdown-item" href="/#123">
                              User Profile
                            </a> */}

                            <div
                              className="dropdown-item cursor-pointer"
                              onClick={this.toggleModal}
                            >
                              Change Password
                            </div>
                            {/* <div
                              className="dropdown-item cursor-pointer"
                              onClick={this.toggleModal}
                            >
                              
                            </div> */}
                            <Link
                              className="dropdown-item cursor-pointer"
                              to="/app/myprofile"
                            >
                              My Profile
                            </Link>
                            <div
                              className="dropdown-item cursor-pointer"
                              onClick={this.onLogout}
                            >
                              Logout
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPasswordModal() {
    const { isOpenModal, data, errors } = this.state;
    return (
      <ModalComp
        isOpen={isOpenModal}
        title="Change Password"
        hideModal={this.toggleModal}
      >
        <div className="modal-body pt-2">
          <div className="login-input mb-2">
            <img src={loginPasswordIcon} alt="" />
            <input
              className="mb-0"
              type="password"
              name="oldPassword"
              value={data.oldPassword}
              placeholder="Enter old password"
              onChange={this.handleInputChange}
            />
            {errors["oldPassword"] && (
              <div className="small text-danger">{errors["oldPassword"]}</div>
            )}
          </div>
          <div className="login-input mb-2">
            <img src={loginPasswordIcon} alt="" />
            <input
              className="mb-0"
              type="password"
              name="newPassword"
              value={data.newPassword}
              placeholder="Enter new password"
              onChange={this.handleInputChange}
            />
            {errors["newPassword"] && (
              <div className="small text-danger">{errors["newPassword"]}</div>
            )}
          </div>
        </div>

        {/* <!-- Modal footer --> */}
        <div className="modal-footer">
          <button
            type="button"
            className="active-btn"
            disabled={this.validate()}
            onClick={this.onChangePass}
          >
            Submit
          </button>
        </div>
      </ModalComp>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    auth: state.auth,
  }),
  actionCreators
)(AdminHeader);
