import { Checkbox, Chip } from "@material-ui/core";
import moment from "moment";
import queryString from "query-string";
import * as React from "react";
import { Check, Filter } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import searchIcon from "../../assets/img/search_icon.png";
import CopyToClipboard from "../../components/CopyToClipboard";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import PaginationComp from "../../components/PaginationComp";
import TabViewComp from "../../components/TabView";
import TitleBarComp from "../../components/TitleBar";
import globalService from "../../services/globalService";
import userService from "../../services/userService";

export interface PaidInvListProps {}

export interface PaidInvListState {}

class PaidInvList extends React.Component<PaidInvListProps, PaidInvListState> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    if (search) {
      this.state.qryLink = search;
    }
    if (search.customerId) {
      this.state.filter.customerId = search.customerId;
    }
    if (search.invoicePLinkId) {
      this.state.filter.invoicePLinkId = search.invoicePLinkId;
    }
  }
  state = {
    tabs: ["All", "Deposit", "Not Deposit"],
    currentTab: "All",
    invList: [] as any,
    quickDetail: {} as any,
    isOpen: false,
    pagination: {
      pageCount: 0,
      pageSize: 25,
      pageNumber: 1,
      itemsCount: 0,
    },
    showInoviceFilter: false,
    clientList: [] as any,
    filter: {
      isPaid: "0",
      loginId: "",
      clientId: "",
      invRef: "",
      customerId: "",
      invoicePLinkId: "",
      expireDate: null as any,
      startDate: null as any,
      endDate: null as any,
    },
    qryLink: {} as any,
    collapseNo: "-1",
    sumInvoice: 0,
    sumComisn: 0,
    sumRecieve: 0,
    allItemCheck: true,
  };

  handleCurrentTab = (item) => {
    this.setState({ currentTab: item });
    this.getTabContent();
    this.getInvList(item);
  };
  getTabContent = () => {
    const { tabs, currentTab } = this.state;
    return this.renderInv();
    if (currentTab === tabs[0]) {
      return this.renderInv();
    } else if (currentTab === tabs[1]) {
      return this.renderPaidInv();
    } else if (currentTab === tabs[2]) {
      return this.renderUnpaidInv();
    }
  };

  handleCollapseRow = (no) => {
    this.setState({ collapseNo: this.state.collapseNo === no ? "-1" : no });
  };
  handleCheckbox = (e, isAll) => {
    let { invList, allItemCheck } = this.state;
    if (isAll) {
      for (let inv of invList) {
        inv["isChecked"] = e.target.checked;
      }
      allItemCheck = e.target.checked;
    } else {
      invList[Number(e.target.name)]["isChecked"] = e.target.checked;
      if (!e.target.checked) {
        allItemCheck = false;
      }
    }
    this.calculateAmounts(invList);
  };

  calculateAmounts = (invList) => {
    let sumInvoice = 0,
      sumComisn = 0,
      sumRecieve = 0;
    let allItemCheck = true;
    for (let inv of invList) {
      if (inv["isChecked"]) {
        if (inv.paymentSessionInfo.length > 0) {
          for (let ps of inv.paymentSessionInfo) {
            sumInvoice += ps.invoiceAmt;
            sumComisn += ps.deliveryFees;
            sumRecieve += ps.clientAmt;
          }
        } else {
          sumInvoice += inv.invoiceAmt;
          sumComisn += inv.commission;
          sumRecieve += inv.invoiceAmt - inv.commission;
        }
      } else {
        if (allItemCheck) {
          allItemCheck = false;
        }
      }
    }

    this.setState({
      invList,
      allItemCheck,
      sumInvoice,
      sumComisn,
      sumRecieve,
    });
  };
  componentDidMount() {
    this.getInvList(this.state.currentTab);
  }

  async getInvList(currentTab) {
    const { filter: f, pagination: p, clientList, tabs } = this.state;
    let qryStr =
      "orderBy=1&invoiceId=&pageSize=" +
      p.pageSize +
      "&pageNumber=" +
      p.pageNumber;

    if (f.loginId && f.loginId !== "") {
      qryStr += "&createdby=" + f.loginId;
    }

    if (f.clientId && f.clientId !== "") {
      qryStr += "&createdby=" + f.clientId;
    }

    if (f.startDate) {
      qryStr += "&startDate=" + moment(f.startDate).format("YYYY-MM-DD");
    }
    if (f.endDate) {
      qryStr += "&endDate=" + moment(f.endDate).format("YYYY-MM-DD");
    }
    if (f.expireDate) {
      qryStr += "&expiryDate=" + moment(f.expireDate).format("YYYY-MM-DD");
    }

    // if (currentTab === tabs[1]) {
    //   qryStr += "&paid=1";
    // } else if (currentTab === tabs[0]) {
    //   if (f.isPaid !== "0") {
    //     qryStr += "&paid=" + (f.isPaid === "1" ? 1 : 2);
    //   }
    // } else if (currentTab === tabs[2]) {
    //   qryStr += "&paid=2";
    // }

    if (currentTab === tabs[1]) {
      qryStr += "&deposit=1";
    } else if (currentTab === tabs[2]) {
      qryStr += "&deposit=2";
    }

    if (f.customerId && f.customerId !== "") {
      qryStr += "&customerId=" + f.customerId;
    }
    if (f.invoicePLinkId && f.invoicePLinkId !== "") {
      qryStr += "&invoicePLinkId=" + f.invoicePLinkId;
    }
    if (f.invRef && f.invRef !== "") {
      qryStr += "&invoiceRefNo=" + f.invRef;
    }

    await userService.custPaidInvList(qryStr).then(({ data: res }) => {
      if (!res.didError) {
        let invList = res.model;
        p.itemsCount = res.itemsCount;
        p.pageCount = res.pageCount;
        p.pageSize = res.pageSize;
        p.pageNumber = res.pageNumber;

        for (let inv of invList) {
          inv["isChecked"] = true;
        }

        this.calculateAmounts(invList);

        this.setState({
          invList,
          paginatoin: p,
        });

        if (clientList.length === 0) {
          this.getClientList();
        }
      }
    });
  }

  async getClientList() {
    await userService.getClientList().then(({ data: res }) => {
      if (!res.didError) {
        let clientList = res.model;
        this.setState({ clientList });
      }
    });
  }

  togglePopup = (inv = null) => {
    if (inv) {
      this.getQuickDetail(inv);
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  async getQuickDetail(inv) {
    await userService
      .getInvoiceQuickDetail(inv.invoiceId)
      .then(({ data: res }) => {
        if (!res.didError) {
          let quickDetail = res.model[0];
          this.setState({ quickDetail });
        }
      });
  }

  async cancelInvoice(invId) {
    await userService.cancelInvoice(invId).then(({ data: res }) => {
      if (!res.didError) {
        toast.success(res.message);
        this.togglePopup();
        this.getInvList(this.state.currentTab);
      } else {
        toast.error(res.errorMessage);
      }
    });
  }

  render() {
    const { quickDetail: qd, sumInvoice, sumComisn, sumRecieve } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Paid Invoice List" />

        <MainContentComp>
          <TabViewComp
            tabs={this.state.tabs}
            onClickTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
          >
            <div className="row text-center border shadow-sm p-2 rounded">
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Invoice Total</h4>
                <h1 className="text-primary">
                  {globalService.getKWD(sumInvoice)}
                </h1>
              </div>
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Commission Total</h4>
                <h1 className="text-danger">
                  {globalService.getKWD(sumComisn)}
                </h1>
              </div>
              <div className="col-12 col-md-4">
                <h4 className="text-secondary">Recieve Total</h4>
                <h1 className="text-success">
                  {globalService.getKWD(sumRecieve)}
                </h1>
              </div>
            </div>

            {this.renderFilter()}
            {this.getTabContent()}
          </TabViewComp>

          {/* {this.renderFilter()} */}
          {/* <div> */}
          {/* {this.renderFilter()} */}
          {/* {this.getTabContent()} */}
          {/* </div>  */}

          {qd.invoiceId && (
            <ModalComp
              title="Quick View"
              isOpen={this.state.isOpen}
              hideModal={() => this.togglePopup(null)}
              size="xl"
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Last Sent Date</label>
                      <p></p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Url</label>
                      <CopyToClipboard>
                        {({ copy }) => (
                          <p
                            className="hideable"
                            onClick={() => copy(qd.customerUrl)}
                          >
                            {qd.customerUrl}
                            <small
                              style={{ fontWeight: 100 }}
                              className="float-right onlyhover cursor-pointer  bg-dark rounded text-white px-1"
                            >
                              COPY
                            </small>
                          </p>
                        )}
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Expire Date</label>
                      <p>
                        {qd.invoiceExpiryDate &&
                          globalService.getLocalDate(qd.invoiceExpiryDate)}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Sent Email Counter</label>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Is Sms Sent Successfully?</label>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 21.37 21.37"
                        >
                          <path
                            id="checked"
                            d="M10.685,0A10.685,10.685,0,1,0,21.37,10.685,10.685,10.685,0,0,0,10.685,0ZM8.725,16.174l-4.7-4.7L5.588,9.9l3.137,3.136L15.782,5.98,17.35,7.549Z"
                            fill="#6ac259"
                          ></path>
                        </svg>
                        Yes
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Sent Sms Counter</label>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Created Date</label>
                      <p>
                        {qd.createdDate &&
                          globalService.getLocalDate(qd.createdDate)}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Views</label>
                      <p>{qd.viewCount}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="input-div default-label">
                      <label>Invoice Type</label>
                      <p>{qd.invoiceType}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="active-btn input-btn">
                    Resend Receipt
                  </button>

                  {!qd.paid && !qd.isCanceled && (
                    <button
                      className="active-btn  input-btn btn-danger bg-danger mx-2"
                      onClick={() => this.cancelInvoice(qd.invoiceId)}
                    >
                      Cancel Invoice
                    </button>
                  )}
                </div>
                <br />
                <table className="width-100 margin-bottom">
                  <tbody>
                    <tr>
                      <td className="title">Invoice Transactions</td>
                      <td className="align-right">
                        <button className="normal-btn">Print</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="grid-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Transaction Start</th>
                        <th>Transaction Status</th>
                        <th>Payment Gateway</th>
                        <th>Payment ID</th>
                        <th>Auth</th>
                        <th>Transaction End</th>
                      </tr>
                    </thead>
                    <tbody>
                      {qd.paymentSessionInfo.map((val, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{val.sessionStartTime}</td>
                            <td>{val.result}</td>
                            <td>{val.gateway}</td>
                            <td>{val.paymentId}</td>
                            <td>{val.auth}</td>
                            <td>
                              {val.sessionClosedtime &&
                                globalService.getLocalDate(
                                  val.sessionClosedtime
                                )}
                            </td>
                          </tr>
                        );
                      })}
                      {qd.paymentSessionInfo.length === 0 && (
                        <tr>
                          <td colSpan={5} className="align-center">
                            <span>
                              <b>Sorry</b> There are no recrods to show!
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <br />
                <div className="align-right">
                  <button
                    className="normal-btn"
                    onClick={() => this.togglePopup(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </ModalComp>
          )}
        </MainContentComp>
      </React.Fragment>
    );
  }

  handlePageChange = ({ currentTarget: e }) => {
    let { pagination } = this.state;
    pagination.pageSize = e.value;
    this.getInvList(this.state.currentTab);
    this.setState({ pagination });
  };

  handlePageClick = (data) => {
    let { pagination } = this.state;
    pagination.pageNumber = data.selected + 1;
    this.setState({ pagination }, () => {
      this.getInvList(this.state.currentTab);
    });
  };

  handleFilterChange = ({ currentTarget: e }) => {
    let filter = this.state.filter;
    filter[e.name] = e.value;
    this.setState({ filter });
  };

  handleDateChange = (name, val) => {
    // debugger;
    let { filter } = this.state;
    filter[name] = val;
    this.setState({ filter });
  };
  handleFilterSearch = () => {
    this.getInvList(this.state.currentTab);
  };

  handleFilterReset = () => {
    let filter = this.state.filter;
    filter.endDate = null;
    filter.startDate = null;
    filter.expireDate = null;
    filter.clientId = "";
    filter.loginId = "";
    this.setState({ filter }, () => this.getInvList(this.state.currentTab));
  };

  handleEnterKey = ({ currentTarget: e }) => {
    if (e.key === "Enter") this.getInvList(this.state.currentTab);
  };
  renderInv() {
    const {
      invList,
      pagination,
      filter,
      collapseNo,
      allItemCheck,
    } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div ">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    color="primary"
                    checked={allItemCheck}
                    onChange={(e) => this.handleCheckbox(e, true)}
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                </th>
                <th>SNo</th>
                <th>Payment Ref</th>
                <th className="text-center">Invoice Amount</th>
                <th></th>
                <th className="text-center">Commission</th>
                <th className="text-center">Deposit</th>
                <th>Customer</th>
                <th>Created By</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr onClick={() => this.handleCollapseRow(idx)}>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          color="primary"
                          checked={inv.isChecked}
                          name={idx + ""}
                          onChange={(e) => this.handleCheckbox(e, false)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                      </td>
                      <td>
                        {idx + 1} {inv.isChecked}
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                          {inv.invoiceRefNo}
                        </Link>
                      </td>
                      <td className="text-center">{inv.invoiceAmt} </td>
                      <td className="text-center">
                        {inv.paymentSessionInfo.length > 0 && (
                          <span className="mx-1">
                            <i className="fa fa-info-circle text-info fa-2x"></i>
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {" "}
                        {inv.paid && inv.commission}
                      </td>
                      <td className="text-center">
                        {inv.deposit && (
                          <div className="text-center">
                            <div>
                              <Check /> Deposit
                            </div>
                            <div>
                              <small>
                                {inv.depositDate &&
                                  globalService.getLocalDate(inv.depositDate)}
                              </small>
                            </div>
                          </div>
                        )}
                      </td>
                      <td>{inv.customer ? inv.customer.fullName : ""}</td>
                      <td>
                        <div>
                          <span>
                            {inv.createdInfo ? inv.createdInfo.firstName : ""}
                          </span>
                        </div>
                        {globalService.getLocalDate(inv.createdDate)}
                      </td>
                      <td className="hideable d-none">
                        <span className="onlyhover"></span>{" "}
                      </td>
                    </tr>
                    <tr
                      className={
                        idx === Number(collapseNo)
                          ? "collapse show"
                          : "collapse"
                      }
                    >
                      <td colSpan={12}>
                        {inv.paymentSessionInfo.length > 0 && (
                          <table className="my-2 bg-secondary">
                            <thead>
                              <th>Customer Paid</th>
                              <th>Commission</th>
                              <th>Receive Amount</th>
                              <th>Charge By</th>
                              <th>Calculation</th>
                            </thead>
                            <tbody>
                              {inv.paymentSessionInfo.map((ci: any, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{ci.clientAmt + ci.deliveryFees}</td>
                                    <td>{ci.deliveryFees}</td>
                                    <td>{ci.clientAmt}</td>
                                    <td>{ci.vendorChargeType}</td>
                                    <td>{ci.calculation}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}

                        {inv.paymentSessionInfo.length === 0 && (
                          <div className="text-center py-2 my-2">No Data</div>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderFilter() {
    const { showInoviceFilter, clientList, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 col-sm-8">
            {/* <button className="active-btn input-btn">
              Export Daily Report
            </button> */}
          </div>
          <div className="col-md-6 col-sm-4 align-right pt-2">
            <span
              className="my-auto font-weight-bold cursor-pointer"
              onClick={() =>
                this.setState({
                  showInoviceFilter: !this.state.showInoviceFilter,
                })
              }
            >
              Advanced Filter <Filter size={24}></Filter>
            </span>
          </div>
        </div>

        <div className={showInoviceFilter ? "d-block" : "d-none"}>
          <div className="gray-bg border">
            <div className="row">
              {/* <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Invoice Status</label>
                  <select
                    className="input-style"
                    value={filter.isPaid}
                    name="isPaid"
                    onChange={this.handleFilterChange}
                  >
                    <option value="0">All</option>
                    <option value="1">Paid</option>
                    <option value="2">Unpaid</option>
                  </select>
                </div>
              </div> */}
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Created By</label>
                  <select
                    name="loginId"
                    className="input-style"
                    value={filter.loginId}
                    onChange={this.handleFilterChange}
                  >
                    <option value="">All</option>
                    {clientList.map((val, idx) => {
                      return (
                        <option key={idx} value={val.clientId}>
                          {val.firstName + " " + val.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Expire Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.expireDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("expireDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>Start Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.startDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("startDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div default-label">
                  <label>End Date</label>
                  <DateTimePicker
                    defaultValue={new Date()}
                    value={filter.endDate}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      this.handleDateChange("endDate", value)
                    }
                    time={false}
                  />
                </div>
              </div>
              <div className="col-12 text-right">
                <button className="grey-btn" onClick={this.handleFilterReset}>
                  Reset
                </button>
                <button
                  className="active-btn"
                  onClick={this.handleFilterSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPaidInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
              autoComplete="off"
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice / Customer Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                {/* <th>Payment Method</th> */}
                <th>Views</th>

                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                        ? inv.customer.customerRefId + " - "
                        : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                        <td className="unpaid text-center">
                          <span
                            className="badge badge-info font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Unpaid
                          </span>
                        </td>
                      ))}
                    {/* <td></td> */}
                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderUnpaidInv() {
    const { invList, pagination, filter } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2 col-sm-4 input-div">
            <table>
              <tbody>
                <tr>
                  <td>Record Show</td>
                  <td>
                    <select
                      value={pagination.pageSize}
                      onChange={this.handlePageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col-md-6 col-sm-4"></div> */}
          <div className="col-md-4 col-sm-4 input-div">
            <input
              type="text"
              name="invRef"
              value={filter.invRef}
              placeholder="Reference #, Option, type"
              onChange={this.handleFilterChange}
              // onBlur={this.handleFilterSearch}
              onKeyDown={this.handleEnterKey}
              onKeyPressCapture={this.handleEnterKey}
              autoComplete="off"
            />
            <img
              src={searchIcon}
              className="search_icon"
              onClick={this.handleFilterSearch}
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-4 my-auto">
            <PaginationComp
              pageCount={pagination.pageCount}
              onPageClick={this.handlePageClick}
            />
          </div>
        </div>

        <div className="grid-table">
          <table>
            <thead>
              <tr>
                <th>Quick View</th>
                <th>Payment Ref</th>
                <th>Created Date</th>
                <th>ID - Customer</th>
                <th>Invoice / Customer Ref</th>
                <th>Invoice Value</th>
                <th>Invoice Option</th>
                <th>Invoice Status</th>
                {/* <th>Payment Method</th> */}
                <th>Views</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {invList.map((inv: any, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        className="text-center text-secondary cursor-pointer"
                        onClick={() => this.togglePopup(inv)}
                      >
                        <i className="material-icons">remove_red_eye</i>
                      </div>
                    </td>
                    <td>
                      <Link to={`/app/inv-detail?id=` + inv.invoiceId}>
                        {inv.invoiceRefNo}
                      </Link>
                    </td>
                    <td>{globalService.getLocalDate(inv.createdDate)}</td>
                    <td>
                      {!inv.customer
                        ? ""
                        : inv.customer.customerRefId
                        ? inv.customer.customerRefId + " - "
                        : ""}{" "}
                      {inv.customer ? inv.customer.fullName : ""}
                    </td>
                    <td>{this.displayChip(inv.customerRef)}</td>
                    <td>{inv.invoiceAmt}</td>
                    <td>{inv.invoiceOption}</td>
                    {inv.isCanceled && (
                      <td className="text-center">
                        <span
                          className="badge badge-danger font-weight-normal"
                          style={{ fontSize: 12 }}
                        >
                          Cancelled
                        </span>
                      </td>
                    )}

                    {!inv.isCanceled &&
                      (inv.paid ? (
                        <td className="paid text-center">
                          <span
                            className="badge badge-success font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Paid
                          </span>
                          <div>
                            <small>
                              {inv.paidDate &&
                                globalService.getLocalDate(inv.paidDate)}
                            </small>
                          </div>
                        </td>
                      ) : (
                        <td className="unpaid text-center">
                          <span
                            className="badge badge-info font-weight-normal"
                            style={{ fontSize: 12 }}
                          >
                            Unpaid
                          </span>
                        </td>
                      ))}
                    {/* <td></td> */}
                    <td>{inv.viewCount} </td>
                    <td className="hideable">
                      {" "}
                      <span>{inv.createdInfo.firstName}</span>{" "}
                      <span className="onlyhover">
                        {inv.createdInfo.lastName}{" "}
                        {inv.company ? (
                          <span>( {inv.company.companyName})</span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-right my-4">
          <PaginationComp
            pageCount={pagination.pageCount}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  renderPopup(isOpen) {
    return <React.Fragment></React.Fragment>;
  }

  displayChip(valueString: string) {
    if (valueString) {
      let list = valueString.split(",");

      return list.map((val, idx) => {
        return <Chip size="small" key={idx} label={val}></Chip>;
      });
    } else {
      return <></>;
    }
  }
}

export default PaidInvList;
