function init() {
    // implement the logger service
}

function log(error) {
    // file the logger service
}

export default {
    init,
    log
};