import {
    UPDATE_USER,
    LOGIN_USER,
    LOGOUT_USER,
    LOGIN_FAILED
} from "../actions/userActions";
export default function userReducer(state = {}, { type, payload }) {
    switch (type) {
        case UPDATE_USER:
            return { data: payload.data, isLoggedIn: payload.isLoggedIn };

        case LOGIN_USER:
            return { data: payload.data, isLoggedIn: payload.isLoggedIn };

        case LOGOUT_USER:
            return { data: payload.data, isLoggedIn: payload.isLoggedIn };

        case LOGIN_FAILED:
            return { data: payload.data, isLoggedIn: payload.isLoggedIn };

        default:
            return state;
    }
}