import * as React from "react";
import TitleBarComp from "../../components/TitleBar";
import MainContentComp from "../../components/MainContent";
// import searchIcon from "../../assets/img/search_icon.png";
// import userService from "../../services/userService";
// import ModalComp from "../../components/Modal";
// import { Eye, Edit } from "react-feather";
// import Joi from "joi-browser";
import GenericForm from "../../components/genericFormComponents/GenericForm";
// import { toast } from "react-toastify";
// import CopyToClipboard from "../../components/CopyToClipboard";
// import GFSelect from "../../components/genericFormComponents/GFSelect";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "./../../stores/index";
import { actionCreators } from "./../../stores/accessStore";
import globalService from "../../services/globalService";
import AuthContainer from "../../components/authContainer";

export interface RoleProps {
  history;
}

export interface RoleState {}

class Role extends GenericForm {
  state = {
    data: {},
    errors: {},
    roleList: [
      { name: "Supervisor", id: "32452erafd3242" },
      { name: "Manager", id: "32452erafd3242" },
      { name: "Accounter", id: "32452erafd3242" },
      { name: "Employee", id: "32452erafd3242" },
    ],
    isConfirmModalOpen: false,
  };

  componentDidMount() {
    this.props.getRoleList();
  }

  handleConfirmModel = (role) => {
    this.setState({ data: role });
    this.toggleConfirmModal();
  };

  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  render() {
    // debugger;
    let roles = [];
    if (this.props.access.roleList) {
      roles = this.props.access.roleList;
    }

    // this.state.roleList;
    return (
      <React.Fragment>
        <TitleBarComp title="Roles" />
        {/* <ModalComp
          title={`Delete Confirmation`}
          isOpen={this.state.isConfirmModalOpen}
          hideModal={this.toggleConfirmModal}
        >
          <div className="row">
            <div className="col-12 text-center text-secondary">
              <p>Are you sure? Do you want delete this?</p>
            </div>
            <div className="col-12 my-3 text-center">
              <button
                className="btn btn-primary btn-sm mx-1"
                onClick={this.deleteRole}
              >
                Yes
              </button>
              <button
                className="btn btn-light btn-sm mx-1"
                onClick={this.toggleConfirmModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalComp> */}

        <MainContentComp>
          <div className="text-right">
            <AuthContainer action="ADD">
              <Link to="/app/role/add" className="normal-btn">
                Add New
              </Link>
            </AuthContainer>
          </div>
          <div className="row my-4 mx-1">
            {roles.map((role, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-6 mt-2 p-2">{this.renderList(role)}</div>
                </React.Fragment>
              );
            })}
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderList(role) {
    return (
      <div
        className="row mx-0 rounded shadow-sm text-white"
        style={{
          background: "rgb(155, 155, 165)",
        }}
      >
        <div className="col-8 my-auto">
          <div className="h6 mb-0">{role.roleName}</div>
          <small>
            {globalService.getLocalDate(role.createdDate, "DD/MM/YYYY")}
          </small>
        </div>
        <div className="col-4 my-auto text-right action-set">
          <AuthContainer action="EDIT">
            <Link to={`/app/role/edit/${role.roleId}?name=${role.roleName}`}>
              <i className="fa fa-pencil" />
            </Link>
          </AuthContainer>
          <AuthContainer action="DELETE">
            <i
              className="fa fa-trash"
              onClick={() => this.handleConfirmModel(role)}
            />
          </AuthContainer>
        </div>
      </div>
    );
  }
}

// export default Role;

export default connect(
  (state: ApplicationState) => ({
    access: state.access,
  }),
  actionCreators
)(Role);
