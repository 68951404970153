import * as React from "react";
import userService from "../../services/userService";
import MainContentComp from "../../components/MainContent";
import TitleBarComp from "../../components/TitleBar";
import globalService from "../../services/globalService";
// import AuthContainer from "../../components/authContainer";

export interface LoginSessionProps {
  history;
}

export interface LoginSessionState {}

class LoginSession extends React.Component<
  LoginSessionProps,
  LoginSessionState
> {
  state = { dataList: [] as any };

  async getLoginSession() {
    await userService.getLoginSession().then(({ data: res }) => {
      if (!res.didError) {
        let dataList = res.model;
        this.setState({ dataList });
      }
    });
  }

  componentDidMount() {
    this.getLoginSession();
  }
  render() {
    const { dataList } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Sessions"></TitleBarComp>

        <MainContentComp>
          {/* <AuthContainer>HEllo</AuthContainer> */}

          <div className="grid-table">
            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Login ID</th>

                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((inv: any, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {globalService.getLocalDate(
                          inv.createdDate,
                          "DD/MM/YYYY HH:MM"
                        )}
                      </td>
                      <td>{inv.loginId}</td>

                      <td>
                        <div className="text-center cursor-pointer text-secondary"></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }
}

export default LoginSession;
