import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

export interface MSelectProps {
  name: any;
  label: any;
  value: any;
  onChange: any;
  error?: any;
  placeholder: string;
  listData: any;
  valueField?: string;
  displayField: string;
}

const MSelect: React.SFC<MSelectProps> = ({
  name,
  label,
  error,
  listData,
  valueField = null,
  displayField,
  placeholder,
  ...rest
}) => {
  return (
    <FormControl className="w-100">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select {...rest} placeholder={placeholder} name={name} id={name}>
        {listData.length === 0 && (
          <MenuItem value="0" disabled={true}>
            None
          </MenuItem>
        )}
        {listData.map((value, index) => {
          return (
            <MenuItem
              key={index}
              value={valueField ? value[valueField] : value}
            >
              {value[displayField]}
            </MenuItem>
            // <option key={index} value={value[valueField]}>
            //   {value.Name}
            // </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MSelect;
