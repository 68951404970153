import { AppThunkAction } from ".";
// import { connect } from "react-redux";
// import { CommonState } from './commonStore';

//CommonStore is for common actions such as loading, popups, and toasts.
//I only kept loading state, and commented the rest.
//Usually, this is connected to a Layout.tsx components, but since its not here, MultiLanguage.tsx was used to display loading bar.

//Global store containg properires of loading, popup, and toast.

type appLang = 'en' | 'ar';


export interface CommonState {
  //   shouldDisplayDialog: boolean;
  //   dialog: DialogProps;
  //   snackbarDisplay: SnackbarProps;

  isLoading: boolean;
  lang: appLang;
}

// Uncomment if you plan to use global Dialogbox in this project
//Note: you have to connect a components to display this like I did for loading.
// interface DialogProps {
//   type: "YesNo" | "Input";
//   title: string;
//   message: string;

//   onYes: () => void;
//   onNo?: () => void;
//   onCancel?: () => void;
//   onSubmit?: (value: string) => void;
// }

// Uncomment if you plan to use in this project
// interface SnackbarProps {
//   message: string;
//   err?: boolean;
// }

//Actions

interface START_LOADING {
  type: "START_LOADING";
}

interface STOP_LOADING {
  type: "STOP_LOADING";
}

interface SET_LANG {
  type: "SET_LANG";
  lang: appLang;
}
// interface SHOW_DIALOG {
//   type: "SHOW_DIALOG";
//   dialog: DialogProps;
// }
// interface RESOLVE_DIALOG {
//   type: "RESOLVE_DIALOG";
// }
// interface SHOW_SNACKBAR {
//   type: "SHOW_SNACKBAR";
//   snackbarProps: SnackbarProps;
// }
// interface DISMISS_SNACKBAR {
//   type: "DISMISS_SNACKBAR";
// }

export type CommonActions = START_LOADING | STOP_LOADING | SET_LANG;
//   | SHOW_DIALOG
//   | RESOLVE_DIALOG
//   | SHOW_SNACKBAR
//   | DISMISS_SNACKBAR;

export const actionCreators = {
  //start and stop loading are only here for example. They are useless, and should never be called.
  startLoading: (): AppThunkAction<CommonActions> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "START_LOADING" });
  },
  stopLoading: (): AppThunkAction<CommonActions> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: "STOP_LOADING" });
  },

  setLanguage: (language: appLang): AppThunkAction<CommonActions> => async (
    dispatch,
    getState) => {
    dispatch({ type: "SET_LANG", lang: language });
  }
};


const unloadedState: CommonState = {
  isLoading: false,
  lang: 'en'
};

export function reducer(
  state: CommonState,
  action: CommonActions
): CommonState {
  switch (action.type) {
    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };
    case "SET_LANG":
      return { ...state, lang: action.lang };
  }
  return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
