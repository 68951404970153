import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import Joi from "joi-browser";
import * as React from "react";
import { connect } from "react-redux";
import downRightIcon from "../../assets/img/down-right-arrow.svg";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import TitleBarComp from "../../components/TitleBar";
import { ApplicationState } from "../../stores";
import { actionCreators } from "../../stores/accessStore";

export interface MenusProps {}

export interface MenusState {}

class Menus extends GenericForm {
  navigate = {
    pageSize: 12,
    pageNumber: 1,
    firstName: "",
    lastName: "",
    userName: "",
    orderBy: 0,
    total: 0,
  };
  state = {
    data: {
      menuId: "0",
      menuName: "",
      displayName: "",
      navi: "",
      type: "",
      icon: "",
      sNo: "",
      parentMenuId: "",
      userType: "",
      isActive: true,
      isDeleted: false,
      notActive: false,
    },
    errors: {},
    checkbox: {
      isActive: true,
      isDeleted: false,
      isDisabled: false,
    },
    menuList: [],
    adminMenus: [],
    clientMenus: [],
    parentMenuList: [],
    roleList: [],
    branchList: [],
    isModalOpen: false,
    isConfirmModalOpen: false,
    oldRoleId: "",
    isUpdate: false,
    searchName: "",
  };

  schema = {
    menuId: Joi.string().allow("", null),
    menuName: Joi.string().required(),
    displayName: Joi.string().required(),
    navi: Joi.string().allow("", null),
    type: Joi.string().required(),
    icon: Joi.string().allow("", null),
    sNo: Joi.string().allow("", null),
    parentMenuId: Joi.string().allow("", null),
    userType: Joi.string().allow("", null),
    isActive: Joi.boolean().allow("", null),
    isDeleted: Joi.boolean().allow("", null),
    notActive: Joi.boolean().allow("", null),
  };

  componentDidMount() {
    this.getMenus();
  }

  openModal = () => {
    this.clearFields();
    this.setState({ isModalOpen: true, isUpdate: false });
  };
  openEditModal = (m) => {
    this.clearFields();
    let { data, checkbox } = this.state;

    data = {
      menuId: m.menuId,
      menuName: m.menuName,
      displayName: m.displayName,
      navi: m.navi,
      type: m.type,
      icon: m.icon,
      sNo: m.sNo,
      parentMenuId: m.parentMenuId,
      userType: m.userType,
      isActive: true,
      isDeleted: false,
      notActive: false,
    };

    checkbox.isActive = m.isActive;
    checkbox.isDeleted = m.isDeleted;
    checkbox.isDisabled = m.notActive;

    this.setState({
      checkbox,
      data,
      isModalOpen: true,
      isUpdate: true,
    });
  };
  openConfirmModal = (user) => {
    let { data } = this.state;
    this.setState({ isModalOpen: false, isConfirmModalOpen: true, data });
  };
  hideModal = () => {
    this.setState({ isModalOpen: false, isConfirmModalOpen: false });
  };

  clearFields() {
    let data = {
      menuId: "0",
      menuName: "",
      displayName: "",
      navi: "",
      type: "",
      icon: "",
      sNo: "",
      parentMenuId: "",
      userType: "",
      isActive: true,
      isDeleted: false,
      notActive: false,
    };
    this.setState({ data });
  }

  getSearchQuery() {
    let qry =
      "pageSize=" +
      this.navigate.pageSize +
      "&pageNumber=" +
      this.navigate.pageNumber +
      "&orderBy=0";
    if (this.state.searchName && this.state.searchName !== "")
      qry = qry + "&fisrtName=" + this.state.searchName;

    return qry;
  }
  getMenus = async () => {
    // let query = this.getSearchQuery();
    await this.props.getDefaultMenus(1);
    let adminMenus: any = this.props.access.defaultMenus;
    await this.props.getDefaultMenus(0);
    setTimeout(() => {
      let clientMenus: any = this.props.access.defaultMenus;
      this.setState({ adminMenus, clientMenus });
    }, 1000);
  };

  // Handle Search
  navigatePage = (act) => {
    if (act === "next") {
      this.navigate.pageNumber += 1;
    }
    if (act === "prev") {
      this.navigate.pageNumber -= 1;
    }
    this.getMenus();
  };

  onChangeSearchText = ({ currentTarget: e }) => {
    this.setState({ searchName: e.value });
  };

  handleSearch = () => {
    this.navigate.pageNumber = 1;
    this.navigate.total = 0;
    this.getMenus();
  };

  handleDelete = async () => {
    await this.props.deleteSubUser(this.state.data);
    if (this.props.access.isRespSuccess) {
      this.setState({ isConfirmModalOpen: false });
      this.getMenus();
    }
  };

  handleCheckbox = ({ currentTarget: e }) => {
    let checkbox = this.state.checkbox;
    checkbox[e.name] = e.checked;
    this.setState({ Checkbox });
  };

  doSubmit = () => {
    this.addUpdateUser();
  };

  async addUpdateUser() {
    debugger;
    let { data, checkbox } = this.state;
    data.isActive = checkbox.isActive;
    data.isDeleted = checkbox.isDeleted;
    data.notActive = checkbox.isDisabled;
    if (!data.parentMenuId) data.parentMenuId = "0";
    await this.props.addUpdateMenu(data);
    if (this.props.access.isRespSuccess) {
      this.setState({ isModalOpen: false });
      this.getMenus();
    }
  }

  render() {
    const {
      isUpdate,
      isModalOpen,
      isConfirmModalOpen,
      clientMenus,
      adminMenus,
    } = this.state;
    // const { pageSize, pageNumber, total } = this.navigate;
    return (
      <React.Fragment>
        <TitleBarComp title="Menu"></TitleBarComp>

        <MainContentComp>
          <div className="row my-3 mx-1">
            <div className="col-12 text-right">
              <button className="normal-btn" onClick={this.openModal}>
                Create
              </button>
            </div>
            <div className="col-12 my-4">
              <div className="row">
                <div className="col-12 bg-app rounded">
                  <h3 className="my-1 text-white">Super Admin Menus</h3>
                </div>
                <div className="col-12 mb-4 mt-1">
                  <div className="grid-table menu">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Menu name</th>
                          <th>Navigation</th>
                          <th>Icon</th>
                          <th>Sno</th>
                          <th>Type</th>
                          <th>User Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminMenus.map((val: any, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <tr className="bg-light mt-2">
                                <td>{val.displayName}</td>
                                <td>{val.menuName}</td>
                                <td>{val.navi}</td>
                                <td>{val.icon}</td>
                                <td>{val.sNo}</td>
                                <td>{val.type}</td>
                                <td>{val.userType}</td>
                                <td>
                                  <IconButton
                                    onClick={() => this.openEditModal(val)}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </IconButton>
                                </td>
                              </tr>
                              {val.usr_ChildMenus.map((child: any, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <img
                                        className="text-danger mr-2"
                                        src={downRightIcon}
                                        alt=""
                                        style={{ width: 15 }}
                                      />
                                      {child.displayName}
                                    </td>
                                    <td>{child.menuName}</td>
                                    <td>{child.navi}</td>
                                    <td>{child.icon}</td>
                                    <td>{child.sNo}</td>
                                    <td>{child.type}</td>
                                    <td>{child.userType}</td>
                                    <td>
                                      <IconButton
                                        onClick={() =>
                                          this.openEditModal(child)
                                        }
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </IconButton>
                                    </td>
                                  </tr>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                        {adminMenus.length === 0 && (
                          <tr>
                            <td colSpan={8} className="align-center">
                              <span>
                                <b>Sorry</b> There are no recrods to show!
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-12 bg-app rounded">
                  <h3 className="my-1 text-white">Client Admin Menus</h3>
                </div>

                <div className="col-12 mb-4 mt-1">
                  <div className="grid-table menu">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Menu name</th>
                          <th>Navigation</th>
                          <th>Icon</th>
                          <th>Sno</th>
                          <th>Type</th>
                          <th>User Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientMenus.map((val: any, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <tr className="bg-light mt-2">
                                <td>{val.displayName}</td>
                                <td>{val.menuName}</td>
                                <td>{val.navi}</td>
                                <td>{val.icon}</td>
                                <td>{val.sNo}</td>
                                <td>{val.type}</td>
                                <td>{val.userType}</td>
                                <td>
                                  {" "}
                                  <IconButton
                                    onClick={() => this.openEditModal(val)}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </IconButton>
                                </td>
                              </tr>
                              {val.usr_ChildMenus.map((child: any, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <img
                                        className="text-danger mr-2"
                                        src={downRightIcon}
                                        alt=""
                                        style={{ width: 15 }}
                                      />
                                      {child.displayName}
                                    </td>
                                    <td>{child.menuName}</td>
                                    <td>{child.navi}</td>
                                    <td>{child.icon}</td>
                                    <td>{child.sNo}</td>
                                    <td>{child.type}</td>
                                    <td>{child.userType}</td>
                                    <td>
                                      {" "}
                                      <IconButton
                                        onClick={() =>
                                          this.openEditModal(child)
                                        }
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </IconButton>
                                    </td>
                                  </tr>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                        {clientMenus.length === 0 && (
                          <tr>
                            <td colSpan={8} className="align-center">
                              <span>
                                <b>Sorry</b> There are no recrods to show!
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 p-2">
              {/* {<NoDataContainer isDisplay={menuList.length === 0} />} */}
            </div>
            <div className="col-12 text-right my-2 p-2">
              {/* {this.navigate.total > 0 && (
              <PaginationComp
                start={pageNumber * pageSize - pageSize + 1}
                end={
                  pageSize * pageNumber > total ? total : pageSize * pageNumber
                }
                total={total}
                navNextPage={() => this.navigatePage("next")}
                navPrevPage={() => this.navigatePage("prev")}
              />
            )} */}
            </div>
          </div>
        </MainContentComp>

        <div>
          <ModalComp
            title={`${isUpdate ? "Edit" : "Add"} Menu`}
            isOpen={isModalOpen}
            hideModal={this.hideModal}
          >
            <div className="modal-body">{this.renderFormContent()}</div>
          </ModalComp>
        </div>
        <div>
          <ModalComp
            title="Delete user"
            isOpen={isConfirmModalOpen}
            hideModal={() =>
              this.setState({
                isConfirmModalOpen: !this.state.isConfirmModalOpen,
              })
            }
          >
            <div className="modal-body text-center">
              <p>Are you sure, Do you want delete this user?</p>
              <div className="my-2">
                <button className="active-btn" onClick={this.handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </ModalComp>
        </div>
      </React.Fragment>
    );
  }

  renderFormContent() {
    const {
      isUpdate,
      data,
      adminMenus,
      clientMenus,
      checkbox: state,
    } = this.state;

    adminMenus.forEach((data: any) => {
      data["dName"] = data.displayName;
    });

    clientMenus.forEach((data: any) => {
      data["dName"] = data.displayName;
    });
    // if

    // for (let d of this.props.access.roleList) {
    //   d["dName"] = d["roleName"];
    // }
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-12">
              {this.renderSelect(
                "userType",
                "Select user type",
                [
                  { dName: "Super Admin", id: 0 },
                  { dName: "Client Admin", id: 1 },
                ],
                "id"
              )}
            </div>
            {data.userType == "0" && (
              <div className="col-12">
                {this.renderSelect(
                  "parentMenuId",
                  "Select parent menu",
                  clientMenus,
                  "menuId"
                )}
              </div>
            )}
            {data.userType == "1" && (
              <div className="col-12">
                {this.renderSelect(
                  "parentMenuId",
                  "Select parent menu",
                  adminMenus,
                  "menuId"
                )}
              </div>
            )}
            <div className="col-6">
              {this.renderInput("displayName", "Display Name")}
            </div>
            <div className="col-6">
              {this.renderInput("menuName", "Menu Name")}
            </div>
            <div className="col-6">
              {this.renderInput("navi", "Navigation")}
            </div>
            <div className="col-6">{this.renderInput("icon", "Icon")}</div>
            <div className="col-6">{this.renderInput("sNo", "SNo")}</div>
            <div className="col-6">
              {this.renderSelect(
                "type",
                "Select Type",
                [{ dName: "Link" }, { dName: "Item" }],
                "dName"
              )}
            </div>

            <div className="col-12">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isActive"
                      checked={state.isActive}
                      onChange={this.handleCheckbox}
                      value="isActive"
                      color="primary"
                    />
                  }
                  label="Is Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isDeleted"
                      checked={state.isDeleted}
                      onChange={this.handleCheckbox}
                      value="isDeleted"
                      color="primary"
                    />
                  }
                  label="Delete"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="isDisabled"
                      checked={state.isDisabled}
                      onChange={this.handleCheckbox}
                      value="isDisabled"
                      color="primary"
                    />
                  }
                  label="Not activated"
                />
              </FormGroup>
            </div>

            <div className="col-12 my-2 text-right">
              {this.renderButton(isUpdate ? "Update" : "Add")}
            </div>
            {/* <div>
              <button onClick={() => console.log(this.state.errors)}>
                {" "}
                test
              </button>
            </div> */}
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderUserCard(data) {
    return (
      <div className="user-card shadow-sm">
        <div className="row m-0 p-0 my-1">
          {/* <div className="col-12 my-1 text-center">
            <i className="fa fa-user text-white fa-4x" />
          </div> */}
          <div className="col-12">
            <div className="h5">{data.displayName}</div>
            <div className="email">
              <i className="fa fa-envelope" />
              <span className="ml-2 email"> {data.userName}</span>
            </div>
            <div className="mobile">
              <i className="fa fa-phone-square" />
              <span className="ml-2 mobile">{data.mobile}</span>
            </div>
          </div>
          <div className="col-12 mt-2 text-center bg-light">
            <span className="badge text-dark my-2">
              {/* {data.roles.length > 0 ? data.roles[0].name : "Not Assigned"} */}
            </span>
          </div>
        </div>

        <div
          className="delete"
          onClick={() => this.openConfirmModal(data)}
          style={{ right: 12, bottom: 10, top: "auto" }}
        >
          <i className="fa fa-trash" />
        </div>
        {!data.isActive && !data.isDeleted && (
          <div className="inActive" style={{ top: 60 }}>
            <div>Not Activated</div>
          </div>
        )}

        {data.isDeleted && (
          <div className="inActive" style={{ top: 60 }}>
            <div>Deleted</div>
          </div>
        )}

        <div className="edit" onClick={() => this.openEditModal(data)}>
          <i className="fa fa-pencil" />
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    access: state.access,
  }),
  actionCreators
)(Menus);
