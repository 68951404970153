import queryString from "query-string";
import * as React from "react";
import logo from "../../assets/img/logo.png";
// import successLogo from "../../assets/img/p-success.svg";
// import failureLogo from "../../assets/img/forbidden.svg";
import successLogo from "../../assets/img/new/success.svg";
import failureLogo from "../../assets/img/new/warning.svg";
import httpService from "../../services/httpService";
// import { Check, X } from "react-feather";

export interface PaymentResultProps {}

export interface PaymentResultState {}

class PaymentResult extends React.Component<
  PaymentResultProps,
  PaymentResultState
> {
  constructor(props) {
    super(props);
    const { location } = props;
    const search: any = queryString.parse(location.search);
    // this.state.init = search.init;
    this.state.search = search;
  }
  state = {
    data: { otp: "", password: "", email: "" },
    errors: {},
    type: "",
    id: "",
    search: {},
    qString: {
      resultIndicator: "",
      sessionVersion: "",
      init: "" as any,
      paymentid: "",
      result: "",
      ref: "",
      tranid: "",
      trackid: "",
      amt: "",
      ErrorMessage: "",
    },
    loader: true,
    renderingScreen: "",
    masterData: {} as any,
  };

  componentDidMount() {
    this.setupData();
  }

  handlePrint = () => {
    window.print();
  };

  fetchMasterData = async (resultIndicator, sessionVersion) => {
    let orderId = sessionStorage.getItem("OrderId");
    let fetchUrl =
      "https://admin.princessq8.com/ZpayDev/api/PaymentMasterResult/123/" +
      orderId +
      "/" +
      resultIndicator +
      "/" +
      sessionVersion;
    let res = await httpService.get(fetchUrl);
    this.setState({ masterData: res.data, loader: false });
    // console.log(res.data);
  };

  setupData() {
    let st = this.state.qString;
    const query = this.state.search;

    st.resultIndicator = query["resultIndicator"];
    st.sessionVersion = query["sessionVersion"];
    st.init = query["init"];
    st.amt = query["amt"];
    st.paymentid = query["paymentid"];
    st.ref = query["ref"];
    st.result = query["result"];
    st.trackid = query["trackid"];
    st.tranid = query["tranid"];
    st.ErrorMessage = query["ErrorMessage"];

    // debugger;
    let renderingScreen = "";
    if (st.init === "1" && st.result === "CAPTURED") {
      renderingScreen = "SUCCESS";
    } else if (st.init === "0" && st.result === "NOT CAPTURED") {
      renderingScreen = "FAILED";
    } else if (st.init === "0" && st.result === "CANCEL") {
      renderingScreen = "CANCEL";
    } else if (st.resultIndicator !== "" || st.resultIndicator !== null) {
      renderingScreen = "MASTER";
      this.fetchMasterData(st.resultIndicator, st.sessionVersion);
    } else {
      renderingScreen = "ERROR";
    }

    this.setState({ qString: st, renderingScreen: renderingScreen });
  }

  render() {
    const { renderingScreen, loader } = this.state;
    let year = new Date().getUTCFullYear();
    return (
      <React.Fragment>
        <div className="payment-result">
          <div
            className="invoice-template-logo bg-white rounded-top"
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            <div className="row">
              <div className="col-6 my-auto">
                <img src={logo} style={{ height: 50 }} alt="" />
              </div>
              <div className="col-6 text-right my-auto">
                <button
                  className="btn btn-sm text-primary"
                  onClick={this.handlePrint}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
          {renderingScreen === "MASTER" && !loader && this.renderMasterSucc()}
          {renderingScreen === "SUCCESS" && this.renderSucc()}
          {renderingScreen === "FAILED" && this.renderFailure()}
          {renderingScreen === "CANCEL" && this.renderCancel()}
          {renderingScreen === "ERROR" && this.renderError()}

          <div className="invoice-footer">
            Copyrights {year}. All Rights Reserved.
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSucc() {
    const { qString: qs } = this.state;
    return (
      <React.Fragment>
        <div className="invoice-template-result shadow rounded-bottom">
          <div className="align-center">
            <img src={successLogo} style={{ height: 200 }} alt="" />
          </div>
          <div className="align-center">
            <p className="success-h">Payment Success</p>
            <span>
              Your Payment is success of Amount {qs.amt}, Invoice send your
              register mail id.
            </span>
          </div>

          <div className="row">
            <div className="col-md-12 align-right"></div>
          </div>
          {/* <hr /> */}
          <div className="row my-4 mx-2 bg-dark-blue py-3 rounded">
            {qs.amt && qs.amt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Amount</span>
                  <p>{qs.amt}</p>
                </div>
              </div>
            )}

            {qs.result && qs.result !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Result </span>
                  <p>{qs.result}</p>
                </div>
              </div>
            )}

            {qs.ref && qs.ref !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Reference </span>
                  <p>{qs.ref}</p>
                </div>
              </div>
            )}

            {qs.paymentid && qs.paymentid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Payment Id </span>
                  <p>{qs.paymentid}</p>
                </div>
              </div>
            )}

            {qs.trackid && qs.trackid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Tracking Id </span>
                  <p>{qs.trackid}</p>
                </div>
              </div>
            )}

            {qs.tranid && qs.tranid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Transaction Id </span>
                  <p>{qs.tranid}</p>
                </div>
              </div>
            )}

            {qs.ErrorMessage && qs.ErrorMessage !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>ErrorMessage</span>
                  <p>{qs.ErrorMessage}</p>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }

  renderFailure() {
    const { qString: qs } = this.state;
    return (
      <React.Fragment>
        <div className="invoice-template-result shadow rounded-bottom">
          <div className="align-center">
            <img src={failureLogo} style={{ height: 200 }} alt="" />
          </div>
          <div className="align-center">
            <p className="fail-h h2 my-2">Payment Failure</p>
            <span>Your Payment has been failed</span>
          </div>

          <div className="row bg-dark-blue mx-2 py-3 rounded my-5">
            {qs.paymentid && qs.paymentid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Payment Id </span>
                  <p>{qs.paymentid}</p>
                </div>
              </div>
            )}

            {qs.ref && qs.ref !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Reference </span>
                  <p>{qs.ref}</p>
                </div>
              </div>
            )}

            {qs.amt && qs.amt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Amount</span>
                  <p>{qs.amt}</p>
                </div>
              </div>
            )}

            {qs.result && qs.result !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Result </span>
                  <p>{qs.result}</p>
                </div>
              </div>
            )}

            {qs.ErrorMessage && qs.ErrorMessage !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>ErrorMessage</span>
                  <p>{qs.ErrorMessage}</p>
                </div>
              </div>
            )}

            {qs.trackid && qs.trackid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Tracking Id </span>
                  <p>{qs.trackid}</p>
                </div>
              </div>
            )}

            {qs.tranid && qs.tranid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Transaction Id </span>
                  <p>{qs.tranid}</p>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }

  renderCancel() {
    const { qString: qs } = this.state;
    return (
      <React.Fragment>
        <div className="invoice-template-result shadow rounded-bottom">
          <div className="align-center">
            <img src={failureLogo} style={{ height: 200 }} alt="" />
          </div>
          <div className="align-center">
            <p className="fail-h h2 my-2">Payment cancel</p>
            <span>Your Payment has been cancel</span>
          </div>

          <div className="row bg-dark-blue mx-2 py-3 rounded my-5 my-5">
            {qs.paymentid && qs.paymentid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Payment Id </span>
                  <p>{qs.paymentid}</p>
                </div>
              </div>
            )}

            {qs.ref && qs.ref !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Reference </span>
                  <p>{qs.ref}</p>
                </div>
              </div>
            )}

            {qs.amt && qs.amt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Amount</span>
                  <p>{qs.amt}</p>
                </div>
              </div>
            )}

            {qs.result && qs.result !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Result </span>
                  <p>{qs.result}</p>
                </div>
              </div>
            )}

            {qs.ErrorMessage && qs.ErrorMessage !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>ErrorMessage</span>
                  <p>{qs.ErrorMessage}</p>
                </div>
              </div>
            )}

            {qs.trackid && qs.trackid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Tracking Id </span>
                  <p>{qs.trackid}</p>
                </div>
              </div>
            )}

            {qs.tranid && qs.tranid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Transaction Id </span>
                  <p>{qs.tranid}</p>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }

  renderError() {
    const { qString: qs } = this.state;
    return (
      <React.Fragment>
        <div className="invoice-template-result shadow rounded-bottom">
          <div className="align-center">
            <img src={failureLogo} style={{ height: 200 }} alt="" />
          </div>
          <div className="align-center">
            <p className="fail-h h2 my-2">Payment Error</p>
            <span>Error detected while your payment. Please try again</span>
          </div>
          <div className="row bg-dark-blue mx-2 py-3 rounded my-5 my-5">
            {qs.paymentid && qs.paymentid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Payment Id </span>
                  <p>{qs.paymentid}</p>
                </div>
              </div>
            )}

            {qs.ref && qs.ref !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Reference </span>
                  <p>{qs.ref}</p>
                </div>
              </div>
            )}

            {qs.amt && qs.amt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Amount</span>
                  <p>{qs.amt}</p>
                </div>
              </div>
            )}

            {qs.result && qs.result !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Result </span>
                  <p>{qs.result}</p>
                </div>
              </div>
            )}

            {qs.ErrorMessage && qs.ErrorMessage !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>ErrorMessage</span>
                  <p>{qs.ErrorMessage}</p>
                </div>
              </div>
            )}

            {qs.trackid && qs.trackid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Tracking Id </span>
                  <p>{qs.trackid}</p>
                </div>
              </div>
            )}

            {qs.tranid && qs.tranid !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Transaction Id </span>
                  <p>{qs.tranid}</p>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }

  renderMasterSucc() {
    const { masterData: md } = this.state;
    return (
      <React.Fragment>
        <div className="invoice-template-result shadow rounded-bottom">
          <div className="align-center">
            <img
              src={md.msgResult ? successLogo : failureLogo}
              style={{ height: 200 }}
              alt=""
            />
          </div>
          <div className="align-center">
            <p className="success-h">
              Payment {md.msgResult ? "Success" : "Failure"}
            </p>
            <span className="d-none">
              Your Payment is success of Amount {"qs.amt"}, Invoice send your
              register mail id.
            </span>
          </div>

          <div className="row">
            <div className="col-md-12 align-right"></div>
          </div>
          {/* <hr /> */}
          <div className="row my-4 mx-2 bg-dark-blue py-3 rounded">
            {/* {qs.amt && qs.amt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Amount</span>
                  <p>{qs.amt}</p>
                </div>
              </div>
            )} */}

            {md.Result && md.Result !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Result </span>
                  <p>{md.Result}</p>
                </div>
              </div>
            )}

            {md.Receipt && md.Receipt !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Receipt ID </span>
                  <p>{md.Receipt}</p>
                </div>
              </div>
            )}

            {md.Terminal && md.Terminal !== "" && (
              <div className="col-md-4">
                <div className="input-div-style">
                  <span>Terminal Id </span>
                  <p>{md.Terminal}</p>
                </div>
              </div>
            )}
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentResult;
