import * as React from "react";
import ReactDOM from "react-dom";

export interface ModalCompProps {
  isOpen: boolean;
  hideModal: any;
  size?: string; //size={`lg`}
  title: string;
}

export interface ModalCompState {}

class ModalComp extends React.Component<ModalCompProps, ModalCompState> {
  state = {};

  renderModal() {
    const isOpen = this.props.isOpen;
    return (
      <div
        className={`modal fade ${isOpen ? "d-block fade show" : ""}`}
        role="dialog"
        style={{ background: "rgba(0,0,0,0.6)" }}
      >
        <div
          className={`modal-dialog modal-${this.props.size}`}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button
                onClick={this.props.hideModal}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), document.getElementById(
      "modal-view"
    ) as Element);
  }
}

export default ModalComp;
