import http from './httpService';
import { API_ROOT } from './globalService'

const COMPANY = API_ROOT + 'Company/'

export function addUpdateCompanyInfo(params) {
    return http.post(COMPANY + 'AddUpdateCompanyInfo', params);
}

// New API for add update the User profile Screen -  Profile > User Profile
export function addUpdateUserProfile(params) {
    return http.post(COMPANY + 'UpdateAddCompanyAll', params);
}


export function addUpdateClientBank(params) {
    return http.post(COMPANY + 'UpdateAddCompanyBankAll', params);
}


export function getCompanyInfo(isApproved = 0) {
    return http.get(COMPANY + 'getClientListByCompany');
}

export function getCompanyList(query) {
    return http.get(COMPANY + 'getCompanyList?' + query);
}


export function getClientCompanyById(query) {
    return http.get(COMPANY + 'getClientCompanyById?' + query);
}

export function approveComp(query) {
    return http.get(COMPANY + 'ApproveCompany/' + query);
}

export function deClineComp(query) {
    return http.get(COMPANY + 'DeclineClientCompany?' + query);
}



export default {
    getCompanyList, getClientCompanyById, getCompanyInfo,
    addUpdateUserProfile, addUpdateClientBank,
    approveComp, deClineComp
}