import * as React from "react";
import { NavLink } from "react-router-dom";
import { withBreadcrumbs } from "./withBreadcrumb";

// const UserBreadcrumb = ({ match }) => <span>{match.params.id}</span>; // use match param userId to fetch/display user name
// const VABreadcrumb = ({ match }) => <span>{match.params.type}</span>; // use match param userId to fetch/display user name

const routes = [
  // { path: "/app", breadcrumb: "Home", icon: "fa fa-home" },
  // { path: "/app/user", breadcrumb: "Users" },
  // { path: "/app/role", breadcrumb: "Role" },
  // { path: "/app/role/:id", breadcrumb: UserBreadcrumb },
  // { path: "/app/branch", breadcrumb: "Branch" },
  // { path: "/app/profile", breadcrumb: "Profile" },
  // { path: "/app/category", breadcrumb: "Category" },
  // { path: "/app/category/:id", breadcrumb: "SubCategory" },
  // { path: "/app/brand", breadcrumb: "Brand" },
  // { path: "/app/asset", breadcrumb: "Assets" },
  // { path: "/app/asset/:type", breadcrumb: VABreadcrumb },
  // { path: "/app/asset/:id", breadcrumb: "Asset Detail" },
  // { path: "/app/masterasset", breadcrumb: "Master Assets" }
];

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className="text-right">
    {/* <ul className="breadcrumb">
          <li>
            <i className="material-icons" style={{ fontSize: "17px" }}>
              home
            </i>
          </li>
          <li>Profile</li>
          <li>Update Profile</li>
        </ul> */}

    {/* <nav className="ml-auto"> */}
    <ul className="breadcrumb text-capitalize">
      <li>
        <i className="material-icons" style={{ fontSize: "20px" }}>
          home
        </i>
      </li>
      {breadcrumbs.map(({ breadcrumb, path, icon, match }, idx) => (
        <li
          key={path}
          className={`breadcrumb-item  ${
            breadcrumbs.length - 1 === idx ? "active" : ""
          }`}
        >
          {breadcrumbs.length - 1 === idx ? (
            <span>
              <i className={icon} /> {breadcrumb}
            </span>
          ) : (
            <NavLink to={match.url}>
              <i className={icon} /> {breadcrumb}
            </NavLink>
          )}
        </li>
      ))}
    </ul>
    {/* </nav> */}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
