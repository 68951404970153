import { toast } from 'react-toastify';
import { AppThunkAction } from ".";
import http from "../services/httpService";
import { AppConstants } from './../common/appConfig';
import { AuthActions } from "./authStore";

const ROLE_API = AppConstants.API_ROOT + "Role/";
const USER_API = AppConstants.API_ROOT + "Users/";

export interface AccessState {
    roleList: any[];
    defaultMenus: any[];
    roleMenus: any[];
    userList: any[];
    userInfo: any[];
    totalRecord: number;
    isRespSuccess: boolean;
}

//Actions
interface GET_ROLE_LIST {
    type: "GET_ROLE_LIST";
    roleList: any[];
}
interface GET_ROLE_MENUS {
    type: "GET_ROLE_MENUS";
    roleMenus: any[];
}

interface GET_DEFAULT_MENUS {
    type: "GET_DEFAULT_MENUS";
    defaultMenus: any[];
}

interface GET_USER_INFO {
    type: "GET_USER_INFO";
    userInfo: any[];
}
interface ADD_UPDATE_ROLE {
    type: "ADD_UPDATE_ROLE";
    isRespSuccess: boolean;
}

interface ADD_UPDATE_MENU {
    type: "ADD_UPDATE_MENU";
    isRespSuccess: boolean;
}

interface UPDATE_USER_INFO {
    type: "UPDATE_USER_INFO";
    isRespSuccess: boolean;
}



interface ADD_UPDATE_SUBUSER {
    type: "ADD_UPDATE_SUBUSER";
    isRespSuccess: boolean;
}
interface DELETE_SUBUSER {
    type: "DELETE_SUBUSER";
    isRespSuccess: boolean;
}
interface GET_USER_LIST {
    type: "GET_USER_LIST";
    userList: any[];
    totalRecord: number;
}

export type AccessActions = AuthActions | GET_ROLE_LIST | GET_ROLE_MENUS | GET_DEFAULT_MENUS |
    ADD_UPDATE_ROLE | UPDATE_USER_INFO | ADD_UPDATE_MENU | ADD_UPDATE_SUBUSER | DELETE_SUBUSER | GET_USER_LIST | GET_USER_INFO;


export const actionCreators = {


    getRoleList: (): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + 'getRoleAll').then(({ data: res }) => {
            if (!res.didError) {
                // console.log(res.message);
                dispatch({ type: "GET_ROLE_LIST", roleList: res.model });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    getRoleMenus: (roleId): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + 'GetMenubyRole/' + roleId).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_ROLE_MENUS", roleMenus: res.model });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    getDefaultMenus: (typeId): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {

        await http.get(ROLE_API + 'GetDefaultMenu/' + typeId).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_DEFAULT_MENUS", defaultMenus: res.model });
            } else {
                dispatch({ type: "GET_DEFAULT_MENUS", defaultMenus: [] });
                toast.error(res.errorMessage);
            }
        })
    },

    getUserInfo: (userId, isOthers = 0): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.get(USER_API + 'getUserInfo?clientId=' + userId + '&isAdmin=' + isOthers).then(({ data: res }) => {
            if (!res.didError) {
                dispatch({ type: "GET_USER_INFO", userInfo: res.data });
            } else {
                toast.error(res.errorMessage);
            }
        })
    },

    addUpdateRole: (data): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.post(ROLE_API + 'AddUpdateRole', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_ROLE", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_ROLE", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    updateUserInfo: (data, isAdmin): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {

        let updateURL = isAdmin === 1 ? "updateAdminUser" : "updateClientUser";

        await http.put(USER_API + updateURL, data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "UPDATE_USER_INFO", isRespSuccess: true });
            } else {
                dispatch({ type: "UPDATE_USER_INFO", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    addUpdateMenu: (data): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.post(ROLE_API + 'AddUpdateMenu', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_MENU", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_MENU", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },
    addUpdateSubUser: (data): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        let link = "AddSubUser";
        if (data.loginId !== '') {
            link = "UpdateSubUser";
        }
        await http.post(ROLE_API + link, data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "ADD_UPDATE_SUBUSER", isRespSuccess: true });
            } else {
                dispatch({ type: "ADD_UPDATE_SUBUSER", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },

    deleteSubUser: (data): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {

        await http.post(ROLE_API + 'UpdateSubUser', data).then(({ data: res }) => {
            if (!res.didError) {
                toast.success(res.message);
                dispatch({ type: "DELETE_SUBUSER", isRespSuccess: true });
            } else {
                dispatch({ type: "DELETE_SUBUSER", isRespSuccess: false });
                toast.error(res.errorMessage);
            }
        })
    },


    getSubUserList: (query): AppThunkAction<AccessActions> => async (
        dispatch,
        getState) => {
        await http.get(ROLE_API + "getsubuser?" + query).then(({ data: res }) => {
            if (!res.didError) {
                // console.log(res.message);
                dispatch({ type: "GET_USER_LIST", userList: res.model, totalRecord: res.itemsCount });
            } else {
                console.error(res.errorMessage);
            }
        })
    },




};


const unloadedState: AccessState = {
    roleList: [],
    defaultMenus: [],
    userInfo: {} as any,
    roleMenus: [],
    userList: [],
    totalRecord: 0,
    isRespSuccess: false

};

export function reducer(
    state: AccessState,
    action: AccessActions
): AccessState {
    switch (action.type) {
        case "GET_ROLE_LIST":
            return { ...state, roleList: action.roleList };
        case "GET_ROLE_MENUS":
            return { ...state, roleMenus: action.roleMenus };
        case "GET_DEFAULT_MENUS":
            return { ...state, defaultMenus: action.defaultMenus };
        case "GET_USER_INFO":
            return { ...state, userInfo: action.userInfo };
        case "UPDATE_USER_INFO":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_ROLE":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_MENU":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "ADD_UPDATE_SUBUSER":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "DELETE_SUBUSER":
            return { ...state, isRespSuccess: action.isRespSuccess };
        case "GET_USER_LIST":
            return { ...state, userList: action.userList, totalRecord: action.totalRecord };
    }
    return state || unloadedState;
}

//This is extra for convinecne. I'm no sure about it's performance impact
// export const connectCommonStore = (component: any) => {
//   return connect(
//     (state: ApplicationState) => state.common,
//     actionCreators
//   )(component);
// };

//This as well
// export type CommonStoreProps = CommonState & typeof actionCreators;
