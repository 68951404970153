import { Checkbox } from "@material-ui/core";
import Joi from "joi-browser";
import * as React from "react";
import { Upload, X } from "react-feather";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContainer from "../../components/authContainer";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import MainContentComp from "../../components/MainContent";
import ModalComp from "../../components/Modal";
import TagInput from "../../components/TagInput";
import TitleBarComp from "../../components/TitleBar";
import commonService from "../../services/commonService";
import globalService from "../../services/globalService";
import userService from "../../services/userService";
import { IInvoice } from "./../../models/IInvoice";

export interface CreateQuickInvoiceProps {}

export interface CreateQuickInvoiceState {}

class CreateQuickInvoice extends GenericForm {
  state = {
    data: {},
    defaultLang: "AR",
    errors: {},
    isModalOpen: false,
    cust: {
      list: [],
      custSearch: "",
      selectedCust: {},
      mobileCode: "965",
    },
    currentCust: "Choose Customer",

    others: {
      invOption: "",
      mobCode: "",
      mobile: "",
      email: "",
    },

    uploads: [] as any,

    enableMobile: true,
    enableEmail: true,
    isMobRequired: true,
    isEmailRequired: false,
    disableCustRefId: false,
    tagValue: {
      tags: [] as any,
      input: "",
    },
    isSearchExtDB: false,
  };
  fileInput;
  excelInput;
  uploadFile = {
    content: "",
    fileName: "",
    extension: "",
    fName: "InvoiceDoc",
  };
  invOption = [
    { dName: "SMS", id: "SMS" },
    { dName: "EMAIL", id: "EMAIL" },
    { dName: "SMS & EMAIL", id: "SMS & EMAIL" },
    { dName: "LINK", id: "LINK" },
  ];
  schema = {};
  doSubmit() {
    // if (this.state.data["custMobile"].length !== 8) {
    //   toast.error("Please enter valid mobile number");
    //   return false;
    // }

    let errors = this.state.errors;
    var emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      !this.state.data["custEmail"].match(emailValidator) &&
      this.state.isEmailRequired
    ) {
      errors["custEmail"] = "Please enter valid email id";
      toast.error("Please enter valid email id");
      this.setState({ errors });
      return false;
    }

    if (
      this.state.data["custMobile"].length !== 8 &&
      this.state.isMobRequired
    ) {
      errors["custMobile"] = "Please enter 8 digit mobile number";
      toast.error("Please enter valid mobile number");
      this.setState({ errors });
      return false;
    }

    if (this.state.cust.selectedCust["fullName"]) {
      this.state.cust.selectedCust["mobileNo"] =
        this.state.data["mobileCode"] + this.state.data["custMobile"];
      this.state.cust.selectedCust["email"] = this.state.data["custEmail"];
      this.state.cust.selectedCust["customerRefId"] = this.state.data[
        "customerRefId"
      ];
      this.state.data["customer"] = this.state.cust.selectedCust;
    } else {
      let obj = {
        fullName: this.state.data["custName"],
        mobileNo: this.state.data["mobileCode"] + this.state.data["custMobile"],
        email: this.state.data["custEmail"],
        customerRefId: this.state.data["customerRefId"],
      };
      this.state.data["customer"] = obj;
    }

    let uploadObj: any[] = [];
    for (let up of this.state.uploads) {
      uploadObj.push({
        fileName: up.name,
        filePath: up.file,
        invoiceAttachDocId: 0,
        invoiceId: "",
        pLink: 0,
      });
    }

    this.state.data["invoiceAttachedDocFile"] = uploadObj;
    this.state.data["invoiceLCVAmt"] = this.state.data["invoiceAmt"];

    let invRef = "";
    let tags: any[] = this.state.tagValue.tags;
    if (tags.length > 0) {
      for (let tag of tags) {
        invRef += invRef === "" ? tag : "," + tag;
      }
    }
    this.state.data["customerRef"] = invRef;

    // debugger;
    this.addInvoice();
  }

  addInvoice = async () => {
    await userService
      .addInvoice(this.state.data)
      .then(({ data: res }) => {
        debugger;
        if (!res.didError) {
          toast.success(res.message);
          this.props.history.push("/app/inv-list");
        } else {
          let data = this.state.data;
          delete data["customer"];
          // console.log(data);
          this.setState({ data });
        }
      })
      .catch((ex) => {
        let { errors, data: datas } = this.state;
        const { status, data } = ex.response;
        if (status === 400) {
          toast.error(data.title);
          let keys = Object.keys(data.errors);

          for (let key of keys) {
            if (key !== "invoiceLCVAmt") errors[key] = data.errors[key];
          }
          delete datas["customer"];
          this.setState({ errors, data: datas });
        }
      });
  };

  componentDidMount() {
    this.setupData();
  }
  setupData() {
    let data: IInvoice = {
      // customer: {},
      customerUrl: window.location.host + "/payment?invid=",
      invoiceLang: "ENG",
      invoiceAmt: "",
      invoiceAmtValue: "KWD",
      invoiceLCVAmt: "",
      invoiceType: "QUICK",
      invoiceOption: "SMS",
      invoiceExpiryDate: new Date(),
      customerRef: "",
      custMobile: "",
      custEmail: "",
      custName: "",
      mobileCode: "965",
      invoiceAttachedDocFile: [],
      customerRefId: "",
    };
    this.setState({ data });
    this.setupSchema();
  }

  setupSchema() {
    let scm = {
      customerUrl: Joi.string().required(),
      invoiceLang: Joi.string().required(),
      invoiceAmt: Joi.number().required(),
      invoiceAmtValue: Joi.string().required(),
      invoiceLCVAmt: Joi.number().allow("", null),
      invoiceType: Joi.string().required(),
      invoiceOption: Joi.string().required(),
      customerRef: Joi.string().allow("", null),
      custMobile: Joi.number().allow("", null),
      custEmail: Joi.string().email().allow("", null),
      custName: Joi.string().required(),
      mobileCode: Joi.string().allow("", null),
      invoiceExpiryDate: Joi.raw().allow("", null),
      invoiceAttachedDocFile: Joi.array().items().optional(),
      customerRefId: Joi.string().allow("", null),
      customer: Joi.array().items().optional(),
    };
    this.schema = scm;
  }

  async getCustList(val) {
    if (val !== "") {
      let search1 = this.state.isSearchExtDB === true ? "&search=1" : "";

      let qryStr = "fullName=" + val + search1;
      await userService.checkCustomer(qryStr).then(({ data: res }) => {
        if (!res.didError) {
          let cust = { ...this.state.cust };
          cust.list = res.model;
          this.setState({ cust });
        }
      });
    } else {
      let cust = { ...this.state.cust };
      cust.list = [];
      this.setState({ cust });
    }
  }

  handlePopup = () => {
    let cust = this.state.cust;
    cust.custSearch = "";
    this.setState({ isModalOpen: !this.state.isModalOpen, cust });
  };

  handleInputChange = ({ currentTarget: e }) => {
    const cust = { ...this.state.cust };
    cust[e.name] = e.value;
    this.getCustList(e.value);
    this.setState({ cust });
  };

  selectCust = (da) => {
    let { cust, data, errors, isEmailRequired, isMobRequired } = this.state;
    cust.selectedCust = da;
    // let data = { ...this.state.data };
    data["custName"] = da.fullName;
    data["custEmail"] = da.email;
    data["custMobile"] =
      da.mobileNo.length > 8 ? da.mobileNo.slice(3) : da.mobileNo;
    data["customerRefId"] = da.customerRefId;
    var emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!da.email.match(emailValidator) && isEmailRequired) {
      errors["custEmail"] = "Please enter valid email id";
    }

    if (data["custMobile"].length !== 8 && isMobRequired) {
      errors["custMobile"] = "Please enter 8 digit mobile number";
    }
    let disableCustRefId = false;
    if (da.customerRefId) {
      disableCustRefId = true;
    } else {
      data["customerRefId"] = "";
    }
    let enableMobile = true,
      enableEmail = true;

    this.setState(
      {
        disableCustRefId,
        cust,
        data,
        errors,
        enableEmail,
        enableMobile,
        isModalOpen: !this.state.isModalOpen,
      },
      this.validate
    );
  };

  handleUploadClick = (e) => {
    this.fileInput.click();
  };

  handleExcelUpload = () => {
    this.excelInput.click();
  };

  handleExcelSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      this.uploadFile.content = file;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleExcelReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  handleAddTag = (e) => {
    const val = e.target.value;
    let tagValue = this.state.tagValue;

    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }
    if ((e.keyCode === 188 || e.key === "Tab" || e.type === "blur") && val) {
      if (
        this.state.tagValue.tags.find(
          (tag: any) => tag.toLowerCase() === val.toLowerCase()
        )
      ) {
        return;
      }
      tagValue.input = "";
      tagValue.tags = [...this.state.tagValue.tags, val];
      this.setState({ tagValue });
    } else if (e.key === "Backspace" && !val) {
      this.handleRemoveTag(tagValue.tags.length - 1);
    } else {
      tagValue.input = e.target.value;
      this.setState({ tagValue });
    }
    // console.log(this.state.errors);
  };
  handleTagInputChange = (e) => {
    let { tagValue } = this.state;
    tagValue.input = e.target.value === "," ? "" : e.target.value;
    this.setState({ tagValue });
  };

  handleRemoveTag = (i) => {
    const newTags = [...this.state.tagValue.tags];
    newTags.splice(i, 1);
    let tagValue = this.state.tagValue;
    tagValue.tags = newTags;
    this.setState({ tagValue });
  };

  async _handleExcelReaderLoaded(readerEvt) {
    const fd = new FormData();

    fd.append("formFile", this.uploadFile.content, this.uploadFile.fileName);

    const { data: res } = await commonService.excelUpload(
      fd,
      window.location.host + "/payment?invid="
    );
    if (!res.didError) {
      toast.success(res.message);
      this.props.history.push("/app/inv-list");
      this.excelInput = null;
    }
  }

  handleFileSelect = (evt) => {
    let files = evt.target.files;
    let file = files[0];
    if (files && file) {
      this.uploadFile.fileName = file.name;
      let fileExt = file.name.split(".").pop();
      this.uploadFile.extension = fileExt;
      let reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };
  async _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.uploadFile.content = btoa(binaryString);
    const { data: res } = await commonService.fileUpload(this.uploadFile);
    if (!res.didError) {
      let upld = this.state.uploads;
      upld.push({
        file: res.message,
        name: this.uploadFile.fileName,
      });
      this.setState({ uploads: upld });
    } else {
      toast.error("Something problem with uploading image");
    }
  }

  handleDeleteUpload = (val) => {
    let uploads = this.state.uploads;
    uploads = uploads.filter((data) => data !== val);
    this.setState({ uploads });
  };

  addNewCust = () => {
    let cust = { ...this.state.cust };
    cust.selectedCust = {};
    let data = { ...this.state.data };
    data["custName"] = this.state.cust.custSearch;
    data["custEmail"] = "";
    data["custMobile"] = "";
    let enableMobile = true,
      enableEmail = true;
    this.setState({
      cust,
      data,
      enableEmail,
      enableMobile,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleLang = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data["invoiceLang"] = e.value;
    this.setState({ data });
  };

  handleLocChange = ({ currentTarget: e }) => {
    const data = { ...this.state.data };
    data[e.name] = e.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e);
    if (errorMessage) errors[e.name] = errorMessage;
    else delete errors[e.name];

    // if()

    this.setState({ data, errors });
  };

  handleInvOption = ({ currentTarget: e }) => {
    let { data } = this.state;
    data[e.name] = e.value;

    // let scms = this.schema;

    // scms["custMobile"] = Joi.number().allow("", null);
    // scms["custEmail"] = Joi.string()
    //   .email()
    //   .allow("", null);

    let isMobRequired = false,
      isEmailRequired = false,
      enableEmail = true,
      enableMobile = true;

    if (e.value === "SMS") {
      isMobRequired = true;
    } else if (e.value === "EMAIL") {
      isEmailRequired = true;
    } else if (e.value === "SMS & EMAIL") {
      isMobRequired = true;
      isEmailRequired = true;
    }

    this.setState({
      data,
      isMobRequired,
      isEmailRequired,
      enableEmail,
      enableMobile,
    });
  };
  handleInvOptionOLD = ({ currentTarget: e }) => {
    let data = { ...this.state.data };
    data[e.name] = e.value;

    let scms = this.schema;

    scms["custMobile"] = Joi.number().allow("", null);
    scms["custEmail"] = Joi.string().email().allow("", null);

    let isMobRequired = false,
      isEmailRequired = false,
      enableEmail = true,
      enableMobile = true;

    if (e.value === "SMS") {
      isMobRequired = true;
      scms["custMobile"] = Joi.number().required();
    } else if (e.value === "SMS & EMAIL") {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string().email().required();
    } else if (e.value === "EMAIL") {
      isEmailRequired = true;
      scms["custEmail"] = Joi.string().email().required();
    } else {
      isMobRequired = true;
      isEmailRequired = true;
      scms["custMobile"] = Joi.number().required();
      scms["custEmail"] = Joi.string().email().required();
    }

    this.schema = scms;
    this.validate();
    this.setState({
      data,
      isMobRequired,
      isEmailRequired,
      enableEmail,
      enableMobile,
    });
  };

  render() {
    const {
      isModalOpen,
      cust,
      enableEmail,
      data,
      isMobRequired,
      isEmailRequired,
      errors,
      uploads,
      disableCustRefId,
      tagValue,
      isSearchExtDB,
    } = this.state;
    return (
      <React.Fragment>
        <TitleBarComp title="Create Quick Invoice" />

        <input
          className="d-none"
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept="image/*,.pdf"
          onChange={this.handleFileSelect}
        ></input>

        <input
          className="d-none"
          ref={(excelInput) => (this.excelInput = excelInput)}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={this.handleExcelSelect}
        ></input>
        <ModalComp
          title="Choose Customer"
          isOpen={isModalOpen}
          hideModal={this.handlePopup}
        >
          <div className="modal-content p-2">
            <div className="row">
              <div className="col-10">
                <input
                  type="text"
                  name="custSearch"
                  placeholder="Search by Name, Mobile or Customer ref"
                  value={this.state.cust.custSearch}
                  onChange={this.handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="col-2">
                <Checkbox
                  checked={isSearchExtDB}
                  onChange={(val) => {
                    this.setState({ isSearchExtDB: !isSearchExtDB }, () =>
                      this.getCustList(this.state.cust.custSearch)
                    );
                    // this.getCustList(this.state.cust.custSearch);
                  }}
                  name="addingInvoice"
                />
              </div>
            </div>

            <div
              style={{
                maxHeight: "calc(100vh - 300px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                {cust.list.map((da: any, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className="p-2 border my-2 bg-light cursor-pointer"
                        onClick={() => this.selectCust(da)}
                      >
                        <div>
                          {da.customerRefId ? (
                            <span>{da.customerRefId} | </span>
                          ) : (
                            ""
                          )}{" "}
                          {da.fullName} | {da.mobileNo} | {da.email}
                          <span className="float-right badge badge-danger my-auto">
                            Select
                          </span>
                          {/* <Check
                            className="text-success float-right cursor-pointer"
                            onClick={() => this.selectCust(da)}
                          ></Check> */}
                        </div>
                      </div>
                    </li>
                  );
                })}

                {cust.list.length === 0 && (
                  <React.Fragment>
                    {/* <li className="text-center mt-4"> Not found </li> */}
                    {cust.custSearch && (
                      <li className="my-2">
                        <div className="text-right">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.addNewCust}
                          >
                            Add as new customer
                          </button>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </ModalComp>
        <MainContentComp>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Name <span>*</span>
                  </label>
                  <div
                    className="rounded border bg-light text-dark p-1"
                    onClick={this.handlePopup}
                  >
                    {data["custName"] !== ""
                      ? data["custName"]
                      : "Choose Customer"}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>Send Inovice Option</label>
                  <select
                    className="input-style"
                    value={this.state.data["invoiceOption"]}
                    name="invoiceOption"
                    onChange={this.handleChange && this.handleInvOption}
                  >
                    {this.invOption.map((inv, idx) => {
                      return (
                        <option key={idx} value={inv.id}>
                          {inv.dName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Mobile{" "}
                    {isMobRequired && <span className="text-danger"> *</span>}
                  </label>

                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td style={{ width: "35%" }}>
                          <select
                            className="input-style"
                            value={this.state.data["mobileCode"]}
                            // disabled={!enableMobile}
                            name="mobileCode"
                            onChange={this.handleChange}
                          >
                            <option value="965">+965</option>
                            <option value="966">+966</option>
                            <option value="973">+973</option>
                            <option value="971">+971</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="custMobile"
                            value={this.state.data["custMobile"]}
                            // disabled={!enableMobile}
                            onChange={this.handleChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Customer Email{" "}
                    {isEmailRequired && <span className="text-danger"> *</span>}
                  </label>
                  <input
                    type="text"
                    name="custEmail"
                    value={this.state.data["custEmail"]}
                    disabled={!enableEmail}
                    onChange={this.handleChange}
                  />
                  {errors["custEmail"] && (
                    <div className="small text-danger">
                      {errors["custEmail"]}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3 col-sm-4">
                {this.renderInput(
                  "customerRefId",
                  "Customer Reference",
                  false,
                  disableCustRefId
                )}
              </div>

              <div className="col-md-3 col-sm-4">
                <div className="input-div md-value">
                  <label>
                    Invoice Value <span>*</span>
                  </label>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td style={{ width: "120px" }}>
                          <select
                            className="input-style"
                            name="invoiceAmtValue"
                            value={this.state.data["invoiceAmtValue"]}
                            onChange={this.handleChange}
                          >
                            <option value="KWD">Kuwait (KD)</option>
                            {/* <option value="SAR">Saudi Arabia (SR)</option>
                            <option value="KWD">Bahrain (BD)</option> */}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="invoiceAmt"
                            value={this.state.data["invoiceAmt"]}
                            onChange={this.handleChange}
                          />
                          {errors["invoiceAmt"] && (
                            <div className="small text-danger">
                              {errors["invoiceAmt"]}
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-4">
                {this.renderInput(
                  "invoiceLCVAmt",
                  "Invoice Local Currency Value",
                  true,
                  false,
                  "number"
                )}
              </div> */}
              <div className="col-md-3 col-sm-4">
                <label>Language</label>
                <div>
                  <div className="radio">
                    <input
                      id="radio-2"
                      name="radio"
                      type="radio"
                      value="ENG"
                      checked={this.state.data["invoiceLang"] === "ENG"}
                      onChange={(e) => this.handleLang(e)}
                    />
                    <label htmlFor="radio-2" className="radio-label">
                      English
                    </label>
                  </div>

                  <div className="radio">
                    <input
                      id="radio-1"
                      name="radio"
                      type="radio"
                      value="AR"
                      checked={this.state.data["invoiceLang"] === "AR"}
                      onChange={(e) => this.handleLang(e)}
                    />
                    <label htmlFor="radio-1" className="radio-label">
                      Arabic
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-8">
                {/* {this.renderInput("customerRef", "Invoice Ref")} */}

                <div className="form-group">
                  <label>
                    Invoice Ref{" "}
                    <small className="text-secondary mx-1">
                      Add more ref by entering comma(,)
                    </small>{" "}
                  </label>

                  <TagInput
                    addTag={this.handleAddTag}
                    removeTag={this.handleRemoveTag}
                    input={tagValue.input}
                    tagss={tagValue.tags}
                    inputChange={this.handleTagInputChange}
                  ></TagInput>
                </div>
              </div>
              <div className="col-md-5 col-sm-8 col-12 mt-4">
                <span
                  className="btn btn-light text-secondary"
                  style={{ border: "1px dashed #898989" }}
                  onClick={this.handleUploadClick}
                >
                  <span>File Upload</span> <Upload size={22} />
                </span>

                <div>
                  {uploads.map((val, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-light border rounded text-secondary p-1 my-2"
                      >
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td className="w-75">
                                <a
                                  href={globalService.IMG_ROOT_INV + val.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {val.name}
                                </a>
                              </td>
                              <td className="text-right">
                                {/* <Eye
                                  className="text-secondary mx-2"
                                  size={22}
                                /> */}
                                <X
                                  className="text-danger mx-2"
                                  onClick={() => this.handleDeleteUpload(val)}
                                  size={22}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <br />
            <div>
              {/* <Link className="deactive-btn" to="/app/inv-list">
                Back to list
              </Link> */}
              <Link to="/app/inv-list" className="deactive-btn">
                Back to list
              </Link>

              <AuthContainer action="ADD">
                {this.renderButton("Create")}
              </AuthContainer>
            </div>
            <br />
            <br />

            {/* <div className="text-center text-secondary">
              <hr className="w-75" />
              <h5>OR</h5>
              <hr className="w-75" />
            </div> */}
          </form>
          <div className="text-center position-relative d-none">
            <span
              className="btn btn-light text-secondary"
              style={{ border: "1px dashed #898989" }}
              onClick={this.handleExcelUpload}
            >
              <span>Bulk Upload</span> <Upload size={22} />
            </span>

            <a
              style={{ position: "absolute", right: 5, top: 5 }}
              href={globalService.BASE_ROOT + "logs/ImportInvoice.xlsx"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Import Format
            </a>
          </div>

          <div>
            {/* <TagInput
              addTag={this.handleAddTag}
              removeTag={this.handleRemoveTag}
              input={tagValue.input}
              tagss={tagValue.tags}
              inputChange={this.handleTagInputChange}
            ></TagInput> */}
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }
}

export default CreateQuickInvoice;
