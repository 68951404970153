import * as React from "react";
import { connect } from "react-redux";
import GenericForm from "../../components/genericFormComponents/GenericForm";
import MainContentComp from "../../components/MainContent";
import TitleBarComp from "../../components/TitleBar";
import GFInput from "./../../components/genericFormComponents/GFInput";
import { actionCreators } from "./../../stores/companyStore";
import { ApplicationState } from "./../../stores/index";

export interface VendorChargeState {}

class VendorCharge extends GenericForm {
  state = {
    data: {},
    errors: {},
    ChargeList: [],
    isConfirmModalOpen: false,
  };

  componentDidMount() {
    this.getChargeList();
  }

  async getChargeList() {
    await this.props.getChargeList();
    let ChargeList = this.props.company.chargeList;

    ChargeList.forEach((data) => {
      data["isEdit"] = false;
    });
    this.setState({ ChargeList });
  }

  handleConfirmModel = (role) => {
    this.setState({ data: role });
    this.toggleConfirmModal();
  };

  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  handleChange = ({ currentTarget: e }) => {
    let ChargeList = this.state.ChargeList;

    ChargeList.forEach((data: any) => {
      if (e.name === data.vendorChargeTypeId) {
        data.vendorChargeType = e.value;
      }
    });
    this.setState({ ChargeList });
  };

  handleEdit = (val) => {
    let ChargeList = this.state.ChargeList;

    ChargeList.forEach((data: any) => {
      if (val.vendorChargeTypeId === data.vendorChargeTypeId) {
        data["isEdit"] = true;
      }
    });
    this.setState({ ChargeList });
  };

  handleUpdate = async (val) => {
    await this.props.updateChargeType(val);
    debugger;
    if (this.props.company.isRespSuccess) {
      let ChargeList = this.state.ChargeList;
      ChargeList.forEach((data: any) => {
        if (val.vendorChargeTypeId === data.vendorChargeTypeId) {
          data["isEdit"] = false;
        }
      });
      this.setState({ ChargeList });
    }
  };

  render() {
    const ChargeList = this.state.ChargeList;
    return (
      <React.Fragment>
        <TitleBarComp title="Vendor Charge Type" />

        <MainContentComp>
          <div className="row my-4 mx-1">
            {ChargeList.map((val: any, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="col-6 mt-2 p-2">{this.renderList(val)}</div>
                </React.Fragment>
              );
            })}
          </div>
        </MainContentComp>
      </React.Fragment>
    );
  }

  renderList(val) {
    return (
      <div className="row mx-0 rounded shadow-sm">
        <div className="col-8 my-auto">
          {val.isEdit && (
            <GFInput
              name={val.vendorChargeTypeId}
              label="Charge Type Name"
              value={val.vendorChargeType}
              isRequired={true}
              onChange={this.handleChange}
              type="text"
              error=""
              placeholder=""
              disabled={false}
            ></GFInput>
          )}
          {val.isEdit === false && (
            <div className="h6 mb-0">{val.vendorChargeType}</div>
          )}
        </div>
        <div className="col-4 my-auto text-right py-2">
          {val.isEdit && (
            <button
              className="btn btn-sm btn-danger"
              onClick={() => this.handleUpdate(val)}
            >
              Update
            </button>
          )}
          {!val.isEdit && (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => this.handleEdit(val)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    company: state.company,
  }),
  actionCreators
)(VendorCharge);
